import React, {useState} from 'react'
import {useFrameContext} from '../../contexts/FrameContext'
import RegistrationTermsOfUse from './RegistrationTermsOfUse'
import {trans} from '../../utilities/Helpers'

const RegistrationPageFooter = () => {
  const {
    creditAppConfig: {privacy_policy_url: privacyPolicyUrl},
    dealership: {
      attributes: {name: dealershipName},
    },
  } = useFrameContext()
  const [showTermsOfUse, setShowTermsOfUse] = useState(false)

  return (
    <div className="tw-text-legalese tw-text-brand-body_text--muted">
      <p className="tw-my-2 tw-mx-6 tw-font-medium">
        <span className="required-asterisk">*</span> {trans('required_field')}
      </p>
      <div id="footer" role="contentinfo">
        <p className="tw-my-2 tw-mx-6 tw-font-normal">
          By submitting this form, I understand that I may be contacted by {dealershipName} and I
          agree to the{' '}
          <a
            className="link tw-font-semibold"
            href="#"
            target="_self"
            onClick={(event) => {
              event.preventDefault()
              setShowTermsOfUse(true)
            }}
          >
            {trans('terms_of_use')}
          </a>
          <RegistrationTermsOfUse open={showTermsOfUse} onClose={() => setShowTermsOfUse(false)} />{' '}
          and{' '}
          <a
            className="link tw-font-semibold"
            href={privacyPolicyUrl}
            target="_blank"
            rel="noreferrer"
          >
            {trans('privacy_policy')}
          </a>
          .
        </p>
      </div>
    </div>
  )
}
export default RegistrationPageFooter

import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {numberToUSD} from '../../../utilities/DataReformatters'
import {getRebateValue} from '../../../utilities/RebateHelpers'

const AutoSelectedRebateCard = ({rebate, selectedDealType, programTerm}) => {
  const {name, expires} = rebate

  return (
    <div className="mb-2 border tw-rounded-lg tw-p-2">
      <div className="tw-grid tw-grid-cols-12 tw-gap-y-3">
        <div className="tw-col-span-1 tw-justify-self-center tw-mr-2">
          <FontAwesomeIcon icon={faCheck} className="tw-text-brand-body_text--primary fa-sm" />
        </div>
        <div
          className="tw-col-span-10 tw-col-start-2 tw-col-end-10 sm:tw-col-end-9 tw-text-sm tw-self-center tw-ml-1 tw-mr-1 md:tw-ml-0 md:tw-mr-0 tw-font-bold"
          data-testid="name"
        >
          {name}
        </div>
        <div
          className="tw-text-sm tw-col-start-12 tw-justify-self-end tw-col-span-1 tw-font-bold"
          data-testid="rebate"
        >
          <span>&ndash;{numberToUSD(getRebateValue(rebate, selectedDealType, programTerm))}</span>
        </div>
        <div
          className="tw-ml-1 tw-mr-1 md:tw-ml-0 md:tw-mr-0 tw-text-xs tw-font-bold tw-col-span-6 tw-col-start-2"
          data-testid="expires"
        >
          Expires {expires}
        </div>
      </div>
    </div>
  )
}

export default AutoSelectedRebateCard

AutoSelectedRebateCard.propTypes = {
  selectedDealType: PropTypes.string,
  programTerm: PropTypes.number,
  rebate: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    expires: PropTypes.string,
    description: PropTypes.string,
    terms: PropTypes.arrayOf(
      PropTypes.shape({
        dealType: PropTypes.string,
        value: PropTypes.number,
        start: PropTypes.number,
        end: PropTypes.number,
      })
    ),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        dealType: PropTypes.string,
        value: PropTypes.number,
      })
    ),
  }),
}

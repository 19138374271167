import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faQuestion} from '@fortawesome/free-solid-svg-icons'
import PopoverHover from 'Src/components/Popovers/PopoverHover'
import {trans} from '../../../utilities/Helpers'

const TermsTooltip = () => {
  const tooltipContent = (
    <p role="tooltip" id="terms-tooltip">
      {trans('what_term_length_tooltip')}
    </p>
  )

  return (
    <PopoverHover content={tooltipContent} data-testid="terms-tooltip">
      <FontAwesomeIcon
        icon={faQuestion}
        className="icon-question-circle tw-ml-1.5"
        data-testid="icon"
      />
    </PopoverHover>
  )
}

export default TermsTooltip

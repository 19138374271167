import React from 'react'
import {ErrorMessage, useFormikContext} from 'formik'
import Accordion from 'Src/components/CreditApp/Utilities/Accordion'
import {useFrameContext} from 'Src/contexts/FrameContext'

const PrivacyPolicy = () => {
  const {
    values: {coBuyerPresent},
    setFieldValue,
    errors,
    isSubmitting,
  } = useFormikContext()
  const {
    creditAppConfig: {privacy_policy_url: privacyPolicyUrl},
  } = useFrameContext()
  const pronoun = coBuyerPresent ? 'We' : 'I'
  const hasErrors = !!(isSubmitting && errors?.privacyPolicyChecked)

  return (
    <Accordion title="Our Commitment to Privacy" id="privacy" hasErrors={hasErrors}>
      <div className="tw-mb-4">
        Your dealer will inform you of the names and addresses of the financing institutions to
        which this credit application will be sent.
      </div>
      <label
        htmlFor="privacy-check-acknowledge"
        className="tw-inline-flex tw-flex-row tw-gap-2 tw-mb-4 tw-items-start"
      >
        <div className="tw-px-1 tw-cursor-pointer">
          <input
            type="checkbox"
            id="privacy-check-acknowledge"
            name="privacyPolicyChecked"
            className="tw-rounded tw-cursor-pointer"
            onChange={({target}) => setFieldValue('privacyPolicyChecked', target.checked)}
          />
        </div>
        <div>
          {pronoun} acknowledge that {pronoun} have read and agreed to the&nbsp;
          <a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer" className="link">
            Privacy Policy
          </a>
          .
        </div>
      </label>
      <div>
        <ErrorMessage
          name="privacyPolicyChecked"
          render={(error) => <span className="error">{error}</span>}
        />
      </div>
    </Accordion>
  )
}

export default PrivacyPolicy

import React from 'react'
import classNames from 'classnames'
import Spinner from './Spinner'
import {transString} from 'Src/utilities/Helpers'

export const PlaceholderSpinner = ({className = ''} = {}) => (
  <div className={classNames('tw-flex tw-flex-col tw-items-center', className)}>
    <div className="tw-mt-40 tw-text-center">
      <Spinner
        border="tw-border-[0.5rem]"
        width="tw-w-[3.75rem]"
        className="tw-text-brand-icon_loading"
        aria-label={transString('loading')}
      />
    </div>
  </div>
)

export default PlaceholderSpinner

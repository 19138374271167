import React, {useState} from 'react'
import classNames from 'classnames'
import {useHistory} from 'react-router'
import InlineButtonSpinner from 'Src/components/Spinners/InlineButtonSpinner'
import CtaButton from 'Src/components/Buttons/CtaButton'
import {trans} from 'Src/utilities/Helpers'
import Modal from 'Src/components/Modal'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useDeleteTrade} from 'Src/services/useDeleteTrade'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'

const RemoveTradeModal = () => {
  const [shouldShowModal, setShouldShowModal] = useState(false)
  const [saving, setSaving] = useState(false)
  const {setMessages} = useFrameContext()
  const history = useHistory()
  const {trackEvent, events} = useAnalytics()

  const deleteTrade = useDeleteTrade()

  const handleRemoveTradeButtonClick = () => {
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.TRADE.REMOVE)
    setShouldShowModal(true)
  }

  const handleYesButtonClick = async () => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.TRADE.REMOVE_CONFIRMATION.YES
    )
    setSaving(true)
    await deleteTrade()
    setShouldShowModal(false)
    setMessages((prevState) => [
      ...prevState,
      {
        title: trans('Trade Removed'),
        description: trans('remove_trade_success'),
      },
    ])
    setSaving(false)
    history.push('/trade')
  }

  const handleNoButtonClick = () => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.TRADE.REMOVE_CONFIRMATION.NO
    )
    setShouldShowModal(false)
  }

  return (
    <>
      <button
        type="button"
        onClick={handleRemoveTradeButtonClick}
        className="link tw-font-bold tw-text-sm"
      >
        {trans('Remove Trade')}
      </button>
      <Modal
        preset="danger"
        scrollable={false}
        open={shouldShowModal}
        onClose={() => setShouldShowModal(false)}
        title={trans('Remove Trade')}
        registration
      >
        <div className="tw-mx-6">{trans('remove_trade_confirmation')}</div>
        <div className="divider tw-my-6" />
        <div className="tw-flex tw-justify-end tw-gap-x-4 tw-mx-6">
          <CtaButton
            preset="dangerPrimary"
            onClick={handleYesButtonClick}
            disabled={saving}
            data-testid="yes-button"
          >
            {saving ? <InlineButtonSpinner /> : null}
            <div className={classNames({'tw-h-0 tw-overflow-hidden': saving})}>{trans('Yes')}</div>
          </CtaButton>
          <CtaButton
            preset="dangerSecondary"
            onClick={handleNoButtonClick}
            disabled={saving}
            data-testid="no-button"
          >
            {trans('No')}
          </CtaButton>
        </div>
      </Modal>
    </>
  )
}

export default RemoveTradeModal

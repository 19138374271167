import React, {useEffect, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretRight, faCaretDown} from '@fortawesome/free-solid-svg-icons'
import {useDealStateContext} from '../../../contexts/DealContext'
import {useProductContext} from '../../../contexts/ProductContext'
import {trans} from '../../../utilities/Helpers'
import {renderTerm} from '../../../utilities/ProductHelpers'
import {numberToUSD} from '../../../utilities/DataReformatters'

const AdditionalDisclosures = () => {
  const [hasSelectedProducts, setHasSelectedProducts] = useState(false)
  const [showDisclosure, setShowDisclosure] = useState(false)
  const handleClick = (event) => {
    event.preventDefault()
    setShowDisclosure(!showDisclosure)
  }
  const {dealType, data} = useDealStateContext()
  const {
    dealTypes: {
      [dealType]: {disclosure},
    },
  } = data?.attributes

  const {selectedProducts, getSelectedRatedProducts} = useProductContext()

  useEffect(() => {
    setHasSelectedProducts(getSelectedRatedProducts(dealType)?.length > 0)
  }, [selectedProducts[dealType].size])

  return (
    <div className="tw-px-3 tw-mb-3">
      <a
        href="#"
        tabIndex="0"
        onClick={handleClick}
        className="link tw-text-lg tw-flex tw-mb-3"
        data-testid="show-disclosure-button"
      >
        <FontAwesomeIcon
          className={showDisclosure ? 'tw-hidden' : 'tw-mr-3 tw-mt-1'}
          icon={faCaretRight}
        />
        <FontAwesomeIcon
          className={!showDisclosure ? 'tw-hidden' : 'tw-mr-2 tw-mt-1'}
          icon={faCaretDown}
        />
        <p>{trans('Additional Disclosures')}</p>
      </a>
      {showDisclosure ? (
        <div
          className="tw-h-40 scroll tw-overflow-scroll tw-pr-2"
          data-testid="disclosure-wrapper"
          tabIndex="0"
        >
          <p className="tw-mb-2 tw-text-brand-dark tw-text-sm" data-testid="disclosure-text">
            *{disclosure}
            {hasSelectedProducts && (
              <div>
                * {trans('Protection Plans')}:&nbsp;
                {getSelectedRatedProducts(dealType)
                  .map((product) => {
                    return `${product.name} ${renderTerm(
                      product.term,
                      product.mileage
                    )} ${numberToUSD(product.price)}`
                  })
                  .join(', ')}
                <div>Estimated taxes include estimated Protection Plan taxes.</div>
              </div>
            )}
          </p>
          <p className="tw-text-sm">
            The above figures are estimates based on the information you have provided to us and
            currently available discounts and incentives for well-qualified customers. Your taxes,
            fees, discounts, rebates, and APR may be different. Your trade-in value requires a prior
            dealer vehicle inspection. See Dealer for details and complete a credit application for
            financing.
          </p>
        </div>
      ) : null}
    </div>
  )
}

export default AdditionalDisclosures

import FieldInput from '../../../Form/Fields/FieldInput'
import HousingSelect from '../../../Form/Fields/SelectFields/HousingSelect'
import MaskedInput from '../../../Form/Fields/MaskedInput'
import PrimaryStateSelect from '../../../Form/Fields/SelectFields/PrimaryStateSelect'

const AdditionalResidencyFieldConfig = (residencyIndex: number) => ({
  key: residencyIndex,
  rows: [
    {
      row: 1,
      columns: [
        {
          column: 1,
          fields: [
            {
              name: 'address1',
              label: 'Address',
              required: true,
              component: FieldInput,
              autoComplete: 'address-line1',
            },
          ],
        },
      ],
    },
    {
      row: 2,
      columns: [
        {
          column: 1,
          fields: [
            {
              name: 'address2',
              label: 'Apt/Unit #',
              component: FieldInput,
              autoComplete: 'address-line2',
            },
          ],
        },
      ],
    },
    {
      row: 3,
      columns: [
        {
          column: 1,
          columnWidth: 'sm:tw-col-span-5',
          fields: [
            {
              name: 'city',
              label: 'City',
              required: true,
              component: FieldInput,
              autoComplete: 'address-level2',
            },
          ],
        },
        {
          column: 2,
          columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-5',
          fields: [
            {
              name: 'state',
              label: 'State',
              required: true,
              component: PrimaryStateSelect,
            },
          ],
        },
        {
          column: 3,
          columnWidth: 'sm:tw-col-span-3 lg:tw-col-span-2',
          fields: [
            {
              name: 'zipCode',
              label: 'ZIP Code',
              required: true,
              component: MaskedInput,
              mask: 'zipCode',
              autoComplete: 'postal-code',
            },
          ],
        },
      ],
    },
    {
      row: 4,
      columns: [
        {
          column: 1,
          columnWidth: 'sm:tw-col-span-5 lg:tw-col-span-4',
          fields: [
            {
              name: 'residenceType',
              label: 'Housing Status',
              required: true,
              component: HousingSelect,
            },
          ],
        },
        {
          column: 2,
          columnWidth: 'sm:tw-col-span-3 lg:tw-col-span-2',
          fields: [
            {
              name: 'housingDurationYears',
              label: 'How Long',
              postLabel: 'Years',
              required: true,
              component: FieldInput,
              type: 'number',
              min: 0,
              max: 110,
              className: 'tw-text-center xs:tw-px-1',
            },
          ],
        },
        {
          column: 3,
          columnWidth: 'sm:tw-col-span-3 lg:tw-col-span-2',
          fields: [
            {
              name: 'housingDurationMonths',
              label: 'displayNone',
              postLabel: 'Months',
              required: true,
              component: FieldInput,
              type: 'number',
              min: 0,
              max: 11,
              className: 'tw-text-center xs:tw-px-1',
            },
          ],
        },
      ],
    },
  ],
})

export default AdditionalResidencyFieldConfig

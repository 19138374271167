import {createContext, useContext} from 'react'
import initialCreditAppValues from './InitialValues/initialValues.json'

export const LendingCenterContext = createContext(null)

export const useLendingCenterContext = () => useContext(LendingCenterContext)

export const initialState = {
  modalInfo: {},
  states: [],
  customerVisitId: null,
  creditAppId: null,
  initialCreditAppValues,
  loading: true,
  isPulling: '',
  creditReportModal: false,
  riskBasedPricingModal: {
    show: false,
  },
}

export const reducer = (state, action) => {
  const updatedField = Object.keys(action)[0]

  if (updatedField === 'initialCreditAppValues') {
    return {
      ...state,
      initialCreditAppValues: {...state.initialCreditAppValues, ...action[updatedField]},
    }
  }

  return {...state, [updatedField]: action[updatedField]}
}

import React from 'react'
import {useFormikContext} from 'formik'

const DisplayFormikState = () => {
  const {values, errors} = useFormikContext()
  return (
    <div style={{margin: '1rem 0'}}>
      <h3 style={{fontFamily: 'monospace'}}>Form State</h3>
      <pre
        style={{
          background: '#f6f8fa',
          fontSize: '.65rem',
          padding: '.5rem',
        }}
      >
        <strong>Errors</strong> = {JSON.stringify(errors, '', 2)}
        <strong>Values</strong> = {JSON.stringify(values, '', 2)}
      </pre>
    </div>
  )
}

export default DisplayFormikState

import {toString} from 'lodash'
import {plurality} from './formatters'
import {trans} from './Helpers'
import {ACTION_DETAILS} from '../services/analytics/constants'

const getProductFieldValue = (product, field) => {
  const fieldValue =
    product?.product_fields?.find((productField) => {
      return productField.type === field
    })?.default_value || 0

  return parseInt(fieldValue, 10)
}

export const getProductRatesByProductId = (rates, productId) => {
  return rates?.filter((rate) => parseInt(productId, 10) === rate.product_id)
}

export const findMatchingRate = (rates, defaults) => {
  return rates
    ?.filter((rate) => {
      if (defaults.name && defaults.name !== rate.name) {
        return false
      }

      if (defaults.miles > 0 && defaults.miles !== rate.miles) {
        return false
      }

      if (defaults.term > 0 && defaults.term !== rate.term) {
        return false
      }

      if (
        parseFloat(defaults.deductible) !== parseFloat(rate.deductible) ||
        toString(defaults.deductible).includes('Disappearing') !==
          toString(rate.deductible).includes('Disappearing')
      ) {
        return false
      }

      return true
    })
    .sort((a, b) => {
      return parseFloat(a.retail_price) - parseFloat(b.retail_price)
    })[0]
}

const getProductFilterFields = (fields) => {
  const filteredFieldTypes = fields
    .map((field) => (field.type === 'Mileage' ? 'miles' : field.type.toLowerCase()))
    .filter((field) => field !== 'price')

  return ['name', ...filteredFieldTypes]
}

export const ProductFilterTypes = {
  mileage: 'Mileage',
  term: 'Term',
  deductible: 'Deductible',
}

export const getDefaultProductFilter = (product) => ({
  miles: getProductFieldValue(product, ProductFilterTypes.mileage),
  term: getProductFieldValue(product, ProductFilterTypes.term),
  deductible: getProductFieldValue(product, ProductFilterTypes.deductible),
})

export const applyRatesFromProducts = (products, rates, filters = new Map()) => {
  return products.map((product) => {
    const productRates = getProductRatesByProductId(rates, product.id)
    const filter = filters.get(product.id) ?? getDefaultProductFilter(product)
    const singleRate = findMatchingRate(productRates, filter)

    if (singleRate) {
      return {
        deductible: singleRate.deductible,
        isRated: true,
        fields: getProductFilterFields(product.product_fields),
        miles: singleRate.miles || 0,
        name: product.name,
        payment: singleRate.payment,
        price: parseFloat(singleRate.retail_price),
        productId: product.id,
        rate: singleRate,
        term: singleRate.term || 0,
      }
    }

    return {
      isRated: true,
      name: product.name,
      productId: product.id,
      rate: false,
    }
  })
}

const mapProductFilterParams = (product) => {
  return {
    mileage: parseInt(
      product.product_fields.find((field) => field.type === 'Mileage')?.default_value || 0,
      10
    ),
    term: parseInt(
      product.product_fields.find((field) => field.type === 'Term')?.default_value || 0,
      10
    ),
  }
}

const mapBatchProductParams = (products) => {
  return products?.map((product) => {
    return {
      id: product.id,
      filters: mapProductFilterParams(product),
    }
  })
}

export const mapBatchProductRateParams = (
  batchToCancel,
  currentDealState,
  deskingData,
  hash,
  products
) => {
  return {
    amount_financed: currentDealState.amountFinanced || 0,
    batch_to_cancel: batchToCancel,
    customer: {id: currentDealState.customerId},
    deal_type: currentDealState.dealType,
    desking_data: deskingData,
    hash,
    include_interest: true,
    miles_per_year: currentDealState.annualMileage || 0,
    products: mapBatchProductParams(products),
    program_id: currentDealState.programId,
    rate: currentDealState.rate || 0,
    term: currentDealState.term || 0,
    vin: currentDealState.vin,
  }
}

export const renderTerm = (termValue, mileage) => {
  if (!termValue) {
    return null
  }

  let term

  if (termValue % 12 !== 0) {
    term = `${termValue} ${trans('month')}${plurality(termValue)}`
  } else {
    const termYears = termValue / 12
    term = `${termYears} ${trans('year')}${plurality(termYears)}`
  }

  term += mileage ? `/${mileage / 1000}k ${trans('miles')}` : ''

  return term
}

export const trackProductConversionEvent = (
  customerId,
  events,
  selectedRatedProducts,
  trackEvent
) => {
  trackEvent(
    events.ENGAGEMENT,
    events.ENGAGEMENT.actions.CONVERSION,
    ACTION_DETAILS.PRODUCT.SELECTED_CATEGORIES,
    customerId,
    {
      selectedProductCategories: selectedRatedProducts.map((product) => product.category),
    }
  )
}

export const getSortedProductIds = (products) =>
  products.map((product) => product.product_id).sort()

import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDealDispatchContext, useDealStateContext} from '../../../contexts/DealContext'
import {resetDownPaymentState} from '../../../actions/DealActions'
import {useAnalytics} from '../../../services/analytics/useAnalytics'
import CtaButton from '../../Buttons/CtaButton'
import {CSS_SECONDARY_BUTTON_GROUP} from '../constants'

const LeaseTerms = ({terms}) => {
  const {
    data,
    leaseTerm,
    selectedLeaseMileageOption,
    leaseDownPayment,
    isUpdating,
  } = useDealStateContext()
  const dealDispatch = useDealDispatchContext()
  const {trackEvent, events} = useAnalytics()
  const [leaseTermChangeCount, setLeaseTermChangeCount] = useState(0)
  const trackLeaseChangeEvent = (selectedTerm) => {
    const changeCount = leaseTermChangeCount + 1
    setLeaseTermChangeCount(changeCount)
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CHANGE, `term ${selectedTerm}`, null, {
      leaseTermChangeCount: changeCount,
    })
  }

  const handleChange = (selectedTerm) => {
    trackLeaseChangeEvent(selectedTerm)
    const program =
      data.attributes?.dealTypes?.lease?.programs?.mileageGroup?.[selectedLeaseMileageOption]
        ?.termsGroup?.[selectedTerm]?.downPaymentGroup?.[leaseDownPayment]

    if (!program) {
      dealDispatch({type: 'update', payload: {displayNoProgramFoundMessage: true}})

      return
    }

    resetDownPaymentState(dealDispatch)
    dealDispatch({type: 'update', payload: {leaseTerm: selectedTerm}})
  }

  return (
    <div className="mt-3">
      <div className="tw-grid tw-gap-y-3 tw-gap-x-6 md:tw-grid-cols-3">
        {Object.keys(terms).map((term) => {
          const isActive = leaseTerm === term

          return (
            <CtaButton
              key={term}
              preset={isActive ? 'secondaryActive' : 'secondaryInactive'}
              className={CSS_SECONDARY_BUTTON_GROUP}
              onClick={() => handleChange(term)}
              disabled={isUpdating}
              aria-pressed={isActive}
              data-testid="term-button"
            >
              {term} months
            </CtaButton>
          )
        })}
      </div>
    </div>
  )
}

LeaseTerms.propTypes = {
  terms: PropTypes.shape({}),
}

export default LeaseTerms

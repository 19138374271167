import {retrieveDealData} from '../actions/DealActions'
import {clearTradeValues} from '../actions/TradeActions'
import {useCustomerDispatchContext, useCustomerStateContext} from '../contexts/CustomerContext'
import {useDealDispatchContext, useDealStateContext} from '../contexts/DealContext'
import {useFrameContext} from '../contexts/FrameContext'
import {useProgressDispatchContext} from '../contexts/ProgressContext'
import {useTradeContext} from '../contexts/TradeContext'
import {useProductContext} from '../contexts/ProductContext'

export const useDeleteTrade = (resetDealDefaults = false) => {
  const tradeContext = useTradeContext()
  const progressDispatch = useProgressDispatchContext()
  const dealState = useDealStateContext()
  const dealDispatch = useDealDispatchContext()
  const customerState = useCustomerStateContext()
  const {trade} = customerState
  const {
    vin,
    dealership,
    useCache,
    a2zCustomerApi,
    a2zApi,
    isCustomerApiReady,
    isApiReady,
  } = useFrameContext()
  const customerDispatch = useCustomerDispatchContext()
  const {getSelectedRatedProducts} = useProductContext()

  return async () => {
    if (!isApiReady || !isCustomerApiReady) return
    progressDispatch({type: 'update', payload: {trade: 'started'}})
    await a2zCustomerApi.delete(`/customers/leads/vehicles/trades/${trade.id}`)

    clearTradeValues(tradeContext)
    customerDispatch({type: 'setTrade', payload: null})

    const requestParams = {
      products: {
        cash: getSelectedRatedProducts('cash') ?? [],
        finance: getSelectedRatedProducts('finance') ?? [],
        lease: getSelectedRatedProducts('lease') ?? [],
      },
    }

    dealDispatch({type: 'update', requestParams})

    await retrieveDealData(
      {dealershipId: dealership.id, requestParams, useCache, vin},
      dealDispatch,
      a2zApi,
      dealState,
      {
        customerData: customerState.customerData,
      },
      resetDealDefaults
    )
    dealDispatch({type: 'update', payload: {noProgramsCause: null}})
  }
}

import React, {useState, useRef, useEffect} from 'react'
import {PropTypes} from 'prop-types'
import SignatureCanvas from 'react-signature-canvas'
import {useFormikContext, ErrorMessage} from 'formik'
import {trans} from '../../../utilities/Helpers'

const SignaturePad = ({name, label}) => {
  const [drawSignatureSelected, setDrawSignatureSelected] = useState(true)
  const [signatureData, setSignatureData] = useState('')
  const [canvasWidth, setCanvasWidth] = useState(750)
  const [canvasHeight, setCanvasHeight] = useState(125)
  const [canvasContainer, setCanvasContainer] = useState(null)
  const {setFieldValue, values} = useFormikContext()
  const signCanvas = useRef(null)
  const typeCanvas = useRef(null)
  const textInputRef = useRef(null)
  const signedFieldName = `${name}Signed`
  const signatureFieldName = `${name}Signature`
  const checkFieldName = `${name}Checked`

  const canvasStyle = {
    borderWidth: '1px',
    borderColor: '#d3d3d3',
    borderStyle: 'solid',
    borderRadius: '25px',
  }
  const maxCanvasWidth = 750
  const maxCanvasHeight = 125
  const xSigPosition = 20
  const maxFontSize = 50
  let fontSize = maxFontSize

  const renderTypedSignature = (value = '', width = canvasWidth, height = canvasHeight) => {
    value = value?.trim()

    const context = typeCanvas.current.getContext('2d')

    // clear canvas to prevent overlapping rendered text
    context.clearRect(0, 0, width, height)

    const getTextWidth = () => context.measureText(value).width
    const setFont = (size) => {
      context.font = `italic ${size}px cursive`
    }
    const maxTextWidth = width - xSigPosition * 2

    setFont(fontSize)

    if (!value) {
      // reset font size
      fontSize = maxFontSize
      textInputRef.current.value = ''
      setFieldValue(signedFieldName, false)
      setFieldValue(signatureFieldName, '')

      return
    }

    // shrink text on the fly
    while (getTextWidth() > maxTextWidth) {
      fontSize *= 0.95
      setFont(fontSize)
    }

    // enlarge text on the fly
    while (getTextWidth() < maxTextWidth * 0.95 && fontSize * 1.05 < maxFontSize) {
      fontSize *= 1.05
      setFont(fontSize)
    }

    context.textBaseline = 'middle'

    context.fillText(value, xSigPosition, height / 2)

    if (!values[signedFieldName]) {
      setFieldValue(signedFieldName, true)
    }

    setFieldValue(signatureFieldName, typeCanvas.current.toDataURL())
  }

  const resizeSignature = (width, height) => {
    if (!drawSignatureSelected) {
      renderTypedSignature(textInputRef?.current?.value, width, height)

      return
    }

    if (!signatureData) {
      return
    }

    signCanvas.current.fromDataURL(signatureData, {width, height})
  }

  const handleResize = () => {
    if (!canvasContainer) {
      return
    }

    const padding = window.getComputedStyle(canvasContainer).paddingLeft.replace('px', '')
    const containerWidth = canvasContainer.clientWidth - Number(padding) * 2

    if (containerWidth >= maxCanvasWidth) {
      setCanvasWidth(maxCanvasWidth)
      setCanvasHeight(maxCanvasHeight)
      resizeSignature(maxCanvasWidth, maxCanvasHeight)

      return
    }

    let height = Math.ceil(containerWidth / 6)
    height = height < 80 ? 80 : height

    setCanvasWidth(containerWidth)
    setCanvasHeight(height)

    resizeSignature(containerWidth, height)
  }

  const clearSignature = (event, signCanvas) => {
    event?.preventDefault()

    setSignatureData('')
    setFieldValue(signedFieldName, false)

    if (drawSignatureSelected) {
      signCanvas.current.clear()

      return
    }

    renderTypedSignature('')
    textInputRef.current.value = ''
  }

  useEffect(() => {
    if (!canvasContainer) {
      return
    }

    handleResize()
  }, [canvasContainer])

  useEffect(() => {
    if (!canvasContainer) {
      return undefined
    }

    window.addEventListener('resize', handleResize, true)

    return () => window.removeEventListener('resize', handleResize, true)
  }, [canvasContainer, signatureData, drawSignatureSelected])

  useEffect(() => {
    if (canvasContainer) {
      return
    }

    const container = document?.querySelector(`#${name}-canvas-container`)

    if (container) {
      setCanvasContainer(container)
    }
  })

  useEffect(() => {
    if (drawSignatureSelected) {
      textInputRef.current.value = ''

      return
    }

    textInputRef.current.focus()
  }, [drawSignatureSelected])

  useEffect(() => {
    setFieldValue(signatureFieldName, drawSignatureSelected ? signatureData : '')
  }, [drawSignatureSelected, signatureData])

  useEffect(() => {
    if (values[signedFieldName]) {
      return
    }

    clearSignature(null, signCanvas)
  }, [values[signedFieldName]])

  const toggleCanvas = ({value}) => {
    if (!!parseInt(value, 10) !== drawSignatureSelected) {
      setSignatureData('')
      setFieldValue(signedFieldName, false)
      setDrawSignatureSelected(!drawSignatureSelected)
    }
  }

  return (
    <div>
      <p className="tw-mb-2">
        <span className="required tw-font-semibold">{label} Signature</span>
      </p>
      <div className="tw-pl-2 tw-mb-2">
        <div
          className="tw-flex tw-flex-wrap tw-gap-x-4 tw-gap-y-2 tw-items-center"
          role="group"
          aria-labelledby={`${name}-type-in-label`}
        >
          <label className="tw-cursor-pointer tw-pl-2" htmlFor={`${name}-type-in`}>
            <input
              type="radio"
              className="tw-cursor-pointer"
              id={`${name}-type-in`}
              name={signedFieldName}
              value={0}
              onClick={({target}) => toggleCanvas(target)}
            />
            <span className="tw-pl-2" id={`${name}-type-in-label`}>
              Type Signature
            </span>
          </label>
          <input
            ref={textInputRef}
            className="tw-w-11/12 xs:tw-w-1/2 tw-rounded tw-px-4 tw-py-2"
            type="text"
            id={`${name}-type-signature`}
            name={`${name}-typed-signature`}
            disabled={drawSignatureSelected}
            onChange={({target: {value}}) => renderTypedSignature(value)}
          />
        </div>
        <div className="tw-flex tw-flex-wrap tw-gap-x-4 tw-gap-y-2 tw-items-center">
          <label className="tw-cursor-pointer tw-pl-2" htmlFor={`${name}-draw`}>
            <input
              type="radio"
              className="tw-cursor-pointer"
              id={`${name}-draw`}
              name={signedFieldName}
              defaultChecked
              value={1}
              onClick={({target}) => toggleCanvas(target)}
            />
            <span className="tw-pl-2">Draw Signature</span>
          </label>
          <div className="tw-pr-5 tw-ml-auto tw-mr-5">
            <button
              type="button"
              className="link tw-font-semibold tw-px-4"
              onClick={(event) => clearSignature(event, signCanvas)}
              aria-label="Clear Signature"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <div id={`${name}-canvas-container`} className="tw-px-2">
        {!drawSignatureSelected ? (
          <canvas
            ref={typeCanvas}
            id={`${name}-type-canvas`}
            style={canvasStyle}
            height={canvasHeight}
            width={canvasWidth}
          />
        ) : (
          <SignatureCanvas
            ref={signCanvas}
            onBegin={() => {
              if (!values[signedFieldName]) {
                setFieldValue(signedFieldName, true)
              }
            }}
            onEnd={() => {
              setSignatureData(signCanvas.current.toDataURL())
            }}
            canvasProps={{
              className: 'sig-canvas',
              id: `${name}-draw-canvas`,
              height: canvasHeight,
              width: canvasWidth,
              style: canvasStyle,
            }}
          />
        )}
      </div>
      <div className="tw-mb-4">
        <ErrorMessage
          name={signedFieldName}
          render={(error) => <span className="error">{error}</span>}
        />
      </div>
      <div>
        <label
          htmlFor={`${name}-check-acknowledge`}
          className="tw-inline-flex tw-flex-row tw-gap-2 tw-items-start tw-cursor-pointer"
        >
          <div className="tw-px-1">
            <input
              type="checkbox"
              id={`${name}-check-acknowledge`}
              name={checkFieldName}
              className="tw-border tw-rounded tw-border-blue-500 tw-cursor-pointer"
              checked={values[checkFieldName]}
              onChange={({target}) => setFieldValue(checkFieldName, target.checked)}
            />
          </div>
          <div>{trans('credit_app.acknowledgement')}</div>
        </label>
      </div>
      <div className="tw-mt-4">
        <ErrorMessage
          name={checkFieldName}
          render={(error) => <span className="error">{error}</span>}
        />
      </div>
    </div>
  )
}

export default SignaturePad

SignaturePad.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

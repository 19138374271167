import * as Yup from 'yup'

const regExp = {
  zipCode: /^\d{5}$/,
}

const schema = Yup.object().shape({
  zipCode: Yup.string()
    .required('ZIP code is required')
    .matches(regExp.zipCode, 'ZIP code is invalid.')
    .min(5, 'Please enter a five digit ZIP code.')
    .max(5, 'Please enter a five digit ZIP code.'),
})

export default schema

export const customSelectStyles = {
  pointerEvents: 'auto',
  indicatorsContainer: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled
      ? 'var(--tw-brand-dropdown--disabled_icon)'
      : 'var(--tw-brand-dropdown_icon)',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    height: '2.3rem',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: 'var(--tw-brand-dropdown_icon_text)',
    '&:hover': {
      color: 'var(--tw-brand-dropdown_icon_text)',
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  control: (styles, state) => ({
    ...styles,
    '--tw-ring-color': 'var(--tw-brand-dropdown--focused_border-color)',
    '--tw-ring-shadow':
      'var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)',
    transition: 'none',
    boxShadow: state.isFocused
      ? 'var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow)'
      : styles.boxShadow,
    backgroundColor: 'white',
    borderColor: state.isFocused
      ? 'var(--tw-brand-dropdown--focused_border-color) !important'
      : 'var(--tw-brand-dropdown_border-color)',
    '&:hover': {
      borderColor: 'var(--tw-brand-dropdown_border-color)',
    },
    height: '2rem',
    fontSize: '1rem',
    overflow: 'hidden',
    ...(state.isDisabled && {
      pointerEvents: 'auto',
      cursor: 'not-allowed',
    }),
  }),
  option: (styles, state) => {
    let {backgroundColor} = styles

    // Hover over an option that's already selected.
    if (state.isFocused && state.isSelected) {
      backgroundColor = 'var(--tw-brand-dropdown_option--focused-selected)'
    }
    // Hover
    else if (state.isFocused) {
      backgroundColor = 'var(--tw-brand-dropdown_option--focused)'
    }
    // Selected
    else if (state.isSelected) {
      backgroundColor = 'var(--tw-brand-dropdown_option--selected)'
    }

    return {
      ...styles,
      backgroundColor,
      ':active': {
        ...styles[':active'],
        // Mouse down
        backgroundColor: 'var(--tw-brand-dropdown_option--clicked)',
      },
    }
  },
  placeholder: () => ({
    color: '#CBCBDF',
    marginLeft: '0.5rem',
  }),
}

export default customSelectStyles

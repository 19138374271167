import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope} from '@fortawesome/free-regular-svg-icons'
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons'
import {useHistory} from 'react-router'
import {useProgressDispatchContext, useProgressStateContext} from 'Src/contexts/ProgressContext'
import {useCustomerDispatchContext, useCustomerStateContext} from '../contexts/CustomerContext'
import {useFrameContext} from '../contexts/FrameContext'
import {useDealDispatchContext, useDealStateContext} from '../contexts/DealContext'
import {useShowMessage} from '../utilities/useShowMessage'
import {emailLeadQuote} from '../actions/EmailQuoteActions'
import {
  useEmailQuoteDispatchContext,
  useEmailQuoteStateContext,
} from '../contexts/EmailQuoteContext'
import {useAnalytics} from '../services/analytics/useAnalytics'
import {useProductContext} from '../contexts/ProductContext'
import {breadcrumbProgressTracking, trans} from '../utilities/Helpers'
import {ACTION_DETAILS} from '../services/analytics/constants'
import CtaButton from './Buttons/CtaButton'

const EmailQuote = ({buttonPreset, buttonClassName, linkClassName, isNotButton, hasIcon}) => {
  const frameContext = useFrameContext()
  const customerState = useCustomerStateContext()
  const progress = useProgressStateContext()
  const progressDispatch = useProgressDispatchContext()
  const {customerData: customer, leadVehicles} = customerState
  const customerDispatch = useCustomerDispatchContext()
  const emailQuoteDispatch = useEmailQuoteDispatchContext()
  const {showAlert, showErrorAlert, sendEmail} = useEmailQuoteStateContext()
  const {trackEvent, events} = useAnalytics()
  const leadVehicle = leadVehicles?.find(({isCurrent}) => isCurrent === true)
  const dealState = useDealStateContext()
  const {dealType} = dealState
  const dealDispatch = useDealDispatchContext()
  const history = useHistory()
  const {getSelectedRatedProducts} = useProductContext()
  const {isUpdating} = dealState
  const {isShareQuoteEnabled} = frameContext
  const buttonText = isShareQuoteEnabled ? trans('Email/Share Deal') : trans('email_me_a_copy')
  const successFlashMessage = `
    <span>
      Our partner A2Z Sync has emailed:
      <br />
      <strong>${customer.email}</strong>
    </span>
  `

  const emailQuote = async () => {
    await emailLeadQuote(
      emailQuoteDispatch,
      dealState,
      customerState,
      frameContext,
      customerDispatch,
      dealDispatch,
      getSelectedRatedProducts
    )
  }

  const handleEmailQuoteClick = async (event) => {
    event.preventDefault()
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.EMAIL.COPY)

    if (isUpdating) {
      return
    }

    if (isShareQuoteEnabled) {
      progressDispatch({
        type: 'update',
        payload: breadcrumbProgressTracking(progress, undefined, dealType),
      })
      history.push('/email-share')

      return
    }

    if (customer.id && leadVehicle) {
      trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.EMAIL.QUOTE)
      await emailQuote()

      return
    }

    emailQuoteDispatch({type: 'setSendEmailAfterRegistration', payload: true})

    history.push('/register')
  }

  useEffect(() => {
    if (sendEmail) {
      emailQuote()
    }
  }, [sendEmail])

  useShowMessage(showAlert, {
    title: 'Quote Sent',
    description: successFlashMessage,
    footer: 'Check your Spam/Junk folder if you did not receive this email.',
    onClose: () => emailQuoteDispatch({type: 'setShowAlert', payload: false}),
  })

  useShowMessage(showErrorAlert, {
    title: 'Quote Not Sent',
    description: 'There was an error sending your email. Try again later.',
    onClose: () => emailQuoteDispatch({type: 'setShowErrorAlert', payload: false}),
    type: 'error',
  })

  if (isNotButton) {
    return (
      <a
        href="#"
        tabIndex="0"
        className={classNames('link', linkClassName)}
        onClick={handleEmailQuoteClick}
      >
        {hasIcon ? (
          <FontAwesomeIcon
            icon={isShareQuoteEnabled ? faPaperPlane : faEnvelope}
            className="tw-mr-2"
          />
        ) : null}
        {buttonText}
      </a>
    )
  }

  return (
    <>
      <CtaButton
        preset={buttonPreset}
        className={buttonClassName}
        onClick={handleEmailQuoteClick}
        disabled={isUpdating}
      >
        {hasIcon ? (
          <FontAwesomeIcon icon={faEnvelope} className="tw-mr-2 tw-text-brand-body_text--primary" />
        ) : null}
        {buttonText}
      </CtaButton>
    </>
  )
}

export default EmailQuote

EmailQuote.propTypes = {
  buttonClassName: PropTypes.string,
  buttonPreset: PropTypes.string,
  linkClassName: PropTypes.string,
  isNotButton: PropTypes.bool,
  hasIcon: PropTypes.bool,
}

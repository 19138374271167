import React, {useState, useRef, useEffect} from 'react'
import {useHistory} from 'react-router'
import * as yup from 'yup'
import _ from 'lodash'
import InlineButtonSpinner from 'Src/components/Spinners/InlineButtonSpinner'
import CtaButton from 'Src/components/Buttons/CtaButton'
import Input from '../../Input'
import {useTradeContext} from '../../../contexts/TradeContext'
import useForm from '../../../utilities/useForm'
import {
  useCustomerDispatchContext,
  useCustomerStateContext,
} from '../../../contexts/CustomerContext'
import {useProgressDispatchContext} from '../../../contexts/ProgressContext'
import {useFrameContext} from '../../../contexts/FrameContext'
import {useDealDispatchContext, useDealStateContext} from '../../../contexts/DealContext'
import {retrieveDealData} from '../../../actions/DealActions'
import {trans} from '../../../utilities/Helpers'
import {useAnalytics} from '../../../services/analytics/useAnalytics'
import {ACTION_DETAILS} from '../../../services/analytics/constants'

const OwedPayments = () => {
  const {
    a2zApi,
    dealership: {id: dealershipId},
    setMessages,
    useCache,
    vehicleData,
    vin,
  } = useFrameContext()
  const {
    TradeVehicleMileage,
    TradeVehicleCondition,
    TradeVehicleValue,
    TradeVehicleMetadata,
    stillOwePayments,
    setStillOwePayments,
    estimatedPayoffAmount,
    setEstimatedPayoffAmount,
  } = useTradeContext()
  const {customerData, leadId} = useCustomerStateContext()
  const dealState = useDealStateContext()
  const dealDispatch = useDealDispatchContext()
  const customerDispatch = useCustomerDispatchContext()
  const history = useHistory()
  const ProgressDispatch = useProgressDispatchContext()
  const {trackEvent, events} = useAnalytics()
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const estimatedPayOffInputRef = useRef()

  useEffect(() => {
    if (stillOwePayments) {
      estimatedPayOffInputRef.current.focus()
    }
  }, [stillOwePayments])

  // Grab only the properties we want to send and not the whole "TradeVehicleMetadata" object.
  // https://medium.com/@captaindaylight/9896148b9c72
  const vehicle = (({yearId, makeName, modelName, originalMSRP}) => ({
    yearId,
    makeName,
    modelName,
    originalMSRP,
  }))(TradeVehicleMetadata)

  const handleSaveError = (data) => {
    let message = 'The following errors occurred:<br/><ul style="list-style: unset">'
    const title = 'Error Saving Trade'

    if (!data) {
      // generic error message
      setMessages([
        {
          title,
          description: 'There was an error while trying to save the trade.',
          type: 'error',
        },
      ])

      return
    }

    const {detail} = data[0]
    Object.keys(detail).forEach((key) => {
      Object.values(detail[key]).forEach((value) => {
        const values = value.split('.')
        message += `<li>${_.capitalize(values[values.length - 2])}</li>`
      })
    })

    message += `</ul>`
    setMessages([
      {
        title,
        description: message,
        type: 'error',
      },
    ])
  }

  const saveTrade = async () => {
    const body = {
      data: {
        attributes: {
          vehicle,
          // Since "very good" is hyphenated on the backend, we're gonna transform it manually to save
          // work on matching a condition up while not breaking the front end, which seems to require
          // the single-word, all lowercase styling at the moment
          trade_condition:
            TradeVehicleCondition === 'verygood' ? 'very-good' : TradeVehicleCondition,
          mileage: TradeVehicleMileage,
          value: TradeVehicleValue,
          payoff: estimatedPayoffAmount || 0,
        },
      },
    }

    try {
      // need the separate variable so we can catch and handle errors
      const response = await a2zApi.post(
        `/digital-retail/customers/leads/${leadId}/vehicles/trades?include=lead`,
        body
      )

      return response
    } catch (e) {
      setHasError(true)
      handleSaveError(e.response.data.errors)

      return e.response.data
    }
  }

  const updateDesking = async (response) => {
    const newTrade = response.data.data
    const tradeVehicle = response.data.included.find(({type}) => type === 'vehicles')

    const tradePayload = {
      id: newTrade.id,
      ...newTrade.attributes,
      vehicle: {id: tradeVehicle?.id, ...tradeVehicle?.attributes},
    }

    customerDispatch({type: 'setTrade', payload: tradePayload})

    return retrieveDealData(
      {
        vin,
        dealershipId,
        useCache,
      },
      dealDispatch,
      a2zApi,
      dealState,
      {customerData, trade: tradePayload},
      false,
      true
    )
  }

  const onSubmit = async () => {
    setHasError(false)

    if (estimatedPayoffAmount) {
      trackEvent(
        events.ENGAGEMENT,
        events.ENGAGEMENT.actions.ADDED,
        ACTION_DETAILS.ESTIMATED_PAYOFF
      )
    }

    setLoading(true)
    const savedResponse = await saveTrade()

    if (savedResponse?.errors) {
      setLoading(false)

      return
    }

    if (savedResponse?.data?.data) {
      await updateDesking(savedResponse)
    }

    setLoading(false)

    if (vehicleData.sellingprice < TradeVehicleValue) {
      history.push('/payment-out-of-range')

      return
    }

    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.INCLUDE_TRADE_IN)
    history.push('/trade-complete?tradeCompleted=true')
    ProgressDispatch({type: 'update', payload: {trade: 'complete'}})
  }

  const rules = yup.object().shape(
    stillOwePayments
      ? {
          estimatedPayoffAmount: yup
            .string()
            .label('Estimated payoff amount')
            .required('Estimated payoff amount is required')
            .matches(/^[0-9]*$/, {message: 'Estimated payoff amount must be an integer'})
            .test(
              'not-within-range',
              '${path} must be between 1 and 1,000,000',
              (value) => value >= 1 && value <= 1000000
            ),
        }
      : {}
  )

  const {handleSubmit, getError, clearError} = useForm(
    rules,
    {
      estimatedPayoffAmount: estimatedPayoffAmount || undefined,
    },
    onSubmit
  )

  return (
    <>
      <form className="md:tw-px-11" onSubmit={handleSubmit} data-testid="owed-payments-form">
        <div className="divider tw-my-12" />
        {TradeVehicleValue ? (
          <>
            <label className="required tw-mb-4 tw-block tw-font-semibold" htmlFor="no-button">
              {trans('trade_int.do_you_owe_payments')}
            </label>
            <div className="tw-mb-10 tw-grid tw-gap-x-6 tw-gap-y-3 sm:tw-grid-cols-2">
              <CtaButton
                preset={stillOwePayments ? 'secondaryActive' : 'secondaryInactive'}
                onClick={() => setStillOwePayments(true)}
                aria-pressed={stillOwePayments}
                data-testid="yes-button"
              >
                {trans('Yes')}
              </CtaButton>
              <CtaButton
                preset={stillOwePayments ? 'secondaryInactive' : 'secondaryActive'}
                onClick={() => {
                  clearError('estimatedPayoffAmount')
                  setStillOwePayments(false)
                  setEstimatedPayoffAmount(0)
                }}
                aria-pressed={!stillOwePayments}
                data-testid="no-button"
                id="no-button"
              >
                {trans('No')}
              </CtaButton>
            </div>
          </>
        ) : null}
        <Input
          ref={estimatedPayOffInputRef}
          label={trans('estimated_payoff')}
          labelStyles="tw-mb-4"
          id="estimatedPayoffAmount"
          name="estimatedPayoffAmount"
          disabled={!stillOwePayments}
          required
          styles="tw-max-w-md"
          type="number"
          value={estimatedPayoffAmount || ''}
          tooltipMessage={trans('estimated_payoff_desc')}
          onChange={(event) => {
            clearError('estimatedPayoffAmount')
            setEstimatedPayoffAmount(event.target.value)
          }}
          error={stillOwePayments ? getError('estimatedPayoffAmount') : ''}
          inputMode="numeric"
        />
        <div className="divider tw-my-12 md:tw-hidden" />
        <div className="tw-mt-12 tw-text-center">
          <CtaButton
            type="submit"
            className="tw-w-full md:tw-max-w-md"
            disabled={!TradeVehicleValue || hasError || loading}
            data-testid="submit-button"
          >
            {loading ? <InlineButtonSpinner /> : <>{trans('trade_int.include_trade')}</>}
          </CtaButton>
        </div>
      </form>
    </>
  )
}

export default OwedPayments

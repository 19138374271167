import FieldInput from '../../../Form/Fields/FieldInput'
import StateSelect from '../../../Form/Fields/SelectFields/StateSelect'
import MaskedInput from '../../../Form/Fields/MaskedInput'

const ResidencyFieldConfig = (residencyIndex: number) => ({
  key: residencyIndex,
  rows: [
    {
      row: 1,
      columns: [
        {
          column: 1,
          fields: [
            {
              name: 'address1',
              label: 'Address',
              required: true,
              component: FieldInput,
              autoComplete: 'address-line1',
            },
          ],
        },
      ],
    },
    {
      row: 2,
      columns: [
        {
          column: 1,
          fields: [
            {
              name: 'address2',
              label: 'Apt/Unit #',
              component: FieldInput,
              autoComplete: 'address-line2',
            },
          ],
        },
      ],
    },
    {
      row: 3,
      columns: [
        {
          column: 1,
          columnWidth: 'sm:tw-col-span-5',
          fields: [
            {
              name: 'city',
              label: 'City',
              required: true,
              component: FieldInput,
              autoComplete: 'address-level2',
            },
          ],
        },
        {
          column: 2,
          columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-5',
          fields: [
            {
              name: 'state',
              label: 'State',
              required: true,
              component: StateSelect,
            },
          ],
        },
        {
          column: 3,
          columnWidth: 'sm:tw-col-span-3 lg:tw-col-span-2',
          fields: [
            {
              name: 'zipCode',
              label: 'ZIP Code',
              required: true,
              component: MaskedInput,
              mask: 'zipCode',
              autoComplete: 'postal-code',
            },
          ],
        },
      ],
    },
  ],
})

export default ResidencyFieldConfig

import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import SelectInput from './SelectInput'
import {LendingCenterContext} from '../../../LendingCenterContext'

const StateSelect = ({
  name,
  prefix,
  label,
  placeholder = '',
  required = false,
  groupWidth = 12,
  visibility = '',
}) => {
  const {
    lendingCenterData: {states},
  } = useContext(LendingCenterContext)
  return (
    <SelectInput
      name={name}
      prefix={prefix}
      label={label}
      placeholder={placeholder}
      required={required}
      selectOptions={states}
      groupWidth={groupWidth}
      visibility={visibility}
    />
  )
}

StateSelect.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  groupWidth: PropTypes.number,
  visibility: PropTypes.string,
}

export default StateSelect

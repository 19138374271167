import React from 'react'
import classNames from 'classnames'
import InlineButtonSpinner from 'Src/components/Spinners/InlineButtonSpinner'
import {useCustomerStateContext, useCustomerDispatchContext} from 'Src/contexts/CustomerContext'
import CtaButton from 'Src/components/Buttons/CtaButton'
import {trans} from 'Src/utilities/Helpers'
import Modal from 'Src/components/Modal'
import {formatDate} from 'Src/utilities/formatters'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {useProductContext} from 'Src/contexts/ProductContext'
import {useIsFetching} from 'react-query'
import {loadExistingQuote} from 'Src/actions/ExistingQuoteActions'

const PreviousDealModal = () => {
  const {isFetching} = useIsFetching({queryKey: ['desking']})
  const {customerData, previouslySavedQuote, showPreviousDealModal} = useCustomerStateContext()
  const customerDispatch = useCustomerDispatchContext()
  const dealState = useDealStateContext()
  const {a2zApi, setMessages} = useFrameContext()
  const dealDispatch = useDealDispatchContext()
  const {selectProduct} = useProductContext()
  const updatedDate = formatDate(previouslySavedQuote?.calculation?.updated_at, 'MMM d, yyyy')

  const closeModal = () => {
    customerDispatch({type: 'setShowPreviousDealModal', payload: false})
  }

  return (
    <Modal
      preset="danger"
      scrollable={false}
      open={showPreviousDealModal}
      onClose={closeModal}
      title={trans('Existing Deal')}
      registration
    >
      <div className="tw-mx-6">{trans('existing_deal_message', {date: updatedDate})}</div>
      <div className="divider tw-my-6" />
      <div className="tw-mx-6 tw-flex tw-justify-end tw-gap-x-4">
        <CtaButton
          preset="dangerPrimary"
          disabled={isFetching}
          data-testid="yes-button"
          onClick={() =>
            loadExistingQuote(
              a2zApi,
              previouslySavedQuote,
              dealDispatch,
              dealState,
              customerDispatch,
              customerData,
              selectProduct,
              setMessages
            )
          }
        >
          {isFetching ? <InlineButtonSpinner /> : null}
          <div className={classNames({'tw-h-0 tw-overflow-hidden': isFetching})}>
            {trans('Yes')}
          </div>
        </CtaButton>
        <CtaButton
          preset="dangerSecondary"
          onClick={closeModal}
          disabled={isFetching}
          data-testid="no-button"
        >
          {trans('No')}
        </CtaButton>
      </div>
    </Modal>
  )
}

export default PreviousDealModal

import FieldInput from '../../../Form/Fields/FieldInput'
import MaskedInput from '../../../Form/Fields/MaskedInput'
import PrimaryStateSelect from '../../../Form/Fields/SelectFields/PrimaryStateSelect'

const ReferenceFieldConfig = ({referenceIndex = 0, required = false} = {}) => ({
  key: referenceIndex,
  rows: [
    {
      row: 1,
      columns: [
        {
          column: 1,
          fields: [
            {
              name: 'relationshipDescription',
              label: 'Relationship',
              component: FieldInput,
              required,
            },
          ],
        },
      ],
    },
    {
      row: 2,
      columns: [
        {
          column: 1,
          fields: [
            {
              name: 'firstName',
              label: 'First Name',
              component: FieldInput,
              required,
              autoComplete: 'given-name',
            },
          ],
        },
        {
          column: 2,
          fields: [
            {
              name: 'lastName',
              label: 'Last Name',
              component: FieldInput,
              required,
              autoComplete: 'family-name',
            },
          ],
        },
      ],
    },
    {
      row: 3,
      columns: [
        {
          column: 1,
          columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-3',
          fields: [
            {
              name: 'phone',
              label: 'Phone',
              component: MaskedInput,
              mask: 'phone',
              required,
              autoComplete: 'tel-national',
            },
          ],
        },
      ],
    },
    {
      row: 4,
      columns: [
        {
          column: 1,
          fields: [
            {
              name: 'address1',
              label: 'Address',
              component: FieldInput,
              required,
              autoComplete: 'address-line1',
            },
          ],
        },
      ],
    },
    {
      row: 5,
      columns: [
        {
          column: 1,
          fields: [
            {
              name: 'address2',
              label: 'Address 2',
              component: FieldInput,
              autoComplete: 'address-line2',
            },
          ],
        },
      ],
    },
    {
      row: 6,
      columns: [
        {
          column: 1,
          columnWidth: 'sm:tw-col-span-5',
          fields: [
            {
              name: 'city',
              label: 'City',
              component: FieldInput,
              required,
              autoComplete: 'address-level2',
            },
          ],
        },
        {
          column: 2,
          columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-5',
          fields: [
            {
              name: 'state',
              label: 'State',
              component: PrimaryStateSelect,
              required,
            },
          ],
        },
        {
          column: 3,
          columnWidth: 'sm:tw-col-span-3 lg:tw-col-span-2',
          fields: [
            {
              name: 'zipCode',
              label: 'ZIP Code',
              component: MaskedInput,
              mask: 'zipCode',
              required,
              autoComplete: 'postal-code',
            },
          ],
        },
      ],
    },
  ],
})

export default ReferenceFieldConfig

import React from 'react'
import {PropTypes} from 'prop-types'
import SelectInput from './SelectInput'

const selectOptions = [
  {key: 'rent', text: 'Rent'},
  {key: 'own', text: 'Own'},
]

const HousingSelect = ({name, prefix, label, required = false, groupWidth = 12}) => (
  <SelectInput
    name={name}
    prefix={prefix}
    label={label}
    required={required}
    selectOptions={selectOptions}
    groupWidth={groupWidth}
    placeholder="Select Status"
  />
)

HousingSelect.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  groupWidth: PropTypes.number,
}

export default HousingSelect

import React, {Fragment, useRef} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {Dialog, Transition} from '@headlessui/react'
import IconButton from './Buttons/IconButton'

const presets = {
  primary: {
    text: '!tw-text-brand-primary',
    iconButton: 'primary',
  },
  danger: {
    text: '!tw-text-brand-body_text--danger',
    iconButton: 'danger',
  },
}

const Modal = ({
  preset = 'primary',
  open = false,
  onClose = () => undefined,
  scrollable = true,
  styles = '',
  title = '',
  icon = null,
  registration = false,
  xlSize = false,
  ...props
}) => {
  const dummyRef = useRef(null)

  return (
    <Transition show={open || false} as={Fragment}>
      <Dialog
        initialFocus={dummyRef}
        static
        open={open || false}
        onClose={onClose}
        className="tw-fixed tw-inset-0 tw-z-50"
      >
        <input type="hidden" ref={dummyRef} />
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-500"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-500"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Dialog.Overlay className="tw-fixed tw-inset-0">
            <div className="tw-absolute tw-inset-0 tw-bg-black tw-opacity-75" onClick={onClose} />
          </Dialog.Overlay>
        </Transition.Child>

        <div
          className={classNames('tw-flex tw-w-screen tw-h-screen', {
            'tw-items-center tw-justify-center': !xlSize,
          })}
        >
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-500"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in tw-duration-500"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <div
              className={classNames(
                'tw-relative tw-w-full tw-h-full tw-overflow-auto lg:tw-overflow-hidden lg:tw-h-auto tw-bg-brand-body tw-rounded-lg tw-mx-auto',
                styles,
                {
                  'tw-py-6 md:tw-max-w-4xl': registration,
                  'tw-py-8 md:tw-max-w-6xl': !registration,
                  'md:tw-h-5/6': scrollable,
                }
              )}
            >
              <div className="tw-flex tw-justify-between tw-px-8">
                <Dialog.Title
                  className={classNames('h2 tw-inline-flex tw-items-center', presets[preset].text)}
                >
                  {icon ? <FontAwesomeIcon icon={icon} className="tw-text-5xl tw-mr-4" /> : null}
                  {title}
                </Dialog.Title>
                <IconButton preset={presets[preset].iconButton} onClick={onClose}>
                  <FontAwesomeIcon icon={faTimes} className="tw-text-2xl" />
                </IconButton>
              </div>

              <div
                className={classNames('md:tw-p-5', {'tw-h-full tw-overflow-y-auto': scrollable})}
              >
                {props.children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal

Modal.propTypes = {
  preset: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  scrollable: PropTypes.bool,
  styles: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.shape({}),
  registration: PropTypes.bool,
  xlSize: PropTypes.bool,
  children: PropTypes.node,
}

import * as Yup from 'yup'

const regExp = {
  ssn: /^(?!000)[0-9]{3}([ -]?)(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$/,
  phone: /^$|^\(?([2-9][0-8][0-9])\)?[\s-]?([2-9][0-9]{2})[\s-]?([0-9]{4})$/,
  zipCode: /^\d{5}$/,
}

export const RegistrationFormSchema = ({phoneRequired = false} = {}) =>
  Yup.object().shape({
    firstName: Yup.string()
      .matches(/^([^0-9]*)$/, 'First Name cannot contain numbers.')
      .required('First Name is required.')
      .max(30, 'First Name must be fewer than 100 characters')
      .min(2, 'This field must be two or more characters'),
    lastName: Yup.string()
      .matches(/^([^0-9]*)$/, 'Last Name cannot contain numbers.')
      .required('Last Name is required.')
      .max(30, 'Last Name must be fewer than 100 characters')
      .min(2, 'This field must be two or more characters'),
    email: Yup.string()
      .email('A valid Email address is required')
      .required('Email is required.')
      .max(45, 'Email must be fewer than 100 characters'),
    zipCode: Yup.string()
      .required('ZIP code is required.')
      .matches(
        regExp.zipCode,
        'ZIP code is invalid. Entered value must be 5 digits between 0 and 9. For example, 80202.'
      )
      .min(5, 'Please enter a five digit ZIP code.')
      .max(5, 'Please enter a five digit ZIP code.'),
    cityCounty: Yup.object().nullable().required('City/County is required.'),
    get phone() {
      const phoneSchema = Yup.string().matches(regExp.phone, 'Phone Number is invalid.').nullable()

      return phoneRequired
        ? phoneSchema.required('Phone Number is required.')
        : phoneSchema.notRequired()
    },
  })

export const ExistingUserFormSchema = Yup.object().shape({
  lastName: Yup.string()
    .matches(/^([^0-9]*)$/, 'Last Name cannot contain numbers.')
    .required('Last Name is required.')
    .max(30, 'Last Name must be fewer than 100 characters')
    .min(2, 'This field must be two or more characters'),
  email: Yup.string()
    .email('A valid Email address is required')
    .required('Email is required.')
    .max(45, 'Email must be fewer than 100 characters'),
})

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {useHistory} from 'react-router'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {useMediaQuery} from 'react-responsive'
import MyJourney from './desking-page/MyJourney/MyJourney'
import FinancialSummary from './desking-page/FinancialSummary/FinancialSummary'
import AdditionalDisclosures from './desking-page/AdditionalDisclosures/AdditionalDisclosures'
import {useDealStateContext} from '../contexts/DealContext'
import PriceBar from './desking-page/PriceBar'
import Contact from './desking-page/MyJourney/Contact'

const SidePanel = ({deal, show, standAlone, className}) => {
  const {data} = useDealStateContext()
  const history = useHistory()
  const isPhone = useMediaQuery({query: `(max-width: 699.9px)`})

  if (isPhone && !standAlone) {
    return null
  }

  return (
    <aside
      id="side-panel"
      role="region"
      aria-describedby="side-panel-heading"
      className={classNames('sidePanel', className, {
        'stand-alone': show,
        'tw-hidden': !show || !standAlone,
        'tw-h-screen': standAlone,
        'sm:tw-block': !standAlone,
      })}
    >
      {standAlone ? (
        <div id="headerContainer" className="tw-sticky tw-top-0 sm:tw-hidden">
          <div className="headerNav">
            <FontAwesomeIcon
              onClick={() => {
                history.push('/')
              }}
              icon={faChevronLeft}
              className="tooltip-icon tw-m-3 tw-text-2xl sm:tw-hidden"
            />
          </div>
          <PriceBar
            standAlone
            hideBreadCrumbs
            containerId="financialSummaryScrollbar"
            className="sm:tw-hidden sm:tw-bg-brand-body"
          />
        </div>
      ) : null}
      <div
        id="financialSummaryScrollbar"
        className={classNames('scroll scrollBox tw-flex tw-flex-col', {
          'tw-px-full tw-w-full': show,
          'tw-px-2': !show,
        })}
      >
        <div>{data ? <FinancialSummary deal={deal} /> : null}</div>
        <div>
          <AdditionalDisclosures />
        </div>
        {standAlone ? <Contact standAlone /> : null}
        <div className="tw-hidden sm:tw-block">
          <MyJourney />
        </div>
      </div>
    </aside>
  )
}

SidePanel.propTypes = {
  deal: PropTypes.string,
  show: PropTypes.bool,
  standAlone: PropTypes.bool,
  className: PropTypes.string,
}

export default SidePanel

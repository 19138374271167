import React, {useState} from 'react'
import {useHistory} from 'react-router'
import * as yup from 'yup'
import {
  ECodeReturnTo,
  useCustomerDispatchContext,
  useCustomerStateContext,
} from '../../../contexts/CustomerContext'
import {useFrameContext} from '../../../contexts/FrameContext'
import {useNavDispatchContext} from '../../../contexts/NavContext'
import {displayPhoneNumber, trans} from '../../../utilities/Helpers'
import useForm from '../../../utilities/useForm'
import InputRadioGroup from '../../InputRadioGroup'
import RegistrationLayout from '../../Layouts/RegistrationLayout'
import {sendVerification} from '../../../api/VerificationActions'
import {formatToInternationalNumber} from '../../../utilities/formatters'
import {useAnalytics} from '../../../services/analytics/useAnalytics'
import {ACTION_DETAILS} from '../../../services/analytics/constants'
import {routes} from '../../../api/routes'
import CtaButton from '../../Buttons/CtaButton'

const NewLeadConfirmation = () => {
  const {customerData: customer, codeContactMethod: contactMethod} = useCustomerStateContext()
  const navDispatch = useNavDispatchContext()
  const customerDispatch = useCustomerDispatchContext()
  const [serverError, setServerError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const history = useHistory()
  const {trackEvent, events} = useAnalytics()

  const contactMethodInputs = [
    {
      id: 'email',
      label: customer.email,
    },
  ]

  if (customer.phone) {
    contactMethodInputs.push({
      id: 'sms',
      label: displayPhoneNumber(customer.phone),
    })
  }

  const {
    dealership: {
      attributes: {name: dealershipName, phone: dealershipPhone},
    },
    a2zApi,
  } = useFrameContext()

  const onSubmit = () => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.REGISTER.NEW_LEAD.VERIFY
    )
    setIsSubmitting(true)

    const customerVerification = {
      id: customer.id,
      email: customer.email,
      phone: formatToInternationalNumber(customer.phone),
    }

    sendVerification(a2zApi, customerVerification, {method: contactMethod})
      .then(() => {
        customerDispatch({type: 'setCodeReturnTo', payload: ECodeReturnTo.NEW_LEAD_CONFIRMATION})
        trackEvent(
          events.NAVIGATION,
          events.NAVIGATION.actions.NAVIGATE,
          ACTION_DETAILS.REGISTER.VERIFY.CODE
        )
        history.push(routes.register.paths.verifyCode())
      })
      .catch((err) => {
        if (err?.response?.status) {
          setServerError(true)

          return
        }

        throw err
      })
  }

  const schema = yup.object().shape({
    contactMethod: yup.string().required().oneOf(['email', 'sms']),
  })

  const setContactMethod = (value) => {
    customerDispatch({type: 'setCodeContactMethod', payload: value})
  }

  const {handleSubmit} = useForm(
    schema,
    {
      contactMethod,
    },
    onSubmit
  )

  const onEditClick = (event) => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.REGISTER.NEW_LEAD.EDIT_INFORMATION
    )
    event.preventDefault()

    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.PAGES.REGISTER)
    history.push('/register')
    navDispatch({type: 'showRegistration'})
  }

  return (
    <RegistrationLayout>
      <h4 className="h4 tw-mb-10 tw-text-center">Hi, {customer.firstName}!</h4>
      <p className="tw-text-center">
        Where would you like us to send your one-time verification code?
      </p>
      <form className="tw-mt-4 tw-flex tw-flex-col tw-items-center" onSubmit={handleSubmit}>
        {serverError ? (
          <p className="error tw-text-center">
            Sorry, there was a problem sending verification code.
          </p>
        ) : null}
        <div className="tw-w-auto">
          <div className="tw-mt-4">
            <InputRadioGroup
              inputs={contactMethodInputs}
              name="contact"
              onChange={setContactMethod}
              selected={contactMethod}
            />
          </div>
        </div>
        <div className="tw-mb-6 tw-mt-10">
          <CtaButton type="submit" className="tw-w-80" disabled={isSubmitting}>
            {trans('Send Code')}
          </CtaButton>
        </div>
        <div className="tw-mb-8">
          <CtaButton preset="link" className="tw-font-bold" onClick={onEditClick}>
            Edit Email or Phone
          </CtaButton>
        </div>
      </form>
      <p className="tw-text-center tw-text-xs">
        By selecting Send Code, you agree to {dealershipName} sending you a one-time verification
        code using the delivery method above.
      </p>
      <p className="tw-mt-4 tw-text-center tw-text-xs">
        {contactMethod === 'email' ? (
          <>
            The email will be sent by our partner <span className="tw-font-semibold">A2Z Sync</span>
            .
          </>
        ) : (
          <>Your mobile carrier’s message and data rates may apply.</>
        )}
      </p>
      {dealershipPhone ? (
        <p className="tw-mt-8 tw-text-center tw-font-semibold tw-text-brand-body_text--muted">
          Questions? Call {displayPhoneNumber(dealershipPhone)}
        </p>
      ) : null}
    </RegistrationLayout>
  )
}

export default NewLeadConfirmation

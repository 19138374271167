import * as yup from 'yup'

const schema = yup.object().shape({
  creditScore: yup
    .number()
    .typeError('Credit Score is required')
    .integer('Must be a whole number')
    .min(300, 'Min score is 300')
    .max(850, 'Max score is 850')
    .required('Credit Score is required'),
})

export default schema

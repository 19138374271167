import React, {ReactNode} from 'react'
import {trans} from 'Src/utilities/Helpers'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import CtaButton from 'Src/components/Buttons/CtaButton'

interface INextButton {
  actionDetails?: {
    click: string
    navigate: string
  }
  isDisabled?: boolean
  onClick(): void
  testId?: string
  text: ReactNode
}

const NextButton = ({actionDetails, isDisabled = false, onClick, testId, text}: INextButton) => {
  const {trackEvent, events} = useAnalytics()

  const handleClick = () => {
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, actionDetails?.click)
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, actionDetails?.navigate)
    onClick()
  }

  const ctaButtonProps: {
    'data-testid'?: string
  } = {}

  if (testId) {
    ctaButtonProps['data-testid'] = testId
  }

  return (
    <CtaButton
      {...{
        className: 'md:tw-min-w-[210px]',
        onClick: handleClick,
        disabled: isDisabled,
        ...ctaButtonProps,
      }}
    >
      {trans('Next')}: {text}
    </CtaButton>
  )
}

export default NextButton

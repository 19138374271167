import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {faAngleUp, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {numberToUSD} from '../../../utilities/DataReformatters'
import InputCheckBox from '../../InputCheckBox'
import {getRebateValue} from '../../../utilities/RebateHelpers'
import {useDealStateContext} from '../../../contexts/DealContext'
import {useShiftEvents, SHIFT_EVENTS} from '../../../utilities/useShiftEvents'
import {capitalize} from '../../../utilities/textUtilities'
import {useAnalytics} from '../../../services/analytics/useAnalytics'
import {ACTION_DETAILS} from '../../../services/analytics/constants'

const StandardRebateCard = (props) => {
  const {rebate, selectedDealType, programTerm, handleCheck, isSelected, isApplied} = props
  const [expanded, setExpanded] = useState(false)
  const {trackEvent, events} = useAnalytics()
  const {trackShiftEvents} = useShiftEvents()
  const {id, name, description, expires} = rebate
  const {isUpdating} = useDealStateContext()
  const incompatibleMessage = 'Incompatible with other rebates'
  const inputId = `${id}-${name}-checkbox`
  const descriptionRef = useRef()
  const handleRebateSelectedEventTracking = (id, isSelected) => {
    handleCheck(id, isSelected)
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.SELECTION,
      `rebate ${isSelected ? 'unselected' : 'selected'}`
    )
    if (isSelected) {
      trackShiftEvents([SHIFT_EVENTS.OFFER_ADDED], {
        offerName: name,
        offerType: capitalize(selectedDealType),
      })
    }
  }

  useEffect(() => {
    if (expanded) {
      /**
       * For accessibility, set focus on the element containing the description
       * to allow the user to scroll its contents using just the keyboard.
       */
      descriptionRef.current.focus()
    }
  }, [expanded])

  return (
    <div
      className={classNames('card card--hover tw-p-2 tw-mb-4', {
        'card--active': isSelected && isApplied,
        'card--inactive': !(isSelected && isApplied),
        'tw-cursor-not-allowed': isUpdating,
        'tw-cursor-pointer': !isUpdating,
      })}
      onClick={({target}) => {
        return (
          !isUpdating &&
          !target.classList.contains('qualify') && // handles expanded state
          target.tagName !== 'path' && // handles expanded state
          handleRebateSelectedEventTracking(id, !isSelected)
        )
      }}
      data-testid="card"
    >
      <div
        id={`${id}-${name}`}
        className="tw-grid tw-grid-cols-12 tw-gap-y-1"
        data-testid="container"
      >
        <div className="tw-col-span-1 tw-justify-self-center">
          <InputCheckBox
            id={inputId}
            checked={isSelected}
            disabled={isUpdating}
            onChange={() => {
              trackEvent(
                events.ENGAGEMENT,
                events.ENGAGEMENT.actions.SELECTION,
                isSelected ? ACTION_DETAILS.REBATE.UNSELECTED : ACTION_DETAILS.REBATE.SELECTED
              )
            }}
          />
        </div>
        <div
          className={classNames(
            'tw-col-span-10 tw-col-start-2 tw-col-end-10 sm:tw-col-end-9 tw-text-sm tw-self-center tw-ml-1 tw-mr-1',
            {
              'tw-font-bold': isSelected && isApplied,
            }
          )}
          data-testid="name"
        >
          <label htmlFor={inputId} className="tw-cursor-pointer">
            {name}
          </label>
        </div>
        <div
          className={classNames(
            'tw-text-sm tw-col-start-12 tw-justify-self-end tw-col-span-1 tw-whitespace-nowrap',
            {
              'tw-font-bold': isSelected && isApplied,
            }
          )}
          data-testid="rebate-value"
        >
          <span>&ndash;{numberToUSD(getRebateValue(rebate, selectedDealType, programTerm))}</span>
        </div>
        {isSelected && !isApplied && !isUpdating ? (
          <div
            className="error tw-col-span-10 tw-col-start-1 tw-col-end-10 sm:tw-col-end-9 tw-text-sm tw-self-center tw-mt-1 tw-ml-3 sm:tw-ml-4"
            data-testid="incompatible"
          >
            <FontAwesomeIcon icon={faExclamationCircle} /> {incompatibleMessage}
          </div>
        ) : null}
        <div className="tw-font-bold tw-text-sm tw-col-start-5 tw-col-end-13 tw-mt-1 tw-justify-self-end">
          <button
            type="button"
            className="link tw-font-semibold qualify"
            onClick={(event) => {
              event.preventDefault()
              trackEvent(
                events.ENGAGEMENT,
                events.ENGAGEMENT.actions.CLICK,
                ACTION_DETAILS.DO_I_QUALIFY
              )
              setExpanded((prevState) => !prevState)
            }}
            data-testid="qualify"
          >
            Do I qualify?
          </button>
        </div>
        <div className="tw-col-span-12 tw-cursor-auto qualify">
          <div
            className={classNames({'mt-2 tw-col-span-12': expanded, 'tw-hidden': !expanded})}
            data-testid="expanded-description"
          >
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{__html: description}}
              className="qualify mt-0 tw-m-2 tw-text-xs tw-leading-6 tw-max-h-64 sm:tw-max-h-60 tw-overflow-scroll sm:tw-overflow-auto scroll tw-pl-2 tw-pr-2 tw-cursor-auto"
              tabIndex="0"
              ref={descriptionRef}
              data-testid="description"
            />
          </div>
          <div
            className={classNames({
              'tw-col-span-12 tw-grid qualify': expanded,
              'tw-hidden': !expanded,
            })}
            data-testid="expanded-expires"
          >
            <div
              className="tw-text-xs tw-col-start-1 tw-font-bold tw-col-span-6 tw-mt-2 tw-m-4 qualify"
              data-testid="expires"
            >
              Expires {expires}
            </div>
            <div
              className="qualify tw-col-start-12 tw-col-span-1 tw-justify-self-center tw-cursor-pointer"
              onClick={() => setExpanded(false)}
              data-testid="minimize"
            >
              <FontAwesomeIcon
                icon={faAngleUp}
                className="qualify tw-text-brand-body_text--primary tw-text-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StandardRebateCard

StandardRebateCard.propTypes = {
  selectedDealType: PropTypes.string,
  programTerm: PropTypes.number,
  rebate: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    expires: PropTypes.string,
    description: PropTypes.string,
    terms: PropTypes.arrayOf(
      PropTypes.shape({
        dealType: PropTypes.string,
        value: PropTypes.number,
        start: PropTypes.number,
        end: PropTypes.number,
      })
    ),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        dealType: PropTypes.string,
        value: PropTypes.number,
      })
    ),
  }),
  isSelected: PropTypes.bool,
  isApplied: PropTypes.bool,
  handleCheck: PropTypes.func,
}

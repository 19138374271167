import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {useHistory, useLocation} from 'react-router'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {
  useProgressStateContext,
  useProgressDispatchContext,
  PROGRESS_STEP,
  PROGRESS_STATE,
} from 'Src/contexts/ProgressContext'
import {useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useNavDispatchContext} from 'Src/contexts/NavContext'
import {navigateIfUserRegistered} from 'Src/actions/RegistrationActions'
import {breadcrumbProgressTracking, getBreadcrumbProgressState} from 'Src/utilities/Helpers'
import {useDealStateContext} from 'Src/contexts/DealContext'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {useProductContext} from 'Src/contexts/ProductContext'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {trackProductConversionEvent} from 'Src/utilities/ProductHelpers'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'

const Breadcrumbs = () => {
  const location = useLocation()
  const history = useHistory()
  const customerState = useCustomerStateContext()
  const {customerData: customer} = customerState
  const navDispatch = useNavDispatchContext()
  const progress = useProgressStateContext()
  const ProgressDispatch = useProgressDispatchContext()
  const {isUpdating, dealType} = useDealStateContext()
  const {trackEvent, events} = useAnalytics()
  const {getSelectedRatedProducts, hasProducts} = useProductContext()
  const {isDocUploadsEnabled} = useFrameContext()

  const trackProductEvents = () => {
    if (hasProducts && location?.pathname === '/products') {
      trackProductConversionEvent(
        customer?.id,
        events,
        getSelectedRatedProducts(dealType),
        trackEvent
      )
    }
  }

  const handleDocumentUploadsProgressClick = (event) => {
    event.preventDefault()

    if (isUpdating) {
      return
    }

    trackEvent(
      events.NAVIGATION,
      events.NAVIGATION.actions.NAVIGATE,
      ACTION_DETAILS.PAGES.DOC_UPLOADS
    )
    navigateIfUserRegistered(
      customer.id,
      '/document-upload',
      navDispatch,
      history,
      () => {
        ProgressDispatch({
          type: 'update',
          payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.uploads, dealType),
        })
      }
    )
  }

  const ProgressSegment = ({type}) => {
    const progressState = getBreadcrumbProgressState(dealType, progress, type)
    const isProgressTouched = [PROGRESS_STATE.started, PROGRESS_STATE.complete].includes(
      progressState
    )

    return (
      <div
        className={classNames('tw-h-1 tw-w-1/3 tw-mb-2 tw-z-0 sm:tw-h-1/3 sm:tw-w-1', {
          'tw-bg-brand-breadcrumbs__path--touched': isProgressTouched,
          'tw-bg-brand-breadcrumbs__path--untouched': !isProgressTouched,
        })}
        data-test={type}
      />
    )
  }

  const ProgressButton = ({onClick, type, name = type}) => {
    const progressState = getBreadcrumbProgressState(dealType, progress, type)

    return (
      <a
        href="#"
        tabIndex="0"
        className="breadcrumbs__link tw-flex tw-flex-col tw-items-center tw-justify-end tw-w-20 sm:tw-flex-row sm:tw-w-full sm:tw-justify-center sm:tw-relative"
        onClick={onClick}
        data-test={`${type}-button`}
      >
        {progressState === PROGRESS_STATE.untouched ? (
          <div
            className="breadcrumbs__pin breadcrumbs__pin--untouched"
            data-test={`untouched-${type}`}
          />
        ) : null}
        {progressState === PROGRESS_STATE.started ? (
          <div
            className="breadcrumbs__pin breadcrumbs__pin--started"
            data-test={`started-${type}`}
          />
        ) : null}
        {progressState === PROGRESS_STATE.complete ? (
          <div
            className="breadcrumbs__pin breadcrumbs__pin--completed"
            data-test={`complete-${type}`}
          >
            <FontAwesomeIcon
              icon={faCheck}
              className="tw-h-2.5 tw-w-2.5 tw-text-brand-breadcrumbs__pin-icon--completed"
            />
          </div>
        ) : null}
        <span className="tw-mt-6 sm:tw-mt-0 sm:tw-absolute sm:tw--top-3 breadcrumbs__title">
          {name[0].toUpperCase() + name.slice(1)}
        </span>
      </a>
    )
  }

  ProgressSegment.propTypes = {
    type: PropTypes.string,
  }

  ProgressButton.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
  }

  return (
    <div>
      {progress ? (
        <div className="tw-relative sm:tw-h-72 sm:tw-w-4/6 sm:tw-left-7 tw-m-3">
          <div className="tw-flex tw-justify-around tw-absolute line-position tw-left-10 sm:tw-flex-col sm:tw-items-center sm:tw-left-0 sm:tw-h-full sm:tw-w-full sm:tw-top-0">
            <ProgressSegment type="payment" />
            <ProgressSegment type="trade" />
            {hasProducts ? <ProgressSegment type="products" /> : null}
            <ProgressSegment type="credit" />
            {isDocUploadsEnabled ? <ProgressSegment type="uploads" /> : null}
          </div>
          <div className="tw-flex tw-w-full tw-text-xs tw-justify-between tw-font-semibold tw-text-brand-body_text--primary tw-mt-10 sm:tw-flex-col sm:tw-items-center sm:tw-content-between sm:tw-h-full">
            <ProgressButton
              onClick={(event) => {
                event.preventDefault()

                if (isUpdating) {
                  return
                }

                trackEvent(
                  events.NAVIGATION,
                  events.NAVIGATION.actions.NAVIGATE,
                  ACTION_DETAILS.PAGES.PAYMENT
                )
                trackProductEvents()
                history.push('/')
                ProgressDispatch({
                  type: 'update',
                  payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.payment, dealType),
                })
              }}
              type="payment"
            />
            <ProgressButton
              onClick={(event) => {
                event.preventDefault()

                if (isUpdating) {
                  return
                }

                trackEvent(
                  events.NAVIGATION,
                  events.NAVIGATION.actions.NAVIGATE,
                  ACTION_DETAILS.PAGES.TRADE
                )
                trackProductEvents()
                navigateIfUserRegistered(customer.id, '/trade', navDispatch, history, () => {
                  ProgressDispatch({
                    type: 'update',
                    payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.trade, dealType),
                  })
                })
              }}
              type="trade"
            />
            {hasProducts ? (
              <ProgressButton
                onClick={(event) => {
                  event.preventDefault()

                  if (isUpdating) {
                    return
                  }

                  trackEvent(
                    events.NAVIGATION,
                    events.NAVIGATION.actions.NAVIGATE,
                    ACTION_DETAILS.PAGES.PRODUCTS
                  )
                  navigateIfUserRegistered(customer.id, '/products', navDispatch, history, () => {
                    ProgressDispatch({
                      type: 'update',
                      payload: breadcrumbProgressTracking(
                        progress,
                        PROGRESS_STEP.products,
                        dealType
                      ),
                    })
                  })
                }}
                type="products"
                name="protection"
              />
            ) : null}
            <ProgressButton
              onClick={(event) => {
                event.preventDefault()

                if (isUpdating) {
                  return
                }

                trackEvent(
                  events.NAVIGATION,
                  events.NAVIGATION.actions.NAVIGATE,
                  ACTION_DETAILS.PAGES.CREDIT
                )
                trackProductEvents()
                navigateIfUserRegistered(customer.id, '/credit', navDispatch, history, () => {
                  ProgressDispatch({
                    type: 'update',
                    payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.credit, dealType),
                  })
                })
              }}
              type="credit"
            />
            {isDocUploadsEnabled ? (
              <ProgressButton
                onClick={handleDocumentUploadsProgressClick}
                name="documents"
                type="uploads"
              />
            ) : null}
            <ProgressButton
              onClick={(event) => {
                event.preventDefault()

                if (isUpdating) {
                  return
                }

                trackEvent(
                  events.NAVIGATION,
                  events.NAVIGATION.actions.NAVIGATE,
                  ACTION_DETAILS.PAGES.FINALIZE
                )
                trackProductEvents()
                navigateIfUserRegistered(customer.id, '/finalize', navDispatch, history, () => {
                  ProgressDispatch({
                    type: 'update',
                    payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.finalize, dealType),
                  })
                })
              }}
              type="finalize"
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Breadcrumbs

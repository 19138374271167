import React from 'react'
import {PropTypes} from 'prop-types'
import SelectInput from './SelectInput'

const IncomeSelect = ({
  name,
  prefix,
  label,
  required = false,
  groupWidth = 12,
  alignItemsCenter,
}) => {
  const options = [
    {
      key: 'monthly',
      text: 'Monthly',
    },
    {
      key: 'annually',
      text: 'Annually',
    },
  ]

  return (
    <SelectInput
      name={name}
      prefix={prefix}
      label={label}
      required={required}
      selectOptions={options}
      groupWidth={groupWidth}
      alignItems={!!alignItemsCenter}
    />
  )
}

IncomeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  groupWidth: PropTypes.number,
  alignItemsCenter: PropTypes.bool,
}

export default IncomeSelect

import React, {useState} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {useField, Field, ErrorMessage, connect} from 'formik'
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

const phone = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
const zipCode = [/\d/, /\d/, /\d/, /\d/, /\d/]
const date = [/\d/, /\d/, '/', /\d/, /\d/, '/', /[1-2]/, /([019])/, /\d/, /\d/]
const ssn = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
const masks = {
  phone,
  date,
  ssn,
  zipCode,
}

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM')

const OBFUSCATED_FIELDS = ['dob', 'ssn', 'coBuyers[0].dob', 'coBuyers[0].ssn']

const TextInput = ({
  name,
  label,
  required = false,
  inputWidth,
  groupWidth,
  mask,
  className,
  placeholder = '',
  prefix = '',
  visibility = '',
  autoComplete,
}) => {
  const [obfuscate, setObfuscate] = useState(false)
  const [field] = useField({name})
  name = prefix + name

  const shouldObfuscate = ({target}) => setObfuscate(target === document.activeElement)

  const getStyle = ({value}, inputName) => {
    const relevantField = value && OBFUSCATED_FIELDS.includes(inputName)
    return relevantField ? {filter: `blur(${obfuscate ? '0' : '3'}px)`} : null
  }

  const props = {}

  if (required) {
    props['aria-required'] = true
  }

  if (autoComplete) {
    props.autoComplete = autoComplete
  }

  return (
    <div
      className={classNames(visibility, {
        [`tw-w-${groupWidth}/12`]: groupWidth,
        'tw-w-full': !groupWidth,
      })}
    >
      <label
        className={classNames('tw-select-none', {
          required,
        })}
        htmlFor={name}
      >
        {label || <>&nbsp;</>}
      </label>
      <div
        className={classNames({
          [`tw-w-${inputWidth}/12`]: inputWidth,
          'tw-w-full': !inputWidth,
        })}
      >
        <Field name={name} value={field.value || ''}>
          {({field}) => (
            <MaskedInput
              id={name}
              mask={masks[mask]}
              type="text"
              {...field}
              pipe={mask === 'date' ? autoCorrectedDatePipe : null}
              placeholder={placeholder}
              className={classNames('tw-w-full tw-rounded tw-px-4 tw-py-2', className)}
              onMouseEnter={() => setObfuscate(true)}
              onMouseLeave={(e) => shouldObfuscate(e)}
              onFocus={() => setObfuscate(true)}
              onBlur={(e) => shouldObfuscate(e)}
              style={getStyle(field, name)}
              {...props}
            />
          )}
        </Field>
        <ErrorMessage name={name} render={(error) => <span className="error">{error}</span>} />
      </div>
    </div>
  )
}

TextInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  inputWidth: PropTypes.number,
  groupWidth: PropTypes.number,
  mask: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  visibility: PropTypes.string,
  autoComplete: PropTypes.string,
}

export default connect(TextInput)

import React, {useState} from 'react'
import PopoverHover from 'Src/components/Popovers/PopoverHover'
import IconQuestionCircle from 'Src/components/IconQuestionCircle'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {validateProgram, defaultFirstProgram} from 'Src/utilities/ProgramHelpers'
import {resetDownPaymentState} from 'Src/actions/DealActions'
import {trans} from 'Src/utilities/Helpers'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import CtaButton from 'Src/components/Buttons/CtaButton'

const DeskingPageBody = () => {
  const dealDispatch = useDealDispatchContext()
  const dealState = useDealStateContext()
  const {trackEvent, events} = useAnalytics()
  const {
    dealType,
    vehicle,
    isUpdating,
    data: {
      attributes: {dealTypes},
    },
  } = dealState
  const [dealTypeChangeCount, setDealTypeChangeCount] = useState(0)
  const hasNoLeaseOptions = Array.isArray(dealTypes.lease) && !dealTypes.lease.length

  const trackDealTypeEvent = (dealType) => {
    const changeCount = dealTypeChangeCount + 1
    setDealTypeChangeCount(changeCount)
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.SELECTION, dealType)
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CHANGE,
      ACTION_DETAILS.DEAL_TYPE_CHANGE,
      null,
      {
        dealTypeChangeCount: changeCount,
      }
    )
  }

  const handleLeaseSwitch = () => {
    if (!validateProgram(dealState, {dealType: 'lease'})) {
      if (!defaultFirstProgram(dealState, dealDispatch, {dealType: 'lease'})) {
        dealDispatch({type: 'update', payload: {displayNoProgramFoundMessage: true}})

        return
      }
    }

    trackDealTypeEvent('lease')
    resetDownPaymentState(dealDispatch)
    dealDispatch({type: 'update', payload: {dealType: 'lease'}})
  }

  const popoverContent = (
    <>
      <strong>{trans('no_programs_found')}</strong>
      <p>{trans('could_not_calculate_payment')}</p>
    </>
  )

  return (
    <>
      <h2 className="h5">{trans('select_deal_type')}</h2>
      <p className="tw-mb-5 tw-text-xs">{trans('select_deal_type_sr_label')}</p>
      <div
        className="tw-grid tw-auto-cols-fr tw-gap-x-6 tw-gap-y-3 md:tw-grid-flow-col"
        data-testid="buttons-container"
      >
        <CtaButton
          preset={dealType === 'finance' ? 'secondaryActive' : 'secondaryInactive'}
          onClick={() => {
            trackDealTypeEvent('finance')
            resetDownPaymentState(dealDispatch)
            dealDispatch({type: 'update', payload: {dealType: 'finance'}})
          }}
          disabled={isUpdating}
          data-testid="finance-button"
        >
          {trans('Finance')}
        </CtaButton>
        {vehicle.isNew ? (
          <CtaButton
            preset={dealType === 'lease' ? 'secondaryActive' : 'secondaryInactive'}
            onClick={() => {
              handleLeaseSwitch()
            }}
            disabled={isUpdating || hasNoLeaseOptions}
            data-testid="lease-button"
          >
            {trans('Lease')}
            {hasNoLeaseOptions ? (
              <PopoverHover
                content={popoverContent}
                data-testid="no-lease-options-tooltip"
                Element="div"
              >
                <IconQuestionCircle />
              </PopoverHover>
            ) : null}
          </CtaButton>
        ) : null}
        <CtaButton
          preset={dealType === 'cash' ? 'secondaryActive' : 'secondaryInactive'}
          onClick={() => {
            trackDealTypeEvent('cash')
            dealDispatch({type: 'update', payload: {dealType: 'cash'}})
          }}
          disabled={isUpdating}
          data-testid="cash-button"
        >
          {trans('Cash')}
        </CtaButton>
      </div>
    </>
  )
}

export default DeskingPageBody

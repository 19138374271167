import React, {forwardRef} from 'react'
import classNames from 'classnames'

export const SIZES = {
  9: 'tw-w-9 tw-h-9',
  10: 'tw-w-10 tw-h-10',
  12: 'tw-w-12 tw-h-12',
}

export const PRESETS = {
  primary: 'tw-text-brand-body_text--primary hover:tw-text-brand-body_text--primary-hover',
  danger: 'tw-text-brand-body_text--danger hover:tw-text-brand-body_text--danger-hover',
}

interface IButton extends React.ComponentPropsWithoutRef<'button'> {
  size?: keyof typeof SIZES
  preset?: keyof typeof PRESETS
}

const IconButton = forwardRef<HTMLButtonElement, IButton>(
  (
    {type = 'button', size = 10, preset = 'primary', className, ...props} = {},
    ref
  ): JSX.Element => {
    return (
      <button
        {...{
          ref,
          type,
          className: classNames(
            'button tw-grid tw-place-items-center',
            SIZES[size],
            PRESETS[preset],
            className
          ),
          ...props,
        }}
      />
    )
  }
)

export default IconButton

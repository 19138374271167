import React from 'react'
import TermsTooltip from './TermsTooltip'
import {trans} from '../../../utilities/Helpers'

const TermsHeader = () => (
  <>
    <div className="tw-leading-relaxed">
      <h2 id="payment-term" className="h5 tw-inline" data-testid="subheading">
        {trans('what_term_length')}
      </h2>
      <TermsTooltip />
    </div>
    <p className="tw-text-xs tw-mb-5">{trans('what_term_length_sr_label')}</p>
  </>
)

export default TermsHeader

import React from 'react'
import {Helmet} from 'react-helmet'
import BackButton from 'Src/components/Buttons/BackButton'
import {useDealStateContext} from 'Src/contexts/DealContext'
import {useEmailShareQuoteContext} from 'Src/contexts/EmailShareQuoteContext'
import {trans} from 'Src/utilities/Helpers'
import QuestionsCallDealershipPhone from '../QuestionsCallDealershipPhone'
import PageLayoutCenter from '../Layouts/PageLayoutCenter'

const EmailShareConfirmation = () => {
  const {getEmailAddresses} = useEmailShareQuoteContext()
  const {
    vehicle: {description},
  } = useDealStateContext()
  const emails: string[] = getEmailAddresses()
  const pageTitle = trans('Email/Share Confirmation')

  return (
    <>
      <Helmet>
        <title>{}</title>
      </Helmet>
      <PageLayoutCenter>
        <PageLayoutCenter.Header>{pageTitle}</PageLayoutCenter.Header>
        <PageLayoutCenter.Body>
          <p className="tw-mb-4">
            {trans('sent_your_quote')}
            <span className="tw-block tw-font-bold">{description}</span>
            {trans('to_the_following_emails')}:
          </p>
          <div className="tw-mb-4">
            <ul className="tw-flex tw-flex-col tw-gap-1 tw-text-center tw-text-sm">
              {emails ? emails.map((email) => <li key={email}>{email}</li>) : null}
            </ul>
          </div>
          <QuestionsCallDealershipPhone />
        </PageLayoutCenter.Body>
        <PageLayoutCenter.Footer>
          <div className="tw-mt-4 tw-py-2">
            <BackButton />
          </div>
        </PageLayoutCenter.Footer>
      </PageLayoutCenter>
    </>
  )
}

export default EmailShareConfirmation

import React, {useEffect, useState} from 'react'
import Confetti from 'react-dom-confetti'
import PropTypes from 'prop-types'

export const ConfettiAnimation = ({config, className, timeout = 0}) => {
  const [isActive, setIsActive] = useState(false)
  useEffect(() => {
    setTimeout(() => setIsActive(true), timeout)
  }, [])

  const defaultConfig = {
    angle: '90',
    spread: '120',
    startVelocity: '50',
    elementCount: '100',
    dragFriction: '0.17',
    duration: '4000',
    stagger: '0',
    width: '10px',
    height: '20px',
    perspective: '300px',
    colors: ['#fcf038', '#3b6cf2', '#ed1e79', '#39b54a', '#f7931e'],
    ...config,
  }

  return (
    <div aria-label="Confetti animation">
      <Confetti className={className} active={isActive} config={defaultConfig} />
    </div>
  )
}

ConfettiAnimation.propTypes = {
  config: PropTypes.shape({
    angle: PropTypes.string,
    spread: PropTypes.string,
    startVelocity: PropTypes.string,
    elementCount: PropTypes.string,
    dragFriction: PropTypes.string,
    duration: PropTypes.string,
    stagger: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    perspective: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.string),
  }),
  className: PropTypes.string,
  timeout: PropTypes.number,
}

import React from 'react'
import PropTypes from 'prop-types'
import PopoverHover from 'Src/components/Popovers/PopoverHover'
import IconQuestionCircle from 'Src/components/IconQuestionCircle'
import Incrementer from '../../Incrementer'
import {formatValue} from '../../../utilities/DataReformatters'
import {trans} from '../../../utilities/Helpers'

const DownPayment = ({downPayment, downPaymentIncrements, leaseFixedDownPayment = false}) => {
  if (leaseFixedDownPayment) {
    return (
      <div className="mb-3">
        <h2 id="down-payment" className="h5 tw-inline">
          {trans('Down Payment')}:{' '}
        </h2>
        <p className="headline tw-inline">{formatValue(downPayment)}</p>
      </div>
    )
  }

  const tooltipContent = (
    <p role="tooltip" id="down-payment-tooltip">
      {trans('how_much_down_payment_tooltip')}
    </p>
  )

  return (
    <div role="group" aria-labelledby="down-payment">
      <div className="tw-leading-relaxed">
        <h2 id="down-payment" className="h5 tw-inline">
          {trans('how_much_down_payment')}
        </h2>
        <PopoverHover content={tooltipContent} data-testid="tooltip">
          <IconQuestionCircle />
        </PopoverHover>
      </div>
      <p className="tw-text-xs tw-mb-5">{trans('how_much_down_payment_sr_label')}</p>
      <Incrementer
        defaultValue={downPayment}
        increments={downPaymentIncrements}
        type={trans('down payment')}
      />
    </div>
  )
}

DownPayment.propTypes = {
  downPayment: PropTypes.number,
  downPaymentIncrements: PropTypes.number,
  leaseFixedDownPayment: PropTypes.bool,
}

export default DownPayment

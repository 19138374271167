export const getRebateValue = (rebate, currentDealType, currentTerm) => {
  let rebateValue = 0

  if (rebate.values) {
    rebate.values.forEach(({dealType, value}) => {
      if (dealType === currentDealType || dealType === 'any') {
        rebateValue = value
      }
    })
  }
  if (rebate.terms) {
    rebate.terms.forEach(({dealType, end, start, value}) => {
      if (dealType === currentDealType && currentTerm <= end && currentTerm >= start) {
        rebateValue = value
      }
    })
  }
  return rebateValue
}

import React from 'react'
import {Helmet} from 'react-helmet'
import BackButton from 'Src/components/Buttons/BackButton'
import {backButtonBreadcrumbInteraction, trans, transString} from 'Src/utilities/Helpers'
import NextButton from 'Src/components/Buttons/NextButton'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import PageLayoutLeft from 'Src/components/Layouts/PageLayoutLeft'
import {useHistory} from 'react-router'
import {THistory} from 'Src/utilities/useAxios'
import DocumentUploadForm from 'Src/components/DocumentUploadForm'
import {useProgressDispatchContext, useProgressStateContext} from 'Src/contexts/ProgressContext'
import {useDealDispatchContext} from 'Src/contexts/DealContext'
import PreviousDealModal from '../Deal/PreviousDealModal'
import {useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useFrameContext} from 'Src/contexts/FrameContext'

const DocumentUpload = () => {
  const pageTitle = transString('Documents Upload')
  const subTitle = transString('document_upload_subtitle')
  const history: THistory = useHistory()
  const progressDispatch = useProgressDispatchContext()
  const progress = useProgressStateContext()
  const {dealType} = useDealDispatchContext()
  const {previouslySavedQuote} = useCustomerStateContext()
  const {isSaveDealEnabled} = useFrameContext()

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageLayoutLeft>
        <PageLayoutLeft.Header>{pageTitle}</PageLayoutLeft.Header>
        <PageLayoutLeft.Body>
          <p className="tw-mb-4">{subTitle}</p>
          <DocumentUploadForm />
        </PageLayoutLeft.Body>
        <PageLayoutLeft.Footer>
          <div className="divider tw-my-12 lg:tw-invisible lg:tw-my-8" />
          <div className="tw-flex tw-flex-col tw-justify-center tw-gap-x-6 sm:tw-mb-4 sm:tw-flex-wrap sm:tw-justify-between md:tw-flex-row-reverse lg:tw-mb-0">
            <NextButton
              actionDetails={{
                click: ACTION_DETAILS.PAGES.DOC_UPLOADS,
                navigate: ACTION_DETAILS.PAGES.FINALIZE,
              }}
              onClick={() => history.push('/finalize')}
              text={trans('Finalize')}
            />
            <div className="tw-mt-4 tw-py-2 sm:tw-mt-0 sm:tw-flex">
              <BackButton
                additionOnClick={() =>
                  progressDispatch({
                    type: 'update',
                    payload: {
                      ...backButtonBreadcrumbInteraction(history, progress, dealType),
                      uploads: 'complete',
                    },
                  })
                }
              />
            </div>
          </div>
        </PageLayoutLeft.Footer>
      </PageLayoutLeft>
      {isSaveDealEnabled && previouslySavedQuote ? <PreviousDealModal /> : null}
    </>
  )
}

export default DocumentUpload

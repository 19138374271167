import React from 'react'
import {PropTypes} from 'prop-types'
import {useFormikContext, FieldArray} from 'formik'
import Accordion from '../../../Utilities/Accordion'
import FormRowRenderer from '../../../Form/FormUtilities/FormRowRenderer'
import EmploymentFieldConfig from './EmploymentFieldConfig'
import AdditionalEmploymentFieldConfig from './AdditionalEmploymentFieldConfig'
import OtherIncomeFieldConfig from './OtherIncomeFieldConfig'
import initialEmploymentValues from '../../../InitialValues/initialEmploymentValues.json'
import {
  HeaderGroup,
  DynamicGroup,
  AddGroupButton,
} from '../../../Form/FormUtilities/DynamicFieldArrayGroup'
import {sectionHasErrors} from '../../../CreditApplicationActions'

const EmploymentInformation = ({prefix}) => {
  const {values, errors, isSubmitting} = useFormikContext()

  const addButtonText = 'Add Previous Job'
  const removeButtonText = 'Remove Previous Job'

  const fieldArrayName = prefix ? 'coBuyers[0].employers' : 'employers'

  const groupArray = prefix ? values.coBuyers[0].employers : values.employers

  return (
    <Accordion
      id={`${prefix || ''}employment`}
      title={`${prefix ? 'Co-Buyer ' : ''}Employment & Income`}
      hasErrors={
        isSubmitting &&
        (sectionHasErrors(errors, EmploymentFieldConfig().rows, 'employers', prefix) ||
          sectionHasErrors(errors, AdditionalEmploymentFieldConfig().rows, 'employers', prefix) ||
          sectionHasErrors(errors, OtherIncomeFieldConfig, 'otherIncome', prefix))
      }
    >
      <FieldArray
        name={fieldArrayName}
        render={(arrayHelpers) => (
          <div>
            {groupArray.map((employment, index) => (
              <div key={index.toString()}>
                {index === 0 && (
                  <HeaderGroup
                    index={index}
                    formGroupHeading="Current Employment"
                    arrayHelpers={arrayHelpers}
                    removeButtonText={removeButtonText}
                    rows={EmploymentFieldConfig().rows}
                    arrayName={fieldArrayName}
                  />
                )}
                {index > 0 && (
                  <DynamicGroup
                    index={index}
                    formGroupHeading={`Previous Employment ${index}`}
                    arrayHelpers={arrayHelpers}
                    removeButtonText={removeButtonText}
                    rows={AdditionalEmploymentFieldConfig().rows}
                    arrayName={fieldArrayName}
                  />
                )}
                {index === 0 && (
                  <div className="tw-mt-3">
                    <FormRowRenderer
                      className="md:tw-mx-6"
                      rows={OtherIncomeFieldConfig}
                      prefix={prefix ? 'coBuyers[0].' : ''}
                    />
                    <div className="tw-my-3 md:tw-mx-6">
                      <p className="tw-italic tw-text-brand-body_text--muted tw-text-sm">
                        Alimony, child support, or separate maintenance income need not be revealed
                        if you do not wish to have it considered as a basis for repaying this
                        obligation.
                      </p>
                    </div>
                  </div>
                )}
                <AddGroupButton
                  addButtonText={addButtonText}
                  arrayHelpers={arrayHelpers}
                  groupArray={groupArray}
                  index={index}
                  addFields={initialEmploymentValues}
                />
              </div>
            ))}
          </div>
        )}
      />
    </Accordion>
  )
}

EmploymentInformation.propTypes = {
  prefix: PropTypes.string,
}

export default EmploymentInformation

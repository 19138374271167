import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {ErrorMessage, Field, useFormikContext} from 'formik'

const MultiActionCheckbox = ({
  label,
  postLabel,
  name,
  required,
  inputWidth,
  groupWidth,
  decorator,
  prefix,
  onChange,
}) => {
  const {setFieldValue} = useFormikContext()
  return (
    <div
      className={classNames({
        [`tw-w-${groupWidth}/12`]: groupWidth,
        'tw-w-full': !groupWidth,
      })}
    >
      {label ? (
        <label
          className={classNames('tw-font-semibold', {
            required: decorator,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      ) : null}
      <div
        className={classNames('tw-mb-4', {
          [`tw-w-${inputWidth}/12`]: inputWidth,
          'tw-w-full': !inputWidth,
        })}
      >
        <label className="tw-inline-flex tw-flex-row tw-gap-2 tw-items-start tw-cursor-pointer">
          <div className="tw-px-1">
            <Field
              type="checkbox"
              className="tw-border tw-rounded tw-border-blue-500 tw-cursor-pointer"
              required={required}
              name={name}
              onChange={
                onChange ||
                ((event) => {
                  if (event.target.checked) {
                    setFieldValue(`${prefix}address1`, '')
                    setFieldValue(`${prefix}address2`, '')
                    setFieldValue(`${prefix}city`, '')
                    setFieldValue(`${prefix}state`, '')
                    setFieldValue(`${prefix}zipCode`, '')
                  }
                  setFieldValue(`${prefix}sameAddressAs`, event.target.checked)
                })
              }
            />
          </div>
          <div>{postLabel}</div>
        </label>
        <ErrorMessage name={name} render={(error) => <span className="error">{error}</span>} />
      </div>
    </div>
  )
}

MultiActionCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  postLabel: PropTypes.string,
  required: PropTypes.bool,
  inputWidth: PropTypes.number,
  groupWidth: PropTypes.number,
  onChange: PropTypes.func,
  decorator: PropTypes.bool,
  prefix: PropTypes.string,
}

export default MultiActionCheckbox

import React, {forwardRef, HTMLProps} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Label from './Label'

interface InputProps extends HTMLProps<HTMLInputElement> {
  disabled?: boolean
  required?: boolean
  name?: string
  label?: string
  error?: string
  styles?: string
  labelStyles?: string
  width?: string
  tooltipMessage?: string
  className?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disabled = false,
      required = false,
      name = '',
      label = null,
      error = '',
      type = '',
      styles = 'tw-block',
      labelStyles = '',
      width = 'tw-w-full',
      tooltipMessage = '',
      className = '',
      ...props
    },
    ref
  ) => (
    <>
      {label || tooltipMessage ? (
        <div>
          {label ? (
            <Label {...{htmlFor: name, styles: labelStyles, disabled, required}}>{label}</Label>
          ) : null}
          {tooltipMessage ? (
            <p
              className={classNames('tw-mb-4 tw-text-xs', {
                'tw-text-brand-body_text--muted': disabled,
              })}
            >
              {tooltipMessage}
            </p>
          ) : null}
        </div>
      ) : null}
      <input
        {...{
          ref,
          type,
          name,
          disabled,
          ...props,
          className: classNames({
            [className]: className,
            'input-field': !className,
            [styles]: !className,
            [width]: !className,
          }),
        }}
      />
      {error ? (
        <div>
          <span className="error">{error}</span>
        </div>
      ) : null}
    </>
  )
)

Input.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  styles: PropTypes.string,
  labelStyles: PropTypes.string,
  width: PropTypes.string,
  tooltipMessage: PropTypes.string,
  className: PropTypes.string,
}

export default Input

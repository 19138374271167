import React from 'react'
import PropTypes from 'prop-types'
import {trans} from '../../../utilities/Helpers'
import kbblogo from '../../../images/kbblogo.png'

const TradeLogo = ({integration}) => {
  switch (integration) {
    case 'kbb':
      return <img src={kbblogo} alt={trans('kbb.logo_alt_text')} className="tw-mx-auto tw-w-48" />
    case 'trade-pending':
    default:
      return null
  }
}

TradeLogo.propTypes = {
  integration: PropTypes.string,
}

export default TradeLogo

import React, {ReactNode, useState, useEffect, useRef, useCallback} from 'react'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons'
import {faTimes, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import {transString} from 'Src/utilities/Helpers'
import IconButton from 'Src/components/Buttons/IconButton'
import {Transition} from '@headlessui/react'

interface IToast {
  show?: boolean
  title: ReactNode
  message: string
  footer?: ReactNode
  error?: boolean
  delay?: number
  autohide?: boolean
  onClose?(): void
}

export const DELAY_DURATION = 5000
const TRANSITION_DURATION = 150

export const TRANSITION_CLASS = {
  EASE: `tw-transition-opacity tw-ease-linear tw-duration-${TRANSITION_DURATION}`, // tw-duration-150
  OPEN: 'tw-opacity-100',
  CLOSE: 'tw-opacity-0',
}

export const ICON_COLOR_CLASS = {
  PRIMARY: 'tw-text-brand-body_text--primary',
  DANGER: 'tw-text-brand-body_text--danger',
}

export const BORDER_COLOR_CLASS = {
  PRIMARY: 'tw-border-brand-primary',
  DANGER: 'tw-border-brand-danger-primary',
}

const Toast = ({
  show = true,
  title,
  message,
  footer = '',
  error = false,
  delay = DELAY_DURATION,
  autohide = false,
  onClose: handleCloseCallback = () => undefined,
}: IToast) => {
  // We use refs for these, because we don't want to restart the autohide
  // timer in case these values change.
  const delayRef = useRef(delay)
  const transitionDelay = delay - TRANSITION_DURATION
  const transitionDelayRef = useRef(transitionDelay)
  const handleCloseCallbackRef = useRef(handleCloseCallback)
  const [transitionShow, setTransitionShow] = useState(false)
  const buttonPreset = error ? 'danger' : 'primary'
  const flashIcon = error ? faExclamationCircle : faCheckCircle
  let transitionTimeout: ReturnType<typeof setTimeout> | undefined
  let autohideTimeout: ReturnType<typeof setTimeout> | undefined
  const autohideToast = !!(autohide && show)

  const autohideFunc = useCallback(() => {
    handleCloseCallbackRef.current()
  }, [autohideToast])

  const handleCloseClick = () => {
    handleCloseCallbackRef.current()
  }

  useEffect(() => {
    transitionDelayRef.current = transitionDelay
    delayRef.current = delay
    handleCloseCallbackRef.current = handleCloseCallback
  }, [delay, handleCloseCallback])

  useEffect(() => {
    if (autohide) {
      // Only reset timer if show or autohide changes.
      transitionTimeout = setTimeout(() => setTransitionShow(false), transitionDelayRef.current)
      autohideTimeout = setTimeout(autohideFunc, delayRef.current)

      return () => {
        clearTimeout(transitionTimeout)
        clearTimeout(autohideTimeout)
      }
    }
  }, [autohideTimeout, autohideFunc])

  useEffect(() => {
    setTimeout(() => setTransitionShow(true), 0)
  }, [])

  return (
    <Transition
      as="div"
      show={transitionShow}
      className={TRANSITION_CLASS.EASE}
      enterFrom={TRANSITION_CLASS.CLOSE}
      enterTo={TRANSITION_CLASS.OPEN}
      leaveFrom={TRANSITION_CLASS.OPEN}
      leaveTo={TRANSITION_CLASS.CLOSE}
    >
      <div
        className={classNames(
          'tw-relative tw-mb-4 tw-w-96 tw-rounded-lg tw-border-3 tw-bg-brand-body tw-p-5 tw-shadow-lg',
          {
            'tw-hidden': !show,
            [BORDER_COLOR_CLASS.PRIMARY]: !error,
            [BORDER_COLOR_CLASS.DANGER]: error,
          }
        )}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        aria-hidden={!show}
      >
        <div className="tw-flex tw-gap-4">
          <FontAwesomeIcon
            icon={flashIcon}
            className={classNames('tw-text-3xl', {
              [ICON_COLOR_CLASS.PRIMARY]: !error,
              [ICON_COLOR_CLASS.DANGER]: error,
            })}
          />
          <div className="tw-text-brand-body_text">
            <h2 className="h4 tw-mb-4">{title || <>&nbsp;</>}</h2>
            <p
              className="tw-mb-4 tw-text-sm"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{__html: message}}
            />
          </div>
        </div>
        <div className="tw-text-xs tw-font-semibold">{footer}</div>
        <IconButton
          size={10}
          preset={buttonPreset}
          className="tw-absolute tw-right-3 tw-top-3"
          onClick={handleCloseClick}
          aria-label={transString('close_toaster')}
        >
          <FontAwesomeIcon icon={faTimes} className="tw-text-3xl" />
        </IconButton>
      </div>
    </Transition>
  )
}

export default Toast

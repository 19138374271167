import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {useNavDispatchContext} from '../../contexts/NavContext'
import {useAnalytics} from '../../services/analytics/useAnalytics'
import {ACTION_DETAILS} from '../../services/analytics/constants'
import IconButton from '../Buttons/IconButton'
import {trans} from '../../utilities/Helpers'

const HeaderlessLayout = ({children, onClose = () => undefined} = {}) => {
  const navDispatch = useNavDispatchContext()
  const {trackEvent, events} = useAnalytics()
  const defaultOnClose = () => navDispatch({type: 'closeSlideOut'})
  onClose = onClose || defaultOnClose

  const onCloseButtonClick = () => {
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.CLOSE_BUTTON)
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.CLOSE)
    onClose()
  }

  return (
    <div className="tw-flex tw-flex-col tw-max-h-screen">
      <div className="tw-flex tw-justify-end tw-px-5 tw-mx-4 tw-mt-8">
        <div>
          <IconButton aria-label={trans('Close')} onClick={onCloseButtonClick}>
            <FontAwesomeIcon icon={faTimes} className="tw-text-2xl" />
          </IconButton>
        </div>
      </div>
      <div className="tw-overflow-y-auto tw-overflow-x-hidden tw-max-h-full tw-flex-grow">
        <div id="registrationFormScrollbar" className="tw-py-10 tw-px-4">
          {children}
        </div>
      </div>
    </div>
  )
}

HeaderlessLayout.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
}

export default HeaderlessLayout

import React, {useEffect, useRef, useState} from 'react'
import classNames from 'classnames'
import {Controller, useForm} from 'react-hook-form'
import Select from 'react-select'
import {yupResolver} from '@hookform/resolvers/yup'
import {useHistory} from 'react-router'
import Input from '../../Input'
import schema from './ZipCodeSchema'
import {useDealDispatchContext, useDealStateContext} from '../../../contexts/DealContext'
import {retrieveDealData, saveQuote} from '../../../actions/DealActions'
import {useFrameContext} from '../../../contexts/FrameContext'
import {
  useCustomerDispatchContext,
  useCustomerStateContext,
} from '../../../contexts/CustomerContext'
import {customSelectStyles} from '../../../utilities/customSelectStyles'
import {preventNonNumericalInput, trans} from '../../../utilities/Helpers'
import {useNavDispatchContext} from '../../../contexts/NavContext'
import {getAndSetCityList, navigateIfUserRegistered} from '../../../actions/RegistrationActions'
import {useRegistrationContext} from '../../../contexts/RegistrationContext'
import {useAnalytics} from '../../../services/analytics/useAnalytics'
import {useProductContext} from '../../../contexts/ProductContext'
import {ACTION_DETAILS} from '../../../services/analytics/constants'
import CtaButton from '../../Buttons/CtaButton'

const INPUT_NAMES = {
  ZIP_CODE: 'zipCode',
}

const ZipCode = () => {
  const {
    vin,
    dealership: {id: dealershipId},
    a2zApi,
    a2zCustomerApi,
    useCache,
  } = useFrameContext()
  const history = useHistory()
  const inputWrapperRef = useRef(null)
  const dealState = useDealStateContext()
  const {
    defaultZipCode,
    dealType,
    validCreditScore,
    isUpdating,
    displayCityCountyMessage,
  } = dealState
  const dealDispatch = useDealDispatchContext()
  const customerContext = useCustomerStateContext()
  const {trackEvent, events} = useAnalytics()
  const {
    customerData: {address: customerAddress, cityCounty, id: customerId, zipCode: customerZipCode},
    leadVehicles,
  } = customerContext
  const {
    registrationState: {cityList, cityListOptions, cityListLoading, cityListLoadingError},
    registrationState,
    registrationDispatch,
  } = useRegistrationContext()

  const [showInput, setShowInput] = useState(displayCityCountyMessage)

  const leadVehicle = leadVehicles?.find(({isCurrent}) => isCurrent === true)
  const customerDispatch = useCustomerDispatchContext()
  const navDispatch = useNavDispatchContext()
  const frameContext = useFrameContext()
  const zipCodePlaceHolder = trans('zip_code_enter')
  const [cityListPlaceholder, setCityListPlaceholder] = useState(zipCodePlaceHolder)
  const [allowSelection, setAllowSelection] = useState(false)
  const {getSelectedRatedProducts} = useProductContext()

  const zipCode = customerZipCode || defaultZipCode

  const {
    register,
    control,
    setValue,
    watch,
    formState: {errors, isDirty},
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      zipCode: customerZipCode,
      cityCounty: cityListOptions.find((option) => option.value.id === cityCounty.id?.toString()),
    },
  })
  const formZipCode = watch('zipCode')
  const [doQuoteSave, setDoQuoteSave] = useState(false)

  useEffect(() => {
    if (zipCode !== formZipCode) {
      setValue('zipCode', zipCode)
    }
  }, [zipCode])

  useEffect(() => {
    if (showInput && !displayCityCountyMessage) {
      inputWrapperRef.current.firstChild.focus()

      return
    }

    setAllowSelection(true)
  }, [showInput])

  useEffect(() => {
    if (doQuoteSave && leadVehicle && dealState.loaded) {
      const saveQuoteAsync = async () => {
        await saveQuote(
          dealState,
          customerContext,
          frameContext,
          customerDispatch,
          dealDispatch,
          getSelectedRatedProducts
        )
      }

      saveQuoteAsync()
      setDoQuoteSave(false)
    }
  }, [doQuoteSave, dealState, customerContext, frameContext, customerDispatch])

  const doNewDesking = async () => {
    const customerState = {
      ...customerContext,
      customerData: {
        ...customerContext.customerData,
        cityCounty: {id: watch('cityCounty').value.id, inCity: watch('cityCounty').value.inCity},
        zipCode: formZipCode,
      },
    }

    await retrieveDealData(
      {vin, dealershipId, dealType, useCache},
      dealDispatch,
      a2zApi,
      dealState,
      customerState
    )
  }

  const toggleInput = () => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      !showInput ? ACTION_DETAILS.ZIP_CODE.OPENED : ACTION_DETAILS.ZIP_CODE.CLOSED
    )
    setShowInput(!showInput)
  }

  const openZip = () => {
    if (isUpdating) {
      return
    }

    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      showInput ? ACTION_DETAILS.ZIP_CODE.CLOSE : ACTION_DETAILS.ZIP_CODE.OPEN
    )
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.PAGES.PAYMENT)
    navigateIfUserRegistered(
      customerId,
      '/',
      navDispatch,
      history,
      () => {
        setDoQuoteSave(true)
      },
      toggleInput,
      false
    )
  }

  const handleBlur = async () => {
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CHANGE, ACTION_DETAILS.ZIP_CODE.BASE)
    setAllowSelection(false)

    if (watch('cityCounty')?.value?.length) {
      setCityListPlaceholder(zipCodePlaceHolder)
      setValue('cityCounty', '')
    }

    const data = await getAndSetCityList(
      a2zApi,
      formZipCode,
      registrationDispatch,
      registrationState
    )

    if (data) {
      setAllowSelection(true)
    }
  }

  const updateCustomerZipCode = () => {
    if (!customerAddress?.id) {
      return
    }

    const cityCounty = watch('cityCounty').value
    const data = {
      type: 'address',
      attributes: {
        region_id: cityCounty.id,
        city: cityCounty.city,
        county: cityCounty.county,
        in_city: cityCounty.inCity,
        zip_code: formZipCode,
      },
    }
    a2zCustomerApi.put(`/addresses/${customerAddress.id}`, {data}).catch((error) => {
      console.error(error)
    })
  }

  useEffect(() => {
    setCityListPlaceholder(zipCodePlaceHolder)
    setValue('cityCounty', '')

    if (cityListOptions.length === 1) {
      setValue('cityCounty', cityListOptions[0])
      return
    }

    if (cityCounty) {
      const foundCity = cityListOptions.find(
        (c) =>
          parseInt(c.value.id, 10) === parseInt(cityCounty?.id, 10) &&
          !!c.value.inCity === !!cityCounty?.inCity
      )
      setValue('cityCounty', foundCity || '')
    }

    if (cityListOptions.length) {
      setCityListPlaceholder(trans('city_select'))
    }
  }, [cityListOptions])

  const updatePayments = (e) => {
    e.preventDefault()

    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.RECALCULATE_PAYMENT
    )
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.PAGES.PAYMENT)
    dealDispatch({type: 'update', payload: {loaded: false}})
    customerDispatch({
      type: 'setCustomerData',
      payload: {zipCode: formZipCode},
    })
    customerDispatch({
      type: 'setCustomerCityCounty',
      payload: {cityCounty: watch('cityCounty').value, cityList},
    })
    doNewDesking()
    setDoQuoteSave(true)
    toggleInput()
    updateCustomerZipCode()
  }

  return (
    <div>
      <div className="tw-mt-4 tw-text-xs">
        <span>{trans('calculations_based_on_zip')}: </span>
        <a
          href="#"
          tabIndex="0"
          className="link tw-font-medium"
          onClick={openZip}
          data-testid="zip-code"
        >
          {zipCode}
        </a>
      </div>
      {cityCounty?.id ? (
        <div>
          <span className="tw-text-xs tw-font-semibold" data-testid="city-county">
            {cityCounty.displayName}
          </span>
        </div>
      ) : null}
      <form
        className={classNames('down-payment__form tw-mt-3 tw-rounded tw-pb-6', {
          'tw-hidden': !showInput,
        })}
      >
        <div className="tw-p-4">
          <span className="tw-text-sm">{trans('zip_code_garage')}</span>
        </div>
        <div className="tw-pl-4">
          <label className="input-label required" htmlFor="zipCode">
            {trans('zip_code')}
          </label>
        </div>
        <div className="tw-pl-4 tw-mb-4 tw-mr-2" ref={inputWrapperRef}>
          <Input
            type="text"
            className="tw-h-9 tw-w-28 tw-py-1.5 tw-px-3 tw-rounded"
            error={errors?.zipCode?.message ? errors?.zipCode?.message : cityListLoadingError || ''}
            {...register(INPUT_NAMES.ZIP_CODE)}
            defaultValue={formZipCode}
            onKeyPress={(e) => preventNonNumericalInput(e)}
            onBlur={handleBlur}
            maxLength={5}
            data-testid="input"
            inputMode="numeric"
            autoComplete="postal-code"
          />
        </div>
        <div className="tw-pl-4">
          <label
            htmlFor="cityCounty"
            className={classNames('tw-mb-2 required input-label', {
              'tw-opacity-25': !allowSelection,
            })}
            data-testid="city-county-label"
          >
            {trans('city_county')}
          </label>
          <Controller
            name="cityCounty"
            control={control}
            defaultValue={cityCounty}
            data-testid="controller"
            render={({field: {name, onChange, onBlur, value}}) => (
              <Select
                className="select tw-w-11/12 tw-font-medium"
                name={name}
                placeholder={cityListPlaceholder}
                isLoading={cityListLoading}
                isDisabled={!cityListOptions.length || !allowSelection}
                styles={customSelectStyles}
                options={cityListOptions}
                onChange={(...args) => {
                  trackEvent(
                    events.ENGAGEMENT,
                    events.ENGAGEMENT.actions.CHANGE,
                    ACTION_DETAILS.ZIP_CODE.CITY_COUNTY
                  )
                  onChange(...args)
                }}
                onBlur={onBlur}
                isSearchable={false}
                value={value}
              />
            )}
          />
          {errors && errors.cityCounty && cityListOptions.length !== 1 ? (
            <span className="error" data-testid="city-county-error">
              {errors.cityCounty.message}
            </span>
          ) : null}
        </div>
        <div className="tw-mt-8 tw-mx-auto tw-w-10/12 tw-text-center">
          <CtaButton
            className="tw-w-full"
            disabled={!isDirty || !watch('cityCounty') || !validCreditScore || isUpdating}
            onClick={(e) => updatePayments(e)}
            data-testid="recalculate-button"
          >
            {trans('recalculate_payment')}
          </CtaButton>
        </div>
      </form>
    </div>
  )
}

export default ZipCode

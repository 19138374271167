import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import AutoSelectedRebateCard from './AutoSelectedRebateCard'
import StandardRebateCard from './StandardRebateCard'
import {useDealStateContext} from '../../../contexts/DealContext'
import {getRebateValue} from '../../../utilities/RebateHelpers'
import {getTermByDealType} from '../../../utilities/ProgramHelpers'

const Rebates = (props) => {
  const {
    selectedDealType,
    programTerm,
    availableRebateIds,
    selectedRebateIds,
    appliedRebatesIds,
    handleCheck,
  } = props
  const dealState = useDealStateContext()
  const {
    data: {
      attributes: {rebates},
    },
    dealType,
  } = dealState

  const addAllAutoSelectedToSelectedRebates = () => {
    Object.values(rebates).forEach((rebate) => {
      const {id, autoSelected} = rebate
      if (rebate && autoSelected && !selectedRebateIds.includes(id)) {
        selectedRebateIds.push(id)
      }
    })
  }

  useEffect(() => {
    addAllAutoSelectedToSelectedRebates()
  }, [])

  const autoSelectedRebates = []
  const standardRebates = []

  const sortAutoSelected = (rebate, index) => {
    const rebateCard = (
      <AutoSelectedRebateCard
        key={index}
        rebate={rebate}
        selectedDealType={selectedDealType}
        programTerm={programTerm}
        data-testid="auto-selected-rebate-card"
      />
    )

    if (rebate.values) {
      // "Available To All" rebates should display first
      autoSelectedRebates.unshift(rebateCard)
      return
    }
    // "Auto Applied" (term-specific) rebates should display after
    const currentTerm = getTermByDealType(dealState)
    const value = getRebateValue(rebate, dealType, currentTerm)
    if (value) {
      autoSelectedRebates.push(rebateCard)
    }
  }

  availableRebateIds.forEach((rebateId, i) => {
    const rebate = rebates[rebateId]
    if (!rebate) return

    if (rebate.autoSelected) {
      sortAutoSelected(rebate, i)
      return
    }

    standardRebates.push(
      <StandardRebateCard
        key={i.toString()}
        rebate={rebates[rebateId]}
        selectedDealType={selectedDealType}
        programTerm={programTerm}
        handleCheck={handleCheck}
        isSelected={selectedRebateIds.includes(rebateId)}
        isApplied={appliedRebatesIds.includes(rebateId)}
        data-testid="standard-rebate-card"
      />
    )
  })

  return (
    <div>
      <div className="md:tw-flex-row">
        {autoSelectedRebates}
        {standardRebates}
      </div>
    </div>
  )
}

Rebates.propTypes = {
  availableRebateIds: PropTypes.arrayOf(PropTypes.number),
  selectedDealType: PropTypes.string,
  programTerm: PropTypes.number,
  handleCheck: PropTypes.func,
  selectedRebateIds: PropTypes.arrayOf(PropTypes.number),
  appliedRebatesIds: PropTypes.arrayOf(PropTypes.number),
}

export default Rebates

import {routes} from 'Src/api/routes'
import KbbTradeFlow from '../components/TradePartners/KBB/KbbTradeFlow'
import TradePendingFlow from '../components/TradePartners/TradePending/TradePendingFlow'
import {trans} from '../utilities/Helpers'

const {TRADE_VALUE_INTEGRATION} = routes.dealerships.includes

const baseUrl = '/digital-retail/vehicles/trades/value/report'
export const integrations = {
  kbb: KbbTradeFlow,
  'trade-pending': TradePendingFlow,
}
export const TRADE_ACTION_STATES = {
  INCOMPLETE: 'incomplete',
  COMPLETE: 'complete',
  ERROR: 'error',
  LOADING: 'loading',
}

export const clearTradeValues = (tradeContext) => {
  const {
    setStillOwePayments,
    setEstimatedPayoffAmount,
    setTradeVehicleCondition,
    setTradeVehicleYearId,
    setTradeVehicleMakeId,
    setTradeVehicleModelId,
    setTradeVehicleTrimId,
    setTradeVehicleMileage,
    setDropDowns,
  } = tradeContext

  setStillOwePayments(false)
  setEstimatedPayoffAmount(0)
  setTradeVehicleCondition(null)
  setTradeVehicleYearId(null)
  setTradeVehicleMakeId(null)
  setTradeVehicleModelId(null)
  setTradeVehicleTrimId(null)
  setTradeVehicleMileage(null)
  setDropDowns({})
}

export const getTradeIntegrationName = (data) => {
  if (data && Object.hasOwn(data, TRADE_VALUE_INTEGRATION)) {
    const {id: integrationName} = data[TRADE_VALUE_INTEGRATION]

    if (Object.keys(integrations).includes(integrationName)) {
      return {data: {integrationName}, error: null}
    }

    return {data: null, error: `Trade Integration Not Found: ${integrationName}`}
  }

  return {data: null, error: 'Error: No trade integrations returned'}
}

export const handleTradeIntegrationErrorResponse = (errorMessage, setMessages) => {
  console.error(errorMessage)

  setMessages([
    {
      title: 'Error',
      description: trans('trade_int.service_down'),
      type: 'error',
    },
  ])
}

const buildQueryParams = (selectedOption, tradeCopy) => {
  if (!tradeCopy) {
    return ''
  }

  const urlSegments = []
  Object.keys(tradeCopy).forEach((key) => {
    if (tradeCopy[key] && selectedOption !== key) {
      urlSegments.push(`${key}=${tradeCopy[key]}`)
    }
  })

  return urlSegments.join('&')
}

export const buildUrl = (selectedOption, value, tradeCopy) => {
  const additionalParams = buildQueryParams(selectedOption, tradeCopy)
  return `${baseUrl}?${additionalParams}${value ? `&${selectedOption}=${value}` : ''}`
}

export const getOptions = async (a2zApi, url, last = false) => {
  const returnValue = {
    payload: [],
    status: TRADE_ACTION_STATES.INCOMPLETE,
  }

  try {
    const {data: response} = await a2zApi.get(url)
    const {attributes} = response.included[0]

    // if we don't have a complete report, return what we need for the next question
    if (response.data.type === 'incompleteReport') {
      // if we just answered the last question on AYC and we still don't
      // have a complete report, return a flag that we need to go to AQ
      if (last) {
        return returnValue
      }

      // otherwise, just return the options
      const {filter, options} = attributes
      return {...returnValue, payload: options, filter}
    }

    // if we have a complete report, we don't need to go to AQ
    if (response.data.type === 'completeReport') {
      const {id} = response.included[0]
      const {attributes: report} = response.data
      const status = TRADE_ACTION_STATES.COMPLETE

      return {id, payload: attributes, report, status}
    }
  } catch (error) {
    console.error(error)

    return {status: TRADE_ACTION_STATES.ERROR, payload: trans('trade_int.missing_car')}
  }
}

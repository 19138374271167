import React from 'react'
import {useHistory} from 'react-router'
import Helmet from 'react-helmet'
import PlaceholderSpinner from 'Src/components/Spinners/PlaceholderSpinner'
import BackButton from 'Src/components/Buttons/BackButton'
import {useFetchTradeIntegration} from 'Src/queries/useFetchDealerships'
import {
  PROGRESS_STEP,
  useProgressDispatchContext,
  useProgressStateContext,
} from 'Src/contexts/ProgressContext'
import {breadcrumbProgressTracking, trans} from 'Src/utilities/Helpers'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {integrations} from 'Src/actions/TradeActions'
import {useProductContext} from 'Src/contexts/ProductContext'
import {useDealStateContext} from 'Src/contexts/DealContext'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import TradeLogo from 'Src/components/TradePartners/common/TradeLogo'

const TradeIntegration = () => {
  const {trackEvent, events} = useAnalytics()
  const history = useHistory()
  const ProgressDispatch = useProgressDispatchContext()
  const progress = useProgressStateContext()
  const {hasProducts} = useProductContext()
  const {dealType} = useDealStateContext()
  const {data} = useFetchTradeIntegration()

  const handleSkipTradeButtonClick = (event) => {
    event.preventDefault()

    if (hasProducts) {
      trackEvent(
        events.NAVIGATION,
        events.NAVIGATION.actions.NAVIGATE,
        ACTION_DETAILS.PAGES.PRODUCTS
      )
      history.push('/products')
      ProgressDispatch({
        type: 'update',
        payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.products, dealType),
      })
    } else {
      trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.PAGES.CREDIT)
      history.push('/credit')
      ProgressDispatch({
        type: 'update',
        payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.credit, dealType),
      })
    }

    ProgressDispatch({type: 'update', payload: {trade: 'untouched'}})
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.TRADE.SKIP)
  }

  const TradeFlow = integrations?.[data?.integrationName] ?? PlaceholderSpinner

  return (
    <>
      <Helmet>
        <title>{trans('Trade-In Value')}</title>
      </Helmet>
      <div className="scroll tradeExperience tw-flex tw-h-screen tw-min-h-full tw-flex-col tw-justify-between tw-overflow-x-hidden tw-p-6">
        <div className="tw-mb-6 empty:tw-hidden">
          <TradeLogo integration={data?.integrationName} />
        </div>
        <TradeFlow />
        <div className="tw-mt-4 tw-flex tw-items-center tw-justify-between tw-py-2">
          <BackButton />
          {/* Empty div tag maintains the 24px line-height for consistency. */}
          <div>&nbsp;</div>
          <button
            type="button"
            className="link !tw-text-sm !tw-font-bold !tw-leading-normal"
            onClick={handleSkipTradeButtonClick}
            data-testid="submit-button"
          >
            {trans('Skip Trade')}
          </button>
        </div>
      </div>
    </>
  )
}

export default TradeIntegration

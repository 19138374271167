import React, {useEffect, useState} from 'react'
import {SHIFT_EVENTS, useTrackShiftEvents} from 'Src/utilities/useShiftEvents'
import {buildUrl, getOptions} from 'Src/actions/TradeActions'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useTradeContext} from 'Src/contexts/TradeContext'
import {trans} from 'Src/utilities/Helpers'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import PlaceholderSpinner from 'Src/components/Spinners/PlaceholderSpinner'
import InputSelect from 'Src/components/InputSelect'
import styles from './Report.module.css'
import OwedPayments from '../common/OwedPayments'
import {conditionOptions} from './conditionOptions'

const Report = () => {
  const {a2zApi} = useFrameContext()
  const {
    TradeVehicleCondition,
    TradeVehicleMetadata,
    setTradeVehicleMetadata,
    setTradeVehicleCondition,
    setTradeVehicleValue,
  } = useTradeContext()
  const {trackEvent, events} = useAnalytics()
  const [loading, setLoading] = useState(false)
  const conditions = conditionOptions()

  useTrackShiftEvents([SHIFT_EVENTS.TRADE_IN_FINISH])

  const fetchNewReport = async (condition) => {
    setLoading(true)

    // if metadata.reports has the condition, swap the top
    // level report elements with the selected condition
    if (Object.keys(TradeVehicleMetadata.reports).includes(condition)) {
      setTradeVehicleMetadata({...TradeVehicleMetadata.reports[condition]})
      setLoading(false)

      return
    }

    // otherwise, fetch it
    const trade = {...TradeVehicleMetadata.ids}
    const url = buildUrl('condition', condition, trade)
    const {payload, report} = await getOptions(a2zApi, url)

    // add the new report to our collection of reports
    TradeVehicleMetadata.reports[condition] = {...report}
    setTradeVehicleMetadata({...payload, ...report})
    setLoading(false)
  }

  // "OwedPayments" component (included below) relies on the value of "TradeVehicleValue",
  // so we're setting it here. If metadata.tradeInValue does not exist, the if will eval
  // undefined to false and fetch a new report based on the trade condition we have stored.
  useEffect(() => {
    if (TradeVehicleMetadata?.tradeInValue?.target) {
      setTradeVehicleValue(TradeVehicleMetadata.tradeInValue.target)
    } else {
      fetchNewReport(TradeVehicleCondition)
    }
  }, [TradeVehicleMetadata?.tradeInValue?.target])

  return (
    <>
      <h1 className="h2 tw-text-center">{trans('kbb.trade_value')}</h1>
      <p className="tw-mb-6 tw-text-center">
        {trans('trade_int.vehicle_value_will_be_based_on_standard_equipment')}
      </p>
      <div className="tw-flex tw-flex-col tw-items-center">
        {loading ? (
          <PlaceholderSpinner className={styles.graphicContainer} />
        ) : (
          <iframe
            className={styles.graphicContainer}
            title={trans('kbb.price_advisor')}
            srcDoc={TradeVehicleMetadata?.reportMedia}
          />
        )}
        <div className="tw-mt-4 tw-w-full md:tw-max-w-md">
          <InputSelect
            inputId="trade-condition"
            name="trade-condition"
            label={trans('condition')}
            options={conditions}
            value={conditions.find(({value}) => value === TradeVehicleCondition)}
            onChange={({value}) => {
              trackEvent(
                events.ENGAGEMENT,
                events.ENGAGEMENT.actions.CHANGE,
                ACTION_DETAILS.CONDITION
              )
              setTradeVehicleCondition(value)
              fetchNewReport(value)
            }}
          />
        </div>
      </div>
      <OwedPayments />
    </>
  )
}

export default Report

import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router'
import PlaceholderSpinner from 'Src/components/Spinners/PlaceholderSpinner'
import CheckAvailabilityButton from 'Src/components/Buttons/CheckAvailabilityButton'
import BackButton from 'Src/components/Buttons/BackButton'
import NextButton from 'Src/components/Buttons/NextButton'
import {IIntegrationData, useFetchTradeIntegration} from 'Src/queries/useFetchDealerships'
import {handleTradeIntegrationErrorResponse} from 'Src/actions/TradeActions'
import {useTradeContext} from 'Src/contexts/TradeContext'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {
  breadcrumbProgressTracking,
  displayPhoneNumber,
  findQuery,
  trans,
} from 'Src/utilities/Helpers'
import {
  PROGRESS_STEP,
  useProgressDispatchContext,
  useProgressStateContext,
} from 'Src/contexts/ProgressContext'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {saveQuote} from 'Src/actions/DealActions'
import {useCustomerDispatchContext, useCustomerStateContext} from 'Src/contexts/CustomerContext'
import useShowMessage from 'Src/utilities/useShowMessage'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {useProductContext} from 'Src/contexts/ProductContext'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import TradeSummary from 'Src/components/TradeSummary'
import RemoveTradeModal from 'Src/components/TradePartners/common/RemoveTradeModal'
import {ConfettiAnimation} from 'Src/components/ConfettiAnimation'
import OrButtonDivider from 'Src/components/OrButtonDivider'
import PageLayoutCenter from 'Src/components/Layouts/PageLayoutCenter'
import PreviousDealModal from '../Deal/PreviousDealModal'

const TradeComplete = () => {
  const {estimatedPayoffAmount, TradeVehicleValue} = useTradeContext()
  const dealState = useDealStateContext()
  const progressDispatch = useProgressDispatchContext()
  const progress = useProgressStateContext()
  const {dealType, displayTradeNoProgramFoundMessage} = dealState
  const history = useHistory()
  const dealDispatch = useDealDispatchContext()
  const frameContext = useFrameContext()
  const {isSaveDealEnabled} = frameContext
  const {
    dealership: {
      attributes: {phone: dealershipPhone},
    },
  } = frameContext
  const customerState = useCustomerStateContext()
  const customerDispatch = useCustomerDispatchContext()
  const {leadVehicles, previouslySavedQuote} = customerState
  const leadVehicle = leadVehicles?.find(({isCurrent}) => isCurrent === true)
  const [hasInitialized, setHasInitialized] = useState(false)
  const tradeCompleted = findQuery('tradeCompleted') || false
  const {trackEvent, events} = useAnalytics()
  const {hasProducts, getSelectedRatedProducts} = useProductContext()
  const {data, error, isLoading} = useFetchTradeIntegration()

  useEffect(() => {
    if (error) {
      handleTradeIntegrationErrorResponse(error)
    }
  }, [error])

  useEffect(() => {
    if (!hasInitialized && leadVehicle) {
      const saveQuoteAsync = async () => {
        await saveQuote(
          dealState,
          customerState,
          frameContext,
          customerDispatch,
          dealDispatch,
          getSelectedRatedProducts
        )
      }

      saveQuoteAsync()
      setHasInitialized(true)
    }
  }, [dealState, customerState, frameContext, customerDispatch])

  useShowMessage(displayTradeNoProgramFoundMessage, {
    title: 'No Programs Found',
    description: `Your trade-in value and down payment exceed this vehicle’s lease price. You can proceed with a finance or cash deal, or remove your trade.${
      dealershipPhone
        ? ` For personalized assistance, please call us at ${displayPhoneNumber(dealershipPhone)}.`
        : ''
    }`,
    onClose: () => {
      dealDispatch({type: 'update', payload: {displayTradeNoProgramFoundMessage: false}})
      history.push('/')
    },
  })

  const handleCheckAvailabilityButtonClick = () => {
    history.push('/check-availability')
  }

  const tradeAddedTitle = (tradeIntegration: string) => {
    switch (tradeIntegration) {
      case 'kbb':
        return trans('kbb.youve_added_a_trade')
      case 'trade-pending':
      default:
        return trans('trade_int.youve_added_a_trade')
    }
  }

  const handleNextButtonClick = () => {
    if (hasProducts) {
      trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.PAGES.PRODUCTS)
      trackEvent(
        events.NAVIGATION,
        events.NAVIGATION.actions.NAVIGATE,
        ACTION_DETAILS.PAGES.PRODUCTS
      )
      history.push('/products')
      progressDispatch({
        type: 'update',
        payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.products, dealType),
      })

      return
    }

    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.CREDIT.PRE_APPROVED
    )
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.PAGES.CREDIT)
    history.push('/credit')
    progressDispatch({
      type: 'update',
      payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.credit, dealType),
    })
  }

  const TradeCompleteNextButton = () => (
    <NextButton
      onClick={handleNextButtonClick}
      text={hasProducts ? trans('Protection Plans') : trans('Get Pre-Approved')}
    />
  )

  const handleBackButtonClick = () => {
    history.push('/')
  }

  return (
    <>
      <PageLayoutCenter>
        <PageLayoutCenter.Body>
          {isLoading ? (
            <PlaceholderSpinner />
          ) : (
            <>
              {tradeCompleted ? (
                <ConfettiAnimation className="confetti-tradeSuccess" timeout={500} />
              ) : null}
              <h1 className="h1 tw-text-center !tw-text-brand-success">
                {trans('trade_int.success_exclaim')}
              </h1>
              <p className="tw-mb-10 tw-text-center tw-font-semibold">
                {trans('trade_int.weve_added_estimated_trade_amount')}
              </p>
              <div className="tw-mb-8 lg:tw-px-4">
                <p className="tw-font-semibold">
                  {tradeAddedTitle((data as IIntegrationData['data'])?.integrationName || '')}
                </p>
                <p className="tw-mb-8 tw-text-xs">
                  {trans('trade_int.vehicle_value_is_based_on_standard_equipment')}
                </p>
                <TradeSummary
                  {...{
                    TradeVehicleValue,
                    estimatedPayoffAmount,
                  }}
                />
                <p className="tw-text-xs">
                  *{trans('trade_int.not_an_offer_contingent_on_inspection')}
                </p>
              </div>
              <div className="tw-text-center">
                <RemoveTradeModal />
              </div>
            </>
          )}
        </PageLayoutCenter.Body>
        <PageLayoutCenter.Footer>
          <div className="divider tw-my-12 lg:tw-invisible lg:tw-my-8" />
          {isSaveDealEnabled ? (
            <div className="tw-flex tw-flex-col tw-justify-center tw-gap-x-6 sm:tw-mb-4 sm:tw-flex-wrap sm:tw-justify-between md:tw-flex-row-reverse lg:tw-mb-0">
              <TradeCompleteNextButton />
              <div className="tw-mt-4 tw-py-2 sm:tw-mt-0 sm:tw-flex">
                <BackButton additionOnClick={handleBackButtonClick} />
              </div>
            </div>
          ) : (
            <>
              <div className="tw-flex tw-flex-col tw-justify-center tw-gap-x-6 tw-gap-y-3 sm:tw-flex-wrap sm:tw-justify-between md:tw-flex-row-reverse md:tw-justify-evenly">
                <TradeCompleteNextButton />
                <OrButtonDivider />
                <CheckAvailabilityButton onClick={handleCheckAvailabilityButtonClick} />
              </div>
              <div className="tw-mt-4 tw-py-2">
                <BackButton additionOnClick={handleBackButtonClick} />
              </div>
            </>
          )}
        </PageLayoutCenter.Footer>
      </PageLayoutCenter>
      {isSaveDealEnabled && previouslySavedQuote ? <PreviousDealModal /> : null}
    </>
  )
}

export default TradeComplete

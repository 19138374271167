import React, {useEffect} from 'react'
import TagManager from 'react-gtm-module'
import {Helmet} from 'react-helmet'
import {SHIFT_EVENTS, useTrackShiftEvents} from 'Src/utilities/useShiftEvents'
import Routes from '../components/Routes'
import SlideOutLayout from '../components/Layouts/SlideOutLayout'
import Theme from '../components/Theme'
import {useCustomerStateContext} from '../contexts/CustomerContext'
import {useProgressDispatchContext} from '../contexts/ProgressContext'
import {useTradeContext} from '../contexts/TradeContext'
import {useConfig} from '../utilities/useConfig'
import {useDealStateContext} from '../contexts/DealContext'
import {useFrameContext} from '../contexts/FrameContext'
import {useProductContext} from '../contexts/ProductContext'
import {applyRatesFromProducts} from '../utilities/ProductHelpers'
import {getCurrentProgram} from '../utilities/ProgramHelpers'
import {useBatchProductRatings} from '../utilities/useBatchProductRatings'
import 'Src/styles/index.scss'

const IndexPage = () => {
  const customerContext = useCustomerStateContext()
  const {trade, hasActiveCreditApp: credit} = customerContext
  const {
    setEstimatedPayoffAmount,
    setTradeVehicleValue,
    TradeVehicleValue,
    estimatedPayoffAmount,
    setTotalTradeAmount,
  } = useTradeContext()
  const progressDispatch = useProgressDispatchContext()
  const {analytics} = useConfig()
  const dealState = useDealStateContext()
  const currentProgram = getCurrentProgram(dealState)
  const {customerData} = useCustomerStateContext()
  const {vehicleData, vin, isIframeVisible} = useFrameContext()
  const {
    getProductsByDealType,
    ratingDealState,
    setProductRates,
    setRatedProductsByDealType,
    setRatingDealState,
  } = useProductContext()

  useTrackShiftEvents(
    !!vehicleData?.vin && isIframeVisible && [SHIFT_EVENTS.INITIAL_CLICK, SHIFT_EVENTS.IMPRESSION]
  )

  const {rates, isLoading: isLoadingProductRatings} = useBatchProductRatings()

  useEffect(() => {
    if (!analytics?.gtmId) {
      return
    }

    TagManager.initialize({gtmId: analytics.gtmId})
  }, [analytics?.gtmId])

  useEffect(() => {
    if (trade) {
      setEstimatedPayoffAmount(parseFloat(trade.payoff))
      setTradeVehicleValue(parseFloat(trade.value))
      progressDispatch({type: 'update', payload: {trade: 'complete'}})
    }
  }, [trade])

  useEffect(() => {
    if (credit) {
      progressDispatch({type: 'update', payload: {credit: 'complete'}})
    }
  }, [credit])

  const totalTradeInAmountForDisplay = (TradeVehicleValue, estimatedPayoffAmount) => {
    const amount = parseFloat(TradeVehicleValue) - estimatedPayoffAmount

    if (amount >= 0) {
      return `$${amount.toLocaleString()}`
    }

    return `-$${(amount * -1).toLocaleString()}`
  }

  const getCurrentDealState = () => ({
    amountFinanced: currentProgram?.amountFinanced,
    term: currentProgram?.term,
    dealType: dealState?.dealType,
    rate: currentProgram?.rate,
    annualMileage: currentProgram?.annualMileage,
    customerId: customerData.id,
    vin,
  })

  useEffect(() => {
    const totalTradeAmountSummary = totalTradeInAmountForDisplay(
      TradeVehicleValue,
      estimatedPayoffAmount
    )
    setTotalTradeAmount(totalTradeAmountSummary)
  }, [TradeVehicleValue, estimatedPayoffAmount])

  useEffect(() => {
    const products = getProductsByDealType(dealState?.dealType)
    const currentDealState = getCurrentDealState()

    if (
      currentProgram &&
      products &&
      currentDealState.customerId &&
      currentDealState.dealType &&
      JSON.stringify(ratingDealState) !== JSON.stringify(currentDealState)
    ) {
      if (isLoadingProductRatings) {
        setRatedProductsByDealType(currentDealState?.dealType, [])
      } else {
        setProductRates(rates)
        setRatingDealState(currentDealState)
        const appliedRates = applyRatesFromProducts(products, rates)
        setRatedProductsByDealType(currentDealState?.dealType, appliedRates)
      }
    }
  }, [isLoadingProductRatings, rates])

  return (
    <SlideOutLayout>
      <Helmet>
        <html lang="en" />
      </Helmet>
      <Theme />
      <Routes />
    </SlideOutLayout>
  )
}

export default IndexPage

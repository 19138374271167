import React from 'react'
import FinanceTerms from './Terms/FinanceTerms'
import TermsHeader from './Terms/TermsHeader'
import DownPayment from './DownPayment/DownPayment'
import ZipCode from './ZipCode/ZipCode'
import CreditScore from './CreditScore/CreditScore'
import {useDealStateContext} from '../../contexts/DealContext'

const Finance = () => {
  const {
    loading,
    data,
    retailDownPayment,
    zipCode,
    retailDownPaymentIncrements,
  } = useDealStateContext()
  const finance = data.attributes?.dealTypes?.finance
  const terms = finance?.programs?.termsGroup

  return loading ? (
    <div data-testid="loading" />
  ) : (
    <div>
      <DownPayment
        downPayment={retailDownPayment}
        downPaymentIncrements={retailDownPaymentIncrements}
      />
      {zipCode ? <ZipCode /> : null}
      <div className="divider tw-my-12" />
      <CreditScore />
      <div className="divider tw-my-12" />
      {terms ? (
        <div role="group" aria-labelledby="payment-term">
          <TermsHeader data-testid="terms-header" />
          <FinanceTerms terms={terms} data-testid="finance-terms" />
        </div>
      ) : null}
    </div>
  )
}
export default Finance

import React, {Dispatch, SetStateAction} from 'react'
import {useFrameContext} from 'Src/contexts/FrameContext'
import Toast from 'Src/components/Notifications/Toast'
import {IMessage} from 'Src/utilities/useAxios'

interface IToaster {
  autohide?: boolean
  destroyOnClose?: boolean
}

interface IFrameContext {
  messages: IMessage[]
  setMessages: Dispatch<SetStateAction<IMessage[]>>
}

export const WINDOW_IFRAME_CLOSE_DESTROY_MESSAGE = 'digitalretail-iframe-close-destroy'

const Toaster = ({autohide = false, destroyOnClose = false}: IToaster = {}) => {
  const {messages, setMessages} = useFrameContext() as IFrameContext

  if (!messages?.length) {
    return null
  }

  return (
    <div className="tw-absolute tw-left-[calc(50%-theme(spacing.48))] tw-top-4 tw-z-[9999]">
      {messages.map(({title, description, footer, type, onClose: handleCloseCallback}) => (
        <Toast
          {...{
            key: description,
            autohide,
            title,
            message: description,
            footer,
            show: true,
            error: type === 'error',
            onClose: () => {
              setMessages((prevState) =>
                prevState.filter((prevMessage) => prevMessage.description !== description)
              )

              handleCloseCallback?.()

              if (destroyOnClose) {
                window.parent.postMessage(WINDOW_IFRAME_CLOSE_DESTROY_MESSAGE, '*')
              }
            },
          }}
        />
      ))}
    </div>
  )
}

export default Toaster

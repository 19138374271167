import React from 'react'
import {useFormikContext} from 'formik'
import Accordion from '../../Utilities/Accordion'
import SignaturePad from '../../SignaturePad'
import {useFrameContext} from '../../../../contexts/FrameContext'

const Acknowledgement = () => {
  const {
    values: {coBuyerPresent},
    errors,
    isSubmitting,
  } = useFormikContext()
  const {
    creditAppConfig: {acknowledgement},
  } = useFrameContext()
  const hasErrors = !!(
    isSubmitting &&
    (errors?.buyerSigned ||
      errors?.buyerChecked ||
      (coBuyerPresent && (errors?.coBuyerSigned || errors?.coBuyerChecked)))
  )

  return (
    <Accordion title="Acknowledgement and Consent" id="acknowledgement" hasErrors={hasErrors}>
      <div className="tw-mb-20">{acknowledgement}</div>
      <div>
        <SignaturePad name="buyer" label="Buyer" />
        {coBuyerPresent && <SignaturePad name="coBuyer" label="Co-Buyer" />}
      </div>
    </Accordion>
  )
}

export default Acknowledgement

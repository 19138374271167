import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import Helmet from 'react-helmet'
import CtaButton from 'Src/components/Buttons/CtaButton'
import BackButton from 'Src/components/Buttons/BackButton'
import {useCustomerDispatchContext, useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {
  backButtonBreadcrumbInteraction,
  breadcrumbProgressTracking,
  trans,
} from 'Src/utilities/Helpers'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {
  PROGRESS_STEP,
  useProgressDispatchContext,
  useProgressStateContext,
} from 'Src/contexts/ProgressContext'
import EmailQuote from 'Src/components/EmailQuote'
import {saveQuote} from 'Src/actions/DealActions'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {useProductContext} from 'Src/contexts/ProductContext'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import PageLayoutCenter from 'Src/components/Layouts/PageLayoutCenter'
import QuestionsCallDealershipPhone from 'Src/components/QuestionsCallDealershipPhone'
import PreviousDealModal from '../Deal/PreviousDealModal'

const Finalize = () => {
  const dealDispatch = useDealDispatchContext()
  const dealState = useDealStateContext()
  const {dealType} = dealState
  const customerState = useCustomerStateContext()
  const {leadVehicles, previouslySavedQuote} = customerState
  const leadVehicle = leadVehicles?.find(({isCurrent}) => isCurrent === true)
  const history = useHistory()
  const frameContext = useFrameContext()
  const {isSaveDealEnabled} = frameContext
  const ProgressDispatch = useProgressDispatchContext()
  const progress = useProgressStateContext()
  const customerDispatch = useCustomerDispatchContext()
  const {trackEvent, events} = useAnalytics()
  const {getSelectedRatedProducts} = useProductContext()
  const [hasInitialized, setHasInitialized] = useState(false)
  const pageTitle = trans('Finalize Your Journey')

  useEffect(() => {
    if (!hasInitialized && leadVehicle) {
      const saveQuoteAsync = async () => {
        await saveQuote(
          dealState,
          customerState,
          frameContext,
          customerDispatch,
          dealDispatch,
          getSelectedRatedProducts
        )
      }

      saveQuoteAsync()
      setHasInitialized(true)
    }
  }, [dealState, customerState, frameContext, customerDispatch])

  // On page load, set breadcrumb to Finalize
  useEffect(() => {
    ProgressDispatch({
      type: 'update',
      payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.finalize, dealType),
    })
  }, [])

  const handleScheduleButtonClick = () => {
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.SCHEDULE)
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.SCHEDULE)
    history.push('/schedule')
    ProgressDispatch({type: 'update', payload: {finalize: 'complete'}})
  }

  const handleMeetButtonClick = () => {
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.MEET)
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.MEET)
    history.push('/meet')
    ProgressDispatch({type: 'update', payload: {finalize: 'complete'}})
  }

  const handleBackButtonClick = () => {
    ProgressDispatch({
      type: 'update',
      payload: backButtonBreadcrumbInteraction(history, progress, dealType),
    })
  }

  const handleCheckAvailabilityButtonClick = () => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.PAGES.CHECK_AVAILABILITY
    )
    trackEvent(
      events.NAVIGATION,
      events.NAVIGATION.actions.NAVIGATE,
      ACTION_DETAILS.PAGES.CHECK_AVAILABILITY
    )
    history.push('/check-availability')
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageLayoutCenter className="tw-max-w-[450px]">
        <PageLayoutCenter.Header>{pageTitle}</PageLayoutCenter.Header>
        <PageLayoutCenter.Body>
          <h2 className="h1 tw-learning-normal tw-font-bold !tw-text-brand-success">
            {trans('Woohoo!')}
          </h2>
          <p className="tw-mb-3 tw-text-lg tw-font-semibold">{trans('Almost there!')}</p>
          <h3 className="tw-mb-6 tw-text-xl tw-font-semibold">
            {trans('What would you like to do next?')}
          </h3>
          <div className="tw-mb-5 tw-flex tw-flex-col tw-justify-around tw-gap-y-2">
            <CtaButton
              preset="secondary"
              className="!sm:tw-px-14 !tw-h-auto !tw-whitespace-normal !tw-px-10 !tw-py-2"
              onClick={handleCheckAvailabilityButtonClick}
            >
              <div className="tw-mb-1 tw-text-base tw-font-bold tw-text-brand-link">
                {trans('Check Availability')}
              </div>
              <div className="tw-text-sm tw-font-normal tw-text-brand-body_text">
                {trans('Love your vehicle? We’ll do everything we can to get you the keys ASAP!')}
              </div>
            </CtaButton>
            <CtaButton
              preset="secondary"
              className="!tw-h-auto !tw-whitespace-normal !tw-px-14 !tw-py-3"
              onClick={handleScheduleButtonClick}
              data-testid="schedule-button"
            >
              <div className="tw-mb-1 tw-text-base tw-font-bold tw-text-brand-link">
                {trans('Schedule a Test Drive')}
              </div>
              <div className="tw-text-sm tw-font-normal tw-text-brand-body_text">
                {trans(
                  'Want to take your vehicle for a spin? We’d be happy to bring the vehicle to you!'
                )}
              </div>
            </CtaButton>
            <CtaButton
              preset="secondary"
              className="!tw-h-auto !tw-whitespace-normal !tw-px-14 !tw-py-3"
              onClick={handleMeetButtonClick}
              data-testid="meet-button"
            >
              <div className="tw-mb-1 tw-text-base tw-font-bold tw-text-brand-link">
                {trans('Meet in Person or Remotely')}
              </div>
              <div className="tw-text-sm tw-font-normal tw-text-brand-body_text">
                {trans('We’ll answer any questions and get you the best deal possible!')}
              </div>
            </CtaButton>
          </div>
          <div className="tw-mb-2 tw-text-center">
            <EmailQuote
              isNotButton
              hasIcon
              linkClassName="tw-inline-block tw-px-6 tw-py-2 tw-font-bold tw-text-sm"
            />
          </div>
          <QuestionsCallDealershipPhone />
        </PageLayoutCenter.Body>
        <PageLayoutCenter.Footer>
          <div className="tw-mt-4 tw-py-2">
            <BackButton additionOnClick={handleBackButtonClick} />
          </div>
        </PageLayoutCenter.Footer>
      </PageLayoutCenter>
      {isSaveDealEnabled && previouslySavedQuote ? <PreviousDealModal /> : null}
    </>
  )
}

export default Finalize

import {useQuery} from 'react-query'
import {useFrameContext} from '../contexts/FrameContext'
import {dealershipKeys} from './key-factories/dealershipKeys'
import {fetchDealership} from 'Src/api/dealerships'
import {AxiosInstance} from 'axios'
import {deserialize} from 'jsonapi-fractal'
import {routes} from 'Src/api/routes'
import {getTradeIntegrationName} from 'Src/actions/TradeActions'

interface IUseFetchDealershipsProps {
  includes?: string[]
}

export const useFetchDealership = ({includes}: IUseFetchDealershipsProps) => {
  const {
    a2zApi,
    dealership: {id: dealershipId},
  } = useFrameContext()

  return useQuery(
    dealershipKeys.dealership(dealershipId, includes),
    async () => {
      if (dealershipId && a2zApi) {
        const res = await fetchDealership(a2zApi as AxiosInstance, dealershipId, {
          includes,
        })

        return deserialize(res?.data)
      }
    },
    {
      enabled: !!dealershipId,
      staleTime: 60 * 60 * 1000,
    }
  )
}

export const useFetchTradeIntegration = () => {
  const query = useFetchDealership({
    includes: [routes.dealerships.includes.TRADE_VALUE_INTEGRATION],
  })

  if (query?.data && !query.isError) {
    const integrationData: IIntegrationData = getTradeIntegrationName(query.data)

    return {
      ...query,
      ...integrationData,
    }
  }

  return query
}

export interface IIntegrationData {
  data: {
    integrationName: string
  } | null
  error: string | null
}

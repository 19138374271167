import React from 'react'
import PropTypes from 'prop-types'
import {Redirect, Route} from 'react-router'
import PlaceholderSpinner from 'Src/components/Spinners/PlaceholderSpinner'
import {useCustomerDispatchContext} from '../contexts/CustomerContext'
import {useFrameContext} from '../contexts/FrameContext'
import {TOKEN_STATE} from '../utilities/useToken'

const ProtectedRoute = ({children, override = false, ...rest}) => {
  const {tokenState} = useFrameContext()
  const customerDispatch = useCustomerDispatchContext()

  if (tokenState === TOKEN_STATE.LOADING) {
    return <PlaceholderSpinner />
  }

  const hasAccess = tokenState === TOKEN_STATE.AUTHENTICATED || override

  const redirectToRegistration = () => {
    // make sure user info is reset and redirect to registration page
    customerDispatch({type: 'clearCustomer'})
    return <Redirect to={{pathname: '/register'}} />
  }

  return <Route {...rest} render={() => (hasAccess ? children : redirectToRegistration())} />
}

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  override: PropTypes.bool,
}

export default ProtectedRoute

import React from 'react'
import {Helmet} from 'react-helmet'
import RegistrationLayout from '../../Layouts/RegistrationLayout'
import RegistrationForm from '../../Registration/RegistrationForm'
import RegistrationPageFooter from '../../Registration/RegistrationPageFooter'
import RegistrationPageHeader from '../../Registration/RegistrationPageHeader'
import {trans} from '../../../utilities/Helpers'

const Registration = () => (
  <RegistrationLayout>
    <Helmet>
      <title>{trans('reg.document_title')}</title>
    </Helmet>
    <RegistrationPageHeader />
    <RegistrationForm />
    <RegistrationPageFooter />
  </RegistrationLayout>
)

export default Registration

import React, {forwardRef} from 'react'
import classNames from 'classnames'
import TailwindTheme from 'tailwindcss/defaultTheme'

const TailwindBorderWidth = TailwindTheme['borderWidth']
type TBorderWidth = keyof typeof TailwindBorderWidth | string

const TailwindSpacing = TailwindTheme['spacing']
type TSpacing = keyof typeof TailwindSpacing | string

interface ISpinner extends React.HTMLAttributes<HTMLElement> {
  as?: keyof JSX.IntrinsicElements
  border?: TBorderWidth
  width?: TSpacing
}

/**
 * Value of the default width is "4", which equates to Tailwind CSS class name "tw-w-4".
 *
 * Because this class is dynamically generated using a template literal, we explicitly
 * include the full class name in this comment due to the way Tailwind CSS scans the
 * code (including comments) looking for fully-qualified classes to include in the build.
 *
 * See: https://www.codeconcisely.com/posts/tailwind-css-dynamic-class/
 */

export const DEFAULT_PROPS = {
  TAG: 'div',
  BORDER: 'tw-border-[0.2em]',
  WIDTH: '4',
}

const Spinner = forwardRef<HTMLElement, ISpinner>(
  (
    {
      as: Component = DEFAULT_PROPS.TAG,
      border = DEFAULT_PROPS.BORDER,
      width = DEFAULT_PROPS.WIDTH,
      className,
      ...props
    },
    ref
  ) => {
    const borderClassName = Object.keys(TailwindBorderWidth).includes(border)
      ? `tw-border-${border}`
      : border
    const widthClassName = Object.keys(TailwindSpacing).includes(width) ? `tw-w-${width}` : width

    return (
      <Component
        role="status"
        {...{
          ref,
          className: classNames(
            'tw-inline-block tw-aspect-square tw-rounded-full tw-border-current tw-border-r-transparent tw-animate-spin tw-transition tw-duration-300',
            className,
            {
              [borderClassName]: borderClassName,
              [widthClassName]: widthClassName,
            }
          ),
          ...props,
        }}
      />
    )
  }
)

export default Spinner

import React, {useEffect, useContext, useReducer} from 'react'
import {useFrameContext} from '../../contexts/FrameContext'
import {populateStateSelectors} from './CreditApplicationActions'
import CreditAppForm from './CreditAppForm'
import {initialState, LendingCenterContext, reducer} from './LendingCenterContext'

const CreditApp = () => {
  const [lendingCenterData, dispatch] = useReducer(reducer, initialState)

  const lendingCenterContext = {
    dispatch,
    lendingCenterData,
  }

  return (
    <LendingCenterContext.Provider value={lendingCenterContext}>
      <CreditAppIsLoading />
    </LendingCenterContext.Provider>
  )
}

const CreditAppIsLoading = () => {
  const {
    dispatch,
    lendingCenterData: {customerVisitId},
  } = useContext(LendingCenterContext)

  const creditAppPageLoad = () => {
    const {a2zApi} = useFrameContext()

    useEffect(() => {
      dispatch({customerVisitId})
      populateStateSelectors(dispatch, null, null, a2zApi)
    }, [])
  }

  creditAppPageLoad()

  return <CreditAppForm />
}

export default CreditApp

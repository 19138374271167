import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import PopoverHover from 'Src/components/Popovers/PopoverHover'
import IconQuestionCircle from 'Src/components/IconQuestionCircle'
import {useIsFetching} from 'react-query'
import {formatValue} from 'Src/utilities/DataReformatters'
import {useDealStateContext} from 'Src/contexts/DealContext'
import {useTradeContext} from 'Src/contexts/TradeContext'
import {parseCurrentData} from 'Src/utilities/ProgramHelpers'
import {useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useProductContext} from 'Src/contexts/ProductContext'
import {displayPhoneNumber, trans} from 'Src/utilities/Helpers'
import {renderTerm} from 'Src/utilities/ProductHelpers'
import {queryKey} from 'Src/queries/useDeskingWithSelectedProducts'
import VehicleImage from './VehicleImage'
import LineItemTooltip from '../../Tooltips/LineItemToolTip'
import AcquisitionFeesTooltip from './AcquisitionFeesTooltip'

const FinancialSummary = () => {
  const {isFetching: isFetchingDeskingWithProducts} = useIsFetching({
    queryKey,
  })
  const {TradeVehicleValue, estimatedPayoffAmount} = useTradeContext()
  const {trade, previouslySavedQuote} = useCustomerStateContext()
  const {
    dealership: {
      attributes: {phone: dealershipPhone},
    },
  } = useFrameContext()
  const {
    dealType,
    currentProgram,
    vehicle,
    fees,
    products,
    vehiclePricing: {discount, sellPrice},
  } = parseCurrentData(useDealStateContext())
  const {
    downPayment,
    downPaymentTax,
    downPaymentTaxRate,
    paymentTax,
    paymentTaxMonthly,
    paymentTaxRate,
    amountFinanced,
    payment,
    rebatesTotal,
    acquisitionFee,
    dueAtSigning,
    totalTax,
    totalTaxRate,
    totalDue,
    residualValue,
  } = currentProgram

  const {
    cashRatedProducts,
    leaseRatedProducts,
    financeRatedProducts,
    selectedProducts,
    getSelectedRatedProducts,
  } = useProductContext()
  const [productsWithoutTotal, setProductsWithoutTotal] = useState([])
  const [showProducts, setShowProducts] = useState(false)

  const productsTotal =
    products?.find((product) => product?.description?.toLowerCase() === 'total')?.value ?? 0
  const feesTotal = fees?.length ? fees[fees.length - 1].value : 0
  const feesWithoutTotal = fees?.length ? fees.slice(0, fees.length - 1) : []
  const {description, includedOptions, msrp, imageUrl} = vehicle

  const sellingPriceWithRebates = sellPrice - rebatesTotal
  const sellingPriceWithRebatesLessThanMSRP = sellingPriceWithRebates < msrp
  const includedOptionsValue =
    includedOptions?.reduce((prev, option) => prev + option.value, 0) || 0

  const mapProductToLineItem = (product) => {
    if (!product?.name) {
      return
    }

    const renderedTerm = renderTerm(product?.term, product?.mileage)
    const term = renderedTerm ? `: ${renderedTerm}` : ''

    return {
      description: `${product.name}${term}`,
      value: product.price || trans('Request Price'),
    }
  }

  // Returns true if there are products in the deal and they either are all zero price (Request Price)
  // or the product total from the deal state matches the total value of the products
  // This prevents showing the product total while the deal state is still loading after the initial
  // product is added to the deal state
  const shouldShowProducts = () => {
    const productTotalsMatch =
      productsWithoutTotal.reduce((prev, cur) => {
        if (typeof cur?.value === 'number') {
          return prev + cur.value
        }

        return prev
      }, 0) === productsTotal

    const productsDontHavePrices = !productsWithoutTotal.some(
      (product) => typeof product?.value === 'number'
    )

    // return false if there are no products
    if (!productsWithoutTotal.length) {
      return false
    }

    // return true if none of the products are rated (Request Price)
    if (productsDontHavePrices) {
      return true
    }

    // return false if there is only one product and the total does not match the deal state
    return productTotalsMatch || productsWithoutTotal.length !== 1
  }

  useEffect(() => {
    if (isFetchingDeskingWithProducts) {
      return
    }

    setShowProducts(shouldShowProducts())
  }, [productsWithoutTotal.length, productsTotal])

  useEffect(() => {
    setProductsWithoutTotal(
      getSelectedRatedProducts(dealType, true).map(mapProductToLineItem).filter(Boolean)
    )
  }, [selectedProducts[dealType].size, cashRatedProducts, leaseRatedProducts, financeRatedProducts])

  useEffect(() => {
    if (previouslySavedQuote && selectedProducts[dealType].size) {
      setShowProducts(true)
    }
  }, [previouslySavedQuote, selectedProducts[dealType].size])


  return (
    <div className="tw-pt-6 tw-mb-10" data-program-expired={currentProgram.isExpired}>
      <h1 id="side-panel-heading" className="h3 tw-text-center tw-mb-3">
        {trans('Vehicle Summary')}
      </h1>
      <div>
        <h2
          className="tw-text-lg tw-text-center tw-mb-3 tw-font-semibold"
          data-testid="description"
        >
          {description}
        </h2>
        <div className="tw-rounded tw-p-4">
          <VehicleImage url={imageUrl} data-testid="image" alt={description} />
        </div>
      </div>
      <div role="status" className="financial-summary" aria-live="polite">
        {vehicle.isNew ? (
          <>
            <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
              <span>{trans('MSRP')}</span>
              <p className="tw-whitespace-nowrap" data-testid="msrp">
                {formatValue(msrp)}
              </p>
            </div>
            {discount > 0 ? (
              <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
                <span>{trans('Discount')}</span>
                <p className="tw-whitespace-nowrap" data-testid="discount">
                  {formatValue(discount, true)}
                </p>
              </div>
            ) : null}
          </>
        ) : null}
        <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
          <span>{trans('Selling Price')}</span>
          <p className="tw-whitespace-nowrap" data-testid="selling-price">
            {formatValue(sellPrice)}
          </p>
        </div>
        {vehicle.isNew && rebatesTotal ? (
          <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
            <span>{trans('Rebates')}</span>
            <p className="tw-whitespace-nowrap" data-testid="rebates">
              {formatValue(rebatesTotal, true)}
            </p>
          </div>
        ) : null}
        {rebatesTotal ? (
          <TotalPriceRow
            sellingPrice={sellingPriceWithRebates}
            hasDiscount={!!sellingPriceWithRebatesLessThanMSRP}
          />
        ) : null}
        {dealType === 'lease' ? (
          <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
            <span data-testid="residual-label">{trans('Residual Value')}</span>
            <p className="tw-whitespace-nowrap" data-testid="residual">
              {formatValue(residualValue || 0)}
            </p>
          </div>
        ) : null}
        {vehicle.isNew && includedOptionsValue ? (
          <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
            <div>
              <span>{trans('Included Options')}</span>
              <PopoverHover
                content={<LineItemTooltip content={includedOptions} />}
                data-testid="included-options-tooltip"
              >
                <IconQuestionCircle />
              </PopoverHover>
            </div>
            <p className="tw-whitespace-nowrap" data-testid="included-options-value">
              {formatValue(includedOptionsValue)}
            </p>
          </div>
        ) : null}
        {trade ? (
          <>
            <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
              <div>
                <span>{trans('Est. Total Trade')}</span>
              </div>
              <p className="tw-whitespace-nowrap" data-testid="trade">
                &ndash;{formatValue(TradeVehicleValue)}
              </p>
            </div>
            {estimatedPayoffAmount > 0 ? (
              <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
                <div>
                  <span>{trans('Est. Trade Payoff')}</span>
                </div>
                <p className="tw-whitespace-nowrap" data-testid="trade-payoff">
                  {formatValue(estimatedPayoffAmount)}
                </p>
              </div>
            ) : null}
          </>
        ) : null}
        {showProducts ? (
          <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
            <div>
              <span>{trans('Protection Plans')}</span>
              <PopoverHover
                content={<LineItemTooltip content={productsWithoutTotal} />}
                data-testid="fees-tooltip"
              >
                <IconQuestionCircle />
              </PopoverHover>
            </div>
            <p className="tw-whitespace-nowrap" data-testid="protection-plans">
              {productsTotal ? formatValue(productsTotal) : trans('Request Price')}
            </p>
          </div>
        ) : null}
        {['finance', 'lease'].includes(dealType) && downPayment > 0 ? (
          <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
            <span>{trans('Down Payment')}</span>
            <p className="tw-whitespace-nowrap" data-testid="down-payment">
              {formatValue(downPayment, !!downPayment)}
            </p>
          </div>
        ) : null}
        {['finance', 'cash'].includes(dealType) ? (
          <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
            <span data-testid="tax-rate">
              {trans('Est. Taxes')} ({totalTaxRate.toFixed(2)}%)
            </span>
            <p className="tw-whitespace-nowrap" data-testid="taxes">
              {formatValue(totalTax)}
            </p>
          </div>
        ) : null}
        {dealType === 'lease' ? (
          <div>
            {downPaymentTax > 0 ? (
              <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
                <span data-testid="lease-down-payment-label">
                  {trans('estimated_down_payment_taxes')} ({downPaymentTaxRate.toFixed(3)}%)
                </span>
                <p className="tw-whitespace-nowrap" data-testid="lease-down-payment-taxes">
                  {formatValue(downPaymentTax)}
                </p>
              </div>
            ) : null}
            <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
              <span data-testid="lease-tax-amount" className="tw-mr-1">
                Est. {paymentTaxMonthly ? 'Monthly' : 'Upfront'} Taxes ({paymentTaxRate.toFixed(3)}
                %)
              </span>
              <p className="tw-whitespace-nowrap" data-testid="lease-tax">
                {formatValue(paymentTax)}
              </p>
            </div>
          </div>
        ) : null}
        {fees && fees.length > 0 ? (
          <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
            <div>
              <span>{trans('Est. Fees')}</span>
              {feesWithoutTotal.length ? (
                <PopoverHover
                  content={<LineItemTooltip content={feesWithoutTotal} />}
                  panelClassName="tw-w-60 tw-text-sm"
                  data-testid="fees-tooltip"
                >
                  <IconQuestionCircle />
                </PopoverHover>
              ) : null}
            </div>
            <p className="tw-whitespace-nowrap" data-testid="fees">
              {formatValue(feesTotal)}
            </p>
          </div>
        ) : null}
        {dealType === 'lease' ? (
          <div className="tw-flex tw-justify-between tw-gap-x-1 tw-m-2">
            <div>
              <span>{trans('Acquisition Fee')}</span>
              <AcquisitionFeesTooltip />
            </div>
            <p className="tw-whitespace-nowrap" data-testid="acquisition-fee">
              {formatValue(acquisitionFee)}
            </p>
          </div>
        ) : null}
        <div className="divider tw-my-5" />
        {dealType === 'finance' && payment > 0 ? (
          <div className="tw-flex tw-justify-between tw-gap-x-1 tw-text-lg tw-m-2">
            <span>{trans('Total Amount Financed')}</span>
            <p className="tw-whitespace-nowrap" data-testid="amount-financed">
              {formatValue(amountFinanced)}
            </p>
          </div>
        ) : null}
        {dealType === 'lease' ? (
          <div className="tw-flex tw-justify-between tw-gap-x-1 tw-text-lg tw-m-2">
            <span>{trans('Due at Signing')}</span>
            <p className="tw-whitespace-nowrap" data-testid="due-at-signing">
              {formatValue(dueAtSigning)}
            </p>
          </div>
        ) : null}
        {(dealType === 'finance' || dealType === 'lease') && payment <= 0 ? (
          <div
            className="tw-flex tw-justify-between tw-gap-x-1 tw-text-lg tw-m-2"
            data-testid="down-payment-exceeds-limits"
          >
            {trans('excess_lender_guidelines')}
            {dealershipPhone ? (
              <>
                &nbsp;
                {trans('call_for_personalized_assistance', {
                  phone: displayPhoneNumber(dealershipPhone),
                })}
              </>
            ) : null}
          </div>
        ) : null}
        {(dealType === 'finance' || dealType === 'lease') && payment > 0 ? (
          <div className="tw-flex tw-justify-between tw-text-lg tw-font-semibold tw-text-brand-success tw-m-2 tw-gap-1">
            <span>{trans('Monthly Payment')}</span>
            <p className="tw-whitespace-nowrap" data-testid="monthly-payment">
              {formatValue(payment)}
            </p>
          </div>
        ) : null}
        {dealType === 'cash' && totalDue <= 0 ? (
          <div
            className="tw-flex tw-justify-between tw-gap-x-1 tw-text-lg tw-m-2"
            data-testid="trade-value-exceeds-limits"
          >
            {trans('trade_in_excess_purchase_price')}
            {dealershipPhone ? (
              <>
                {trans('call_for_personalized_assistance', {
                  phone: displayPhoneNumber(dealershipPhone),
                })}
              </>
            ) : null}
          </div>
        ) : null}
        {dealType === 'cash' && totalDue > 0 ? (
          <div
            className="tw-flex tw-justify-between tw-font-semibold tw-text-lg tw-text-brand-success tw-m-2 tw-gap-1"
            data-testid="total-due"
          >
            <span>{trans('Total Due')} </span>
            <p className="tw-whitespace-nowrap" data-testid="cash-total-due">
              {formatValue(totalDue)}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

const TotalPriceRow = ({sellingPrice, hasDiscount}) => {
  const label = hasDiscount ? trans('Your Discounted Price') : trans('Your Total Price')

  return (
    <div className="tw-flex tw-justify-between tw-gap-x-1 tw-font-semibold tw-text-brand-success tw-m-2">
      <span data-testid="price-label">{label}</span>
      <p className="tw-whitespace-nowrap" data-testid="price">
        {formatValue(sellingPrice)}
      </p>
    </div>
  )
}

TotalPriceRow.propTypes = {
  sellingPrice: PropTypes.number,
  hasDiscount: PropTypes.bool,
}

export default FinancialSummary

import React from 'react'
import EmailQuote from 'Src/components/EmailQuote'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {trans} from 'Src/utilities/Helpers'

const CopyDealEmailQuote = () => {
  const {isShareQuoteEnabled} = useFrameContext()

  return (
    <>
      <p className="tw-mb-4 tw-text-sm">{trans('Would you also like a copy of your deal?')}</p>
      {isShareQuoteEnabled ? (
        <EmailQuote isNotButton hasIcon linkClassName="tw-font-semibold tw-py-1" />
      ) : (
        <EmailQuote buttonPreset="primary" buttonClassName="tw-h-12" />
      )}
    </>
  )
}

export default CopyDealEmailQuote

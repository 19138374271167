import React, {useState, useEffect, useRef} from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import NumberFormat from 'react-number-format'
import {useHistory} from 'react-router'
import Select from 'react-select'
import classNames from 'classnames'
import {useConfig} from 'Src/utilities/useConfig'
import {getAndSetCityList} from '../../actions/RegistrationActions'
import {useCustomerDispatchContext, useCustomerStateContext} from '../../contexts/CustomerContext'
import {useDealDispatchContext} from '../../contexts/DealContext'
import {useFrameContext} from '../../contexts/FrameContext'
import {useRegistrationContext} from '../../contexts/RegistrationContext'
import {useDebounce} from '../../utilities/CustomHooks'
import {customSelectStyles} from '../../utilities/customSelectStyles'
import {preventNonNumericalInput, trans} from '../../utilities/Helpers'
import {
  ExistingUserFormSchema,
  RegistrationFormSchema,
} from '../../validation-schemas/RegistrationFormSchema'
import Input from '../Input'
import {SHIFT_EVENTS, useTrackShiftEvents, useShiftEvents} from '../../utilities/useShiftEvents'
import {useAnalytics} from '../../services/analytics/useAnalytics'
import {ACTION_DETAILS} from '../../services/analytics/constants'
import CtaButton from '../Buttons/CtaButton'

const INPUT_NAMES = {
  CITY_COUNTY: 'cityCounty',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  ZIP_CODE: 'zipCode',
}

const RegistrationForm = () => {
  const customerDispatch = useCustomerDispatchContext()
  const dealDispatch = useDealDispatchContext()
  const history = useHistory()
  const {customerData: customer} = useCustomerStateContext()
  const {trackEvent, events} = useAnalytics()
  const {registrationState, registrationDispatch} = useRegistrationContext()
  const {newLead, cityListOptions, cityListLoading, cityListLoadingError} = registrationState
  const zipCodePlaceHolder = trans('zip_code_enter')
  const cityPlaceHolder = trans('city_select')
  const [cityListPlaceholder, setCityListPlaceholder] = useState(zipCodePlaceHolder)
  const {
    a2zApi,
    dealership: {
      attributes: {zip_code: dealershipZip},
    },
    vehicleData,
    isIframeVisible,
  } = useFrameContext()
  const {registrationForm: {phoneRequired} = {phoneRequired: false}} = useConfig()

  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors},
    clearErrors,
    formState: {isDirty},
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      newLead ? RegistrationFormSchema({phoneRequired}) : ExistingUserFormSchema
    ),
    defaultValues: customer,
  })

  const watchZipCode = watch(INPUT_NAMES.ZIP_CODE, '')

  useDebounce(
    async () => {
      if (!watchZipCode) {
        return
      }

      await getAndSetCityList(
        a2zApi,
        watchZipCode,
        registrationDispatch,
        registrationState.cityListZipCode
      )
    },
    250,
    [watchZipCode]
  )

  const {trackShiftEvents} = useShiftEvents()

  useTrackShiftEvents(isDirty && [SHIFT_EVENTS.LEAD_FORM_START])
  useTrackShiftEvents(!!vehicleData?.vin && isIframeVisible && [SHIFT_EVENTS.LEAD_FORM_SHOWN])

  useEffect(() => {
    switch (cityListOptions?.length || 0) {
      case 0:
        setCityListPlaceholder(zipCodePlaceHolder)
        setValue(INPUT_NAMES.CITY_COUNTY, '')
        break
      case 1:
        setValue(INPUT_NAMES.CITY_COUNTY, cityListOptions[0].value)
        break
      default: {
        const cityCountyOption =
          cityListOptions.find((x) => x?.value?.id === customer?.cityCounty?.id) || ''
        setValue(INPUT_NAMES.CITY_COUNTY, cityCountyOption.value)
        setCityListPlaceholder(cityPlaceHolder)
        break
      }
    }
  }, [cityListOptions])

  const buildClickOrNavEvent = (type, actionDetail) => {
    switch (type) {
      case 'nav':
        trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, actionDetail)
        break
      case 'click':
        trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, actionDetail)
        break
    }
  }

  const findMatchingLeads = (formData) => {
    const queryParams = [
      `filter[last_name]=${encodeURIComponent(formData.lastName)}`,
      `filter[email]=${encodeURIComponent(formData.email)}`,
      `include=customer.phones`,
    ].join('&')

    return a2zApi
      .get(`/customers/leads/verify?${queryParams}`)
      .then((response) => response.data)
      .then(({data, included}) =>
        data.map((lead) => {
          const includedCustomer = included.find(
            ({type, id}) => type === 'customers' && id === `${lead.attributes.customer_id}`
          )
          const includedCustomerPhone = included.find(
            ({type, id}) =>
              type === 'customerPhones' &&
              id === `${includedCustomer.relationships?.phones?.data[0]?.id}`
          )
          return {
            id: includedCustomer.id,
            leadId: lead.id,
            ...includedCustomer.attributes,
            phone: includedCustomerPhone?.attributes.number,
          }
        })
      )
  }

  const formRef = useRef()

  useEffect(() => {
    formRef.current.elements[0].focus()
    const values = getValues()
    const clearFields = []
    ;[INPUT_NAMES.FIRST_NAME, INPUT_NAMES.LAST_NAME].forEach((field) => {
      if (!values?.[field]?.length) {
        clearFields.push(field)
      }
    })
    clearErrors(clearFields)
  }, [newLead])

  const onSubmit = async (data) => {
    dealDispatch({type: 'update', payload: {loaded: false}})

    customerDispatch({type: 'setCustomerData', payload: data})

    if (data.phone && data.phone !== '') {
      trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.ADDED, ACTION_DETAILS.PHONE)
    }

    trackShiftEvents([SHIFT_EVENTS.LEAD_FORM_FINISH])

    findMatchingLeads(data).then((foundLeads) => {
      if (foundLeads.length >= 1) {
        registrationDispatch({
          type: 'setFoundLeads',
          payload: foundLeads,
        })

        buildClickOrNavEvent('click', ACTION_DETAILS.REGISTER.RETURN_USER.CONTINUE)
        buildClickOrNavEvent('nav', ACTION_DETAILS.REGISTER.MATCHING_LEAD)
        history.push('/register/matching-lead')
      } else if (!newLead) {
        // someone thought they were an existing lead, but no leads were found
        registrationDispatch({type: 'setNewLead', payload: true})
        registrationDispatch({type: 'setSkipMatchingLead', payload: true})
        buildClickOrNavEvent('click', ACTION_DETAILS.REGISTER.REDIRECT)
        buildClickOrNavEvent('nav', ACTION_DETAILS.PAGES.REGISTER)
        history.push('/register')
      } else {
        // we have a "true" new lead
        buildClickOrNavEvent('click', ACTION_DETAILS.REGISTER.FIRST_TIME_CONTINUE)
        buildClickOrNavEvent('nav', ACTION_DETAILS.REGISTER.NEW_LEAD.CONFIRMATION)
        history.push('/register/new-lead-confirmation')
      }
    })
  }

  return (
    <div className="tw-mx-6 tw-p-2">
      <form name="register" id="register" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <p id="errors" role="alert" aria-atomic="true" className="tw-sr-only">
          {Object.keys(errors).map((errorKey) => (
            <React.Fragment key={errorKey}>
              <span>{errors[errorKey].message}</span>
              <br />
            </React.Fragment>
          ))}
        </p>
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-mb-6">
          {newLead ? (
            <div
              className="lg:tw-w-2/4 lg:tw-mr-6 lg:tw-mb-0"
              id="register-firstName"
              aria-live="assertive"
              aria-atomic="false"
            >
              <label htmlFor="firstName" className="tw-mb-2 required input-label">
                {trans('first_name')}
              </label>
              <Input
                type="text"
                id="firstName"
                className="input-field tw-w-full"
                error={errors?.firstName?.message}
                {...register(INPUT_NAMES.FIRST_NAME)}
                defaultValue={customer?.firstName}
                autoComplete="given-name"
                required
                aria-required="true"
              />
            </div>
          ) : null}
          <div
            className={classNames('lg:tw-mt-0', {
              'lg:tw-w-3/5 tw-mt-6': newLead,
              'lg:tw-w-full': !newLead,
            })}
            id="register-lastName"
            aria-live="assertive"
            aria-atomic="false"
          >
            <label htmlFor="lastName" className="tw-mb-2 required input-label">
              {trans('last_name')}
            </label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              className="input-field tw-w-full"
              {...register(INPUT_NAMES.LAST_NAME)}
              error={errors?.lastName?.message}
              defaultValue={customer?.lastName}
              autoComplete="family-name"
              required
              aria-required="true"
            />
          </div>
        </div>
        <div
          className={classNames({
            'tw-mb-6': newLead,
            'tw-mb-12': !newLead,
          })}
          id="register-emailInput"
          aria-live="assertive"
          aria-atomic="false"
        >
          <label htmlFor="emailInput" className="tw-mb-2 required input-label">
            {trans('email')}
          </label>
          <Input
            type="email"
            placeholder="example@email.com"
            className="input-field tw-w-full md:tw-placeholder-transparent"
            id="emailInput"
            {...register(INPUT_NAMES.EMAIL)}
            error={errors?.email?.message}
            defaultValue={customer?.email}
            autoComplete="email"
            required
            aria-required="true"
          />
        </div>
        {newLead ? (
          <>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
              <div
                className="md:tw-w-1/5 md:tw-mr-6 tw-mb-6 tw-w-32"
                id="register-zipCode"
                aria-live="assertive"
                aria-atomic="false"
              >
                <label htmlFor="zipCode" className="tw-mb-2 required input-label">
                  {trans('zip')}
                </label>
                <Input
                  type="text"
                  id="zipCode"
                  placeholder={dealershipZip}
                  className="input-field tw-w-full md:tw-placeholder-transparent"
                  error={
                    errors?.zipCode?.message ? errors?.zipCode?.message : cityListLoadingError || ''
                  }
                  {...register(INPUT_NAMES.ZIP_CODE)}
                  defaultValue={customer?.zipCode}
                  onKeyPress={(e) => preventNonNumericalInput(e)}
                  maxLength={5}
                  inputMode="numeric"
                  autoComplete="postal-code"
                  required
                  aria-required="true"
                />
              </div>
              <div
                className="md:tw-w-4/5 tw-mb-6"
                id="register-cityCounty"
                aria-live="assertive"
                aria-atomic="false"
              >
                <label htmlFor="cityCounty" className="tw-mb-2 required input-label">
                  {trans('city_county')}
                </label>
                <Controller
                  name={INPUT_NAMES.CITY_COUNTY}
                  placeholder={trans('city_county')}
                  defaultValue={customer?.cityCounty}
                  control={control}
                  render={({field: {name, onChange, onBlur, value}}) => (
                    <Select
                      className="select md:tw-placeholder-transparent"
                      name={name}
                      inputId="cityCounty"
                      placeholder={cityListPlaceholder}
                      isLoading={cityListLoading}
                      isDisabled={!cityListOptions.length}
                      styles={customSelectStyles}
                      options={cityListOptions}
                      onChange={(option) => onChange(option?.value)}
                      onBlur={onBlur}
                      isSearchable={false}
                      value={
                        cityListOptions.find(
                          (x) => x?.value?.id === value?.id && x?.value?.inCity === value?.inCity
                        ) || ''
                      }
                    />
                  )}
                />
                {errors?.cityCounty && cityListOptions.length !== 1 ? (
                  <span className="error">{errors.cityCounty.message}</span>
                ) : null}
              </div>
            </div>
            <div
              className="tw-w-4/5 md:tw-w-3/5 tw-mb-12"
              id="register-phone"
              aria-live="assertive"
              aria-atomic="false"
            >
              <label
                htmlFor="phone"
                className={classNames('tw-mb-2 input-label', {required: phoneRequired})}
              >
                {trans('phone_number')}
              </label>
              <Controller
                name={INPUT_NAMES.PHONE}
                control={control}
                render={({field: {onChange, onBlur, name, value, ref}}) => (
                  <NumberFormat
                    {...{
                      getInputRef: ref,
                      id: 'phone',
                      className: 'input-field tw-w-full md:tw-placeholder-transparent',
                      defaultValue: customer?.phone,
                      format: '(###) ###-####',
                      mask: ' ',
                      name,
                      value,
                      'aria-required': phoneRequired,
                      autoComplete: 'tel-national',
                      onBlur,
                      onValueChange: (v) => onChange(v.value),
                    }}
                  />
                )}
              />
              {errors && errors.phone ? (
                <span className="error">{errors.phone.message}</span>
              ) : null}
            </div>
          </>
        ) : null}
        <div className="tw-flex tw-justify-center tw-mb-8">
          <CtaButton type="submit" className="tw-w-80">
            {trans('Continue')}
          </CtaButton>
        </div>
      </form>
    </div>
  )
}

export default RegistrationForm

import React from 'react'
import {useFormikContext, FieldArray} from 'formik'
import PropTypes from 'prop-types'
import Accordion from '../../../Utilities/Accordion'
import ResidencyFieldConfig from './ResidencyFieldConfig'
import MailingAddressFieldConfig from './MailingAddressFieldConfig'
import AdditionalResidencyFieldConfig from './AdditionalResidencyFieldConfig'
import initialResidenceValues from '../../../InitialValues/initialResidenceValues.json'
import MultiActionCheckbox from '../../../Form/Fields/MultiActionCheckbox'
import {
  HeaderGroup,
  DynamicGroup,
  AddGroupButton,
} from '../../../Form/FormUtilities/DynamicFieldArrayGroup'
import {sectionHasErrors} from '../../../CreditApplicationActions'

const ResidencyInformation = ({prefix}) => {
  const {values, setFieldValue, errors, isSubmitting} = useFormikContext()

  const addButtonText = 'Add Previous Address'
  const removeButtonText = 'Remove Previous Address'

  const fieldArrayName = prefix ? 'coBuyers[0].residences' : 'residences'

  const groupArray = prefix ? values.coBuyers[0].residences : values.residences

  const mailingAddressCheck = prefix
    ? values.coBuyers[0].residences.length > 0 && values.coBuyers[0].residences[0].mailingAddress
    : values.residences[0].mailingAddress

  const shouldRenderFields = prefix ? !values.coBuyers[0].residenceSameAsBuyer : true
  const isCoBuyer = prefix && prefix === 'coBuyers'

  return (
    <Accordion
      id={`${prefix || ''}residency`}
      title={`${prefix ? 'Co-Buyer ' : ''}Residency & Mailing Address`}
      hasErrors={
        isSubmitting &&
        (sectionHasErrors(errors, ResidencyFieldConfig().rows, 'residences', isCoBuyer) ||
          sectionHasErrors(errors, MailingAddressFieldConfig().rows, 'residences', isCoBuyer) ||
          sectionHasErrors(errors, AdditionalResidencyFieldConfig().rows, 'residences', isCoBuyer))
      }
    >
      {isCoBuyer ? (
        <>
          <div className="tw-flex tw-flex-row tw-justify-between tw-self-center tw-mb-2">
            <h3 className="h5">Current Address</h3>
          </div>
          <div className="md:tw-mx-6">
            <MultiActionCheckbox
              name="coBuyers[0].residenceSameAsBuyer"
              postLabel="Same as Buyer"
              onChange={(event) => {
                setFieldValue(
                  'coBuyers[0].residences',
                  event.target.checked
                    ? []
                    : [
                        {
                          ...initialResidenceValues,
                          ...{mailingAddress: true, currentAddress: true},
                        },
                      ]
                )
                setFieldValue(
                  'coBuyers[0].isCommunityPropertyResidence',
                  event.target.checked && values.isCommunityPropertyResidence
                )
                setFieldValue('coBuyers[0].residenceSameAsBuyer', event.target.checked)
              }}
            />
          </div>
        </>
      ) : null}
      {shouldRenderFields ? (
        <FieldArray
          name={fieldArrayName}
          render={(arrayHelpers) => (
            <div>
              {groupArray.map((residence, index) => (
                <div key={index.toString()}>
                  {index === 0 && values.residences[0].currentAddress && (
                    <div>
                      <HeaderGroup
                        index={index}
                        formGroupHeading={prefix && prefix === 'coBuyers' ? ' ' : 'Current Address'}
                        arrayHelpers={arrayHelpers}
                        removeButtonText={removeButtonText}
                        rows={ResidencyFieldConfig().rows}
                        arrayName={fieldArrayName}
                      />
                      <MultiActionCheckbox
                        name={`${fieldArrayName}[${index}].mailingAddress`}
                        label="Mailing Address"
                        postLabel="Same as Current Address"
                        groupWidth={12}
                        onChange={(event) => {
                          // toggles mailing address section
                          event.target.checked
                            ? arrayHelpers.remove(1)
                            : arrayHelpers.insert(1, {
                                ...initialResidenceValues,
                                ...{mailingAddress: true},
                              })
                          setFieldValue(
                            `${fieldArrayName}[${index}].mailingAddress`,
                            event.target.checked
                          )
                        }}
                      />
                    </div>
                  )}
                  {index === 1 && !mailingAddressCheck && (
                    <HeaderGroup
                      index={index}
                      formGroupHeading="Mailing Address"
                      arrayHelpers={arrayHelpers}
                      removeButtonText={removeButtonText}
                      rows={MailingAddressFieldConfig().rows}
                      arrayName={fieldArrayName}
                    />
                  )}
                  {(mailingAddressCheck ? index > 0 : index > 1) && (
                    <DynamicGroup
                      index={index}
                      formGroupHeading={`Previous Address ${
                        mailingAddressCheck ? index : index - 1
                      }`}
                      arrayHelpers={arrayHelpers}
                      removeButtonText={removeButtonText}
                      rows={AdditionalResidencyFieldConfig().rows}
                      arrayName={fieldArrayName}
                    />
                  )}
                  <AddGroupButton
                    addButtonText={addButtonText}
                    arrayHelpers={arrayHelpers}
                    groupArray={groupArray}
                    index={index}
                    maxFields={mailingAddressCheck ? 9 : 10}
                    addFields={
                      index === 1
                        ? {...initialResidenceValues, ...{mailingAddress: false}}
                        : initialResidenceValues
                    }
                  />
                </div>
              ))}
            </div>
          )}
        />
      ) : null}
    </Accordion>
  )
}

ResidencyInformation.propTypes = {
  prefix: PropTypes.string,
}

export default ResidencyInformation

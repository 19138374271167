import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDealDispatchContext, useDealStateContext} from '../../../contexts/DealContext'
import {resetDownPaymentState} from '../../../actions/DealActions'
import {useAnalytics} from '../../../services/analytics/useAnalytics'
import CtaButton from '../../Buttons/CtaButton'
import {CSS_SECONDARY_BUTTON_GROUP} from '../constants'

const FinanceTerms = ({terms}) => {
  const {retailTerm, retailDownPayment, isUpdating} = useDealStateContext()
  const dispatch = useDealDispatchContext()
  const {trackEvent, events} = useAnalytics()
  const [financeTermChangeCount, setFinanceTermChangeCount] = useState(0)
  const trackFinanceChangeEvent = (months) => {
    const changeCount = financeTermChangeCount + 1
    setFinanceTermChangeCount(changeCount)
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CHANGE, `term ${months}`, null, {
      financeTermChangeCount: changeCount,
    })
  }

  return (
    <div className="mt-3">
      <div className="tw-grid tw-gap-y-3 tw-gap-x-6 md:tw-grid-cols-3">
        {Object.keys(terms).map((months) => {
          const downPaymentGroups = terms?.[months]?.downPaymentGroup
          // Default to first if none for default down payment
          const apr = (
            downPaymentGroups?.[retailDownPayment] ||
            downPaymentGroups?.[Object.keys(downPaymentGroups)[0]]
          )?.rate
          const isActive = retailTerm === months

          return (
            <CtaButton
              key={months}
              preset={isActive ? 'secondaryActive' : 'secondaryInactive'}
              className={CSS_SECONDARY_BUTTON_GROUP}
              onClick={() => {
                resetDownPaymentState(dispatch)
                dispatch({type: 'update', payload: {retailTerm: months}})
                trackFinanceChangeEvent(months)
              }}
              disabled={isUpdating}
              aria-pressed={isActive}
              aria-labelledby={`payment-term-${months}-months`}
              data-testid="button"
            >
              <span data-testid="months" id={`payment-term-${months}-months`}>
                {months} months
              </span>
              {isActive && apr !== null ? (
                <div className="tw-w-full tw-text-center tw-text-xs" data-testid="apr">
                  {`${apr}% APR`}
                </div>
              ) : null}
            </CtaButton>
          )
        })}
      </div>
    </div>
  )
}

FinanceTerms.propTypes = {
  terms: PropTypes.shape({}),
}

export default FinanceTerms

import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useProgressDispatchContext} from '../contexts/ProgressContext'
import {useCustomerStateContext} from '../contexts/CustomerContext'
import {useTradeContext} from '../contexts/TradeContext'
import {formatValue} from '../utilities/DataReformatters'

const TradeSummary = ({TradeVehicleValue, estimatedPayoffAmount}) => {
  const ProgressDispatch = useProgressDispatchContext()
  const {tradeIntegration} = useTradeContext()
  const {trade} = useCustomerStateContext()

  useEffect(() => {
    if (trade) {
      ProgressDispatch({type: 'update', payload: {trade: 'complete'}})
    }
  }, [])

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center lg:tw-border-b tw-mb-4">
        <div>{tradeIntegration === 'kbb' ? 'Kelley Blue Book®' : ''} Trade-In Value</div>
        <div>{formatValue(TradeVehicleValue)}</div>
      </div>
      <div className="tw-flex tw-justify-between tw-items-center lg:tw-border-b tw-mb-4">
        <div>Estimated Trade Payoff</div>
        <div>{formatValue(estimatedPayoffAmount)}</div>
      </div>
      <div className="tw-flex tw-justify-between tw-items-center lg:tw-border-b tw-font-bold tw-text-brand-success tw-mb-8">
        <div>Estimated Total Trade Amount*</div>
        <div>{formatValue(TradeVehicleValue - estimatedPayoffAmount)}</div>
      </div>
    </>
  )
}

export default TradeSummary

TradeSummary.propTypes = {
  TradeVehicleValue: PropTypes.number,
  estimatedPayoffAmount: PropTypes.number,
}

import React from 'react'
import PropTypes from 'prop-types'
import placeholder from '../../../images/vehicle_placeholder.svg'

const VehicleImage = ({url, alt = ''}) => {
  const props = {src: url, alt, 'aria-describedby': 'summary-image'}

  if (!url) {
    props.src = placeholder
    props.alt = 'Not Found'
  }

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...props} />
}

VehicleImage.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
}

export default VehicleImage

import React from 'react'
import _ from 'lodash'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {useFormikContext} from 'formik'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {trans} from 'Src/utilities/Helpers'
import Accordion from '../../../Utilities/Accordion'
import ReferenceFieldConfig from './ReferencesFieldConfig'
import {AddPrefixToFieldConfig} from '../CustomerInfo/FieldNameUtility'
import FormRowRenderer from '../../../Form/FormUtilities/FormRowRenderer'
import {sectionHasErrors} from '../../../CreditApplicationActions'

const ReferencesRequiredFieldHeader = () => (
  <div className="tw-flex tw-flex-nowrap tw-items-center">
    <FontAwesomeIcon
      icon={faExclamationTriangle}
      className="tw-text-brand-body_text--warning tw-mr-2"
    />
    <div>{trans('credit_app.references_required_field_header')}</div>
  </div>
)

const PersonalReferences = () => {
  const {errors, isSubmitting} = useFormikContext()
  const fieldArrayName = 'references'
  const {
    creditAppConfig: {
      requirements: {customerReferences},
    },
  } = useFrameContext()
  const referenceGroupCount =
    !parseInt(customerReferences, 10) || parseInt(customerReferences, 10) < 3
      ? 2
      : parseInt(customerReferences, 10)

  return (
    <Accordion
      id="references"
      title="Personal References"
      headerOptions={{component: ReferencesRequiredFieldHeader}}
      show={false}
      hasErrors={
        isSubmitting && sectionHasErrors(errors, ReferenceFieldConfig().rows, 'references')
      }
    >
      {_.times(referenceGroupCount, (index) => {
        const {rows} = AddPrefixToFieldConfig(
          ReferenceFieldConfig({index, required: !!errors?.references?.[index]}),
          `${fieldArrayName}[${index}].`
        )

        return (
          <div key={`reference-${index}`}>
            {index ? <div className="divider tw-my-6" /> : null}
            <div className="tw-flex tw-flex-row tw-justify-between tw-self-center tw-mb-2">
              <h3 className="h5">Reference {index + 1}</h3>
            </div>
            <FormRowRenderer className="md:tw-mx-6" rows={rows} />
          </div>
        )
      })}
    </Accordion>
  )
}

export default PersonalReferences

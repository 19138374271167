import * as Yup from 'yup'

const regExp = {
  ssn: /^(?!000)\d{3}([ -]?)(?!00)\d{2}\1(?!0000)\d{4}$/,
  phone: /^\(?([2-9][0-8]\d)\)?[\s-]?([2-9]\d{2})[\s-]?(\d{4})$/,
  zipCode: /^\d{5}$/,
}

const currentDate = new Date()
const earliestDate = new Date()
earliestDate.setFullYear(currentDate.getFullYear() - 120)

Yup.addMethod(
  Yup.object,
  'optional',
  function callback(isOptional = true, defaultValue = undefined) {
    return this.transform((value) => {
      if (!isOptional) {
        return value
      }

      // If any child property has a value, skip the transform
      if (value && Object.values(value).some((v) => !(v === null || v === undefined || v === ''))) {
        return value
      }

      return defaultValue
    }).default(defaultValue)
  }
)

export const CreditApplicationSchema = Yup.object().shape({
  buyerTypeSigned: Yup.boolean()
    .when('coBuyerPresent', {
      is: (coBuyerPresent) => coBuyerPresent,
      then: Yup.boolean().oneOf([true], 'Buyer signature is required'),
    })
    .label('This'),
  buyerTypeChecked: Yup.boolean()
    .when('coBuyerPresent', {
      is: (coBuyerPresent) => coBuyerPresent,
      then: Yup.boolean().oneOf([true], 'Buyer must accept terms and conditions'),
    })
    .label('This'),
  coBuyerTypeSigned: Yup.boolean()
    .when('coBuyerPresent', {
      is: (coBuyerPresent) => coBuyerPresent,
      then: Yup.boolean().oneOf([true], 'Co-Buyer signature is required'),
    })
    .label('This'),
  coBuyerTypeChecked: Yup.boolean()
    .when('coBuyerPresent', {
      is: (coBuyerPresent) => coBuyerPresent,
      then: Yup.boolean().oneOf([true], 'Co-Buyer must accept terms and conditions'),
    })
    .label('This'),
  firstName: Yup.string()
    .trim()
    .min(2, 'This field must be two or more characters')
    .max(100, 'This field must be fewer than 100 characters')
    .required()
    .label('This'),
  lastName: Yup.string()
    .trim()
    .min(2, 'This field must be two or more characters')
    .max(100, 'This field must be fewer than 100 characters')
    .required()
    .label('This'),
  middleName: Yup.string()
    .trim()
    .min(1, 'This field must be two or more characters')
    .max(100, 'This field must be fewer than 100 characters')
    .nullable()
    .label('This'),
  email: Yup.string()
    .trim()
    .email('A valid email address is required')
    .max(100, 'Email must be fewer than 100 characters')
    .required()
    .label('This'),
  phone1: Yup.string()
    .trim()
    .min(10, 'Phone number should be ten digits, including area code')
    .matches(regExp.phone, 'Phone number is not valid')
    .required()
    .label('This'),
  phoneType1: Yup.string().required('Select Phone Type').label('This'),
  phone2: Yup.string()
    .trim()
    .min(10, 'Phone number should be ten digits, including area code')
    .matches(regExp.phone, 'Phone number is not valid')
    .test('oneOfRequired', 'This is a required field when type selected', function callback() {
      return (
        this.parent.phone2 ||
        (this.parent.phone2 && this.parent.phoneType2) ||
        (!this.parent.phone2 && !this.parent.phoneType2)
      )
    })
    .label('This'),
  phoneType2: Yup.string()
    .test('oneOfRequired', 'Select Phone Type', function callback() {
      return (
        this.parent.phoneType2 ||
        (this.parent.phone2 && this.parent.phoneType2) ||
        (!this.parent.phone2 && !this.parent.phoneType2)
      )
    })
    .label('This'),
  maritalStatus: Yup.string()
    .when('isCommunityPropertyResidence', {
      is: (isCommunityPropertyResidence) => isCommunityPropertyResidence,
      then: Yup.string().required(),
    })
    .label('This'),
  dob: Yup.date()
    .max(currentDate, 'Date of birth must not be later than today')
    .min(earliestDate, 'Please enter a valid date of birth')
    .required()
    .typeError('Please enter a valid date of birth')
    .label('This'),
  ssn: Yup.string()
    .trim()
    .length(11, 'Please enter a valid social security number')
    .required()
    .matches(regExp.ssn, 'Social Security number is not valid')
    .label('This'),
  driverLicenseNumber: Yup.string()
    .trim()
    .max(19, 'This field must be fewer than 20 characters')
    .required()
    .label('This'),
  driverLicenseState: Yup.number().required().label('This'),
  residences: Yup.array().of(
    Yup.object({
      address1: Yup.string()
        .trim()
        .min(2, 'This field must be two or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .required()
        .label('This'),
      address2: Yup.string()
        .trim()
        .min(1, 'This field must be one or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .nullable()
        .label('This'),
      city: Yup.string()
        .trim()
        .min(2, 'This field must be two or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .required()
        .label('This'),
      state: Yup.number().required().label('This'),
      zipCode: Yup.string()
        .trim()
        .matches(regExp.zipCode, 'Zip Code must be 5 digits')
        .required()
        .label('This'),
      residenceType: Yup.string()
        .test('oneOfRequired', 'This is a required field', function callback() {
          return (
            (this.parent.mailingAddress && !this.parent.currentAddress) || this.parent.residenceType
          )
        })
        .nullable()
        .label('This'),
      housingDurationYears: Yup.number()
        .min(0, 'Please enter a value between 0 - 110')
        .max(110, 'Please enter a value between 0 - 110')
        .test('oneOfRequired', 'Residency must be >= 1 month', function callback() {
          return (
            (this.parent.mailingAddress && !this.parent.currentAddress) ||
            this.parent.housingDurationMonths ||
            this.parent.housingDurationYears
          )
        })
        .nullable()
        .label('This'),
      housingDurationMonths: Yup.number()
        .min(0, 'Please enter a value between 0 - 11')
        .max(11, 'Please enter a value between 0 - 11')
        .test('oneOfRequired', 'Residency must be >= 1 month', function callback() {
          return (
            (this.parent.mailingAddress && !this.parent.currentAddress) ||
            this.parent.housingDurationMonths ||
            this.parent.housingDurationYears
          )
        })
        .nullable()
        .label('This'),
      housingPayment: Yup.number()
        .when(['currentAddress'], {
          is: (currentAddress) => currentAddress,
          then: Yup.number().required(),
        })
        .nullable()
        .label('This'),
      mailingAddress: Yup.boolean().label('This'),
      currentAddress: Yup.boolean().label('This'),
    })
  ),
  employers: Yup.array().of(
    Yup.object({
      name: Yup.string()
        .trim()
        .min(2, 'This field must be two or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .required()
        .label('This'),
      phone: Yup.string()
        .trim()
        .min(10, 'Phone number should be ten digits, including area code')
        .matches(regExp.phone, 'Phone number is not valid')
        .required()
        .label('This'),
      address1: Yup.string()
        .trim()
        .min(2, 'This field must be two or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .required()
        .label('This'),
      address2: Yup.string()
        .trim()
        .min(1, 'This field must be one or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .label('This')
        .nullable()
        .label('This'),
      city: Yup.string()
        .trim()
        .min(2, 'This field must be two or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .required()
        .label('This'),
      state: Yup.number().required().label('This'),
      zipCode: Yup.string()
        .trim()
        .matches(regExp.zipCode, 'Zip Code must be 5 digits')
        .required()
        .label('This'),
      jobTitle: Yup.string()
        .trim()
        .min(2, 'This field must be two or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .required()
        .label('This'),
      employmentYears: Yup.number()
        .min(0, 'Please enter a value between 0 - 110')
        .max(110, 'Please enter a value between 0 - 110')
        .test('oneOfRequired', 'Employment must be >= 1 month', function callback() {
          return this.parent.employmentMonths || this.parent.employmentYears
        })
        .nullable()
        .label('This'),
      employmentMonths: Yup.number()
        .min(0, 'Please enter a value between 0 - 11')
        .max(11, 'Please enter a value between 0 - 11')
        .test('oneOfRequired', 'Employment must be >= 1 month', function callback() {
          return this.parent.employmentMonths || this.parent.employmentYears
        })
        .nullable()
        .label('This'),
      salary: Yup.number()
        .min(0)
        .max(100000000)
        .when(['currentEmployer'], {
          is: (currentEmployer) => currentEmployer,
          then: Yup.number().required(),
        })
        .label('This'),
      incomeReceived: Yup.string()
        .when(['currentEmployer'], {
          is: (currentEmployer) => currentEmployer,
          then: Yup.string().required(),
        })
        .label('This'),
      currentEmployer: Yup.boolean().label('This'),
    })
  ),
  otherIncome: Yup.array().of(
    Yup.object({
      amount: Yup.number().min(0).max(100000000).required().label('This'),
      received: Yup.string().required().label('This'),
      source: Yup.string()
        .trim()
        .min(1, 'This field must be two or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .required()
        .label('This'),
    }).optional()
  ),
  references: Yup.array().of(
    Yup.object({
      relationshipDescription: Yup.string()
        .trim()
        .max(9, 'This field must be fewer than 10 characters')
        .test('oneOfRequired', 'This is a required field', function callback() {
          return (
            this.parent.relationshipDescription ||
            (!this.parent.firstName &&
              !this.parent.lastName &&
              !this.parent.phone &&
              !this.parent.address1 &&
              !this.parent.city &&
              !this.parent.state &&
              !this.parent.zipCode)
          )
        })
        .label('This'),
      firstName: Yup.string()
        .trim()
        .min(2, 'This field must be two or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .test('oneOfRequired', 'This is a required field', function callback() {
          return (
            this.parent.firstName ||
            (!this.parent.relationshipDescription &&
              !this.parent.lastName &&
              !this.parent.phone &&
              !this.parent.address1 &&
              !this.parent.city &&
              !this.parent.state &&
              !this.parent.zipCode)
          )
        })
        .label('This'),
      lastName: Yup.string()
        .trim()
        .min(2, 'This field must be two or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .test('oneOfRequired', 'This is a required field', function callback() {
          return (
            this.parent.lastName ||
            (!this.parent.relationshipDescription &&
              !this.parent.firstName &&
              !this.parent.phone &&
              !this.parent.address1 &&
              !this.parent.city &&
              !this.parent.state &&
              !this.parent.zipCode)
          )
        })
        .label('This'),
      phone: Yup.string()
        .trim()
        .min(10, 'Phone number should be ten digits, including area code')
        .matches(regExp.phone, 'Phone number is not valid')
        .test('oneOfRequired', 'This is a required field', function callback() {
          return (
            this.parent.phone ||
            (!this.parent.relationshipDescription &&
              !this.parent.firstName &&
              !this.parent.lastName &&
              !this.parent.address1 &&
              !this.parent.city &&
              !this.parent.state &&
              !this.parent.zipCode)
          )
        })
        .label('This'),
      address1: Yup.string()
        .trim()
        .min(2, 'This field must be two or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .test('oneOfRequired', 'This is a required field', function callback() {
          return (
            this.parent.address1 ||
            (!this.parent.relationshipDescription &&
              !this.parent.firstName &&
              !this.parent.lastName &&
              !this.parent.phone &&
              !this.parent.city &&
              !this.parent.state &&
              !this.parent.zipCode)
          )
        })
        .label('This'),
      address2: Yup.string()
        .trim()
        .min(1, 'This field must be one or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .nullable()
        .label('This'),
      city: Yup.string()
        .trim()
        .min(2, 'This field must be two or more characters')
        .max(100, 'This field must be fewer than 100 characters')
        .test('oneOfRequired', 'This is a required field', function callback() {
          return (
            this.parent.city ||
            (!this.parent.relationshipDescription &&
              !this.parent.firstName &&
              !this.parent.lastName &&
              !this.parent.phone &&
              !this.parent.address1 &&
              !this.parent.state &&
              !this.parent.zipCode)
          )
        })
        .label('This'),
      state: Yup.number()
        .test('oneOfRequired', 'This is a required field', function callback() {
          return (
            this.parent.state ||
            (!this.parent.relationshipDescription &&
              !this.parent.firstName &&
              !this.parent.lastName &&
              !this.parent.phone &&
              !this.parent.address1 &&
              !this.parent.city &&
              !this.parent.zipCode)
          )
        })
        .label('This'),
      zipCode: Yup.string()
        .trim()
        .matches(regExp.zipCode, 'Zip Code must be 5 numbers')
        .test('oneOfRequired', 'This is a required field', function callback() {
          return (
            this.parent.zipCode ||
            (!this.parent.relationshipDescription &&
              !this.parent.firstName &&
              !this.parent.lastName &&
              !this.parent.phone &&
              !this.parent.address1 &&
              !this.parent.state &&
              !this.parent.city)
          )
        })
        .label('This'),
    })
  ),
  spouse: Yup.array()
    .nullable()
    .when(['isCommunityPropertyResidence', 'maritalStatus', 'isCoBuyerSpouse'], {
      is: (isCommunityPropertyResidence, maritalStatus, isCoBuyerSpouse) =>
        isCommunityPropertyResidence &&
        (maritalStatus === 'Married' || maritalStatus === 'Separated') &&
        !isCoBuyerSpouse,
      then: Yup.array().of(
        Yup.object({
          firstName: Yup.string()
            .trim()
            .min(2, 'This field must be two or more characters')
            .max(100, 'This field must be fewer than 100 characters')
            .required()
            .label('This'),
          lastName: Yup.string()
            .trim()
            .min(2, 'This field must be two or more characters')
            .max(100, 'This field must be fewer than 100 characters')
            .required()
            .label('This'),
          phone: Yup.string()
            .trim()
            .min(10, 'Phone number should be ten digits, including area code')
            .matches(regExp.phone, 'Phone number is not valid')
            .required()
            .label('This'),
          sameAddressAs: Yup.boolean().label('This'),
          address1: Yup.string()
            .when('sameAddressAs', {
              is: false,
              then: Yup.string()
                .trim()
                .min(2, 'This field must be two or more characters')
                .max(100, 'This field must be fewer than 100 characters')
                .required(),
            })
            .nullable()
            .label('This'),
          address2: Yup.string()
            .trim()
            .min(1, 'This field must be one or more characters')
            .max(100, 'This field must be fewer than 100 characters')
            .nullable()
            .label('This'),
          city: Yup.string()
            .when('sameAddressAs', {
              is: false,
              then: Yup.string()
                .trim()
                .min(2, 'This field must be two or more characters')
                .max(100, 'This field must be fewer than 100 characters')
                .required(),
            })
            .nullable()
            .label('This'),
          state: Yup.number()
            .when('sameAddressAs', {
              is: false,
              then: Yup.number().required(),
            })
            .nullable()
            .label('This'),
          zipCode: Yup.string()
            .when('sameAddressAs', {
              is: false,
              then: Yup.string().matches(regExp.zipCode, 'Zip Code must be 5 digits').required(),
            })
            .trim()
            .nullable()
            .label('This'),
        })
      ),
    }),
  coBuyerPresent: Yup.boolean().required(),
  coBuyers: Yup.array()
    .nullable()
    .when('coBuyerPresent', {
      is: (coBuyerPresent) => coBuyerPresent,
      then: Yup.array().of(
        Yup.object({
          firstName: Yup.string()
            .trim()
            .min(2, 'This field must be two or more characters')
            .max(100, 'This field must be fewer than 100 characters')
            .required()
            .label('This'),
          lastName: Yup.string()
            .trim()
            .min(2, 'This field must be two or more characters')
            .max(100, 'This field must be fewer than 100 characters')
            .required()
            .label('This'),
          middleName: Yup.string()
            .trim()
            .min(1, 'This field must be two or more characters')
            .max(100, 'This field must be fewer than 100 characters')
            .nullable()
            .label('This'),
          email: Yup.string()
            .trim()
            .email('A valid email address is required')
            .max(100, 'Email must be fewer than 100 characters')
            .required()
            .label('This'),
          phone1: Yup.string()
            .trim()
            .min(10, 'Phone number should be ten digits, including area code')
            .matches(regExp.phone, 'Phone number is not valid')
            .required()
            .label('This'),
          phoneType1: Yup.string().required('Select Phone Type').label('This'),
          phone2: Yup.string()
            .trim()
            .min(10, 'Phone number should be ten digits, including area code')
            .matches(regExp.phone, 'Phone number is not valid')
            .test(
              'oneOfRequired',
              'This is a required field when type selected',
              function callback() {
                return (
                  this.parent.phone2 ||
                  (this.parent.phone2 && this.parent.phoneType2) ||
                  (!this.parent.phone2 && !this.parent.phoneType2)
                )
              }
            )
            .label('This'),
          phoneType2: Yup.string()
            .test('oneOfRequired', 'Select Phone Type', function callback() {
              return (
                this.parent.phoneType2 ||
                (this.parent.phone2 && this.parent.phoneType2) ||
                (!this.parent.phone2 && !this.parent.phoneType2)
              )
            })
            .label('This'),
          maritalStatus: Yup.string()
            .when('isCommunityPropertyResidence', {
              is: (isCommunityPropertyResidence) => isCommunityPropertyResidence,
              then: Yup.string().required(),
            })
            .label('This'),
          dob: Yup.date()
            .max(currentDate, 'Date of birth must not be later than today')
            .min(earliestDate, 'Please enter a valid date of birth')
            .required()
            .typeError('Please enter a valid date of birth')
            .label('This'),
          ssn: Yup.string()
            .trim()
            .length(11, 'Please enter a valid social security number')
            .required()
            .matches(regExp.ssn, 'Social Security number is not valid')
            .label('This'),
          relationship: Yup.string().required('Relationship is required').label('This'),
          driverLicenseNumber: Yup.string()
            .trim()
            .max(19, 'This field must be fewer than 20 characters')
            .required()
            .label('This'),
          driverLicenseState: Yup.number().required().label('This'),
          residenceSameAsBuyer: Yup.boolean().label('This'),
          residences: Yup.array()
            .nullable()
            .when('residenceSameAsBuyer', {
              is: false,
              then: Yup.array().of(
                Yup.object({
                  address1: Yup.string()
                    .trim()
                    .min(2, 'This field must be two or more characters')
                    .max(100, 'This field must be fewer than 100 characters')
                    .required()
                    .label('This'),
                  address2: Yup.string()
                    .trim()
                    .min(1, 'This field must be one or more characters')
                    .max(100, 'This field must be fewer than 100 characters')
                    .nullable()
                    .label('This'),
                  city: Yup.string()
                    .trim()
                    .min(2, 'This field must be two or more characters')
                    .max(100, 'This field must be fewer than 100 characters')
                    .required()
                    .label('This'),
                  state: Yup.number().required().label('This'),
                  zipCode: Yup.string()
                    .trim()
                    .matches(regExp.zipCode, 'Zip Code must be 5 digits')
                    .required()
                    .label('This'),
                  residenceType: Yup.string()
                    .test('oneOfRequired', 'This is a required field', function callback() {
                      return (
                        (this.parent.mailingAddress && !this.parent.currentAddress) ||
                        this.parent.residenceType
                      )
                    })
                    .nullable()
                    .label('This'),
                  housingDurationYears: Yup.number()
                    .min(0, 'Please enter a value between 0 - 110')
                    .max(110, 'Please enter a value between 0 - 110')
                    .test('oneOfRequired', 'Residency must be >= 1 month', function callback() {
                      return (
                        (this.parent.mailingAddress && !this.parent.currentAddress) ||
                        this.parent.housingDurationMonths ||
                        this.parent.housingDurationYears
                      )
                    })
                    .nullable()
                    .label('This'),
                  housingDurationMonths: Yup.number()
                    .min(0, 'Please enter a value between 0 - 11')
                    .max(11, 'Please enter a value between 0 - 11')
                    .test('oneOfRequired', 'Residency must be >= 1 month', function callback() {
                      return (
                        (this.parent.mailingAddress && !this.parent.currentAddress) ||
                        this.parent.housingDurationMonths ||
                        this.parent.housingDurationYears
                      )
                    })
                    .nullable()
                    .label('This'),
                  housingPayment: Yup.number()
                    .when(['currentAddress'], {
                      is: (currentAddress) => currentAddress,
                      then: Yup.number().required(),
                    })
                    .nullable()
                    .label('This'),
                  mailingAddress: Yup.boolean().label('This'),
                  currentAddress: Yup.boolean().label('This'),
                })
              ),
            }),
          employers: Yup.array().of(
            Yup.object({
              name: Yup.string()
                .trim()
                .min(2, 'This field must be two or more characters')
                .max(100, 'This field must be fewer than 100 characters')
                .required()
                .label('This'),
              phone: Yup.string()
                .trim()
                .min(10, 'Phone number should be ten digits, including area code')
                .matches(regExp.phone, 'Phone number is not valid')
                .required()
                .label('This'),
              address1: Yup.string()
                .trim()
                .min(2, 'This field must be two or more characters')
                .max(100, 'This field must be fewer than 100 characters')
                .required()
                .label('This'),
              address2: Yup.string()
                .trim()
                .min(1, 'This field must be one or more characters')
                .max(100, 'This field must be fewer than 100 characters')
                .label('This')
                .nullable()
                .label('This'),
              city: Yup.string()
                .trim()
                .min(2, 'This field must be two or more characters')
                .max(100, 'This field must be fewer than 100 characters')
                .required()
                .label('This'),
              state: Yup.number().required().label('This'),
              zipCode: Yup.string()
                .trim()
                .matches(regExp.zipCode, 'Zip Code must be 5 digits')
                .required()
                .label('This'),
              jobTitle: Yup.string()
                .trim()
                .min(2, 'This field must be two or more characters')
                .max(100, 'This field must be fewer than 100 characters')
                .required()
                .label('This'),
              employmentYears: Yup.number()
                .min(0, 'Please enter a value between 0 - 110')
                .max(110, 'Please enter a value between 0 - 110')
                .test('oneOfRequired', 'Employment must be >= 1 month', function callback() {
                  return this.parent.employmentMonths || this.parent.employmentYears
                })
                .nullable()
                .label('This'),
              employmentMonths: Yup.number()
                .min(0, 'Please enter a value between 0 - 11')
                .max(11, 'Please enter a value between 0 - 11')
                .test('oneOfRequired', 'Employment must be >= 1 month', function callback() {
                  return this.parent.employmentMonths || this.parent.employmentYears
                })
                .nullable()
                .label('This'),
              salary: Yup.number()
                .min(0)
                .max(100000000)
                .when(['currentEmployer'], {
                  is: (currentEmployer) => currentEmployer,
                  then: Yup.number().required(),
                })
                .label('This'),
              incomeReceived: Yup.string()
                .when(['currentEmployer'], {
                  is: (currentEmployer) => currentEmployer,
                  then: Yup.string().required(),
                })
                .label('This'),
              currentEmployer: Yup.boolean().label('This'),
            })
          ),
          otherIncome: Yup.array().of(
            Yup.object({
              amount: Yup.number().min(0).max(100000000).required().label('This'),
              received: Yup.string().required().label('This'),
              source: Yup.string()
                .trim()
                .min(1, 'This field must be two or more characters')
                .max(100, 'This field must be fewer than 100 characters')
                .required()
                .label('This'),
            }).optional()
          ),
          spouse: Yup.array()
            .nullable()
            .when(['isCommunityPropertyResidence', 'maritalStatus', 'relationship'], {
              is: (isCommunityPropertyResidence, maritalStatus, relationship) =>
                isCommunityPropertyResidence &&
                (maritalStatus === 'Married' || maritalStatus === 'Separated') &&
                relationship !== 'Spouse',
              then: Yup.array().of(
                Yup.object({
                  firstName: Yup.string()
                    .trim()
                    .min(2, 'This field must be two or more characters')
                    .max(100, 'This field must be fewer than 100 characters')
                    .required()
                    .label('This'),
                  lastName: Yup.string()
                    .trim()
                    .min(2, 'This field must be two or more characters')
                    .max(100, 'This field must be fewer than 100 characters')
                    .required()
                    .label('This'),
                  phone: Yup.string()
                    .trim()
                    .min(10, 'Phone number should be ten digits, including area code')
                    .matches(regExp.phone, 'Phone number is not valid')
                    .required()
                    .label('This'),
                  sameAddressAs: Yup.boolean().label('This'),
                  address1: Yup.string()
                    .when('sameAddressAs', {
                      is: false,
                      then: Yup.string()
                        .trim()
                        .min(2, 'This field must be two or more characters')
                        .max(100, 'This field must be fewer than 100 characters')
                        .required(),
                    })
                    .nullable()
                    .label('This'),
                  address2: Yup.string()
                    .trim()
                    .min(1, 'This field must be one or more characters')
                    .max(100, 'This field must be fewer than 100 characters')
                    .nullable()
                    .label('This'),
                  city: Yup.string()
                    .when('sameAddressAs', {
                      is: false,
                      then: Yup.string()
                        .trim()
                        .min(2, 'This field must be two or more characters')
                        .max(100, 'This field must be fewer than 100 characters')
                        .required(),
                    })
                    .nullable()
                    .label('This'),
                  state: Yup.number()
                    .when('sameAddressAs', {
                      is: false,
                      then: Yup.number().required(),
                    })
                    .nullable()
                    .label('This'),
                  zipCode: Yup.string()
                    .when('sameAddressAs', {
                      is: false,
                      then: Yup.string()
                        .trim()
                        .matches(regExp.zipCode, 'Zip Code must be 5 digits')
                        .required(),
                    })
                    .nullable()
                    .label('This'),
                })
              ),
            }),
        })
      ),
    }),
  privacyPolicyChecked: Yup.boolean().oneOf([true], 'This is a required field').label('This'),
  buyerSigned: Yup.boolean().oneOf([true], 'Buyer signature is required').label('This'),
  buyerChecked: Yup.boolean().oneOf([true], 'Buyer must accept terms and conditions').label('This'),
  coBuyerSigned: Yup.boolean()
    .when('coBuyerPresent', {
      is: (coBuyerPresent) => coBuyerPresent,
      then: Yup.boolean().oneOf([true], 'Co-Buyer signature is required'),
    })
    .label('This'),
  coBuyerChecked: Yup.boolean()
    .when('coBuyerPresent', {
      is: (coBuyerPresent) => coBuyerPresent,
      then: Yup.boolean().oneOf([true], 'Co-Buyer must accept terms and conditions'),
    })
    .label('This'),
})

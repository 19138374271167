import React from 'react'
import PropTypes from 'prop-types'
import PopoverHover from 'Src/components/Popovers/PopoverHover'
import IconQuestionCircle from 'Src/components/IconQuestionCircle'
import LineItemTooltip from 'Src/components/Tooltips/LineItemToolTip'
import Incrementer from 'Src/components/Incrementer'
import {formatValue} from 'Src/utilities/DataReformatters'
import {trans} from 'Src/utilities/Helpers'

const DueAtSigning = ({
  header,
  dueAtSigning,
  toolTipContent,
  leaseFixedDownPayment,
  downPaymentIncrements,
}) => {
  const totalDueTooltip = toolTipContent ? (
    <PopoverHover content={<LineItemTooltip content={toolTipContent} />} data-testid="tooltip">
      <IconQuestionCircle />
    </PopoverHover>
  ) : null

  if (!leaseFixedDownPayment) {
    return (
      <div className="mb-3">
        <h2 className="h5 tw-inline" data-testid="header">
          {header}:{' '}
        </h2>
        <p className="headline tw-inline" data-testid="due">
          {formatValue(dueAtSigning)}
        </p>
        {totalDueTooltip}
      </div>
    )
  }

  const headerTooltip = (
    <p role="tooltip" id="due-at-signing-tooltip">
      {trans('how_much_due_at_signing_tooltip')}
    </p>
  )

  return (
    <div role="group" aria-labelledby="due-at-signing">
      <div className="tw-mb-5 tw-leading-relaxed">
        <h2 id="down-payment" className="h5 tw-inline">
          {trans('how_much_due_at_signing')}
        </h2>
        <PopoverHover content={headerTooltip} data-testid="tooltip">
          <IconQuestionCircle />
        </PopoverHover>
      </div>
      <Incrementer
        defaultValue={dueAtSigning}
        increments={downPaymentIncrements}
        type={trans('due at signing')}
        tooltip={totalDueTooltip}
      />
    </div>
  )
}

DueAtSigning.propTypes = {
  header: PropTypes.string.isRequired,
  downPaymentIncrements: PropTypes.number,
  dueAtSigning: PropTypes.number.isRequired,
  leaseFixedDownPayment: PropTypes.bool,
  toolTipContent: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
}

export default DueAtSigning

import React from 'react'
import {useHistory} from 'react-router'
import classNames from 'classnames'
import {navigateIfUserRegistered} from 'Src/actions/RegistrationActions'
import {useNavDispatchContext} from 'Src/contexts/NavContext'
import {useCustomerDispatchContext, useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useProgressDispatchContext} from 'Src/contexts/ProgressContext'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import CheckAvailabilityButton from 'Src/components/Buttons/CheckAvailabilityButton'
import NextButton from 'Src/components/Buttons/NextButton'
import {trans} from 'Src/utilities/Helpers'
import OrButtonDivider from 'Src/components/OrButtonDivider'
import {saveQuote} from 'Src/actions/DealActions'
import {useProductContext} from 'Src/contexts/ProductContext'
import Spinner from 'Src/components/Spinners/Spinner'
import CtaButton from 'Src/components/Buttons/CtaButton'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCloud} from '@fortawesome/free-solid-svg-icons'
import { useAnalytics } from 'Src/services/analytics/useAnalytics'

const TradeNextButtons = () => {
  const history = useHistory()
  const navDispatch = useNavDispatchContext()
  const customerState = useCustomerStateContext()
  const {
    customerData: {id: customerId},
  } = customerState
  const ProgressDispatch = useProgressDispatchContext()
  const dealState = useDealStateContext()
  const frameContext = useFrameContext()
  const {isUpdating} = dealState
  const {isSaveDealEnabled} = frameContext
  const dealDispatch = useDealDispatchContext()
  const {getSelectedRatedProducts} = useProductContext()
  const customerDispatch = useCustomerDispatchContext()
  const {trackEvent, events} = useAnalytics()

  const handleNextButtonClick = () => {
    navigateIfUserRegistered(customerId, '/trade', navDispatch, history, () => {
      ProgressDispatch({type: 'update', payload: {payment: 'complete', trade: 'started'}})
    })
  }

  const handleCheckAvailabilityButtonClick = () => {
    navigateIfUserRegistered(customerId, '/check-availability', navDispatch, history)
  }

  const handleSaveDealButtonClick = () => {
    if (!customerId) {
      dealDispatch({type: 'update', payload: {isRedirectedBeforeSave: true}})
    }

    navigateIfUserRegistered(customerId, '/', navDispatch, history, async () => {
      trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.SAVE_MY_DEAL)
      dealDispatch({type: 'savingDealQuote', payload: true})
      await saveQuote(
        dealState,
        customerState,
        frameContext,
        customerDispatch,
        dealDispatch,
        getSelectedRatedProducts
      )
      dealDispatch({type: 'savingDealQuote', payload: false})
    })
  }

  return (
    <div
      className={classNames(
        'tw-flex tw-flex-col tw-justify-center tw-gap-x-6 sm:tw-flex-wrap sm:tw-justify-between md:tw-flex-row-reverse md:tw-justify-evenly',
        {
          'tw-gap-y-3': !isSaveDealEnabled,
          'tw-gap-y-4': isSaveDealEnabled,
        }
      )}
    >
      <NextButton
        actionDetails={{click: ACTION_DETAILS.TRADE.ADD, navigate: ACTION_DETAILS.PAGES.TRADE}}
        isDisabled={isUpdating}
        onClick={handleNextButtonClick}
        testId="add-trade-button"
        text={trans('Add Trade')}
      />
      {isSaveDealEnabled ? (
        <CtaButton preset="secondary" onClick={handleSaveDealButtonClick} disabled={isUpdating}>
          <div className="tw-flex tw-items-center tw-justify-center">
            {isUpdating ? (
              <Spinner className="tw-mr-2" />
            ) : (
              <FontAwesomeIcon icon={faCloud} className="tw-mr-2" />
            )}
            {trans('Save My Deal')}
          </div>
        </CtaButton>
      ) : (
        <>
          <OrButtonDivider />
          <CheckAvailabilityButton onClick={handleCheckAvailabilityButtonClick} />
        </>
      )}
    </div>
  )
}

export default TradeNextButtons

import React from 'react'

const PageNotFound = () => (
  <div>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
)

export default PageNotFound

import React from 'react'
import PropTypes from 'prop-types'
import {Field, FastField, ErrorMessage} from 'formik'
import classNames from 'classnames'

const FieldInput = ({
  name,
  prefix = '',
  label,
  postLabel,
  required = false,
  inputWidth,
  groupWidth,
  type = 'text',
  step = '1',
  min = 0,
  max = 100000000,
  className = '',
  alignItemsCenter = false,
  disabled = false,
  visibility = '',
  autoComplete,
}) => {
  // FastField does not work with toggling disabled
  const Component = disabled ? Field : FastField
  const customClass = classNames('tw-w-full', className)
  const prefixName = prefix + name

  const commonProps = {
    className: customClass,
    id: prefixName,
    name: prefixName,
    type,
    disabled,
  }

  if (required) {
    commonProps['aria-required'] = true
  }

  if (autoComplete) {
    commonProps.autoComplete = autoComplete
  }

  if (['number', 'range', 'date', 'datetime-local', 'month', 'time', 'week'].includes(type)) {
    commonProps.step = step
    commonProps.min = min
    commonProps.max = max
  }

  return (
    <div
      className={classNames(visibility, {
        [`tw-w-${groupWidth}/12`]: groupWidth,
        'tw-w-full': !groupWidth,
        'tw-hidden': visibility === 'invisible',
      })}
      aria-live="assertive"
      aria-atomic="false"
    >
      {label ? (
        <label
          className={classNames({
            'tw-invisible': label === 'displayNone',
            required,
          })}
          htmlFor={prefixName}
        >
          {!label || label === 'displayNone' ? <>&nbsp;</> : label}
        </label>
      ) : null}
      <div
        className={classNames({
          [`tw-w-${inputWidth}/12`]: inputWidth,
          'tw-w-full': !inputWidth,
        })}
      >
        {postLabel ? (
          <div className="tw-inline-flex tw-items-center xs:tw-w-full">
            <Component {...commonProps} />
            <span className="tw-select-none tw-ml-1">{postLabel}</span>
          </div>
        ) : (
          <Component
            {...{
              ...commonProps,
              onKeyPress: (event) => {
                if (type === 'number' && ['e', '-', '+'].includes(event.key)) {
                  event.preventDefault()
                }
              },
            }}
          />
        )}
        <div>
          <ErrorMessage
            name={prefixName}
            render={(error) => (
              <span
                className={classNames('error', {
                  'tw-absolute': alignItemsCenter,
                })}
              >
                {error}
              </span>
            )}
          />
        </div>
      </div>
    </div>
  )
}

FieldInput.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  postLabel: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  step: PropTypes.string,
  inputWidth: PropTypes.number,
  groupWidth: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  className: PropTypes.string,
  alignItemsCenter: PropTypes.bool,
  disabled: PropTypes.bool,
  visibility: PropTypes.string,
  autoComplete: PropTypes.string,
}

export default FieldInput

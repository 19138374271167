import React from 'react'
import {useFormikContext} from 'formik'
import ResidencyInformation from '../CustomerInformation/ResidencyInfo/ResidencyInformation'
import EmploymentInformation from '../CustomerInformation/EmploymentInfo/EmploymentInformation'
import Accordion from '../../Utilities/Accordion'
import CoBuyerFields from './CoBuyerFieldConfig'
import {sectionHasErrors} from '../../CreditApplicationActions'
import FormRowRenderer from '../../Form/FormUtilities/FormRowRenderer'

const CoBuyerInformation = () => {
  const {rows} = CoBuyerFields
  const {errors, isSubmitting} = useFormikContext()

  return (
    <>
      <Accordion
        title="Co-Buyer Information"
        id="coBuyer"
        hasErrors={isSubmitting && sectionHasErrors(errors, rows, '', true)}
      >
        <FormRowRenderer rows={rows} prefix="coBuyers[0]." />
      </Accordion>
      <ResidencyInformation prefix="coBuyers" />
      <EmploymentInformation prefix="coBuyer" />
    </>
  )
}

export default CoBuyerInformation

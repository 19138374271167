import objectHash from 'object-hash'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {mapBatchProductRateParams} from './ProductHelpers'
import {useProductContext} from '../contexts/ProductContext'
import {useDealStateContext} from '../contexts/DealContext'
import {getCurrentProgram} from './ProgramHelpers'
import {useCustomerStateContext} from '../contexts/CustomerContext'
import {useFrameContext} from '../contexts/FrameContext'
import {TOKEN_STATE} from './useToken'
import {buildParams} from '../actions/DealActions'
import {fetchCompletedProductRates, requestBatchProductRates} from '../api/products'
import {useConfig} from './useConfig'
import {useWebSockets} from './useWebSockets'
import {useAxios} from './useAxios'

export const useBatchProductRatings = () => {
  const [a2zApi, isApiReady] = useAxios()
  const config = useConfig()
  const customerContext = useCustomerStateContext()
  const dealState = useDealStateContext()
  const {tokenState, vin} = useFrameContext()
  const {pathname} = useLocation()
  const {getProductsByDealType, hasFetchedProducts, hasProducts} = useProductContext()
  const {isReady: areWebSocketsReady, listeners} = useWebSockets()

  const [batchIdDealStateHashes, setBatchIdDealStateHashes] = useState(new Map())
  const [cachedProductRatings, setCachedProductRatings] = useState(new Map())
  const [currentBatchId, setCurrentBatchId] = useState(null)
  const [incomingProductsRatedEvent, setIncomingProductsRatedEvent] = useState(null)
  const [isError, setIsError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [rates, setRates] = useState(null)

  const currentProgram = getCurrentProgram(dealState)

  const dealStateForHash = {
    annualMileage: currentProgram?.annualMileage,
    customerId: customerContext.customerData.id,
    dealType: dealState?.dealType,
    programId: currentProgram?.programId,
    rate: currentProgram?.rate,
    term: currentProgram?.term,
    vin,
  }

  const currentDealState = {
    ...dealStateForHash,
    amountFinanced: currentProgram?.amountFinanced,
    dealershipId: config?.dealership?.id,
  }

  const deskingData = buildParams(dealState, customerContext)

  const addRatesToCache = (batchId, data) => {
    const eventDealStateHash = batchIdDealStateHashes.get(batchId)
    const cache = new Map()

    cache.set(eventDealStateHash, data)
    setCachedProductRatings(new Map([...cachedProductRatings, ...cache]))
  }

  const requestBatchProductRatesByDealType = async (hashKey, products) => {
    const data = await requestBatchProductRates(
      a2zApi,
      mapBatchProductRateParams(currentBatchId, currentDealState, deskingData, hashKey, products)
    )

    if (!data) {
      setIsError(true)
      setIsLoading(false)
    } else {
      setIsError(false)
      setIsLoading(true)
      setCurrentBatchId(data.id)

      const tempBatchHashes = new Map()
      tempBatchHashes.set(data.id, hashKey)
      setBatchIdDealStateHashes(new Map([...tempBatchHashes, ...batchIdDealStateHashes]))
    }
  }

  const isBatchIdDealStateEqualToCurrentDealState = () => {
    return (
      batchIdDealStateHashes.get(incomingProductsRatedEvent?.batch_id) ===
      objectHash(dealStateForHash)
    )
  }

  useEffect(() => {
    if (
      isApiReady &&
      tokenState === TOKEN_STATE.AUTHENTICATED &&
      dealState?.dealType &&
      hasFetchedProducts &&
      hasProducts &&
      dealStateForHash.customerId
    ) {
      const products = getProductsByDealType(dealState.dealType)
      const dealStateHashKey = objectHash(dealStateForHash)
      const cachedRates = cachedProductRatings.get(dealStateHashKey)

      if (cachedRates) {
        return setRates(cachedRates)
      }

      setIsLoading(true)
      requestBatchProductRatesByDealType(dealStateHashKey, products)

      return
    }

    setRates(null)
  }, [pathname, dealState?.dealType])

  useEffect(() => {
    if (areWebSocketsReady) {
      listeners?.listenForProductsRatedEvent(setIncomingProductsRatedEvent)
    }
  }, [areWebSocketsReady])

  useEffect(() => {
    if (incomingProductsRatedEvent?.batch_id && isBatchIdDealStateEqualToCurrentDealState()) {
      fetchCompletedProductRates(a2zApi, incomingProductsRatedEvent?.batch_id).then((data) => {
        addRatesToCache(incomingProductsRatedEvent?.batch_id, data)
        setRates(data)
        setIsLoading(false)
      })
    }
  }, [incomingProductsRatedEvent?.batch_id, batchIdDealStateHashes])

  return {
    rates,
    isError,
    isLoading,
    test: {
      function: {
        setBatchIdDealStateHashes,
        setIncomingProductsRatedEvent,
      },
      state: {
        cachedProductRatings,
      },
    },
  }
}

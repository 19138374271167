import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import * as yup from 'yup'
import Input from '../../Input'
import InputSelect from '../../InputSelect'
import useForm from '../../../utilities/useForm'
import {useTradeContext} from '../../../contexts/TradeContext'
import {useFrameContext} from '../../../contexts/FrameContext'
import {trans} from '../../../utilities/Helpers'
import {buildUrl, getOptions, TRADE_ACTION_STATES} from '../../../actions/TradeActions'
import CtaButton from '../../Buttons/CtaButton'

const AboutYourCar = () => {
  const {a2zApi, setMessages} = useFrameContext()
  const {
    setTradeVehicleYearId,
    setTradeVehicleMakeId,
    setTradeVehicleModelId,
    setTradeVehicleTrimId,
    setTradeVehicleMileage,
    setTradeVehicleMetadata,
    TradeVehicleYearId,
    TradeVehicleMakeId,
    TradeVehicleModelId,
    TradeVehicleTrimId,
    TradeVehicleMileage,
    TradeVehicleMetadata,
    dropDowns,
    setDropDowns,
  } = useTradeContext()
  const history = useHistory()

  const [years, setYears] = useState(dropDowns.years)
  const [makes, setMakes] = useState(dropDowns.makes)
  const [models, setModels] = useState(dropDowns.models)
  const [trims, setTrims] = useState(dropDowns.trims)

  const [mileage, setMileage] = useState(TradeVehicleMileage)
  const [selectedYear, setSelectedYear] = useState(TradeVehicleYearId)
  const [selectedMake, setSelectedMake] = useState(TradeVehicleMakeId)
  const [selectedModel, setSelectedModel] = useState(TradeVehicleModelId)
  const [selectedTrim, setSelectedTrim] = useState(TradeVehicleTrimId)

  const [reportStatus, setReportStatus] = useState(
    TradeVehicleMetadata?.status ?? TRADE_ACTION_STATES.INCOMPLETE
  )

  const transYear = trans('Year')
  const transMake = trans('Make')
  const transModel = trans('Model')
  const transTrim = trans('Trim')
  const transMileage = trans('Mileage')

  const set = {
    year: setYears,
    make: setMakes,
    model: setModels,
    trim: setTrims,
    mileage: setMileage,
  }
  const setSelected = {
    year: setSelectedYear,
    make: setSelectedMake,
    model: setSelectedModel,
    trim: setSelectedTrim,
  }
  const initialTrade = {
    year: selectedYear,
    make: selectedMake,
    model: selectedModel,
    trim: selectedTrim,
  }
  const [trade, setTrade] = useState(initialTrade)

  useEffect(() => {
    setTrade({
      year: selectedYear,
      make: selectedMake,
      model: selectedModel,
      trim: selectedTrim,
    })
  }, [selectedYear, selectedMake, selectedModel, selectedTrim])

  const onSubmit = async (values) => {
    setTradeVehicleYearId(values.year)
    setTradeVehicleMakeId(values.make)
    setTradeVehicleModelId(values.model)
    setTradeVehicleTrimId(values.trim)
    setTradeVehicleMileage(values.mileage)
    setDropDowns({years, makes, models, trims})

    // if we don't have a complete report and therefore have not
    // previously set our metadata, set it now.
    if (reportStatus === TRADE_ACTION_STATES.INCOMPLETE) {
      setTradeVehicleMetadata({...values, status: reportStatus})
      history.push('/trade-experience/additional-questions')
    } else {
      history.push('/trade-experience/owed-payments')
    }
  }

  const tradeSchema = yup.object().shape({
    year: yup
      .number()
      .min(1000)
      .max(new Date().getFullYear() + 10)
      .label(transYear)
      .required(trans('validation.filled', {attribute: transYear}))
      .nullable(),
    make: yup
      .string()
      .label(transMake)
      .required(trans('validation.filled', {attribute: transMake}))
      .nullable(),
    model: yup
      .string()
      .label(transModel)
      .required(trans('validation.filled', {attribute: transModel}))
      .nullable(),
    trim: yup
      .string()
      .label(transTrim)
      .required(trans('validation.filled', {attribute: transTrim}))
      .nullable(),
    mileage: yup
      .number()
      .min(0)
      .max(999999)
      .label(transMileage)
      .required(trans('validation.filled', {attribute: transMileage}))
      .test(
        'not-within-range',
        `${trans('validation.between.numeric', {
          attribute: transMileage,
          min: 0,
          max: '999,999',
        })}`,
        (value) => value > 0 && value < 999999
      ),
  })

  const {handleSubmit, getError, errors, clearError} = useForm(
    tradeSchema,
    {
      year: selectedYear,
      make: selectedMake,
      model: selectedModel,
      trim: selectedTrim,
      mileage: mileage || undefined,
    },
    onSubmit
  )

  const clearValues = (parent, tradeCopy) => {
    // purposefully allow case fallthrough so we wipe out all subsequent answers
    switch (parent) {
      case 'all':
        setSelectedYear(null)
        tradeCopy.year = ''
      case 'year':
        setSelectedMake(null)
        tradeCopy.make = ''
      case 'make':
        setSelectedModel(null)
        tradeCopy.model = ''
      case 'model':
        setSelectedTrim(null)
        tradeCopy.trim = ''
        break
      default:
        break
    }

    return tradeCopy
  }

  const handleError = (nextOptions, selectedOption, payload) => {
    // the error message payload only ever comes back as missing_car
    // if we failed on the first call to set up options for Year, then
    // tradePending is likely down and we should display the error accordingly.
    const message = !selectedOption ? trans('trade_int.service_down') : payload
    if (nextOptions) {
      set[nextOptions](null)
    }

    setMessages([
      {
        title: trans('trade_int.trade_evaluation_error'),
        description: message,
        type: 'error',
      },
    ])
  }

  const handleEmptyFields = (payload, tradeCopy) => {
    Object.keys(tradeCopy).forEach((item) => {
      if (!tradeCopy[item]) {
        const listItem = [{value: payload[item], label: payload[item]}]
        set[item](listItem)
        setSelected[item](payload[item])
      }
    })
  }

  const handleOptionsResponse = (response, nextOptions, selectedOption, tradeCopy) => {
    const {payload, status, report} = response

    switch (status) {
      case TRADE_ACTION_STATES.INCOMPLETE:
        if (nextOptions && typeof payload === 'object') {
          set[nextOptions](payload)
        }

        break
      case TRADE_ACTION_STATES.COMPLETE:
        setTradeVehicleMetadata({...payload, ...report, status})
        handleEmptyFields(payload, tradeCopy)

        break
      case TRADE_ACTION_STATES.ERROR:
      default:
        handleError(nextOptions, selectedOption, payload)
    }
    setReportStatus(status)
  }

  const handleNextOptions = async (nextOptions, selectedOption, value, tradeCopy) => {
    const url = buildUrl(selectedOption, value, tradeCopy)
    const response = await getOptions(a2zApi, url, !nextOptions)

    handleOptionsResponse(response, nextOptions, selectedOption, tradeCopy)
  }

  const setOptions = async (nextOptions, selectedOption = '', value = '') => {
    setReportStatus(TRADE_ACTION_STATES.LOADING)
    // make a copy of the state object to make multiple changes without waiting on state
    const local = {...trade}
    const tradeCopy = clearValues(selectedOption === 'year' ? 'all' : selectedOption, local)

    if (selectedOption) {
      setSelected[selectedOption](value)
      tradeCopy[selectedOption] = value
      clearError(selectedOption)
    }

    await handleNextOptions(nextOptions, selectedOption, value, tradeCopy)

    // push the changes made within the function back to state all at one time
    setTrade(tradeCopy)
  }

  useEffect(() => {
    // if we don't have a years dropdown already, grab the options for it
    // this prevents overriding the Year dropdown with the options for any
    // questions after Trim if we've completed AYC once, hit submit,
    // and then clicked back.
    if (!years) {
      setOptions('year')
    }
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <br />
      <h1 className="h3 tw-w-full md:tw-max-w-md tw-mx-auto tw-mt-12 tw-mb-3">
        {trans('trade_int.get_your_estimated_tradein_value')}
      </h1>
      <p id="formInstructions" className="tw-w-full md:tw-max-w-md tw-mx-auto tw-text-xs tw-mb-6">
        {trans('required_field_sr')}
      </p>
      <p id="errors" role="alert" aria-atomic="true" className="tw-sr-only">
        {errors?.map(({message}, index) => (
          <React.Fragment key={`error-${index.toString()}`}>
            <span>{message}</span>
            <br />
          </React.Fragment>
        ))}
      </p>
      <div
        className="tw-w-full md:tw-max-w-md tw-mx-auto tw-mb-5"
        aria-live="assertive"
        aria-atomic="false"
      >
        <InputSelect
          label={transYear}
          name="year"
          options={years}
          value={years?.find(({value}) => value === selectedYear?.toString()) ?? ''}
          disabled={!years}
          loading={!years}
          required
          onChange={({value}) => setOptions('make', 'year', value)}
          error={getError('year')}
          inputMode="numeric"
        />
      </div>
      <div
        className="tw-w-full md:tw-max-w-md tw-mx-auto tw-mb-3"
        aria-live="assertive"
        aria-atomic="false"
      >
        <InputSelect
          label={transMake}
          name="make"
          options={makes}
          value={makes?.find(({value}) => value.toString() === selectedMake?.toString()) ?? ''}
          disabled={!selectedYear || !makes}
          loading={selectedYear && !makes}
          required
          onChange={({value}) => setOptions('model', 'make', value)}
          error={getError('make')}
        />
      </div>
      <div
        className="tw-w-full md:tw-max-w-md tw-mx-auto tw-mb-3"
        aria-live="assertive"
        aria-atomic="false"
      >
        <InputSelect
          label={transModel}
          name="model"
          options={models}
          value={models?.find(({value}) => value.toString() === selectedModel?.toString()) ?? ''}
          disabled={!selectedMake || !models}
          loading={selectedMake && !models}
          required
          onChange={({value}) => setOptions('trim', 'model', value)}
          error={getError('model')}
        />
      </div>
      <div
        className="tw-w-full md:tw-max-w-md tw-mx-auto tw-mb-3"
        aria-live="assertive"
        aria-atomic="false"
      >
        <InputSelect
          label={transTrim}
          name="trim"
          options={trims}
          value={trims?.find(({value}) => value.toString() === selectedTrim?.toString()) ?? ''}
          disabled={!selectedModel || !trims}
          loading={selectedModel && !trims}
          required
          onChange={({value}) => setOptions(null, 'trim', value)}
          error={getError('trim')}
        />
      </div>
      <div
        className="tw-w-full md:tw-max-w-md tw-mx-auto tw-mb-10"
        aria-live="assertive"
        aria-atomic="false"
      >
        <Input
          label={transMileage}
          value={mileage ?? ''}
          type="number"
          name="mileage"
          id="mileage"
          required
          onChange={(event) => {
            clearError('mileage')
            setMileage(event.target.value)
          }}
          error={getError('mileage')}
          inputMode="numeric"
          maxLength={6}
        />
        <div className="divider tw-mb-6 md:tw-hidden" />
      </div>
      <div className="tw-text-center tw-mb-3">
        <CtaButton
          type="submit"
          className="tw-w-full md:tw-max-w-md"
          disabled={[TRADE_ACTION_STATES.LOADING, TRADE_ACTION_STATES.ERROR].includes(reportStatus)}
        >
          {trans('Next')}
        </CtaButton>
      </div>
    </form>
  )
}

export default AboutYourCar

import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars, faCaretDown, faTimes} from '@fortawesome/free-solid-svg-icons'
import IconButton from 'Src/components/Buttons/IconButton'
import {trans} from 'Src/utilities/Helpers'
import {useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import HamburgerMenu from 'Src/components/HamburgerMenu/HamburgerMenu'
import UserList from 'Src/components/UserList'

const Header = ({onClose, hideMenu = false}) => {
  const {customerData: customer} = useCustomerStateContext()
  const [showMenu, setShowMenu] = useState(false)
  const [showUserList, setShowUserList] = useState(false)
  const {trackEvent, events} = useAnalytics()

  const handleCloseButtonClick = () => {
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.CLOSE)
    onClose()
  }

  return (
    <div className="headerNav tw-flex">
      <div className="tw-mx-2 tw-flex tw-flex-1 tw-flex-row tw-justify-between lg:tw-mx-4">
        {hideMenu ? null : (
          <>
            <div id="main-menu-button" role="navigation" className="tw-grid tw-place-items-center">
              <IconButton
                tabIndex={0}
                type="button"
                aria-expanded={showMenu}
                aria-label="Main Menu Button"
                onClick={() => setShowMenu(true)}
              >
                <FontAwesomeIcon icon={faBars} className="tw-text-2xl" />
              </IconButton>
            </div>
            <HamburgerMenu open={showMenu} onClose={() => setShowMenu(false)} />
          </>
        )}
        <div className="tw-ml-auto tw-grid tw-grid-flow-col tw-place-items-center">
          {customer.firstName ? (
            <>
              <div id="rightnav" role="navigation" aria-label="Account Menu" className="tw-mr-2">
                <button
                  type="button"
                  tabIndex={0}
                  className="button tw-font-semibold !tw-normal-case tw-text-brand-body_text--primary hover:tw-text-brand-body_text--primary-hover"
                  onClick={() => setShowUserList(true)}
                  aria-label={trans('Account Menu Button')}
                  aria-expanded={showUserList}
                >
                  <FontAwesomeIcon icon={faCaretDown} className="tw-mr-1" />
                  <span className="tw-pr-2 tw-text-lg">
                    {customer.firstName ? `Hi, ${customer.firstName}` : 'Welcome'}!
                  </span>
                </button>
              </div>
              <UserList open={showUserList} onClose={() => setShowUserList(false)} />
            </>
          ) : null}
          <IconButton onClick={handleCloseButtonClick}>
            <FontAwesomeIcon icon={faTimes} className="tw-text-2xl" />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  onClose: PropTypes.func,
  hideMenu: PropTypes.bool,
}

export default Header

import {
  getDealStateFromExistingQuote,
  getUpdatedRegionFromExistingQuote,
  selectProductsFromExistingQuote,
} from 'Src/utilities/ExistingQuoteHelpers'
import {IDealDispatch, IDealState} from 'Src/utilities/DealContextTypes'
import {ICustomerData, ICustomerDispatch} from 'Src/utilities/CustomerContextTypes'
import {ILeadVehicle} from 'Src/utilities/LeadVehicleTypes'
import {ISelectProductFunction} from 'Src/utilities/ProductContextTypes'
import {routes} from 'Src/api/routes'
import {Dispatch, SetStateAction} from 'react'
import {IMessage} from 'Src/utilities/useAxios'
import {AxiosInstance} from 'axios'
import {transString} from 'Src/utilities/Helpers'

export const loadExistingQuote = async (
  a2zApi: AxiosInstance,
  previouslySavedQuote: ILeadVehicle,
  dealDispatch: IDealDispatch,
  dealState: IDealState,
  customerDispatch: ICustomerDispatch,
  customerData: ICustomerData,
  selectProduct: ISelectProductFunction,
  setMessages: Dispatch<SetStateAction<IMessage[]>>
) => {
  try {
    dealDispatch({type: 'update', payload: {loading: true, isUpdating: true}})
    customerDispatch({type: 'setShowPreviousDealModal', payload: false})
    const {
      data: {data: responseData},
    } = await a2zApi.get(routes.deskingLeadVehicles.paths.leadVehicle(previouslySavedQuote.id))

    if (!responseData.attributes.notice) {
      setMessages([
        {
          title: transString('Deal Updated'),
          description: transString('quote_imported_success'),
        },
      ])
    }

    const {calculation: paymentCalculationData} = previouslySavedQuote
    const {cityCounty} = customerData
    const updatedState = getDealStateFromExistingQuote(
      paymentCalculationData,
      responseData,
      dealState
    )

    dealDispatch({type: 'update', payload: updatedState})
    selectProductsFromExistingQuote(paymentCalculationData, selectProduct)

    const regionUpdate = getUpdatedRegionFromExistingQuote(paymentCalculationData, cityCounty)

    if (regionUpdate) {
      customerDispatch({type: 'setCustomerData', payload: regionUpdate})
    }
  } catch (e) {
    console.error(e)
    dealDispatch({type: 'update', payload: {loading: false, isUpdating: false}})
  }
}

import React from 'react'
import {useFormikContext} from 'formik'
import SpouseFieldConfig from './SpouseFieldConfig'
import FormRowRenderer from '../../Form/FormUtilities/FormRowRenderer'
import Accordion from '../../Utilities/Accordion'
import {sectionHasErrors} from '../../CreditApplicationActions'

const SpouseInformation = ({subtitle}) => {
  const spouseFieldConfig = SpouseFieldConfig(subtitle)
  const {values, errors, isSubmitting} = useFormikContext()

  const spouse = subtitle === 'Co-Buyer' ? values.coBuyers[0].spouse[0] : values.spouse[0]

  const visibility = spouse.sameAddressAs ? 'invisible' : 'visible'

  const prefix = `${subtitle === 'Co-Buyer' ? 'coBuyers[0].' : ''}spouse[0].`
  const panelName = subtitle === 'Co-Buyer' ? 'Co-Buyer' : 'Customer'
  return (
    <Accordion
      id={spouseFieldConfig.id}
      title={spouseFieldConfig.title}
      hasErrors={
        isSubmitting &&
        ((subtitle !== 'Joint' &&
          sectionHasErrors(
            errors,
            SpouseFieldConfig(subtitle, panelName, prefix, visibility).rows,
            'spouse',
            subtitle === 'Co-Buyer'
          )) ||
          (subtitle === 'Joint' &&
            (sectionHasErrors(
              errors,
              SpouseFieldConfig(
                subtitle,
                'Customer',
                'spouse[0].',
                values.spouse[0].sameAddressAs ? 'invisible' : 'visible'
              ).rows,
              'spouse',
              false
            ) ||
              sectionHasErrors(
                errors,
                SpouseFieldConfig(
                  subtitle,
                  'Co-Buyer',
                  'coBuyers[0].spouse[0].',
                  values.coBuyers[0].spouse[0].sameAddressAs ? 'invisible' : 'visible'
                ).rows,
                'spouse',
                true
              ))))
      }
    >
      {subtitle !== 'Joint' ? (
        <>
          <strong className="card-title">{subtitle}&apos;s Spouse</strong>
          <FormRowRenderer
            prefix={prefix}
            rows={SpouseFieldConfig(subtitle, panelName, prefix, visibility).rows}
          />
        </>
      ) : (
        <>
          <div>
            <strong className="card-title">Customer&apos;s Spouse</strong>
            <FormRowRenderer
              prefix="spouse[0]."
              rows={
                SpouseFieldConfig(
                  subtitle,
                  'Customer',
                  'spouse[0].',
                  values.spouse[0].sameAddressAs ? 'invisible' : 'visible'
                ).rows
              }
            />
          </div>
          <div>
            <strong className="card-title">Co-Buyer&apos;s Spouse</strong>
            <FormRowRenderer
              rows={
                SpouseFieldConfig(
                  subtitle,
                  'Co-Buyer',
                  'coBuyers[0].spouse[0].',
                  values.coBuyers[0].spouse[0].sameAddressAs ? 'invisible' : 'visible'
                ).rows
              }
              prefix="coBuyers[0].spouse[0]."
            />
          </div>
        </>
      )}
    </Accordion>
  )
}
export default SpouseInformation

import React, {useRef} from 'react'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router'
import {useCustomerStateContext, useCustomerDispatchContext} from '../contexts/CustomerContext'
import {useNavDispatchContext} from '../contexts/NavContext'
import {initialState, useProgressDispatchContext} from '../contexts/ProgressContext'
import {useRegistrationContext} from '../contexts/RegistrationContext'
import {logout} from '../api/VerificationActions'
import {useFrameContext} from '../contexts/FrameContext'
import {useTradeContext} from '../contexts/TradeContext'
import {useDealDispatchContext} from '../contexts/DealContext'
import {TOKEN_STATE} from '../utilities/useToken'
import useMenuEscapeKey from '../utilities/useMenuEscapeKey'
import {useAnalytics} from '../services/analytics/useAnalytics'
import {useProductContext} from '../contexts/ProductContext'
import {ACTION_DETAILS} from '../services/analytics/constants'
import {generateNewSessionId} from '../utilities/useSessionId'

const UserList = ({open, onClose}) => {
  const {userList, customerData: customer} = useCustomerStateContext()
  const navDispatch = useNavDispatchContext()
  const history = useHistory()
  const progressDispatch = useProgressDispatchContext()
  const {registrationDispatch} = useRegistrationContext()
  const {a2zCustomerApi, setTokenState} = useFrameContext()
  const customerDispatch = useCustomerDispatchContext()
  const {resetTrade} = useTradeContext()
  const dealDispatch = useDealDispatchContext()
  const {trackEvent, events} = useAnalytics()
  const {resetProducts} = useProductContext()
  const userListRef = useRef()
  useMenuEscapeKey(userListRef, open, onClose)

  if (!open) {
    return null
  }

  const routeToNewRegistration = async () => {
    registrationDispatch({type: 'reset'})
    customerDispatch({type: 'clearCustomer'})
    history.push('/register')
  }

  const handleLogoutLinkClick = async (event) => {
    event.preventDefault()
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.LOGOUT)
    await logout(a2zCustomerApi)
    setTokenState(TOKEN_STATE.UNAUTHENTICATED)
    await routeToNewRegistration() // land on registration before resetting other states
    resetTrade()
    resetProducts()
    generateNewSessionId()
    dealDispatch({type: 'resetDeal'})
    progressDispatch({
      type: 'update',
      payload: initialState,
    })
    onClose()
    navDispatch({
      type: 'navigateAfterRegistration',
      payload: {page: '/'},
    })
  }

  return (
    <div ref={userListRef} className="tw-absolute tw-h-screen tw-inset-0 tw-z-50" tabIndex="0">
      <div className="sm:tw-px-4 tw-absolute tw-h-screen tw-inset-0" onClick={() => onClose()} />
      <div className="tw-mb-4 tw-absolute tw-w-full tw-right-1 sm:tw-right-2 md:tw-w-64 tw-top-12">
        <div className="menu__body tw-flex tw-flex-col tw-pt-4 tw-pb-6 tw-px-3 tw-rounded-lg tw-gap-y-6 md:tw-gap-y-3">
          {userList
            .filter((user) => user.name !== customer.firstName)
            .map((user) => (
              <button
                type="button"
                key={user.id}
                onClick={() => {
                  history.push('/register/matching-lead')
                  onClose()
                }}
                className="link tw-font-semibold tw-py-1 tw-text-center"
              >
                {user.name}
              </button>
            ))}
          <button
            type="button"
            className="link tw-font-semibold tw-py-1 tw-text-center"
            onClick={handleLogoutLinkClick}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  )
}

UserList.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default UserList

import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {useMediaQuery} from 'react-responsive'
import {useLocation} from 'react-router'
import {useDealStateContext} from 'Src/contexts/DealContext'
import {formatMileage, formatValue} from 'Src/utilities/DataReformatters'
import {parseCurrentData} from 'Src/utilities/ProgramHelpers'
import {capitalize} from 'Src/utilities/textUtilities'
import Breadcrumbs from 'Src/components/Breadcrumbs'

const PriceBar = ({
  standAlone,
  className = '',
  hideBreadCrumbs = false,
  containerId = 'main-scroll-panel',
}) => {
  const isBrowser = typeof window !== 'undefined'
  const {dealType, currentProgram, noProgramsCause, vehicle, vehiclePricing} = parseCurrentData(
    useDealStateContext()
  )
  const {payment, term, rate, annualMileage, rebatesTotal} = currentProgram || {}
  const {pathname} = useLocation()
  const dealTypeCompare = dealType?.toLowerCase()
  const displayDealType = capitalize(dealTypeCompare)
  const yourPrice = noProgramsCause ? vehicle.msrp : vehiclePricing.sellPrice - rebatesTotal
  const containerRef = useRef()
  const isMinimizable = useRef(true)
  const shouldCollapse = useRef(false)
  const [isMinimized, setIsMinimized] = useState(false)
  const isPhone = useMediaQuery({query: `(max-width: 797px)`})

  // Default to wait for CSS animation before checking height
  const setCssHeaderHeight = (timeToWait = 500) => {
    setTimeout(() => {
      const headerHeight = containerRef.current?.clientHeight + 50
      document.documentElement.style.setProperty('--headerHeight', `${headerHeight}px`)
    }, timeToWait)
  }

  const setIsMinimizable = () => {
    const container = document.getElementById(containerId)
    const headerHeight = containerRef.current?.clientHeight + 50
    const viewableContainerSpace = window.innerHeight - headerHeight
    const overflowHeight = container.scrollHeight - viewableContainerSpace
    isMinimizable.current = overflowHeight > 90 // price bar hide height
    setIsMinimized(isMinimizable.current && shouldCollapse.current)
  }

  // Collapse price bar on scroll in mobile
  useEffect(() => {
    if (!isBrowser) {
      return
    }

    const container = document.getElementById(containerId)

    // Set initial height
    setCssHeaderHeight(0)
    setIsMinimizable()

    if (!isPhone) {
      return
    }

    const scrollHandler = () =>
      window.requestAnimationFrame(() => {
        // Check if we should toggle collapsed price bar
        const scrolledDown = container.scrollTop > 0

        if (scrolledDown !== shouldCollapse.current) {
          shouldCollapse.current = scrolledDown
          setIsMinimizable()
          setCssHeaderHeight()
        }
      })

    container.addEventListener('scroll', scrollHandler)

    return () => container.removeEventListener('scroll', scrollHandler)
  }, [pathname])

  return (
    <div
      id="price-bar"
      role="banner"
      className={classNames(
        'priceBar tw-sticky tw-top-0 tw-z-30 tw-bg-brand-primary sm:tw-static',
        className,
        {
          'stand-alone': standAlone || noProgramsCause,
          'price-bar-minimize': isMinimized,
        }
      )}
      ref={containerRef}
      data-testid="container"
    >
      <div className="tw-top-0 tw-z-30 tw-flex tw-h-full tw-w-full tw-flex-col tw-text-brand-primary_text sm:tw-static">
        <div
          id="priceBarSticky"
          className={classNames(
            'tw-sticky tw-z-20 tw-bg-brand-primary tw-px-4 tw-pt-4 sm:tw-px-8',
            {'price-bar-padding': isMinimized}
          )}
          data-testid="sticky-bar"
        >
          <div
            className={
              !noProgramsCause && (dealTypeCompare === 'finance' || dealTypeCompare === 'lease')
                ? 'tw-flex tw-w-full tw-justify-between'
                : 'tw-flex tw-w-full tw-justify-end'
            }
            data-testid="price-bar-main"
          >
            {noProgramsCause ? null : (
              <>
                {dealTypeCompare === 'finance' ? (
                  <div className="tw-flex tw-flex-col tw-gap-y-1" data-testid="deal">
                    <div className="tw-text-sm tw-font-bold" data-testid="deal-type">
                      {displayDealType}
                    </div>
                    <div data-testid="payment">
                      <p
                        role="status"
                        aria-live="polite"
                        className="tw-inline tw-text-2xl tw-font-bold sm:tw-text-3xl"
                      >
                        {formatValue(payment)}*
                      </p>
                      <span className="tw--ml-1">/month</span>
                    </div>
                  </div>
                ) : null}
                {dealTypeCompare === 'lease' ? (
                  <div className="tw-flex tw-flex-col tw-gap-y-1 tw-font-bold" data-testid="deal">
                    <div className="tw-text-sm" data-testid="deal-type">
                      {displayDealType}
                    </div>
                    <div data-testid="payment">
                      <p
                        role="status"
                        aria-live="polite"
                        className="tw-inline tw-text-2xl sm:tw-text-3xl"
                      >
                        {formatValue(payment)}*
                      </p>
                      <span className="tw--ml-1">/month</span>
                    </div>
                  </div>
                ) : null}
              </>
            )}
            <div className="tw-flex tw-flex-col tw-items-end tw-gap-y-1 tw-font-bold">
              <div className="tw-text-sm">Your Price</div>
              <p
                role="status"
                aria-live="polite"
                data-testid="your-price"
                className="tw-text-2xl sm:tw-text-3xl"
              >
                {formatValue(yourPrice)}
              </p>
            </div>
          </div>
        </div>
        {noProgramsCause ? null : (
          <div
            id="price-bar-hide"
            className={classNames('tw-z-10 tw-bg-brand-primary tw-px-4 tw-pb-4 sm:tw-px-8', {
              'price-bar-hide': isMinimized,
            })}
            data-testid="price-bar-hide"
          >
            {dealTypeCompare === 'cash' ? (
              <>
                <div role="status" aria-live="polite" data-testid="price-terms">
                  &nbsp;
                </div>
                <div className="tw-mt-4 tw-block tw-text-xs" data-testid="price-description">
                  &nbsp;
                </div>
              </>
            ) : null}
            {dealTypeCompare === 'finance' ? (
              <>
                <div role="status" aria-live="polite" data-testid="price-terms">
                  {term} months, {rate}% APR
                </div>
                <div className="tw-mt-4 tw-block tw-text-xs" data-testid="price-description">
                  *Monthly Payment is estimated. Terms may vary based on actual approved credit.
                </div>
              </>
            ) : null}
            {dealType === 'lease' ? (
              <>
                <div role="status" aria-live="polite" data-testid="price-terms">
                  {formatMileage(annualMileage)} miles/yr for {term} months
                </div>
                <div className="tw-mt-4 tw-block tw-text-xs" data-testid="price-description">
                  *Monthly Payment is estimated. Terms may vary based on actual approved credit.
                </div>
              </>
            ) : null}
          </div>
        )}
        {hideBreadCrumbs ? null : (
          <div id="mobileBreadcrumbs" className="tw-bg-brand-body sm:tw-hidden tw-overflow-hidden">
            <Breadcrumbs />
          </div>
        )}
      </div>
    </div>
  )
}

export default PriceBar

PriceBar.propTypes = {
  standAlone: PropTypes.bool,
  className: PropTypes.string,
  hideBreadCrumbs: PropTypes.bool,
  containerId: PropTypes.string,
}

import classNames from 'classnames'
import React, {forwardRef} from 'react'
import Select, {createFilter} from 'react-select'
import customSelectStyles from '../utilities/customSelectStyles'
import {Config} from 'react-select/src/filters'

import type {A2zSelectProps, Props} from 'react-select/base'
// This import is necessary for module augmentation.
// It allows us to extend the 'Props' interface in the 'react-select/base' module
// and add our custom properties to it.
// https://react-select.com/typescript#custom-select-props

declare module 'react-select/base' {
  export interface A2zSelectProps extends Props {
    disabled?: boolean
    required?: boolean
    loading?: boolean
    name?: string
    label?: string
    error?: string
  }
}

const searchConfig: Config = {
  matchFrom: 'start',
}

const customSelectStylesWithOverrides = {
  ...customSelectStyles,
  placeholder: () => ({
    display: 'none',
  }),
}

const InputSelectTyped = forwardRef<Select<A2zSelectProps>, A2zSelectProps>(
  (
    {
      disabled = false,
      loading = null,
      error = '',
      label = null,
      name = '',
      required = false,
      ...props
    },
    ref
  ) => {
    return (
      <>
        {label ? (
          <label
            htmlFor={name}
            className={classNames('tw-mt-2 tw-font-semibold', {
              'tw-text-brand-body_text--muted': disabled,
              required,
            })}
          >
            {label}
          </label>
        ) : null}
        <Select
          inputRef={ref}
          name={name}
          classNamePrefix="select"
          inputId={name}
          styles={customSelectStylesWithOverrides}
          filterOption={createFilter(searchConfig)}
          isDisabled={disabled}
          isLoading={loading !== null ? loading : !disabled && !props.options?.length}
          isSearchable
          {...props}
        />
        {error ? (
          <span role="alert" className="tw-block tw-text-sm tw-text-brand-body_text--danger">
            {error}
          </span>
        ) : null}
      </>
    )
  }
)

export default InputSelectTyped

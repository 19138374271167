export const ucFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const AddPrefixToFieldConfig = (FieldConfigJson, prefix, capitalise = false) => {
  if (prefix === undefined) {
    return FieldConfigJson
  }

  FieldConfigJson.rows.forEach((row) => {
    row.columns.forEach((column) => {
      column.fields.forEach((field) => {
        if (field.name.includes(prefix)) {
          // @todo find better solution for this patch
          // An issue with using anything formik related inside CoBuyerInformation component.
          // It causes an additional re-render, which causes AddPrefixToFieldConfig inside CoBuyerFields component run again.
          // So the AddPrefixToFieldConfig function keeps appending another prefix to the input field name, i.e. coBuyer.coBuyer.firstName
          return
        }
        field.name = `${prefix}${capitalise ? ucFirst(field.name) : field.name}`
      })
    })
  })

  return FieldConfigJson
}

import React, {useState} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleUp} from '@fortawesome/free-solid-svg-icons'
import {useAnalytics} from '../../../services/analytics/useAnalytics'
import {ACTION_DETAILS} from '../../../services/analytics/constants'

const VehicleConditionOption = ({
  children,
  condition,
  label,
  description,
  selectedOption,
  onSelect,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const {trackEvent, events} = useAnalytics()
  const selected = selectedOption === condition

  return (
    <div
      className={classNames('card tw-flex tw-flex-col tw-relative tw-p-4 tw-mb-4', {
        'card--active': selected,
        'card--inactive': !selected,
      })}
    >
      <div className="tw-flex tw-items-center">
        <label
          htmlFor={condition}
          className={classNames(
            'tw-cursor-pointer tw-font-medium tw-text-lg tw-flex tw-items-center tw-gap-x-2.5',
            {
              'tw-font-semibold': selected,
            }
          )}
        >
          <input
            id={condition}
            name="condition"
            type="radio"
            checked={selected}
            className="tw-h-4 tw-w-4"
            onChange={() => {
              trackEvent(
                events.ENGAGEMENT,
                events.ENGAGEMENT.actions.SELECTION,
                `${condition} condition`
              )
              onSelect(condition)
            }}
          />
          {label}
        </label>
      </div>
      <p className="tw-my-3">{description}</p>
      <button
        type="button"
        className={classNames(
          'link tw-block lg:tw-absolute tw-self-end tw-font-semibold tw-select-none',
          {
            'tw-opacity-50': isExpanded,
          }
        )}
        onClick={() => {
          if (!isExpanded) {
            trackEvent(
              events.ENGAGEMENT,
              events.ENGAGEMENT.actions.CLICK,
              ACTION_DETAILS.LEARN_MORE.EXPANDED
            )
          }
          setIsExpanded(!isExpanded)
        }}
        aria-expanded={isExpanded}
      >
        Learn More
      </button>
      <div
        data-testid="expanded-div"
        className={classNames('tw-flex tw-flex-col tw-mt-2', {
          'tw-hidden': !isExpanded,
        })}
      >
        <div className="tw-max-h-64 tw-overflow-y-auto tw-leading-relaxed">{children}</div>
        <button
          type="button"
          className="link tw-w-8 tw-h-8 tw-self-end tw-text-lg"
          onClick={() => setIsExpanded(false)}
          data-testid="icon-div"
        >
          <FontAwesomeIcon icon={faAngleUp} />
        </button>
      </div>
    </div>
  )
}

VehicleConditionOption.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  condition: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  selectedOption: PropTypes.string,
  onSelect: PropTypes.func,
}

export default VehicleConditionOption

import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from 'Src/components/Spinners/Spinner'
import {numberToUSD} from '../../utilities/DataReformatters'
import InputCheckBox from '../InputCheckBox'
import {trans} from '../../utilities/Helpers'
import {useAnalytics} from '../../services/analytics/useAnalytics'
import {useProductContext} from '../../contexts/ProductContext'
import {renderTerm} from '../../utilities/ProductHelpers'
import {SHIFT_EVENTS, ADD_ON_TYPES, useTrackShiftEvents} from '../../utilities/useShiftEvents'
import {ACTION_DETAILS} from '../../services/analytics/constants'

const ProductCard = ({dealType, onFilter, isDisabled = true, product, ratedProduct = {}}) => {
  const {trackEvent, events} = useAnalytics()
  const {selectedProducts, toggleProductSelection} = useProductContext()

  const isProductSelected = (dealType, productId, ratedProductUid) =>
    selectedProducts[dealType]?.has(productId) && !!ratedProductUid

  const [isSelected, setIsSelected] = useState(() =>
    isProductSelected(dealType, product?.id, ratedProduct?.rate?.uid)
  )

  const isCheckboxDisabled = isDisabled || !ratedProduct?.isRated

  useEffect(() => {
    if (selectedProducts && dealType) {
      setIsSelected(selectedProducts[dealType].has(product?.id))
    }
  }, [selectedProducts[dealType].size])

  useTrackShiftEvents(isSelected && [SHIFT_EVENTS.ADD_TO_CART], {
    addOnName: product?.name,
    addOnType: ADD_ON_TYPES.FI,
  })

  const getFieldTypeValue = (fieldType) => {
    return product?.product_fields.find((field) => field.type === fieldType)?.default_value ?? ''
  }

  const termValue = ratedProduct.term || parseInt(getFieldTypeValue('Term'), 10)
  const mileage = ratedProduct.miles || parseInt(getFieldTypeValue('Mileage'), 10)

  useEffect(() => {
    setIsSelected(isProductSelected(dealType, product?.id, ratedProduct))
  }, [selectedProducts[dealType].size])

  const handleCheckboxChange = () => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.SELECTION,
      isSelected ? ACTION_DETAILS.PRODUCT.DESELECTED : ACTION_DETAILS.PRODUCT.SELECTED,
      null,
      {productCategory: product?.product_categories?.name}
    )
    toggleProductSelection(dealType, product?.id)
    setIsSelected(!isSelected)
  }

  const renderLeaseFinancePrice = (payment) => {
    if (payment) {
      return `${numberToUSD(payment)}/mo`
    }

    return ''
  }

  const renderedPrice = useMemo(() => {
    if (ratedProduct?.rate) {
      const {payment, price} = ratedProduct

      if (['finance', 'lease'].includes(dealType)) {
        return renderLeaseFinancePrice(payment)
      }

      if (dealType === 'cash' && price) {
        return numberToUSD(price)
      }
    }

    return trans('Request Price')
  }, [ratedProduct?.rate])

  const handleFilter = (ratedProduct) => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.PRODUCT.FILTER.LINK
    )
    onFilter(ratedProduct)
  }

  return (
    <div
      className={classNames('card card--hover tw-p-2 tw-mb-4', {
        'card--active': isSelected,
        'card--inactive': !isSelected,
        'tw-cursor-not-allowed': isCheckboxDisabled,
        'tw-cursor-pointer': !isCheckboxDisabled,
      })}
      data-product-id={product?.id}
      data-product-payment={ratedProduct?.payment}
      data-product-retail-price={ratedProduct?.price}
      data-product-taxable={product?.taxable}
      data-product-different-tax-percent={product?.different_tax_percent}
      data-product-special-tax-percent={product?.special_tax_rate}
    >
      <div id="id" className="tw-grid tw-grid-cols-12 tw-gap-y-1" data-testid="container">
        <div className="tw-col-span-1 tw-justify-self-center">
          <InputCheckBox
            checked={isSelected}
            disabled={isCheckboxDisabled}
            onChange={handleCheckboxChange}
          />
        </div>
        <div
          className={classNames(
            'tw-col-span-8 tw-col-start-2 tw-text-sm tw-self-center tw-ml-1 tw-mr-1 tw-mt-auto',
            {
              'tw-font-bold': isSelected,
              'tw-font-medium': !isSelected,
            }
          )}
          onClick={() => {
            if (!isCheckboxDisabled) {
              handleCheckboxChange()
            }
          }}
        >
          {product?.name ?? ''}
        </div>
        <div
          className={classNames('tw-text-sm tw-justify-self-end tw-col-span-3 tw-text-right', {
            'tw-font-bold': isSelected,
            'tw-font-medium': !isSelected,
          })}
        >
          <span
            className={classNames('tw-text-xs tw-mt-auto', {
              'tw-text-brand-body_text--muted':
                ratedProduct.isRated && renderedPrice === trans('Request Price'),
            })}
          >
            {!ratedProduct.isRated ? (
              <>
                <span className="tw-mr-2">{trans('Getting Price')}</span>
                <Spinner
                  border="tw-border-[0.25em]"
                  className="tw-text-brand-icon_loading tw-align-text-bottom"
                />
              </>
            ) : (
              renderedPrice
            )}
          </span>
        </div>

        <div className="tw-col-span-12 tw-cursor-auto">
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: product?.description ?? ''}}
            className="tw-m-2 tw-text-xs tw-leading-4 sm:tw-pl-2 tw-pr-2 tw-cursor-auto"
          />
          <div className="tw-flex tw-justify-between">
            <div className="tw-pl-2 sm:tw-pl-4 tw-font-medium tw-text-xs tw-mt-auto">
              {ratedProduct?.isRated && ratedProduct.rate ? (
                <span
                  className="link"
                  onClick={() => {
                    handleFilter(ratedProduct)
                  }}
                >
                  {renderTerm(termValue, mileage)}
                </span>
              ) : (
                renderTerm(termValue, mileage)
              )}
            </div>
            <div className="tw-font-bold tw-text-sm tw-col-start-5 tw-col-end-13 tw-mt-1 tw-justify-self-end">
              {product?.more_info_link ? (
                <a
                  className="tw-font-semibold link"
                  onClick={() => {
                    trackEvent(
                      events.ENGAGEMENT,
                      events.ENGAGEMENT.actions.CLICK,
                      ACTION_DETAILS.LEARN_MORE.BASE,
                      null,
                      {productCategory: product?.product_categories?.name}
                    )
                  }}
                  href={product.more_info_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {trans('Learn More')}
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProductCard.propTypes = {
  dealType: PropTypes.string,
  isDisabled: PropTypes.bool,
  onFilter: PropTypes.func,
  product: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    different_tax_percent: PropTypes.bool,
    name: PropTypes.string,
    mileage: PropTypes.number,
    more_info_link: PropTypes.string,
    price: PropTypes.number,
    product_categories: PropTypes.shape({
      name: PropTypes.string,
    }),
    product_fields: PropTypes.arrayOf(
      PropTypes.shape({
        default_value: PropTypes.string,
        id: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    special_tax_rate: PropTypes.number,
    tax_rate: PropTypes.number,
    taxable: PropTypes.bool,
    term: PropTypes.number,
  }),
  ratedProduct: PropTypes.shape({
    productId: PropTypes.string,
    taxRate: PropTypes.number,
    rate: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        uid: PropTypes.string,
      }),
    ]),
    price: PropTypes.number,
    term: PropTypes.number,
    mileage: PropTypes.number,
    isRated: PropTypes.bool,
  }),
}

export default ProductCard

import React from 'react'
import {useHistory} from 'react-router'
import {
  backButtonBreadcrumbInteraction,
  breadcrumbProgressTracking,
  trans,
} from 'Src/utilities/Helpers'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import {useFrameContext} from 'Src/contexts/FrameContext'
import BackButton from 'Src/components/Buttons/BackButton'
import NextButton from 'Src/components/Buttons/NextButton'
import QuestionsCallDealershipPhone from 'Src/components/QuestionsCallDealershipPhone'
import PageLayoutCenter from 'Src/components/Layouts/PageLayoutCenter'
import {THistory} from 'Src/utilities/useAxios'
import {useDealStateContext} from 'Src/contexts/DealContext'
import {
  PROGRESS_STEP,
  useProgressDispatchContext,
  useProgressStateContext,
} from 'Src/contexts/ProgressContext'

const CreditComplete = () => {
  const {
    dealership: {
      attributes: {name: dealershipName},
    },
    isSaveDealEnabled,
    isDocUploadsEnabled,
  } = useFrameContext()
  const progress = useProgressStateContext()
  const {dealType} = useDealStateContext()
  const progressDispatch = useProgressDispatchContext()

  const history: THistory = useHistory()

  const handleNextButtonClick = () => {
    if (isDocUploadsEnabled) {
      progressDispatch({
        type: 'update',
        payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.uploads, dealType),
      })
      history.push('/document-upload')

      return
    }

    history.push('/finalize')
  }

  const handleBackButtonClick = () => {
    progressDispatch({
      type: 'update',
      payload: backButtonBreadcrumbInteraction(history, progress, dealType),
    })
  }

  const CreditCompleteNextButton = () =>
    isDocUploadsEnabled ? (
      <NextButton
        actionDetails={{
          click: ACTION_DETAILS.CREDIT.COMPLETE,
          navigate: ACTION_DETAILS.PAGES.DOC_UPLOADS,
        }}
        onClick={handleNextButtonClick}
        text={trans('Documents Upload')}
      />
    ) : (
      <NextButton
        actionDetails={{
          click: ACTION_DETAILS.CREDIT.COMPLETE,
          navigate: ACTION_DETAILS.PAGES.FINALIZE,
        }}
        onClick={handleNextButtonClick}
        text={trans('Finalize')}
      />
    )

  return (
    <PageLayoutCenter className="tw-max-w-[400px]">
      <PageLayoutCenter.Body>
        <h1 className="h3 tw-mb-4 !tw-text-brand-success">{trans('Congratulations!')}</h1>
        <p data-testid="application-submitted" className="tw-mb-4 tw-text-lg tw-font-semibold">
          Your application has been successfully submitted to {dealershipName}.
        </p>
        <p data-testid="inform-notice" className="tw-mb-4">
          {dealershipName} will inform you of the status of the application as well as the names and
          addresses of the financing institutes to which the credit application will be sent.
        </p>
        <QuestionsCallDealershipPhone />
      </PageLayoutCenter.Body>
      <PageLayoutCenter.Footer>
        <div className="divider tw-my-12 lg:tw-invisible lg:tw-my-8" />
        {isSaveDealEnabled ? (
          <div className="tw-flex tw-flex-col tw-justify-center tw-gap-x-6 sm:tw-mb-4 sm:tw-flex-wrap sm:tw-justify-between md:tw-flex-row-reverse lg:tw-mb-0">
            <CreditCompleteNextButton />
            <div className="tw-mt-4 tw-py-2 sm:tw-mt-0 sm:tw-flex">
              <BackButton additionOnClick={handleBackButtonClick} />
            </div>
          </div>
        ) : (
          <>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-center">
              <CreditCompleteNextButton />
            </div>
            <div className="tw-mt-4 tw-py-2">
              <BackButton additionOnClick={handleBackButtonClick} />
            </div>
          </>
        )}
      </PageLayoutCenter.Footer>
    </PageLayoutCenter>
  )
}

export default CreditComplete

import React from 'react'
import classNames from 'classnames'
import Layout from 'Src/components/Layout'
import {PAGE_LAYOUT_SLOTS} from 'Src/components/Layouts/PageLayoutConstants'
import {getSlotsFromChildren} from './PageLayoutHelpers'
import {
  IPageLayoutProps,
  IPageLayoutHeaderProps,
  IPageLayoutBodyProps,
  IPageLayoutFooterProps,
} from 'Src/components/Layouts/PageLayoutTypes'

const PageLayoutCenter = ({children, className}: IPageLayoutProps) => {
  const {header, body, footer} = getSlotsFromChildren(children)

  return (
    <Layout mainClassName="sm:tw-pt-12">
      <div className={classNames('tw-mx-auto tw-flex-1 tw-text-center', className)}>
        {header}
        {body}
      </div>
      {footer}
    </Layout>
  )
}

const PageLayoutCenterHeader = ({children, className}: IPageLayoutHeaderProps) => {
  if (!children) {
    return null
  }

  return <h1 className={classNames('h3 tw-mb-4', className)}>{children}</h1>
}

const PageLayoutCenterBody = ({children}: IPageLayoutBodyProps) => {
  return <>{children}</>
}

const PageLayoutCenterFooter = ({children}: IPageLayoutFooterProps) => {
  return <>{children}</>
}

PageLayoutCenterHeader.displayName = PAGE_LAYOUT_SLOTS.HEADER
PageLayoutCenterBody.displayName = PAGE_LAYOUT_SLOTS.BODY
PageLayoutCenterFooter.displayName = PAGE_LAYOUT_SLOTS.FOOTER

PageLayoutCenter.Header = PageLayoutCenterHeader
PageLayoutCenter.Body = PageLayoutCenterBody
PageLayoutCenter.Footer = PageLayoutCenterFooter

export default PageLayoutCenter

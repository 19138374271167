import React from 'react'
import {PropTypes} from 'prop-types'

const FieldComponentFactory = ({options, options: {component}}) => {
  const Component = component
  return <Component {...options} />
}

FieldComponentFactory.propTypes = {
  options: PropTypes.shape({
    component: PropTypes.func.isRequired,
  }),
}

export default FieldComponentFactory

import React from 'react'
import {useFormikContext} from 'formik'
import Accordion from '../../Utilities/Accordion'
import SignaturePad from '../../SignaturePad'
import initialCoBuyerValues from '../../InitialValues/initialCoBuyerValues.json'
import RequiredDecorator from '../../Form/Fields/RequiredDecorator'
import {useAnalytics} from '../../../../services/analytics/useAnalytics'
import {ACTION_DETAILS} from '../../../../services/analytics/constants'

const ApplicationType = () => {
  const {values, setFieldValue, isSubmitting, errors} = useFormikContext()
  const {trackEvent, events} = useAnalytics()

  return (
    <Accordion
      id="applicationType"
      title="Application Type"
      hasErrors={
        isSubmitting &&
        values.coBuyerPresent &&
        (errors?.buyerTypeSigned ||
          errors?.buyerTypeChecked ||
          errors?.coBuyerTypeSigned ||
          errors?.coBuyerTypeChecked)
      }
    >
      <div className="tw-flex tw-flex-col tw-gap-y-4">
        <label htmlFor="individual" className="tw-flex tw-flex-row tw-items-start">
          <div className="tw-pl-1 tw-pr-2 tw-cursor-pointer">
            <input
              id="individual"
              name="applicationType"
              type="radio"
              className="tw-cursor-pointer"
              checked={!values.coBuyerPresent}
              onChange={() => {
                trackEvent(
                  events.ENGAGEMENT,
                  events.ENGAGEMENT.actions.CHANGE,
                  ACTION_DETAILS.APPLICATION_TYPE.INDIVIDUAL
                )
                setFieldValue('coBuyerPresent', false)
                setFieldValue('isCoBuyerSpouse', false)
                setFieldValue('coBuyerTypeChecked', false)
                setFieldValue('coBuyerTypeSigned', false)
                setFieldValue('buyerTypeChecked', false)
                setFieldValue('buyerTypeSigned', false)
                setFieldValue('coBuyerChecked', false)
                setFieldValue('coBuyerSigned', false)
                setFieldValue('buyerChecked', false)
                setFieldValue('buyerSigned', false)

                if (values.coBuyers) {
                  setFieldValue('coBuyers[0].relationship', '')
                  setFieldValue('coBuyers[0].maritalStatus', '')
                }
              }}
            />
          </div>
          <div>
            <strong className="tw-cursor-pointer">Individual:</strong> You are applying for
            individual credit in your own name and are relying on your own income and assets and not
            the income or assets of another person as the basis for repayment of the credit
            requested. All fields marked with an asterisk (
            <RequiredDecorator />) are required.
          </div>
        </label>
        <label htmlFor="joint" className="tw-flex tw-flex-row tw-items-start">
          <div className="tw-pl-1 tw-pr-2 tw-cursor-pointer">
            <input
              id="joint"
              name="applicationType"
              type="radio"
              className="tw-cursor-pointer"
              checked={values.coBuyerPresent}
              onChange={() => {
                trackEvent(
                  events.ENGAGEMENT,
                  events.ENGAGEMENT.actions.CHANGE,
                  ACTION_DETAILS.APPLICATION_TYPE.JOINT
                )
                setFieldValue('coBuyerPresent', true)
                setFieldValue('buyerChecked', false)
                setFieldValue('buyerSigned', false)

                if (!values.coBuyers) {
                  setFieldValue('coBuyers', [initialCoBuyerValues])
                }
              }}
            />
          </div>
          <div>
            <strong className="tw-cursor-pointer">Joint:</strong> In accordance with{' '}
            <a
              href="https://www.federalreserve.gov/boarddocs/supmanual/cch/fair_lend_reg_b.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              Regulation B
            </a>
            , you certify that you are applying for joint credit with another person. All fields
            marked with an asterisk (
            <RequiredDecorator />) are required.
          </div>
        </label>
      </div>
      {values.coBuyerPresent ? (
        <div>
          <div className="divider tw-my-6" />
          <SignaturePad name="buyerType" label="Buyer" />
          <div className="divider tw-my-6" />
          <SignaturePad name="coBuyerType" label="Co-Buyer" />
        </div>
      ) : null}
    </Accordion>
  )
}

export default ApplicationType

import {ReactNode, Children} from 'react'
import {TReactElementWithDisplayName} from 'Src/utilities/Types'
import {PAGE_LAYOUT_SLOT_NAMES} from 'Src/components/Layouts/PageLayoutConstants'
import {TPageLayoutChildrenSlots} from 'Src/components/Layouts/PageLayoutTypes'

const childrenDefaultValue = Object.freeze(
  PAGE_LAYOUT_SLOT_NAMES.reduce((acc, slotName) => {
    acc[slotName] = null

    return acc
  }, {} as TPageLayoutChildrenSlots)
)

export const getSlotsFromChildren = (children: ReactNode) => {
  return Children.toArray(children).reduce(
    (acc, child) => {
      const {displayName} = (child as TReactElementWithDisplayName)?.type || {}

      if (displayName && PAGE_LAYOUT_SLOT_NAMES.includes(displayName)) {
        acc[displayName] = child
      }

      return acc
    },
    {...childrenDefaultValue}
  )
}

import React from 'react'
import Layout from '../Layout'

const TermsOfUse = () => (
  <Layout data-testid="layout">
    <div>
      <h2 className="h2 tw-text-center">Terms of Use</h2>
      <h2>Introduction</h2>
      <p>
        Welcome to our dealer website. We have developed this website to give you a uniquely
        personal experience in finding your next vehicle. This website is designed to provide the
        information you will need and answer your questions when you are shopping or “just looking”
        for what is available. We provide tools for you to customize your searches and explore
        financing options as well.
      </p>
      <p>
        We do have rules for your use of the website; and these Terms of Use give these rules for
        using our website. Please review these provisions so you are familiar with them. Using the
        website means you understand and agree to these Terms of Use.
      </p>
      <p>Thank you for spending time with us. </p>
      <h2>Acceptance of Terms of Use</h2>
      <p>
        The dealer owns and operates this website either directly or through the use of service
        providers (“we”). Your use of this website constitutes your agreement to these Terms of Use
        as well as our privacy policy. Do not use this website if you do not agree to these Terms of
        Use or the <u>privacy policy</u>. These Terms and Conditions constitute an agreement between
        the dealer and you.
      </p>
      <p>
        We have the right to amend these Terms of Use as well as the privacy policy from time to
        time in its sole discretion. If you use this website after amended terms are posted, your
        use constitutes your agreement to the amended terms.
      </p>
      <h2>Ownership and Use of Information</h2>
      <p>
        This website and all information posted or contained on the site is protected under United
        States and international copyright and trademark laws. We and any third- party information
        providers own all the information on this site. You do not obtain any ownership, license, or
        intellectual property rights by accessing, downloading, or printing any information. You may
        use the information for your non-commercial, personal use only. You may not use the dealer’s
        name or trademarks. You may not hack or interfere with the operation of this website or use
        of an automated device of any kind to monitor, spoof, data mine, link, or commit any
        wrongdoing with respect to this website. Any unauthorized use may be prosecuted in any court
        having jurisdiction.
      </p>
      <p>
        We make commercially reasonable efforts to provide timely and accurate information on this
        website. However, despite these efforts, some inaccuracies may occasionally occur. Pictures
        of vehicles may vary in color or general appearance based on your computer settings. We
        sometimes post stock photos of vehicles and will make an effort to indicate when that is the
        case. Please contact the store where a vehicle is located to verify the accuracy of the
        website content. We also have the right to correct errors in a vehicle description such as
        incorrect statements of pricing, specifications, accessories, and warranties, without
        notice. Vehicle mileage may vary due to test driving or vehicle relocation.
      </p>
      <p>
        We may provide links on this website to sites operated by third parties. These sites are not
        in our control and may have terms of use or policies different from these Terms of Use and
        our privacy policy. We make no representations or warranties concerning these third-party
        sites or their content and you use them at your own risk.
      </p>
      <h2>Disclaimer of Warranties and Limitation of Liability</h2>
      <p>
        WE MAKE NO REPRESENTATIONS OR WARRANTIES CONCERNING THE SUITABILITY OF THIS WEBSITE OR ITS
        CONTENT FOR ANY PURPOSE WHATSOEVER. ALL INFORMATION ON THE WEBSITE IS PROVIDED “AS IS”
        WITHOUT ANY WARRANTIES, EXPRESS OR IMPLIED. WE SPECIFICALLY DISCLAIM WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. NO
        WARRANTIES ARISE FROM COURSE OF DEALING, PERFORMANCE, OR TRADE USAGE. SOME JURISDICTIONS DO
        NOT ALLOW FOR THE EXCLUSION OF IMPLIED WARRANTIES OR CERTAIN TYPES OF DAMAGES SO THE
        DISCLAIMER OF IMPLIED WARRANTIES AND CERTAIN DAMAGES MAY NOT APPLY TO YOU.
      </p>
      <p>
        WE WILL NOT BE LIABLE TO ANY PERSON OR ENTITY FOR ANY DIRECT, INDIRECT, SPECIAL,
        CONSEQUENTIAL, OR OTHER DAMAGES INCLUDING BUT NOT LIMITED TO LOST PROFITS, LOSS OF
        INFORMATION, BUINESS INTERRUPTION OR LOST DATA ON YOUR SYSTEM RELATED TO THE USE OR
        INABILITY TO USE THIS WEBSITE OR ITS CONTENT.
      </p>
      <p>
        WE DO NOT REPRESENT OR WARRANT THAT THIS WEBSITE WILL NOT BE INTERRUPTED, DOWN, ERROR-FREE.
        OR THAT THIS WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR HARMFUL
        CONTACTS. YOU USE THIS WEBSITE AT YOUR OWN RISK.
      </p>
      <h2>Manufacturer Incentives</h2>
      <p>
        From time to time, vehicle manufacturers or their captive finance companies may offer
        rebates or other incentives. The manufacturers and finance companies specify the terms for
        these rebates and incentives such as persons who qualify, eligible vehicle models,
        timeframes, credit criteria, and limitations. Please call the dealer for full details on
        current incentives.
      </p>
      <h2>Ownership and Use of Information</h2>
      <p>
        The dealer owns all information submitted through this website including consumer
        information that you may provide. We may use information for any purpose in accordance with
        our privacy policy and applicable law.
      </p>
      <h2>Indemnification</h2>
      <p>
        You agree to defend, indemnify, and hold harmless the dealer and its service providers from
        and against all third-party claims, proceedings, damages, costs, and expenses (including
        attorney’s fees) relating to your use of this website, without regard to the type of such
        claim or action.
      </p>
      <h2>Termination</h2>
      <p>
        If we believe you have violated these Terms of Use or the privacy policy, we may suspend or
        restrict your access to this website. If you do not agree to these Terms of Use or the
        privacy policy, your sole remedy is to cease further use of this website.
      </p>
      <h2>Other Terms</h2>
      <p>
        These Terms of Use and the privacy policy are governed by laws of the state in which the
        dealer maintains its principal place of business and any suit or claim must be brought, if
        at all, there. Any cause of action you may have concerning this website or its use must be
        brought within 12 calendar months after the cause of action accrued.
      </p>
      <p>
        We operate this website in the United States. If you access this website from outside of the
        United States, you do so at your own risk and you are responsible for compliance with all
        national and local laws.
      </p>
      <p>
        This website is intended for use in the United States only and by persons 18 years of age or
        older. In compliance with the Children’s Online Privacy Protection Act, we do not knowingly
        or intentionally solicit or collect information from minors, and our marketing and
        advertising are not directed at minors. We reserve the right to restrict use and delete any
        information of users we learn are under 18 years of age..
      </p>
      <p>
        In the event any part of this website or the Terms and Conditions are found to be void or
        unenforceable, the remaining terms will continue in full force and effect. The website,
        these terms and conditions, and the privacy policy represent the entire agreement governing
        your use of the website.
      </p>
    </div>
  </Layout>
)

export default TermsOfUse

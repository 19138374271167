import React from 'react'
import PropTypes from 'prop-types'
import {formatValue} from '../../utilities/DataReformatters'
import generateUuid from '../../utilities/generateUuid'

const LineItemTooltip = ({content}) => (
  <table className="tw-table tw-w-full">
    <tbody>
      {content.map(({description, value}) => {
        const key = `line-item_${generateUuid()}`

        const formattedValue = typeof value === 'number' ? formatValue(value) : value

        if (description === 'Total') {
          return (
            <tr {...{key, className: 'tw-font-semibold tw-bg-brand-secondary'}}>
              <td className="tw-pr-2 tw-py-2">{description}</td>
              <td className="tw-text-right">{formattedValue}</td>
            </tr>
          )
        }

        return (
          <tr {...{key}}>
            <td className="tw-pr-2">{description}</td>
            <td className="tw-text-right">{formattedValue}</td>
          </tr>
        )
      })}
    </tbody>
  </table>
)

LineItemTooltip.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
}

export default LineItemTooltip

import {AxiosInstance} from 'axios'
import {routes} from 'Src/api/routes'
import {JsonApiCompoundResponse, JsonApiResponse} from 'Src/api/types'

export interface FetchDocumentsResponse {
  createdAt: string
  file_name: string
  id: string
  is_expired: boolean
  path: string
  type: string
  updatedAt: string
  url: string | null
}

export interface UploadedDocumentResponse {
  id: string
}

export const fetchDocuments = ({a2zApi, leadId}: {a2zApi: AxiosInstance; leadId: string}) =>
  a2zApi.get<JsonApiCompoundResponse<FetchDocumentsResponse>>(
    routes.customers.paths.getDocuments(leadId)
  )

export const deleteDocument = ({axios, documentId}: {axios: AxiosInstance; documentId: string}) =>
  axios.delete(routes.customers.paths.deleteDocument(documentId))

export const uploadDocument = ({a2zApi, formData}: {a2zApi: AxiosInstance; formData: FormData}) =>
  a2zApi.post<JsonApiResponse<UploadedDocumentResponse>>(
    routes.customers.paths.uploadDocument(),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

import Echo from 'laravel-echo'
// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js'
import {useEffect, useState} from 'react'
import {fetchConfigs} from '../api/configs'
import {useConfig} from './useConfig'
import {useAxios} from './useAxios'

const EVENTS = {
  products_rated: {name: 'Products.ProductsRatedEvent', type: 'products_rated'},
}

export const useWebSockets = () => {
  const [a2zApi, isApiReady] = useAxios()
  const {client, dealership} = useConfig()

  const [webSocketConfig, setWebSocketConfig] = useState(null)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (a2zApi) {
      fetchConfigs(a2zApi).then((data) => {
        if (data?.web_socket_config) {
          setWebSocketConfig(data.web_socket_config)
        }
      })
    }
  }, [isApiReady])

  const socketConnect = (webSocketConfig) => {
    if (typeof window.Echo == 'undefined' && webSocketConfig) {
      window.Echo = new Echo({
        broadcaster: webSocketConfig?.driver,
        key: webSocketConfig?.key,
        cluster: webSocketConfig?.options?.cluster,
        disableStats: true,
        forceTLS: true,
        encrypted: true,
      })
      setIsReady(true)
    }
  }

  useEffect(() => {
    if (webSocketConfig && client?.uuid && dealership?.id) {
      socketConnect(webSocketConfig)
    }
  }, [webSocketConfig])

  const listenForEvent = (channel, eventName, onEvent) => {
    window.Echo.channel(channel).listen(eventName, (event) => {
      onEvent(event)
    })
  }

  const listenForProductsRatedEvent = (onEvent) => {
    listenForEvent(
      `${client.uuid}_${dealership.id}_${EVENTS.products_rated.type}`,
      EVENTS.products_rated.name,
      onEvent
    )
  }

  return {
    isReady,
    listeners: isReady
      ? {
          listenForProductsRatedEvent,
        }
      : null,
  }
}

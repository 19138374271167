import React, {useState} from 'react'
import classNames from 'classnames'
import {useHistory} from 'react-router'
import CtaButton from 'Src/components/Buttons/CtaButton'
import InlineButtonSpinner from 'Src/components/Spinners/InlineButtonSpinner'
import {trans, displayPhoneNumber} from 'Src/utilities/Helpers'
import {useFrameContext} from '../../contexts/FrameContext'
import {useNavDispatchContext} from '../../contexts/NavContext'
import {useDeleteTrade} from '../../services/useDeleteTrade'
import PriceBar from '../desking-page/PriceBar'
import Header from '../Header'

const DeskingTradeOutOfRange = () => {
  const history = useHistory()
  const navDispatch = useNavDispatchContext()
  const {
    dealership: {
      attributes: {phone: dealershipPhone},
    },
  } = useFrameContext()
  const deleteTrade = useDeleteTrade(true)
  const [saving, setSaving] = useState(false)

  const errorMessage =
    "Your trade-in value and down payment exceed this vehicle's purchasing price. You can remove your trade or select another vehicle."

  const handleRemoveTradeButtonClick = async () => {
    setSaving(true)
    await deleteTrade()
    setSaving(false)
    history.push('/')
  }

  const handleSelectNewVehicleClick = () => navDispatch({type: 'closeSlideOut'})

  return (
    <div className="tw-w-full tw-h-full" id="desking-trade-out-of-range">
      <div className="headerNav">
        <Header onClose={() => navDispatch({type: 'closeSlideOut'})} hideMenu />
      </div>
      <PriceBar hideBreadCrumbs />
      <div id="page-content" className="tw-w-full tw-h-full tw-flex error-bkgd">
        <div
          id="main-scroll-panel"
          className="scroll scrollBox tw-w-full tw-text-center tw-justify-center"
        >
          <h2 className="whoops tw-mb-4 tw-mt-6">Whoops</h2>
          <div className="tw-px-6">
            <div className="tw-text-lg tw-font-medium tw-px-4 tw-mb-6">{errorMessage}</div>
            <div className="tw-flex tw-justify-center tw-flex-col sm:tw-flex-row tw-mb-6 tw-gap-y-3 tw-gap-x-6">
              <CtaButton
                preset="secondary"
                onClick={handleRemoveTradeButtonClick}
                disabled={saving}
              >
                {saving ? <InlineButtonSpinner /> : null}
                <div className={classNames({'tw-h-0 tw-overflow-hidden': saving})}>
                  {trans('Remove Trade')}
                </div>
              </CtaButton>
              <CtaButton preset="secondary" onClick={handleSelectNewVehicleClick}>
                Select New Vehicle
              </CtaButton>
            </div>
            {dealershipPhone ? (
              <div className="tw-font-medium">
                For personalized assistance, please call us at {displayPhoneNumber(dealershipPhone)}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeskingTradeOutOfRange

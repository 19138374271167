import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import InlineButtonSpinner from 'Src/components/Spinners/InlineButtonSpinner'
import * as yup from 'yup'
import _ from 'lodash'
import CtaButton from 'Src/components/Buttons/CtaButton'
import {conditionOptions} from './conditionOptions'
import VehicleConditionOption from './VehicleConditionOption'
import {buildUrl, getOptions} from '../../../actions/TradeActions'
import {useTradeContext} from '../../../contexts/TradeContext'
import {useFrameContext} from '../../../contexts/FrameContext'
import useForm from '../../../utilities/useForm'
import {trans} from '../../../utilities/Helpers'

const VehicleCondition = () => {
  const {a2zApi} = useFrameContext()
  const {
    TradeVehicleMetadata,
    TradeVehicleCondition,
    setTradeVehicleMetadata,
    setTradeVehicleCondition,
  } = useTradeContext()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [conditions] = useState([...conditionOptions()])

  const getReportsFromConditions = async () => {
    const trade = {...TradeVehicleMetadata?.ids}
    const values = await Promise.all(
      conditions
        .filter(({value}) => !Object.keys(TradeVehicleMetadata.reports).includes(value))
        .map(async ({value}) => {
          const url = buildUrl('condition', value, trade)
          const {report} = await getOptions(a2zApi, url)

          return report
        })
    )

    const updatedReports = _.keyBy(values, 'condition')

    TradeVehicleMetadata.reports = {...TradeVehicleMetadata.reports, ...updatedReports}
    setLoading(false)
  }

  const chooseCondition = (selectedCondition) => {
    setTradeVehicleCondition(selectedCondition)
    setTradeVehicleMetadata({...TradeVehicleMetadata.reports[selectedCondition]})
  }

  useEffect(() => {
    getReportsFromConditions()
  }, [conditions])

  const onSubmit = async () => {
    await chooseCondition(TradeVehicleCondition)

    history.push('/trade-experience/owed-payments')
  }

  const {handleSubmit, getError, clearError} = useForm(
    yup.object().shape({
      condition: yup.string().label('Condition').required('Condition is required'),
    }),
    {
      condition: TradeVehicleCondition || undefined,
    },
    onSubmit
  )

  const error = getError('condition')

  return (
    <form className="tw-flex tw-flex-col md:tw-px-11" onSubmit={handleSubmit}>
      <h2 className="h3">{trans('trade_int.what_is_vehicle_condition')}</h2>
      <p className="tw-mb-6">
        {trans('trade_int.vehicle_value_will_be_based_on_standard_equipment')}
      </p>
      {conditions.map((condition) => (
        <VehicleConditionOption
          key={condition.value}
          condition={condition.value}
          label={condition.label}
          description={condition.description}
          selectedOption={TradeVehicleCondition}
          onSelect={(selectedCondition) => {
            clearError('condition')
            chooseCondition(selectedCondition)
          }}
        >
          {condition.text}
        </VehicleConditionOption>
      ))}
      {error ? (
        <p
          data-testid="error-message"
          className="tw-mb-3 tw-text-sm tw-text-brand-body_text--danger"
        >
          {error}
        </p>
      ) : null}
      <p className="tw-text-sm tw-font-medium md:tw-mb-12">
        {trans('kbb.no_value_vehicle_poor_condition')}
      </p>
      <div className="divider tw-my-6 md:tw-hidden" />
      <div className="tw-text-center">
        <CtaButton type="submit" className="tw-w-full md:tw-max-w-md" disabled={loading}>
          {loading ? <InlineButtonSpinner /> : trans('Next')}
        </CtaButton>
      </div>
    </form>
  )
}

export default VehicleCondition

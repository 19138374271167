import React, {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFingerprint} from '@fortawesome/free-solid-svg-icons'
import {useHistory} from 'react-router'
import InlineButtonSpinner from 'Src/components/Spinners/InlineButtonSpinner'
import CtaButton from 'Src/components/Buttons/CtaButton'
import QuestionsCallDealershipPhone from 'Src/components/QuestionsCallDealershipPhone'
import {getAndSetCityList, getLeadTradeAndDealData} from '../../../actions/RegistrationActions'
import {
  ECodeReturnTo,
  useCustomerDispatchContext,
  useCustomerStateContext,
} from '../../../contexts/CustomerContext'
import {useFrameContext} from '../../../contexts/FrameContext'
import {useNavDispatchContext, useNavStateContext} from '../../../contexts/NavContext'
import {useRegistrationContext} from '../../../contexts/RegistrationContext'
import {FINALIZE_STEPS, trans} from '../../../utilities/Helpers'
import Input from '../../Input'
import RegistrationLayout from '../../Layouts/RegistrationLayout'
import {checkVerification} from '../../../api/VerificationActions'
import {buildCustomerDataFromResponse} from '../../../reducers/CustomerReducer'
import {useDealDispatchContext, useDealStateContext} from '../../../contexts/DealContext'
import {getPhoneTypeBySlug, usePhoneTypes} from '../../../services/usePhoneTypes'
import {
  useEmailQuoteDispatchContext,
  useEmailQuoteStateContext,
} from '../../../contexts/EmailQuoteContext'
import {
  PROGRESS_STATE,
  PROGRESS_STEP,
  useProgressDispatchContext,
} from '../../../contexts/ProgressContext'
import {useAnalytics} from '../../../services/analytics/useAnalytics'
import {createNewLead, getDrLeadFromResponse} from '../../../api/leads'
import {ACTION_DETAILS} from '../../../services/analytics/constants'

const CodeVerification = () => {
  const customerState = useCustomerStateContext()
  const {customerData: customer, codeContactMethod, codeReturnTo} = customerState
  const navDispatch = useNavDispatchContext()
  const customerDispatch = useCustomerDispatchContext()
  const frameContext = useFrameContext()
  const {
    dealership: {id: dealershipId},
    vehicleData: vehicle,
    a2zApi,
    a2zCustomerApi,
    isPriceLocked,
    useCache,
    shouldPreventRedirects,
    isSaveDealEnabled,
  } = frameContext
  const {page} = useNavStateContext()
  const {registrationDispatch, registrationState} = useRegistrationContext()
  const dealDispatch = useDealDispatchContext()
  const dealState = useDealStateContext()
  const history = useHistory()
  const [verificationCode, setVerificationCode] = useState('')
  const [isCodeValid, setIsCodeValid] = useState(true)
  const [verificationInProgress, setVerificationInProgress] = useState(false)
  const [isChanging, setIsChanging] = useState(true)
  const [isPasted, setIsPasted] = useState(false)
  const phoneTypes = usePhoneTypes()
  const emailQuoteDispatch = useEmailQuoteDispatchContext()
  const {sendEmailAfterRegistration} = useEmailQuoteStateContext()
  const ProgressDispatch = useProgressDispatchContext()
  const {trackEvent, events} = useAnalytics()

  const onTryAgainClick = () => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.REGISTER.VERIFY.TRY_AGAIN
    )

    switch (codeReturnTo) {
      case ECodeReturnTo.SEND_CODE:
        trackEvent(
          events.NAVIGATION,
          events.NAVIGATION.actions.NAVIGATE,
          ACTION_DETAILS.REGISTER.SEND.CODE
        )
        history.push('/register/send-code')
        break
      case ECodeReturnTo.NEW_LEAD_CONFIRMATION:
      default:
        trackEvent(
          events.NAVIGATION,
          events.NAVIGATION.actions.NAVIGATE,
          ACTION_DETAILS.REGISTER.NEW_LEAD.CONFIRMATION
        )
        history.push('/register/new-lead-confirmation')
        break
    }

    setIsCodeValid(true)
  }

  const getProgressPayload = (step) => {
    if (step === PROGRESS_STEP.products) {
      return {
        [step]: {
          [dealState.dealType]: PROGRESS_STATE.started,
        },
        payment: PROGRESS_STATE.complete,
      }
    }

    return FINALIZE_STEPS.includes(step)
      ? {finalize: PROGRESS_STATE.complete}
      : {[step]: PROGRESS_STATE.started, payment: PROGRESS_STATE.complete}
  }

  const onSubmit = async () => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.REGISTER.VERIFY.CODE
    )
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CHANGE,
      isPasted ? ACTION_DETAILS.REGISTER.CODE.PASTED : ACTION_DETAILS.REGISTER.CODE.TYPED
    )
    setVerificationInProgress(true)
    const mobileType = getPhoneTypeBySlug(phoneTypes, 'mobile')

    await checkVerification(a2zApi, customer, mobileType.id, {
      method: codeContactMethod,
      code: verificationCode,
    })
      .then((response) => response.data)
      .then(async (checkVerificationCodeResponseBody) => {
        const customerData = buildCustomerDataFromResponse(
          checkVerificationCodeResponseBody,
          customer
        )
        customerDispatch({type: 'setCustomerData', payload: customerData})
        const cityList = await getAndSetCityList(
          a2zApi,
          customerData.zipCode,
          registrationDispatch,
          registrationState
        )

        if (!customerData.cityCounty.id) {
          dealDispatch({type: 'update', payload: {displayCityCountyMessage: true}})
          customerData.cityCounty.id = cityList[0].id
          customerData.cityCounty.city = cityList[0].attributes.city
          customerData.cityCounty.county = cityList[0].attributes.county
          customerData.cityCounty.inCity = cityList[0].attributes.inCity
        }

        customerDispatch({
          type: 'setCustomerCityCounty',
          payload: {cityCounty: customerData.cityCounty, cityList},
        })

        const lead =
          getDrLeadFromResponse(checkVerificationCodeResponseBody) ||
          (await createNewLead(a2zCustomerApi, customerData.id))

        await getLeadTradeAndDealData(
          lead.id,
          vehicle,
          dealershipId,
          {customerData},
          customerDispatch,
          dealDispatch,
          dealState,
          a2zApi,
          a2zCustomerApi,
          isPriceLocked,
          useCache,
          isSaveDealEnabled,
        )

        if (sendEmailAfterRegistration) {
          emailQuoteDispatch({type: 'setSendEmailAfterRegistration', payload: false})
          emailQuoteDispatch({type: 'setSendEmail', payload: true})
        }

        if (customer.id) {
          // only need to touch the lead when this verification was for an existing Customer
          await a2zCustomerApi.post(`/customers/leads/${lead.id}/touch`)

          trackEvent(
            events.AUTHENTICATION,
            events.AUTHENTICATION.actions.AUTHENTICATED,
            null,
            customerData.id
          )
        } else {
          trackEvent(
            events.AUTHENTICATION,
            events.AUTHENTICATION.actions.REGISTERED,
            null,
            customerData.id
          )
        }

        setIsCodeValid(true)

        customerDispatch({type: 'setLeadId', payload: lead.id})

        // Set Breadcrumbs
        const step = page.replace('/', '') // e.g. '/trade' => 'trade'

        ProgressDispatch({type: 'update', payload: getProgressPayload(step)})

        setVerificationInProgress(false)

        if (!shouldPreventRedirects) {
          // Redirect to app
          const pageToRedirectTo = page || '/'
          trackEvent(
            events.NAVIGATION,
            events.NAVIGATION.actions.NAVIGATE,
            pageToRedirectTo === '/' ? 'payment' : step
          )
          navDispatch({type: 'clearAfterRegistration'})
          history.push(pageToRedirectTo)
        }
      })
      .catch((err) => {
        setVerificationInProgress(false)

        if (err?.response?.status) {
          // @TODO We should probably handle different error types with different messaging, but this
          // should be sufficient for now.
          setIsChanging(false)

          return setIsCodeValid(false)
        }

        throw err
      })
  }

  return (
    <RegistrationLayout>
      <p className="tw-mb-4 tw-text-center tw-font-semibold">
        <FontAwesomeIcon icon={faFingerprint} className="tw-mr-2 tw-h-4 tw-w-4" />
        {trans('code_verification.get_verified')}
      </p>
      {codeContactMethod === 'email' && (
        <h4 data-test="emailMessage" className="h4 tw-mb-10 tw-text-center">
          {trans('code_verification.we_emailed_you')}
        </h4>
      )}
      {codeContactMethod === 'sms' && (
        <h4 data-test="smsMessage" className="h4 tw-mb-10 tw-text-center">
          {trans('code_verification.we_texted_you')}
        </h4>
      )}
      <p className="tw-mb-4 tw-text-center">{trans('code_verification.enter_code')}</p>
      <div className="tw-mx-auto tw-my-6 tw-w-80" role="form">
        <Input
          id="one-time-code"
          name="one-time-code"
          type="text"
          data-test="input"
          onPaste={() => {
            setIsPasted(true)
          }}
          onKeyDown={(e) => {
            if (e.metaKey || e.ctrlKey) {
              return
            }

            setIsPasted(false)
          }}
          onInput={(e) => {
            setIsChanging(true)
            setVerificationCode(e.target.value)
          }}
          label={trans('code_verification.one_time_code')}
          required
          width="tw-w-80"
          inputMode="numeric"
          autoComplete="one-time-code"
          autoFocus
          labelStyles="tw-mb-2"
        />
        {!isCodeValid && !isChanging ? (
          <p data-test="invalidCode" className="error">
            {trans('validation.invalid', {attribute: trans('code')})}
          </p>
        ) : null}
      </div>
      <div className="tw-my-10 tw-text-center">
        <CtaButton
          className="tw-w-80"
          onClick={onSubmit}
          disabled={!verificationCode || verificationInProgress || !isChanging}
          data-test="submitButton"
        >
          <div className="tw-flex tw-items-center tw-justify-center">
            {trans('Continue')}
            {verificationInProgress ? (
              <InlineButtonSpinner besideText data-test="buttonSpinner" />
            ) : null}
          </div>
        </CtaButton>
      </div>
      {codeContactMethod === 'email' ? (
        <p data-test="emailP" className="tw-text-center tw-text-xs tw-leading-relaxed">
          {trans('code_verification.check_spam')}{' '}
          <span className="tw-font-bold">{trans('a2z_sync')}</span>
        </p>
      ) : null}
      <div className="divider tw-my-12" />
      <div className="tw-flex tw-flex-col tw-items-center">
        <p className="tw-mb-2 tw-font-semibold">{trans('code_verification.code_not_received')}</p>
        <button
          type="button"
          className="link tw-font-semibold"
          onClick={onTryAgainClick}
          data-test="tryAgainButton"
        >
          {trans('code_verification.try_again')}
        </button>
        <QuestionsCallDealershipPhone
          className="tw-mt-8 tw-font-semibold tw-text-brand-body_text--muted"
          questionsClassName={null}
        />
      </div>
    </RegistrationLayout>
  )
}

export default CodeVerification

import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import CtaButton from 'Src/components/Buttons/CtaButton'
import InputSelect from '../InputSelect'
import {trans} from '../../utilities/Helpers'
import {
  addThousandsSeparator,
  addTermMonthsYears,
  deductibleFormatter,
} from '../../utilities/DataReformatters'
import {useProductContext} from '../../contexts/ProductContext'
import {getProductRatesByProductId} from '../../utilities/ProductHelpers'
import {capitalize} from '../../utilities/textUtilities'
import {useAnalytics} from '../../services/analytics/useAnalytics'
import {ACTION_DETAILS} from '../../services/analytics/constants'
import IconButton from '../Buttons/IconButton'

const ProductRatingFilter = ({hide, product, onSubmit}) => {
  const {trackEvent, events} = useAnalytics()
  const {productRates} = useProductContext()
  const [currentProductRates, setCurrentProductRates] = useState(null)

  // these override the defaults for the form label and how the value
  // should be displayed in the dropdown
  const fieldOverrides = {
    name: {
      label: trans('Coverage'),
      validationType: 'string',
    },
    miles: {
      formatter: addThousandsSeparator,
      label: trans('Mileage'),
      validationType: 'number',
    },
    term: {
      formatter: addTermMonthsYears,
      validationType: 'number',
    },
    deductible: {
      formatter: deductibleFormatter,
      validationType: 'string',
    },
  }

  const getFieldOverride = (field, override) => fieldOverrides?.[field]?.[override]

  const getLabel = (field) => getFieldOverride(field, 'label') ?? capitalize(field)

  const validationSchema = () => {
    const schema = {}

    product.fields.forEach((field) => {
      const errorMessage = trans(`:field is required`, {field: getLabel(field)})

      const fieldSchema =
        getFieldOverride(field, 'validationType') === 'number' ? Yup.number() : Yup.string()

      schema[field] = fieldSchema.required(errorMessage).typeError(trans(errorMessage))
    })

    return schema
  }

  const {
    control,
    formState: {errors, isDirty},
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      term: null,
      miles: null,
      deductible: null,
    },
    resolver: yupResolver(Yup.object().shape(validationSchema())),
  })

  const getProductFieldKey = (field) =>
    product.fields.findIndex((productField) => productField === field)

  const filterOptions = (validators) => {
    const vKeys = Object.keys(validators)

    if (!vKeys.length) {
      return currentProductRates
    }

    return currentProductRates.filter(
      (rate) => !vKeys.map((key) => validators[key] === rate[key]).includes(false)
    )
  }

  const deduplicateOptionsByField = (options, field) => {
    return [...new Set(options.map((rate) => rate[field]))]
  }

  const getOptions = (field) => {
    if (!currentProductRates) {
      return []
    }

    const key = getProductFieldKey(field)
    const validators = {}

    if (key) {
      // if the field above is not filled in, don't render options
      if (getValues(product.fields[key - 1]) === null) {
        return []
      }

      // options are dependent on the fields already selected
      product.fields.slice(0, key).forEach((productField) => {
        const fieldValue = getValues(productField)

        if (fieldValue !== null) {
          validators[productField] = fieldValue
        }
      })
    }

    return deduplicateOptionsByField(filterOptions(validators), field).map((rate) => {
      const formatter = getFieldOverride(field, 'formatter')

      return {
        label: formatter ? formatter(rate) : rate,
        value: rate,
      }
    })
  }

  const isDisabled = (field) => {
    // field is disabled if the field above it is not filled out or
    // there is only one option
    return (
      getValues(product.fields[getProductFieldKey(field) - 1]) === null ||
      getOptions(field).length === 1
    )
  }

  useEffect(() => {
    if (productRates?.length && !currentProductRates) {
      setCurrentProductRates(getProductRatesByProductId(productRates, product.productId))
      // set default values
      product.fields.forEach((field) => {
        setValue(field, product.rate[field])
      })
    }
  }, [productRates])

  const isLoading = () => {
    return !currentProductRates?.length
  }

  const getError = (field) => {
    return errors[field]?.message
  }

  const resetValues = (field) => {
    product.fields.slice(getProductFieldKey(field) + 1).forEach((productField) => {
      const options = getOptions(productField)

      if (options.length === 1) {
        // if there's only one option, select that option
        setValue(productField, options[0].value)
      } else {
        setValue(productField, null)
      }

      clearErrors()
    })
  }

  const applyValue = (field, value) => {
    return getOptions(field).find((o) => o.value === value) ?? []
  }

  const saveFilters = (values) => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.PRODUCT.FILTER.SAVE,
      null,
      {
        productFilterChanged: isDirty,
      }
    )
    onSubmit(product.productId, values)
    hide()
  }

  const handleBackButtonClick = () => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.PRODUCT.FILTER.BACK_TO_PRODUCTS,
      null,
      {
        productFilterChanged: isDirty,
      }
    )
    hide()
  }

  return (
    <div className="tw-overflow-x-hidden tw-h-screen tw-absolute tw-w-full inset-0 tw-bg-brand-body tw-z-[9999]">
      <IconButton size={12} onClick={handleBackButtonClick} aria-label="Back Button">
        <FontAwesomeIcon icon={faChevronLeft} className="tw-text-2xl" />
      </IconButton>
      <div className="tw-px-6 tw-flex tw-flex-col tw-justify-between tw-m-auto tw-w-full md:tw-w-8/12">
        <h1 className="h3 tw-text-center tw-mb-5 tw-line-clamp-2">{product?.name}</h1>
        <form data-testid="product-rating-filter-form" onSubmit={handleSubmit(saveFilters)}>
          {product?.fields?.map((field) => {
            return (
              <div key={field} className="tw-w-full md:tw-max-w-md tw-mx-auto tw-mb-3">
                <Controller
                  control={control}
                  name={field}
                  render={({field: {onChange, onBlur, name, ref, value}}) => (
                    <InputSelect
                      disabled={isDisabled(name)}
                      error={getError(name)}
                      label={getLabel(name)}
                      loading={isLoading()}
                      name={name}
                      onBlur={onBlur}
                      onChange={(v) => {
                        onChange(v.value)
                        resetValues(name)
                      }}
                      options={getOptions(name)}
                      ref={ref}
                      required
                      value={applyValue(name, value)}
                    />
                  )}
                />
              </div>
            )
          })}
          <div className="tw-text-center tw-mt-10">
            <CtaButton type="submit" className="tw-w-full md:tw-max-w-md">
              {trans('Save')}
            </CtaButton>
          </div>
        </form>
      </div>
    </div>
  )
}

ProductRatingFilter.propTypes = {
  hide: PropTypes.func,
  onSubmit: PropTypes.func,
  product: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    productId: PropTypes.string,
    rate: PropTypes.shape({
      name: PropTypes.string,
      miles: PropTypes.number,
      term: PropTypes.number,
      deductible: PropTypes.string,
    }),
  }),
}

export default ProductRatingFilter

import React, {useEffect, useState} from 'react'
import {Redirect, useHistory} from 'react-router'
import PlaceholderSpinner from 'Src/components/Spinners/PlaceholderSpinner'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useRegistrationContext} from 'Src/contexts/RegistrationContext'
import {getCurrentProgram} from 'Src/utilities/ProgramHelpers'
import useShowMessage from 'Src/utilities/useShowMessage'
import {fetchProducts} from 'Src/api/products'
import {
  breadcrumbProgressTracking,
  displayPhoneNumber,
  trans,
  transString,
} from 'Src/utilities/Helpers'
import {useConfig} from 'Src/utilities/useConfig'
import {useProductContext} from 'Src/contexts/ProductContext'
import {SHIFT_EVENTS, useTrackShiftEvents} from 'Src/utilities/useShiftEvents'
import {useAxios} from 'Src/utilities/useAxios'
import {routes} from 'Src/api/routes'
import {useFetchDealership} from 'Src/queries/useFetchDealerships'
import {Helmet} from 'react-helmet'
import {saveQuote} from 'Src/actions/DealActions'
import {useCustomerDispatchContext, useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useNavDispatchContext} from 'Src/contexts/NavContext'
import {
  PROGRESS_STEP,
  useProgressDispatchContext,
  useProgressStateContext,
} from 'Src/contexts/ProgressContext'
import {navigateIfUserRegistered} from 'Src/actions/RegistrationActions'
import Cash from '../desking-page/Cash'
import DeskingPageBody from '../desking-page/DeskingPageBody'
import Finance from '../desking-page/Finance'
import Lease from '../desking-page/Lease'
import RebatesSection from '../desking-page/Rebates/RebatesSection'
import TradeNextButtons from '../desking-page/TradeNextButtons/TradeNextButtons'
import PageLayoutLeft from '../Layouts/PageLayoutLeft'
import PreviousDealModal from '../Deal/PreviousDealModal'

const dealTypeSwitch = (dealType) => {
  switch (dealType) {
    case 'finance':
      return <Finance />
    case 'lease':
      return <Lease />
    case 'cash':
      return <Cash />
    default:
      return null
  }
}

const DeskingHome = () => {
  const [a2zApi, isApiReady] = useAxios()
  const navDispatch = useNavDispatchContext()
  const {dealership} = useConfig()
  const dealState = useDealStateContext()
  const dealDispatch = useDealDispatchContext()
  const {registrationState} = useRegistrationContext()
  const customerState = useCustomerStateContext()
  const progressDispatch = useProgressDispatchContext()
  const progress = useProgressStateContext()
  const {
    customerData: {id: customerId},
    previouslySavedQuote,
  } = customerState
  const frameContext = useFrameContext()
  const history = useHistory()
  const customerDispatch = useCustomerDispatchContext()
  const {
    dealership: {
      attributes: {phone: dealershipPhone},
    },
    vehicleData,
    isIframeVisible,
    isSaveDealEnabled,
  } = frameContext
  const {
    dealType,
    loading,
    displayNoProgramFoundMessage,
    loaded,
    loadFailed,
    displaySwitchToFirstProgramMessage,
    displayCityCountyMessage,
    noProgramsCause,
    isRedirectedBeforeSave,
  } = dealState
  const [description, setDescription] = useState('')
  const [hasShownExpiredProgramMessage, setHasShownExpiredProgramMessage] = useState(false)
  const currentProgram = getCurrentProgram(dealState)
  const {setProductsByDealType, hasFetchedProducts, getSelectedRatedProducts} = useProductContext()
  const pageTitle = transString('Calculate Payments')

  // Prefetch data for Trade page
  useFetchDealership({
    includes: [routes.dealerships.includes.TRADE_VALUE_INTEGRATION],
  })

  const getVehicleType = () => {
    if (!Object.keys(dealState?.vehicle || {}).length) {
      return null
    }

    const {isCPO, isNew} = dealState.vehicle

    return isCPO ? 'certified' : isNew ? 'new' : 'used'
  }

  useEffect(() => {
    progressDispatch({
      type: 'update',
      payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.payment, dealType),
    })
  }, [])

  useEffect(() => {
    if (
      isApiReady &&
      dealership.id &&
      dealType &&
      getVehicleType() &&
      dealState.vehicle &&
      !hasFetchedProducts
    ) {
      ;['cash', 'lease', 'finance'].map((dealType) =>
        fetchProducts(a2zApi, dealership.id, dealType, getVehicleType()).then((data) => {
          setProductsByDealType(dealType, data)
        })
      )
    }
  }, [dealership?.id, dealState?.vehicle?.isCPO, dealState?.vehicle?.isNew, isApiReady])

  useEffect(() => {
    if (!loaded) {
      return
    }

    dealDispatch({
      type: 'update',
      payload: {
        ...{loading: false, isUpdating: false},
      },
    })
  }, [loaded])

  useEffect(() => {
    if (!currentProgram) {
      return
    }

    const {isExpired} = currentProgram

    if (isExpired && !hasShownExpiredProgramMessage) {
      setHasShownExpiredProgramMessage(true)
    }
  }, [dealState])

  useShowMessage(hasShownExpiredProgramMessage, {
    title: '',
    description: trans('expired_program_message', {
      dealershipPhone: displayPhoneNumber(dealershipPhone),
    }),
    onClose: () => undefined, // No additional actions needed since hasShownExpiredProgramMessage has toggled to true and shown once
  })

  useShowMessage(displayNoProgramFoundMessage, {
    title: 'No Programs Found',
    description: "We couldn't calculate a payment using your selection.",
    onClose: () => {
      dealDispatch({type: 'update', payload: {displayNoProgramFoundMessage: false}})
    },
  })

  useShowMessage(displaySwitchToFirstProgramMessage, {
    title: 'No Programs Found',
    description: `We couldn't calculate a payment using your current deal structure. Please make another selection.`,
    onClose: () => {
      dealDispatch({type: 'update', payload: {displaySwitchToFirstProgramMessage: false}})
    },
  })

  useShowMessage(displayCityCountyMessage, {
    title: 'Verify Your Location',
    description: `${registrationState.cityListOptions[0]?.label} was selected for City/County. Please verify and make another selection if needed.`,
    onClose: () => {
      dealDispatch({type: 'update', payload: {displayCityCountyMessage: false}})
    },
  })

  useEffect(() => {
    if (!loaded || noProgramsCause || !currentProgram) {
      return
    }

    setDescription(currentProgram.programDescription)
  }, [currentProgram])

  useTrackShiftEvents(!!vehicleData?.vin && isIframeVisible && [SHIFT_EVENTS.PAYMENT_CALC_SHOWN])

  useEffect(() => {
    if (!isRedirectedBeforeSave || !customerId) {
      return
    }

    navigateIfUserRegistered(customerId, '/', navDispatch, history, async () => {
      dealDispatch({type: 'savingDealQuote', payload: true})
      customerDispatch({type: 'setExistingVehicle', payload: null})
      await saveQuote(
        dealState,
        customerState,
        frameContext,
        customerDispatch,
        dealDispatch,
        getSelectedRatedProducts
      )
      dealDispatch({type: 'savingDealQuote', payload: false})
    })

    dealDispatch({type: 'update', payload: {isRedirectedBeforeSave: false}})
  }, [])

  if (noProgramsCause === 'TradeOutOfRange') {
    return <Redirect to="/payment-out-of-range" />
  }

  if (loadFailed) {
    return <Redirect to="/error" />
  }

  if (loading || !hasFetchedProducts) {
    return <PlaceholderSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageLayoutLeft>
        <PageLayoutLeft.Header>{pageTitle}</PageLayoutLeft.Header>
        <PageLayoutLeft.Body>
          <DeskingPageBody />
          <div className="divider tw-my-12" />
          {dealTypeSwitch(dealType, dealDispatch)}
          {dealState?.data?.attributes?.rebates &&
          Object.keys(dealState.data.attributes.rebates).length ? (
            <>
              <div className="divider tw-my-12" />
              <RebatesSection />
            </>
          ) : null}
        </PageLayoutLeft.Body>
        <PageLayoutLeft.Footer>
          <div className="divider tw-my-12 lg:tw-invisible lg:tw-my-8" />
          <TradeNextButtons />
          <div className="tw-relative tw--z-10 tw-text-legalese tw-text-brand-body">
            {description}
          </div>
        </PageLayoutLeft.Footer>
      </PageLayoutLeft>
      {isSaveDealEnabled && previouslySavedQuote ? <PreviousDealModal /> : null}
    </>
  )
}

export default DeskingHome

import React from 'react'
import PropTypes from 'prop-types'

export const flattenErrors = (errorsArray, prefix = '') => {
  const errorsFlat = {}

  errorsArray.forEach((errors, index) => {
    if (!errors) {
      return
    }

    Object.entries(errors).forEach(([errorKey, errorValue]) => {
      if (typeof errorValue === 'string') {
        errorsFlat[`${prefix ? `${prefix}${index}_` : ''}${errorKey}`] = errorValue
        return
      }

      Object.assign(
        errorsFlat,
        flattenErrors(errorValue, `${prefix ? `${prefix}_` : ''}${errorKey}`)
      )
    })
  })

  return errorsFlat
}

const AccessibilityErrors = ({errors}) => (
  <section>
    <p id="errors" role="alert" aria-atomic="true" className="tw-sr-only">
      {Object.entries(flattenErrors([errors])).map(([errorKey, errorValue]) => (
        <React.Fragment key={errorKey}>
          <span>{errorValue}</span>
          <br />
        </React.Fragment>
      ))}
    </p>
  </section>
)

export default AccessibilityErrors

AccessibilityErrors.propTypes = {
  errors: PropTypes.shape({}),
}

import React, {useState, useEffect} from 'react'
import {trans} from 'Src/utilities/Helpers'
import {useTradeContext} from 'Src/contexts/TradeContext'
import {SHIFT_EVENTS, useTrackShiftEvents} from 'Src/utilities/useShiftEvents'
import OwedPayments from '../common/OwedPayments'
import ResultsIframe from './ResultsIframe'
import imageTradePendingLogo from '../../../images/tradepending-logo.svg'
import imageTradePendingOdometer from '../../../images/tradepending-odometer.svg'

const Results = () => {
  const {TradeVehicleMetadata: vehicle, setTradeVehicleValue} = useTradeContext()
  const [iframeOpen, setIframeOpen] = useState(false)
  const [iframeSrc, setIframeSrc] = useState(null)

  useTrackShiftEvents(vehicle && [SHIFT_EVENTS.TRADE_IN_FINISH])

  /**
   * "OwedPayments" component (included below) relies on the value of "TradeVehicleValue",
   * so we're setting it here.
   */
  useEffect(() => {
    if (vehicle?.tradeInValue?.target) {
      setTradeVehicleValue(vehicle.tradeInValue.target)
    }
  }, [vehicle?.tradeInValue?.target])

  useEffect(() => {
    if (vehicle?.reportMedia) {
      setIframeSrc(vehicle.reportMedia)
    }
  }, [vehicle?.reportMedia])

  if (!vehicle) {
    return null
  }

  const handleLearnMoreLinkClick = (event) => {
    event.preventDefault()
    setIframeOpen(true)
  }

  const handleIframeCloseButtonClick = () => {
    setIframeOpen(false)
  }

  const vehicleNameLine1 = `${vehicle.yearId} ${vehicle.makeName} ${vehicle.modelName} ${vehicle.trim}`.trim()
  const vehicleNameLine2 = `${vehicle.drivetrain} ${vehicle.engine} ${vehicle.body} ${vehicle.fuelType}`.trim()

  return (
    <>
      <ResultsIframe open={iframeOpen} handleClose={handleIframeCloseButtonClick} src={iframeSrc} />
      <div className="tw--mx-32 tw-mb-3 tw-bg-brand-primary tw-pb-1.5 tw-pt-3 tw-text-center tw-text-brand-primary_text sm:tw-mb-9 sm:tw-py-5">
        <div className="tw-mx-auto tw-w-screen md:tw-w-auto">
          <h2 className="h3 tw-mx-auto tw-mb-2 tw-w-60 !tw-text-brand-primary_text sm:tw-w-auto">
            {trans('trade_int.your_estimated_tradein_value')}
          </h2>
          <p className="tw-text-xs">
            {trans('trade_int.vehicle_value_will_be_based_on_standard_equipment')}
          </p>
        </div>
      </div>
      <div className="mb-3 tw-text-center">
        <div className="tw-font-bold" data-testid="vehicle-name-line1">
          {vehicleNameLine1}
        </div>
        <div className="tw-text-sm" data-testid="vehicle-name-line2">
          {vehicleNameLine2}
        </div>
      </div>
      <div className="tw-mx-auto tw-max-w-sm">
        <div className="tw-mb-4 tw-py-10" data-testid="estimated-value-box">
          <div className="mb-3 tw-text-center tw-font-bold">
            <div>{trans('trade_int.estimated_value')}</div>
            <div
              className="tw-text-3xl tw-text-brand-body_text--primary"
              data-testid="trade-in-value-target"
            >
              ${vehicle.tradeInValue.target.toLocaleString()}
            </div>
          </div>
          <div className="mb-1">
            <img
              src={imageTradePendingOdometer}
              alt={trans('trade_int.estimated_tradein_value')}
              width="190"
              height="70"
              className="tw-mx-auto tw-block tw-object-contain"
            />
          </div>
          <div className="tw-flex tw-text-center tw-font-bold">
            <div className="tw-flex-1" data-testid="trade-in-value-low">
              ${vehicle.tradeInValue.low.toLocaleString()}
            </div>
            <div className="tw-flex-1" data-testid="trade-in-value-high">
              ${vehicle.tradeInValue.high.toLocaleString()}
            </div>
          </div>
        </div>
        <div className="tw-flex">
          <div className="tw-flex-1">
            <img
              src={imageTradePendingLogo}
              alt={trans('TradePending Logo')}
              width="90"
              className="tw-mx-auto tw-block"
            />
          </div>
          <div className="tw-flex-1 tw-text-center tw-text-sm">
            <button
              type="button"
              className="link tw-font-semibold"
              onClick={handleLearnMoreLinkClick}
              data-testid="learn-more-link"
            >
              {trans('Learn More')}
            </button>
          </div>
        </div>
      </div>
      <OwedPayments />
    </>
  )
}

export default Results

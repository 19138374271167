import React, {useState, useContext, ReactNode} from 'react'
import {NavDispatchContext} from 'Src/contexts/NavContext'
import {useDealStateContext} from 'Src/contexts/DealContext'
import Header from 'Src/components/Header'
import PriceBar from 'Src/components/desking-page/PriceBar'
import SidePanel from 'Src/components/SidePanel'
import HamburgerMenu from 'Src/components/HamburgerMenu/HamburgerMenu'
import Main from './Main'

interface ILayoutProps {
  children: ReactNode
  mainClassName?: string
}

const Layout = ({children, mainClassName}: ILayoutProps) => {
  const navDispatch = useContext(NavDispatchContext)
  const {dealType} = useDealStateContext()
  const [showMenu, setShowMenu] = useState(false)

  return (
    <>
      <Header onClose={() => navDispatch({type: 'closeSlideOut'})} />
      <PriceBar />
      <div id="page-content" className="tw-flex tw-h-full tw-w-full">
        <Main className={mainClassName}>{children}</Main>
        <SidePanel deal={dealType} />
        <HamburgerMenu open={showMenu} onClose={() => setShowMenu(false)} />
      </div>
    </>
  )
}

export default Layout

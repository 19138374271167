import React from 'react'
import {Route, Switch, useRouteMatch} from 'react-router'
import AboutYourCar from './AboutYourCar'
import AdditionalQuestions from './AdditionalQuestions'
import Results from './Results'

const TradePendingFlow = () => {
  const {path} = useRouteMatch()

  /**
   * AboutYourCar > MoreQuestions > Results
   * -OR-
   * AboutYourCar > Results
   */

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <AboutYourCar />
      </Route>
      <Route exact path={`${path}/additional-questions`}>
        <AdditionalQuestions />
      </Route>
      <Route exact path={`${path}/owed-payments`}>
        <Results />
      </Route>
    </Switch>
  )
}

export default TradePendingFlow

import React from 'react'
import PropTypes from 'prop-types'
import {trans} from '../utilities/Helpers'

const InputRadioGroup = ({
  inputs = [{id: '', label: ''}],
  name = '',
  onChange = () => {},
  selected = null,
}) => (
  <div className="tw-grid tw-gap-y-4">
    {inputs.map((input) =>
      input.label ? (
        <div key={`radio-${input.id}`}>
          <label
            htmlFor={`radio-${input.id}`}
            className="tw-cursor-pointer tw-font-medium tw-flex tw-items-center tw-gap-x-2.5"
          >
            <input
              id={`radio-${input.id}`}
              name={name}
              type="radio"
              checked={selected === input.id}
              className="tw-h-4 tw-w-4"
              onChange={() => onChange(input.id)}
            />
            <div>
              {input.label}
              {input.id === 'sms' ? (
                <span className="tw-font-normal"> ({trans('SMS')})</span>
              ) : null}
            </div>
          </label>
        </div>
      ) : null
    )}
  </div>
)

export default InputRadioGroup

InputRadioGroup.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  name: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

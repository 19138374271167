import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import FieldComponentFactory from '../Fields/FieldComponentFactory'

const COLUMN_WIDTH_DEFAULT = 'sm:tw-col-span-6'

const FormRowRenderer = ({
  rows,
  columnWidth = COLUMN_WIDTH_DEFAULT,
  prefix = '',
  className = '',
} = {}) => (
  <div className={classNames('tw-mb-5', className)}>
    {rows.map(({row, columns, visibility}) => (
      <div
        key={row}
        className={classNames('tw-grid sm:tw-grid-cols-12 tw-gap-x-6', {
          'tw-hidden': visibility === 'invisible',
        })}
      >
        {columns.map(({column, fields, columnWidth: width}) => {
          const isMultipleColumnsOrNotDefaultClass =
            columns.length > 1 || columnWidth !== COLUMN_WIDTH_DEFAULT

          return (
            <div
              key={`${prefix}${column}`}
              className={classNames('tw-col-span-full', width, {
                [columnWidth]: !width && isMultipleColumnsOrNotDefaultClass,
              })}
            >
              {fields.map((options) => (
                <FieldComponentFactory key={options.name} options={{...options, prefix}} />
              ))}
            </div>
          )
        })}
      </div>
    ))}
  </div>
)

FormRowRenderer.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  columnWidth: PropTypes.string,
  prefix: PropTypes.string,
  className: PropTypes.string,
}

export default FormRowRenderer

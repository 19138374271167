import PropTypes from 'prop-types'
import React, {useEffect, useRef} from 'react'
import {useLocation} from 'react-router'

const ScrollToTop = ({children}) => {
  const {pathname} = useLocation()
  const ref = useRef()

  useEffect(() => {
    // We want to target the children of this component
    ref.current?.firstChild?.scrollTo({top: 0, behavior: 'smooth'})
  }, [pathname])

  return (
    <div ref={ref} className="tw-relative">
      {children}
    </div>
  )
}

ScrollToTop.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

export default ScrollToTop

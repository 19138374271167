import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import PopoverHover from 'Src/components/Popovers/PopoverHover'
import IconQuestionCircle from 'Src/components/IconQuestionCircle'
import schema from './CreditScoreSchema'
import {navigateIfUserRegistered} from '../../../actions/RegistrationActions'
import {useCustomerStateContext} from '../../../contexts/CustomerContext'
import {useNavDispatchContext} from '../../../contexts/NavContext'
import {useDealDispatchContext, useDealStateContext} from '../../../contexts/DealContext'
import {retrieveDealData} from '../../../actions/DealActions'
import {useFrameContext} from '../../../contexts/FrameContext'
import {
  breadcrumbProgressTracking,
  preventNonNumericalInput,
  trans,
} from '../../../utilities/Helpers'
import CreditSlider from './CreditSlider'
import {
  PROGRESS_STEP,
  useProgressDispatchContext,
  useProgressStateContext,
} from '../../../contexts/ProgressContext'
import {useAnalytics} from '../../../services/analytics/useAnalytics'
import {ACTION_DETAILS} from '../../../services/analytics/constants'

const INPUT_NAMES = {
  CREDIT_SCORE: 'creditScore',
}

const CreditScore = () => {
  const {
    vin,
    dealership: {id: dealershipId},
    a2zApi,
    useCache,
  } = useFrameContext()
  const history = useHistory()
  const {trackEvent, events} = useAnalytics()
  const {
    customerData: {id: customerId},
  } = useCustomerStateContext()
  const navDispatch = useNavDispatchContext()
  const dealDispatch = useDealDispatchContext()
  const progressDispatch = useProgressDispatchContext()
  const progress = useProgressStateContext()
  const dealState = useDealStateContext() || {}
  const customerContext = useCustomerStateContext()
  const {creditScore, minCreditScore, maxCreditScore, dealType} = dealState
  const rangeMinCreditScore = 300
  const rangeMaxCreditScore = 850
  const {
    register,
    formState: {errors},
    setValue,
    watch,
    clearErrors,
    trigger,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {creditScore},
  })
  const formCreditScore = watch('creditScore')
  const [creditScoreInputChangeCount, setCreditScoreInputChangeCount] = useState(0)
  const [creditScoreSliderChangeCount, setCreditScoreSliderChangeCount] = useState(0)
  const trackCreditChangeEvent = (type) => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CHANGE,
      ACTION_DETAILS.PAGES.CREDIT,
      null,
      {
        creditScoreChangeCount: creditScoreSliderChangeCount + creditScoreInputChangeCount + 1,
      }
    )

    if (type === 'slider') {
      const sliderChangeCount = creditScoreSliderChangeCount + 1
      setCreditScoreSliderChangeCount(sliderChangeCount)
      trackEvent(
        events.ENGAGEMENT,
        events.ENGAGEMENT.actions.CHANGE,
        ACTION_DETAILS.CREDIT.SLIDER,
        null,
        {
          creditScoreSliderChangeCount: sliderChangeCount,
        }
      )

      return
    }

    const inputChangeCount = creditScoreInputChangeCount + 1
    setCreditScoreInputChangeCount(inputChangeCount)
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CHANGE,
      ACTION_DETAILS.CREDIT.INPUT,
      null,
      {
        creditScoreInputChangeCount: inputChangeCount,
      }
    )
  }

  // Validate on load (required to show validation errors when switching between Finance and Lease)
  useEffect(() => {
    trigger()
  }, [])

  useEffect(() => {
    if (creditScore.toString() !== formCreditScore) {
      setValue('creditScore', creditScore)
    }
  }, [creditScore])

  // Store whether current value is valid. We use this to prevent updating score unless it is valid.
  const creditScoreErrorType = errors?.creditScore?.type
  useEffect(() => {
    const validCreditScore = !creditScoreErrorType
    dealDispatch({type: 'update', payload: {validCreditScore}})
  }, [creditScoreErrorType])

  const handleInput = async (updatedCreditScore) => {
    const prevValue = {creditScore}

    const payload = {creditScore: updatedCreditScore?.toString()}

    dealDispatch({type: 'update', payload})

    if (
      (updatedCreditScore > maxCreditScore || updatedCreditScore < minCreditScore) &&
      updatedCreditScore >= rangeMinCreditScore &&
      updatedCreditScore <= rangeMaxCreditScore
    ) {
      dealDispatch({type: 'update', payload: {isUpdating: true}})
      await retrieveDealData(
        {
          vin,
          dealershipId,
          dealType,
          prevValue,
          requestParams: payload,
          useCache,
        },
        dealDispatch,
        a2zApi,
        dealState,
        customerContext
      )
    }
  }

  const tooltipContent = (
    <p role="tooltip" id="credit-score-tooltip">
      {trans('credit_score_tool_tip')}
    </p>
  )

  return (
    <div role="group" aria-labelledby="credit-score">
      <div className="tw-leading-relaxed">
        <h2 id="credit-score" className="h5 tw-inline">
          {trans('credit_score_prompt')}
        </h2>
        <PopoverHover content={tooltipContent} data-testid="tooltip">
          <IconQuestionCircle />
        </PopoverHover>
      </div>
      <p className="tw-text-xs tw-mb-5">{trans('credit_score_prompt_sr_label')}</p>
      <form noValidate>
        <p id="errors" role="alert" aria-atomic="true" className="tw-sr-only">
          {Object.keys(errors).map((errorKey) => (
            <React.Fragment key={errorKey}>
              <span>{errors[errorKey].message}</span>
              <br />
            </React.Fragment>
          ))}
        </p>
        <div
          id="credit-score-container"
          aria-live="assertive"
          aria-atomic="false"
          className="credit-score-wrapper"
        >
          <input
            name="creditScore"
            className="credit-score"
            type="text"
            step={5}
            {...register(INPUT_NAMES.CREDIT_SCORE)}
            data-testid="credit-input"
            onKeyPress={(e) => preventNonNumericalInput(e)}
            onBlur={({target: {value}}) => {
              if (value < rangeMinCreditScore) {
                value = rangeMinCreditScore
              } else if (value > rangeMaxCreditScore) {
                value = rangeMaxCreditScore
              }
              clearErrors('creditScore')
              setValue('creditScore', value)

              trackCreditChangeEvent('input')
              handleInput(value)
            }}
            inputMode="numeric"
            maxLength={3}
          />
          {errors ? <small className="error">{errors.creditScore?.message}</small> : null}
        </div>

        <CreditSlider
          min={rangeMinCreditScore}
          max={rangeMaxCreditScore}
          value={formCreditScore}
          onPointerUp={(value) => {
            trackCreditChangeEvent('slider')
            handleInput(value)
          }}
          onChange={(value) => {
            clearErrors('creditScore')
            setValue('creditScore', value)
          }}
          reference={register}
          data-testid="credit-slider"
        />
      </form>
      <div />
      <div className="credit-score__callout tw-rounded">
        <p className="text">{trans('credit_score_unknown')}</p>
        <p className="text">
          Get{' '}
          <a
            href="#"
            tabIndex="0"
            className="link"
            onClick={(event) => {
              event.preventDefault()
              trackEvent(
                events.ENGAGEMENT,
                events.ENGAGEMENT.actions.CLICK,
                ACTION_DETAILS.CREDIT.PRE_APPROVED
              )
              trackEvent(
                events.NAVIGATION,
                events.NAVIGATION.actions.NAVIGATE,
                ACTION_DETAILS.PAGES.CREDIT
              )
              navigateIfUserRegistered(customerId, '/credit', navDispatch, history, () => {
                progressDispatch({
                  type: 'update',
                  payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.credit, dealType),
                })
              })
            }}
            data-testid="pre-approved-link"
          >
            Pre-Approved
          </a>{' '}
          now!
        </p>
      </div>
    </div>
  )
}

export default CreditScore

import React from 'react'
import Helmet from 'react-helmet'
import {useConfig} from '../utilities/useConfig'

export const THEMES = {
  a2z: {
    name: 'A2Z',
    stylesheets: [
      'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap',
    ],
  },
  mazda: {
    name: 'Mazda',
    stylesheets: [`/static/theme-mazda.css?t=${new Date().toJSON().slice(0, 10)}`], // Prints YYYY-MM-DD
  },
}

const Theme = () => {
  const {theme} = useConfig()
  const themeKey = theme in THEMES ? theme : 'a2z'
  document.documentElement.classList.add(`theme--${themeKey}`)

  return (
    <Helmet>
      {THEMES[themeKey].stylesheets.map((stylesheet) => (
        <link key={stylesheet} href={stylesheet} rel="stylesheet" />
      ))}
    </Helmet>
  )
}

export default Theme

import {saveQuote} from './DealActions'

const getRequestTypeId = (slug, requestTypes) =>
  requestTypes?.find(({attributes}) => attributes.slug === slug)?.id

export async function addRequestType(
  requestTypeSlug,
  dealState,
  customerState,
  frameContext,
  customerDispatch,
  dealDispatch,
  getSelectedRatedProducts
) {
  const {a2zApi, requestTypes} = frameContext
  const leadVehicleId = await saveQuote(
    dealState,
    customerState,
    frameContext,
    customerDispatch,
    dealDispatch,
    getSelectedRatedProducts
  )
  if (!leadVehicleId) return

  const leadVehicleData = {
    data: {
      type: 'leadVehicles',
      id: leadVehicleId,
      attributes: {},
      relationships: {
        leadRequestTypes: {
          data: [
            {
              type: 'leadRequestTypes',
              id: getRequestTypeId(requestTypeSlug, requestTypes),
            },
          ],
        },
      },
    },
  }

  a2zApi.put(`/customers/leads/vehicles/${leadVehicleId}`, leadVehicleData).catch((error) => {
    console.error(error)
  })
}

export const requestTypeActionsTestMethods = {
  getRequestTypeId,
}

const buildNestedErrorNames = (allErrors, errorName, nameList, prevErrorName = null) => {
  allErrors[errorName].forEach((errorsObj, index) => {
    if (!errorsObj) {
      // Given error object in an array will exist as null in it's array when validation criteria is met for that object
      return
    }

    Object.keys(errorsObj).forEach((errorName2) => {
      if (Array.isArray(errorsObj[errorName2])) {
        buildNestedErrorNames(errorsObj, errorName2, nameList, `${errorName}[${index}]`)

        return
      }

      const fullErrorName = prevErrorName
        ? `${prevErrorName}.${errorName}[${index}].${errorName2}`
        : `${errorName}[${index}].${errorName2}`

      nameList.push(fullErrorName)
    })
  })
}

const buildErrorNames = (allErrors) => {
  const errorKeys = Object.keys(allErrors)
  const nameList = []

  errorKeys.forEach((errorName) => {
    if (Array.isArray(allErrors[errorName])) {
      buildNestedErrorNames(allErrors, errorName, nameList)

      return
    }

    nameList.push(errorName)
  })

  return nameList
}

const getExistingNodes = (names) => {
  if (!names.length) {
    return []
  }

  let errorElements = document.getElementsByName(names[0])

  if (!errorElements.length) {
    errorElements = document.getElementsByName(`${names[0]}Private`)
  }

  if (!errorElements.length) {
    console.error(`Validation exists for "${names[0]}", but input element does not exist!`)

    names.shift()

    if (names.length) getExistingNodes(names)

    return []
  }

  return errorElements
}

const ErrorFocus = (errors) => {
  const names = buildErrorNames(errors)
  const node = getExistingNodes(names)
  // focus after render
  if (node.length) setTimeout(() => node[0].focus())

  return null
}

export default ErrorFocus

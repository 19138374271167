import React from 'react'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router'
import InlineButtonSpinner from 'Src/components/Spinners/InlineButtonSpinner'
import CtaButton from 'Src/components/Buttons/CtaButton'
import {useAnalytics} from '../../services/analytics/useAnalytics'
import {trans} from '../../utilities/Helpers'
import BackButton from '../Buttons/BackButton'
import {ACTION_DETAILS} from '../../services/analytics/constants'

const FormFooter = ({isSubmitting}: {isSubmitting: boolean}) => {
  const history = useHistory()
  const {trackEvent, events} = useAnalytics()

  const handleBackButtonClick = () => {
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.BACK)
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.PAGES.CREDIT)
  }

  const handleSkipCreditApplicationButtonClick = () => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.CREDIT.APPLICATION.SKIP
    )
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.PAGES.FINALIZE)
    history.goBack()
    history.push('/finalize')
  }

  return (
    <div className="flex-wrap tw-grid tw-grid-cols-5 tw-justify-between tw-py-2 md:tw-flex">
      <div className="tw-justify-left tw-col-span-2 tw-flex-col tw-items-start tw-justify-center tw-text-left md:tw-flex md:tw-w-3/12">
        <BackButton additionOnClick={handleBackButtonClick} />
      </div>
      <div className="tw-justify-right tw-col-span-3 tw-flex-col tw-justify-center tw-text-right md:tw-flex md:tw-w-3/12 md:tw-text-center">
        <CtaButton
          preset="link"
          className="!tw-text-sm !tw-font-bold"
          onClick={handleSkipCreditApplicationButtonClick}
        >
          {trans('Skip Credit Application')}
        </CtaButton>
      </div>
      <div className="w-3/12 tw-col-span-5 tw-row-start-1 tw-flex tw-flex-col tw-items-end tw-justify-center tw-pb-6 tw-text-center md:tw-pb-0 md:tw-pt-0">
        <CtaButton type="submit" className="tw-w-full" disabled={isSubmitting}>
          <div className="tw-flex tw-items-center tw-justify-center">
            {trans('credit_app.agree_submit')}
            {isSubmitting ? <InlineButtonSpinner besideText /> : null}
          </div>
        </CtaButton>
      </div>
    </div>
  )
}

FormFooter.propTypes = {
  isSubmitting: PropTypes.bool,
}

export default FormFooter

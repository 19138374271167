import React, {useRef} from 'react'
import PropTypes from 'prop-types'
import {useHistory, useLocation} from 'react-router'
import {useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useNavDispatchContext} from 'Src/contexts/NavContext'
import {navigateIfUserRegistered} from 'Src/actions/RegistrationActions'
import {
  useProgressStateContext,
  useProgressDispatchContext,
  PROGRESS_STEP,
} from 'Src/contexts/ProgressContext'
import {breadcrumbProgressTracking, trans} from 'Src/utilities/Helpers'
import {useDealStateContext} from 'Src/contexts/DealContext'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {useProductContext} from 'Src/contexts/ProductContext'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {trackProductConversionEvent} from 'Src/utilities/ProductHelpers'
import useMenuEscapeKey from 'Src/utilities/useMenuEscapeKey'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import EmailQuote from '../EmailQuote'

const Hamburger = ({open, onClose = () => undefined} = {}) => {
  const mainMenuRef = useRef()
  const location = useLocation()
  const history = useHistory()
  const customerState = useCustomerStateContext()
  const {trackEvent, events} = useAnalytics()
  const {
    customerData: {id: customerId},
  } = customerState
  const navDispatch = useNavDispatchContext()
  const ProgressDispatch = useProgressDispatchContext()
  const progress = useProgressStateContext()
  const {isUpdating} = useDealStateContext()
  const {getSelectedRatedProducts, hasProducts} = useProductContext()
  const {isDocUploadsEnabled} = useFrameContext()
  const dealState = useDealStateContext()
  const {dealType} = dealState
  const shouldRenderComponent = open && !isUpdating
  useMenuEscapeKey(mainMenuRef, shouldRenderComponent, onClose)

  if (!shouldRenderComponent) {
    return null
  }

  const trackProductEvents = () => {
    if (hasProducts && location?.pathname === '/products') {
      trackProductConversionEvent(
        customerId,
        events,
        getSelectedRatedProducts(dealState.dealType),
        trackEvent
      )
    }
  }

  const handleDocumentUploadsProgressClick = (event) => {
    event.preventDefault()
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.MENU.DOC_UPLOADS)
    trackEvent(
      events.NAVIGATION,
      events.NAVIGATION.actions.NAVIGATE,
      ACTION_DETAILS.PAGES.DOC_UPLOADS
    )
    navigateIfUserRegistered(customerId, '/document-upload', navDispatch, history, () => {
      ProgressDispatch({
        type: 'update',
        payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.uploads, dealType),
      })
      onClose()
    })
  }

  return (
    <div
      ref={mainMenuRef}
      id="main-menu"
      role="navigation"
      aria-label="Main Menu"
      className="tw-absolute tw-inset-0 tw-z-50 tw-h-screen"
      tabIndex="0"
    >
      <div
        data-test="div"
        className="tw-absolute tw-inset-0 tw-h-screen"
        onClick={() => onClose()}
      />
      <div className="tw-w-58 tw-absolute tw-left-1 tw-top-12 tw-mb-4 tw-flex tw-justify-between sm:tw-left-4">
        <div className="menu__body tw-h-84 tw-flex tw-flex-col tw-justify-between tw-gap-y-6 tw-rounded-lg tw-px-5 tw-pb-6 tw-pt-4 md:tw-gap-y-3">
          <a
            data-testid="main-menu-link deal-details"
            href="#"
            tabIndex="0"
            className="link tw-py-1 tw-font-semibold sm:tw-hidden"
            onClick={(event) => {
              event.preventDefault()
              trackEvent(
                events.ENGAGEMENT,
                events.ENGAGEMENT.actions.CLICK,
                ACTION_DETAILS.MENU.VIEW.DEAL_DETAILS
              )
              trackEvent(
                events.NAVIGATION,
                events.NAVIGATION.actions.NAVIGATE,
                ACTION_DETAILS.MENU.SIDE_PANEL
              )
              trackProductEvents()
              history.push('/side-panel')
              onClose()
            }}
          >
            View Deal Details
          </a>
          <EmailQuote isNotButton linkClassName="tw-font-semibold tw-py-1" />
          <a
            data-testid="main-menu-link desking"
            href="#"
            tabIndex="0"
            className="link tw-py-1 tw-font-semibold"
            onClick={(event) => {
              event.preventDefault()
              trackEvent(
                events.ENGAGEMENT,
                events.ENGAGEMENT.actions.CLICK,
                ACTION_DETAILS.MENU.VIEW.PRICING_AND_PAYMENTS
              )
              trackEvent(
                events.NAVIGATION,
                events.NAVIGATION.actions.NAVIGATE,
                ACTION_DETAILS.PAGES.PAYMENT
              )
              trackProductEvents()
              history.push('/')
              ProgressDispatch({
                type: 'update',
                payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.payment, dealType),
              })
              onClose()
            }}
          >
            View Pricing &amp; Payments
          </a>
          <a
            data-testid="main-menu-link trade"
            href="#"
            tabIndex="0"
            className="link tw-py-1 tw-font-semibold"
            onClick={(event) => {
              event.preventDefault()
              trackEvent(
                events.ENGAGEMENT,
                events.ENGAGEMENT.actions.CLICK,
                ACTION_DETAILS.MENU.ESTIMATE_TRADE
              )
              trackEvent(
                events.NAVIGATION,
                events.NAVIGATION.actions.NAVIGATE,
                ACTION_DETAILS.PAGES.TRADE
              )
              trackProductEvents()
              navigateIfUserRegistered(customerId, '/trade', navDispatch, history, () => {
                ProgressDispatch({
                  type: 'update',
                  payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.trade, dealType),
                })
                onClose()
              })
            }}
          >
            Estimate My Trade
          </a>
          {hasProducts && (
            <a
              data-testid="main-menu-link products"
              href="#"
              tabIndex="0"
              className="link tw-py-1 tw-font-semibold"
              onClick={(event) => {
                event.preventDefault()
                trackEvent(
                  events.ENGAGEMENT,
                  events.ENGAGEMENT.actions.CLICK,
                  ACTION_DETAILS.MENU.VIEW.PROTECTION_PLANS
                )
                trackEvent(
                  events.NAVIGATION,
                  events.NAVIGATION.actions.NAVIGATE,
                  ACTION_DETAILS.PAGES.PRODUCTS
                )
                navigateIfUserRegistered(customerId, '/products', navDispatch, history, () => {
                  ProgressDispatch({
                    type: 'update',
                    payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.products, dealType),
                  })
                  onClose()
                })
              }}
            >
              {trans('View Protection Plans')}
            </a>
          )}
          <a
            data-testid="main-menu-link pre-approval"
            href="#"
            tabIndex="0"
            className="link tw-py-1 tw-font-semibold"
            onClick={(event) => {
              event.preventDefault()
              trackEvent(
                events.ENGAGEMENT,
                events.ENGAGEMENT.actions.CLICK,
                ACTION_DETAILS.MENU.GET.PRE_APPROVED
              )
              trackEvent(
                events.NAVIGATION,
                events.NAVIGATION.actions.NAVIGATE,
                ACTION_DETAILS.PAGES.CREDIT
              )
              trackProductEvents()
              navigateIfUserRegistered(customerId, '/credit', navDispatch, history, () => {
                ProgressDispatch({
                  type: 'update',
                  payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.credit, dealType),
                })
                onClose()
              })
            }}
          >
            Get Pre-Approved
          </a>
          {isDocUploadsEnabled ? (
            <a
              data-testid="main-menu-link doc-uploads"
              href="#"
              tabIndex="0"
              className="link tw-py-1 tw-font-semibold"
              onClick={handleDocumentUploadsProgressClick}
            >
              {trans('Upload Documents')}
            </a>
          ) : null}
          <a
            data-testid="main-menu-link availability"
            href="#"
            tabIndex="0"
            className="link tw-py-1 tw-font-semibold"
            onClick={(event) => {
              event.preventDefault()
              trackEvent(
                events.ENGAGEMENT,
                events.ENGAGEMENT.actions.CLICK,
                ACTION_DETAILS.MENU.GET.CHECK_AVAILABILITY
              )
              trackEvent(
                events.NAVIGATION,
                events.NAVIGATION.actions.NAVIGATE,
                ACTION_DETAILS.PAGES.CHECK_AVAILABILITY
              )
              trackProductEvents()
              navigateIfUserRegistered(
                customerId,
                '/check-availability',
                navDispatch,
                history,
                () => {
                  ProgressDispatch({
                    type: 'update',
                    payload: breadcrumbProgressTracking(progress, undefined, dealType),
                  })
                  onClose()
                }
              )
            }}
          >
            Check Availability
          </a>
          <a
            data-testid="main-menu-link test-drive"
            href="#"
            tabIndex="0"
            className="link tw-py-1 tw-font-semibold"
            onClick={(event) => {
              event.preventDefault()
              trackEvent(
                events.ENGAGEMENT,
                events.ENGAGEMENT.actions.CLICK,
                ACTION_DETAILS.MENU.SCHEDULE_TEST_DRIVE
              )
              trackEvent(
                events.NAVIGATION,
                events.NAVIGATION.actions.NAVIGATE,
                ACTION_DETAILS.PAGES.SCHEDULE
              )
              trackProductEvents()
              navigateIfUserRegistered(customerId, '/schedule', navDispatch, history, () => {
                ProgressDispatch({
                  type: 'update',
                  payload: {
                    ...breadcrumbProgressTracking(progress, undefined, dealType),
                    finalize: 'complete',
                  },
                })
                onClose()
              })
            }}
          >
            Schedule a Test Drive
          </a>
          <a
            data-testid="main-menu-link meet"
            href="#"
            tabIndex="0"
            className="link tw-py-1 tw-font-semibold"
            onClick={(event) => {
              event.preventDefault()
              trackEvent(
                events.ENGAGEMENT,
                events.ENGAGEMENT.actions.CLICK,
                ACTION_DETAILS.MENU.MEET_WITH_US
              )
              trackEvent(
                events.NAVIGATION,
                events.NAVIGATION.actions.NAVIGATE,
                ACTION_DETAILS.PAGES.MEET
              )
              trackProductEvents()
              navigateIfUserRegistered(customerId, '/meet', navDispatch, history, () => {
                ProgressDispatch({
                  type: 'update',
                  payload: {
                    ...breadcrumbProgressTracking(progress, undefined, dealType),
                    finalize: 'complete',
                  },
                })
                onClose()
              })
            }}
          >
            Meet with Us
          </a>
        </div>
      </div>
    </div>
  )
}

Hamburger.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default Hamburger

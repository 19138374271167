import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import CtaButton from 'Src/components/Buttons/CtaButton'
import {trans} from 'Src/utilities/Helpers'
import {UploadedDocument} from 'Src/transformers/documentTransformer'

export type OnDeleteDocument = ({documentId}: {documentId: string}) => void

const DocumentsList = ({
  documents,
  onDeleteDocument,
}: {
  documents: UploadedDocument[]
  onDeleteDocument: OnDeleteDocument
}) => (
  <div>
    {documents.map((document) => {
      if (!document) {
        return null
      }

      return (
        <div
          key={document.id}
          className="tw-mx-auto tw-mt-4 tw-flex tw-flex-col tw-items-center tw-gap-4 sm:tw-flex-col md:tw-flex-row md:tw-items-center md:tw-justify-center md:tw-gap-4"
        >
          <p className="mr-2">
            <FontAwesomeIcon icon={faCheck} color="green" className="tw-h-3.5 tw-w-3.5" />
            {trans('uploaded_on_date', {date: document.updatedAt})}
          </p>
          <CtaButton
            preset="dangerSecondary"
            onClick={() => onDeleteDocument({documentId: document.id})}
          >
            {trans('Delete')}
          </CtaButton>
        </div>
      )
    })}
  </div>
)

export default DocumentsList

import React from 'react'
import classNames from 'classnames'
import {Helmet} from 'react-helmet'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes, faPlus} from '@fortawesome/free-solid-svg-icons'
import useShowMessage from 'Src/utilities/useShowMessage'
import {useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useEmailShareQuoteContext} from 'Src/contexts/EmailShareQuoteContext'
import Input from 'Src/components/Input'
import IconButton from 'Src/components/Buttons/IconButton'
import CtaButton from 'Src/components/Buttons/CtaButton'
import BackButton from 'Src/components/Buttons/BackButton'
import {trans, transString} from 'Src/utilities/Helpers'
import PageLayoutCenter from 'Src/components/Layouts/PageLayoutCenter'
import QuestionsCallDealershipPhone from 'Src/components/QuestionsCallDealershipPhone'

const EmailShareDeal = () => {
  const {customerData: customer} = useCustomerStateContext()
  const {
    emailErrors,
    emails,
    serverError,
    handleAddAnotherEmailClick,
    handleEmailBlur,
    handleEmailChange,
    handleEmailInputRemoveClick,
    handleSendEmails,
  } = useEmailShareQuoteContext()
  const emailKeys = Object.keys(emails)
  const hasMultipleEmails = emailKeys.length > 1
  const pageTitle = transString('Email/Share Deal')

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageLayoutCenter>
        <PageLayoutCenter.Header>{pageTitle}</PageLayoutCenter.Header>
        <PageLayoutCenter.Body>
          <p className="tw-mx-auto tw-mb-4 tw-max-w-[300px]">{trans('other_email_info')}</p>
          <form onSubmit={handleSendEmails}>
            {serverError && (
              <p className="error tw-text-center">
                Sorry, there was a problem updating customer primary email address.
              </p>
            )}
            <ul className="tw-mx-auto tw-mb-8 tw-flex tw-w-80 tw-flex-col tw-gap-2">
              {emailKeys.map((key, index) => {
                const placeholder = !index ? 'your-email@email.com' : 'other-email@email.com'
                const isReadOnly = customer?.email !== '' && key === 'yourEmail'

                return (
                  <li key={key}>
                    <div className="tw-flex tw-items-center tw-justify-center">
                      <Input
                        type="email"
                        required
                        placeholder={placeholder}
                        aria-required="true"
                        onChange={handleEmailChange}
                        onBlur={(event) => handleEmailBlur(event, key)}
                        value={emails[key]}
                        name={key}
                        readOnly={isReadOnly}
                        disabled={isReadOnly}
                      />
                      {hasMultipleEmails ? (
                        <div>
                          <IconButton
                            size={9}
                            preset="danger"
                            onClick={() => handleEmailInputRemoveClick(key)}
                            aria-label={transString('Remove Email')}
                          >
                            <FontAwesomeIcon className="tw-text-2xl" icon={faTimes} />
                          </IconButton>
                        </div>
                      ) : null}
                    </div>
                    {emailErrors[key] ? (
                      <div className="tw-text-left tw-text-sm tw-text-brand-body_text--danger">
                        {emailErrors[key]}
                      </div>
                    ) : null}
                  </li>
                )
              })}
              <li>
                {emailKeys.length < 10 ? (
                  <div
                    className={classNames('tw-text-right', {
                      'tw-pr-9': hasMultipleEmails,
                    })}
                  >
                    <CtaButton
                      preset="link"
                      type="button"
                      className="!tw-text-sm"
                      onClick={handleAddAnotherEmailClick}
                    >
                      <FontAwesomeIcon icon={faPlus} className="tw-mr-2" />
                      {trans('add_another_email')}
                    </CtaButton>
                  </div>
                ) : null}
              </li>
            </ul>
            <CtaButton type="submit" className="tw-mb-4">
              {trans('send')}
            </CtaButton>
          </form>
          <QuestionsCallDealershipPhone />
        </PageLayoutCenter.Body>
        <PageLayoutCenter.Footer>
          <div className="tw-mt-4 tw-py-2">
            <BackButton />
          </div>
        </PageLayoutCenter.Footer>
      </PageLayoutCenter>
    </>
  )
}

export default EmailShareDeal

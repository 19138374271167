import {AxiosInstance} from 'axios'
import {routes} from './routes'

interface IFetchDealershipOptions {
  includes?: string[]
}

export const fetchDealership = (
  a2zApi: AxiosInstance,
  dealershipId: number,
  options?: IFetchDealershipOptions
) => a2zApi.get(routes.dealerships.paths.dealership(dealershipId, options?.includes))

import React, {useState} from 'react'
import {PropTypes} from 'prop-types'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import classNames from 'classnames'
import {useLendingCenterContext} from '../../../LendingCenterContext'

const PrimaryStateSelect = ({name, prefix, label, placeholder = '', required = false}) => {
  const {
    lendingCenterData: {states},
  } = useLendingCenterContext()

  return (
    <SelectInput
      name={name}
      prefix={prefix}
      label={label}
      placeholder={placeholder}
      required={required}
      selectOptions={states}
    />
  )
}

PrimaryStateSelect.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}

const SelectInput = ({
  name,
  prefix = '',
  label,
  selectOptions,
  required = false,
  placeholder = '',
  showPlaceholder = true,
}) => {
  const {
    lendingCenterData: {states},
  } = useLendingCenterContext()
  const {values, setFieldValue, setFieldTouched} = useFormikContext()
  const isCoBuyer = prefix?.includes('coBuyer')
  const [customerStateId, setCustomerStateId] = useState(values.residences[0].state)
  const [coBuyerStateId, setCoBuyerStateId] = useState(
    isCoBuyer && values.coBuyers[0].residences[0].state
  )

  const getStateCommunityProperty = (stateId) =>
    Boolean(states.find((state) => Number(state.key) === Number(stateId))?.communityProperty)

  return (
    <>
      {label ? (
        <label className={classNames({required})} htmlFor={prefix + name}>
          {label}
        </label>
      ) : null}
      <div>
        <Field
          as="select"
          name={prefix + name}
          id={prefix + name}
          className="tw-w-full tw-rounded tw-px-4 tw-py-2"
          onChange={(e) => {
            const {
              target: {name: targetName, value},
            } = e
            const stateCommunityProperty = getStateCommunityProperty(value)
            const isPrimaryResidence = targetName.includes('residences[')
              ? Number(targetName.split('residences[')[1].split(']')[0]) === 0
              : false

            setFieldValue(targetName, value)
            setFieldTouched(targetName, true)

            if (!isCoBuyer) {
              if (
                values.coBuyerPresent &&
                values.coBuyers[0].residenceSameAsBuyer &&
                isPrimaryResidence
              ) {
                setFieldValue('coBuyers[0].isCommunityPropertyResidence', stateCommunityProperty)
              }

              setCustomerStateId(value)
            } else {
              setCoBuyerStateId(value)
            }

            if (!isPrimaryResidence) {
              return
            }

            setFieldValue(
              `${isCoBuyer ? 'coBuyers[0].' : ''}isCommunityPropertyResidence`,
              stateCommunityProperty
            )
          }}
        >
          {showPlaceholder ? <option value="">{placeholder}</option> : null}
          {selectOptions.map(({key, text}) => (
            <option key={key} value={key}>
              {text}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name={prefix + name}
          render={(error) => <span className="error">{error}</span>}
        />
      </div>
    </>
  )
}

SelectInput.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showPlaceholder: PropTypes.bool,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  required: PropTypes.bool,
}

export default PrimaryStateSelect

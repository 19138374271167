import React, {ReactNode} from 'react'
import classNames from 'classnames'

const Main = ({children, className}: {children: ReactNode; className?: string}) => {
  return (
    <main
      id="main-scroll-panel"
      className={classNames(
        'scroll scrollBox tw-flex tw-min-h-full tw-flex-1 tw-flex-col tw-justify-between tw-p-6',
        className
      )}
    >
      {children}
    </main>
  )
}

export default Main

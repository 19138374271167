import React from 'react'
import CtaButton from 'Src/components/Buttons/CtaButton'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {useDealStateContext} from 'Src/contexts/DealContext'
import {trans} from 'Src/utilities/Helpers'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'

interface ICheckAvailabilityButton {
  onClick?(): void
}

const CheckAvailabilityButton = ({onClick = () => undefined}: ICheckAvailabilityButton = {}) => {
  const {trackEvent, events} = useAnalytics()
  const {isUpdating} = useDealStateContext()

  const handleClick = () => {
    trackEvent(
      events.NAVIGATION,
      events.NAVIGATION.actions.NAVIGATE,
      ACTION_DETAILS.PAGES.CHECK_AVAILABILITY
    )
    onClick()
  }

  return (
    <CtaButton
      preset="secondary"
      onClick={handleClick}
      disabled={isUpdating}
      data-testid="check-availability-button"
    >
      {trans('Check Availability')}
    </CtaButton>
  )
}

export default CheckAvailabilityButton

import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronUp, faChevronDown} from '@fortawesome/free-solid-svg-icons'
import {useForm} from 'react-hook-form'
import {useDebounce} from '../utilities/CustomHooks'
import {removeNonNumeric} from '../utilities/DataReformatters'
import {useDealDispatchContext, useDealStateContext} from '../contexts/DealContext'
import {validateProgram, getMinMaxDownPayment} from '../utilities/ProgramHelpers'
import {setDownPaymentButtons} from '../actions/DealActions'
import {useAnalytics} from '../services/analytics/useAnalytics'
import {trans} from '../utilities/Helpers'
import {ACTION_DETAILS} from '../services/analytics/constants'
import CtaButton from './Buttons/CtaButton'

const INPUT_NAMES = {
  VALUE: 'value',
}

const Incrementer = ({defaultValue, increments, type, tooltip = null}) => {
  const dealState = useDealStateContext()
  const {trackEvent, events} = useAnalytics()
  const {
    dealType,
    isUpdating,
    disableDecreaseDownPayment,
    disableIncreaseDownPayment,
    displayDownPaymentMessage,
  } = dealState
  const isFinance = dealType === 'finance'

  const {
    minDownPayment,
    maxDownPayment,
    exceedingMinLimitMessage,
    exceedingMaxLimitMessage,
  } = getMinMaxDownPayment(dealState, type)

  const dispatch = useDealDispatchContext()
  const messageRef = useRef(null)
  const increaseButtonRef = useRef(null)
  const decreaseButtonRef = useRef(null)
  const [message, setMessage] = useState('')
  const [downPaymentChangeCount, setDownPaymentChangeCount] = useState(0)
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      value: defaultValue,
    },
  })

  const trackDownPaymentChange = () => {
    const changeCount = downPaymentChangeCount + 1
    setDownPaymentChangeCount(changeCount)
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CHANGE,
      ACTION_DETAILS.DOWN_PAYMENT,
      null,
      {
        downPaymentChangeCount: changeCount,
      }
    )
  }

  const {register, handleSubmit, watch, setValue, getValues} = form
  const payment = watch(INPUT_NAMES.VALUE)

  const onSubmit = () => {
    const key = isFinance ? 'retailDownPayment' : 'leaseDownPayment'
    const updatedDownPayment = parseInt(removeNonNumeric(getValues(INPUT_NAMES.VALUE)), 10)
    dispatch({
      type: 'update',
      payload: {
        [key]: updatedDownPayment,
      },
    })
  }

  useDebounce(handleSubmit(onSubmit), 0, [payment])

  const handleLimitReached = () => {
    dispatch({
      type: 'update',
      payload: {
        displayDownPaymentMessage: true,
      },
    })
  }

  const hideMessage = () => {
    dispatch({
      type: 'update',
      payload: {
        displayDownPaymentMessage: false,
      },
    })
  }

  const increaseDownPayment = () => {
    trackDownPaymentChange()
    const currentValue = parseInt(removeNonNumeric(getValues(INPUT_NAMES.VALUE)), 10)
    const newValue = currentValue > 0 ? currentValue + increments : increments

    hideMessage()

    if (newValue > maxDownPayment) {
      setMessage(exceedingMaxLimitMessage)
      setDownPaymentButtons(true, dispatch, 'up')
      handleLimitReached()
      return
    }

    if (!validateProgram(dealState, {downPayment: newValue})) {
      dispatch({type: 'update', payload: {displayNoProgramFoundMessage: true}})
      setDownPaymentButtons(true, dispatch, 'up')
      return
    }

    setDownPaymentButtons(false, dispatch, 'down')

    if (newValue <= maxDownPayment) {
      setValue(INPUT_NAMES.VALUE, newValue, {
        shouldDirty: true,
      })
    }
  }

  const decreaseDownPayment = () => {
    trackDownPaymentChange()
    const currentValue = parseInt(removeNonNumeric(getValues(INPUT_NAMES.VALUE)), 10)
    const newValue = currentValue - increments

    hideMessage()

    setDownPaymentButtons(false, dispatch, 'up')

    if (newValue >= minDownPayment) {
      setValue(INPUT_NAMES.VALUE, newValue, {
        shouldDirty: true,
      })
    }

    if (newValue < minDownPayment) {
      setMessage(exceedingMinLimitMessage)
      setDownPaymentButtons(true, dispatch, 'down')
      handleLimitReached()
    }
  }

  const buttonClassName = '!tw-h-auto !tw-px-0 tw-w-11'

  return (
    <form id="down-payment-toggle" aria-labelledby="down-payment">
      <input type="hidden" name="value" {...register(INPUT_NAMES.VALUE)} data-testid="amount" />
      <div role="status" aria-live="polite" className="tw-flex tw-justify-center tw-content-center">
        <div className="tw-flex tw-items-center tw-w-28 tw-text-xl tw-font-medium tw-select-none">
          $ {payment.toLocaleString()}
        </div>
        <div>
          <CtaButton
            preset="secondary"
            id="decrease"
            aria-label={trans('Decrease')}
            ref={decreaseButtonRef}
            className={classNames(buttonClassName, '!tw-rounded-l-xl !tw-rounded-r-none')}
            onClick={decreaseDownPayment}
            disabled={isUpdating || parseInt(payment, 10) === 0 || disableDecreaseDownPayment}
            data-testid="decrease"
          >
            <FontAwesomeIcon className="tw-pointer-events-none" icon={faChevronDown} />
          </CtaButton>
          <CtaButton
            preset="secondary"
            id="increase"
            aria-label={trans('Increase')}
            ref={increaseButtonRef}
            className={classNames(
              buttonClassName,
              '!tw-border-l-0 !tw-rounded-r-xl !tw-rounded-l-none'
            )}
            onClick={increaseDownPayment}
            disabled={isUpdating || disableIncreaseDownPayment}
            data-testid="increase"
          >
            <FontAwesomeIcon className="tw-pointer-events-none" icon={faChevronUp} />
          </CtaButton>
        </div>
        {tooltip}
      </div>
      {displayDownPaymentMessage ? (
        <p
          className="tw-text-brand-body_text--danger tw-mt-3 tw-text-center"
          ref={messageRef}
          data-testid="error"
        >
          {message}
        </p>
      ) : null}
    </form>
  )
}

Incrementer.propTypes = {
  defaultValue: PropTypes.number,
  increments: PropTypes.number,
  tooltip: PropTypes.element,
  type: PropTypes.string,
}

export default Incrementer

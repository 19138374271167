import React from 'react'
import PopoverHover from 'Src/components/Popovers/PopoverHover'
import IconQuestionCircle from 'Src/components/IconQuestionCircle'

const AcquisitionFeesTooltip = () => {
  const popoverContent = (
    <p>A charge from a lessor or lender to cover the expenses incurred for arranging a lease.</p>
  )

  return (
    <PopoverHover content={popoverContent} data-testid="tooltip">
      <IconQuestionCircle />
    </PopoverHover>
  )
}

export default AcquisitionFeesTooltip

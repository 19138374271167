import React from 'react'
import PropTypes from 'prop-types'
import {numberToUSD} from '../../../utilities/DataReformatters'
import RebatesTooltip from './RebatesTooltip'
import {trans} from '../../../utilities/Helpers'

const RebatesHeader = ({rebateIds, rebatesTotal}) => (
  <>
    <div className="tw-grid tw-grid-cols-12">
      <div className="tw-col-span-8 tw-leading-relaxed">
        <h2 id="rebates" className="h5 tw-inline" data-testid="rebates-count">
          {trans('Rebates')} <span>({rebateIds.length})</span>
        </h2>
        <RebatesTooltip />
      </div>
      <div
        className="tw-col-span-4 tw-justify-self-end tw-font-bold tw-mr-4"
        data-testid="rebates-total"
      >
        <p>
          {rebatesTotal ? <>&ndash;</> : null}
          {numberToUSD(rebatesTotal)}
        </p>
      </div>
    </div>
    <p className="tw-text-xs tw-mb-5">{trans('rebates_sr_label')}</p>
  </>
)

RebatesHeader.propTypes = {
  rebateIds: PropTypes.arrayOf(PropTypes.number),
  rebatesTotal: PropTypes.number,
}

export default RebatesHeader

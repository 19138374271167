import React from 'react'
import {Route, Switch, useRouteMatch} from 'react-router'
import AboutYourCar from './AboutYourCar'
import Report from './Report'
import VehicleCondition from './VehicleCondition'

const KbbTradeFlow = () => {
  const {path} = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <AboutYourCar />
      </Route>
      <Route exact path={`${path}/vehicle-condition`}>
        <VehicleCondition />
      </Route>
      <Route exact path={`${path}/owed-payments`}>
        <Report />
      </Route>
    </Switch>
  )
}

export default KbbTradeFlow

import {useQuery} from 'react-query'
import objectHash from 'object-hash'
import {getCurrentProgram} from 'Src/utilities/ProgramHelpers'
import {getSortedProductIds} from 'Src/utilities/ProductHelpers'
import {useProductContext} from '../contexts/ProductContext'
import {useDealStateContext} from '../contexts/DealContext'
import {createDeskingDataPayload} from '../actions/DealActions'
import {useFrameContext} from '../contexts/FrameContext'
import {useCustomerStateContext} from '../contexts/CustomerContext'
import {updateDeskingData} from '../api/desking'

export const queryKey = ['desking', 'products']

export const useDeskingWithSelectedProducts = () => {
  const customerState = useCustomerStateContext()
  const dealState = useDealStateContext()
  const {
    a2zApi,
    dealership: {id: dealershipId},
    useCache,
    vin,
  } = useFrameContext()
  const {
    cashRatedProducts,
    financeRatedProducts,
    leaseRatedProducts,
    getRatedProductsByDealType,
    getSelectedRatedProducts,
  } = useProductContext()

  const {dealType} = dealState
  const {annualMileage, rate, term} = getCurrentProgram(dealState)

  const queryDependencies = [
    getSortedProductIds(getSelectedRatedProducts(dealType)),
    cashRatedProducts,
    financeRatedProducts,
    leaseRatedProducts,
    annualMileage,
    rate,
    term,
  ]

  return useQuery(
    ['desking', 'products', ...queryDependencies],
    () => {
      const payload = {
        products: {
          cash: getSelectedRatedProducts('cash') ?? [],
          finance: getSelectedRatedProducts('finance') ?? [],
          lease: getSelectedRatedProducts('lease') ?? [],
        },
      }

      const dealDataPayload = createDeskingDataPayload(
        {
          vin,
          dealershipId,
          dealType,
          requestParams: payload,
          useCache,
        },
        dealState,
        customerState
      )

      return updateDeskingData(a2zApi, dealershipId, dealDataPayload, vin)
    },
    {
      enabled: getRatedProductsByDealType(dealType).length > 0,
      staleTime: 60 * 60 * 1000,
      queryKeyHashFn: objectHash,
    }
  )
}

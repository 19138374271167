import React from 'react'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import EmailQuote from '../../EmailQuote'
import {displayPhoneNumber} from '../../../utilities/Helpers'
import {useFrameContext} from '../../../contexts/FrameContext'

const Contact = (props) => {
  const {standAlone} = props
  const {
    dealership: {
      attributes: {phone: dealershipPhone, email: dealershipEmail},
    },
  } = useFrameContext()

  return (
    <div
      id="journey-contact"
      className={classNames('tw-text-center', {'stand-alone': standAlone})}
      data-testid="contact-container"
    >
      <EmailQuote
        linkClassName="tw-inline-block tw-mb-2"
        isNotButton
        hasIcon
        data-testid="email-quote"
      />
      <p className="tw-mb-4 tw-text-sm">Have questions or not sure what to do?</p>
      <div className="tw-flex-row tw-justify-center tw-mb-8">
        {dealershipPhone ? (
          <p className="tw-mb-2 tw-text-xs">
            <a href={`tel:${dealershipPhone}`} className="link" data-testid="phone">
              <FontAwesomeIcon icon={faPhone} className="tw-mr-2" />
              {displayPhoneNumber(dealershipPhone)}
            </a>
          </p>
        ) : null}
        {dealershipEmail ? (
          <p className="tw-text-xs">
            <a href={`mailto:${dealershipEmail}`} className="link" data-testid="email">
              <FontAwesomeIcon icon={faEnvelope} className="tw-mr-2" />
              {dealershipEmail}
            </a>
          </p>
        ) : null}
      </div>
    </div>
  )
}

export default Contact

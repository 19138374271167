import {useEffect} from 'react'
import {useLocation} from 'react-router'
import {useFrameContext} from '../contexts/FrameContext'
import {TOKEN_STATE} from '../utilities/useToken'

const CheckAuth = () => {
  const {pathname} = useLocation()
  const {a2zCustomerApi, tokenState} = useFrameContext()

  useEffect(() => {
    // This is for refreshing the JWT auth (refresh) token (updates the ttl) while the user uses the app.
    if (tokenState === TOKEN_STATE.AUTHENTICATED) {
      a2zCustomerApi.post(`/refresh-token`).catch((error) => {
        console.error(error)
      })
    }
  }, [pathname])

  return null
}

export default CheckAuth

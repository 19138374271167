import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {
  useCustomerDispatchContext,
  useCustomerStateContext,
} from '../../../contexts/CustomerContext'
import {useRegistrationContext} from '../../../contexts/RegistrationContext'
import {useAnalytics} from '../../../services/analytics/useAnalytics'
import {trans} from '../../../utilities/Helpers'
import InputRadioGroup from '../../InputRadioGroup'
import RegistrationLayout from '../../Layouts/RegistrationLayout'
import {ACTION_DETAILS} from '../../../services/analytics/constants'
import CtaButton from '../../Buttons/CtaButton'

const MatchingLead = () => {
  const history = useHistory()
  const customerDispatch = useCustomerDispatchContext()
  const [selectedUserId, setSelectedUserId] = useState(0)
  const [currentCustomerMatch, setCurrentCustomerMatch] = useState(null)
  const {registrationState, registrationDispatch} = useRegistrationContext()
  const {foundLeads: userList, newLead} = registrationState
  const {customerData: customer} = useCustomerStateContext()
  const {trackEvent, events} = useAnalytics()
  const selectedUserName = userList?.find(({id}) => id === selectedUserId)?.name
  const singleLead = userList.length === 1

  useEffect(() => {
    if (customer.firstName) {
      setCurrentCustomerMatch(customer)
    }
  }, [])

  const buildClickOrNavEvent = (type, actionDetail) => {
    switch (type) {
      case 'nav':
        trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, actionDetail)
        break
      case 'click':
        trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, actionDetail)
        break
    }
  }

  function onSubmit() {
    const foundCustomer = userList?.find(({id}) => id === selectedUserId.toString())
    setCurrentCustomerMatch(foundCustomer)
    registrationDispatch({type: 'setFoundLeads', payload: []})

    if (foundCustomer) {
      customerDispatch({
        type: 'setCustomerData',
        payload: {
          ...foundCustomer,
          firstName: foundCustomer.first_name, // response returns snake case
          maskedEmail: foundCustomer.email,
          maskedPhone: foundCustomer.phone,
        },
      })
      customerDispatch({type: 'setLeadId', payload: foundCustomer.leadId})
      buildClickOrNavEvent('click', ACTION_DETAILS.REGISTER.RETURN_USER.CONFIRM)
      buildClickOrNavEvent('nav', ACTION_DETAILS.REGISTER.SEND.CODE)
      history.push('/register/send-code')
    } else if (!newLead) {
      registrationDispatch({type: 'setNewLead', payload: true})
      registrationDispatch({type: 'setSkipMatchingLead', payload: true})
      buildClickOrNavEvent('click', ACTION_DETAILS.REGISTER.REDIRECT)
      buildClickOrNavEvent('nav', ACTION_DETAILS.PAGES.REGISTER)
      history.push('/register')
    } else {
      buildClickOrNavEvent('click', ACTION_DETAILS.REGISTER.FIRST_TIME_CONTINUE)
      buildClickOrNavEvent('nav', ACTION_DETAILS.REGISTER.NEW_LEAD.CONFIRMATION)
      history.push('/register/new-lead-confirmation')
    }

    setSelectedUserId(0)
  }

  return (
    <RegistrationLayout>
      <h4 data-testid="greetingMessage" className="h4 tw-text-center tw-mb-10">
        Hi
        {(currentCustomerMatch?.firstName || selectedUserName) &&
          `, ${currentCustomerMatch?.firstName || selectedUserName}`}
        !
      </h4>
      <p data-testid="firstRecordMessage" className="tw-text-center">
        We found {singleLead ? 'a record' : 'several records'} in our database matching your last
        name and email.
      </p>
      <p data-testid="secondRecordMessage" className="tw-font-bold tw-text-center tw-mb-8">
        Could {singleLead ? 'this record' : 'any of these records'} be you?
      </p>
      <div className="tw-grid tw-place-items-center tw-mb-10">
        <InputRadioGroup
          data-testid="inputRadioGroup"
          inputs={userList
            .map((knownUser) => {
              if (
                !selectedUserId &&
                knownUser.first_name?.toLowerCase() === customer.firstName?.toLowerCase()
              ) {
                setSelectedUserId(knownUser.id)
              }

              return {
                id: knownUser.id,
                label: knownUser.first_name,
              }
            })
            .concat({
              id: 'none',
              label: 'None of the above',
            })}
          name="user"
          onChange={setSelectedUserId}
          selected={selectedUserId}
        />
      </div>
      <div className="tw-text-center tw-mb-10">
        <CtaButton
          type="submit"
          className="tw-w-80"
          onClick={onSubmit}
          disabled={!selectedUserId}
          data-testid="submitButton"
        >
          {trans('Continue')}
        </CtaButton>
      </div>
    </RegistrationLayout>
  )
}

export default MatchingLead

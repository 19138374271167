import React from 'react'
import Breadcrumbs from '../../Breadcrumbs'
import Contact from './Contact'

const MyJourney = () => {
  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-text-center">
      <div className="tw-bg-brand-breadcrumbs__journey tw-rounded tw-pb-16 tw-mb-4">
        <h3 className="tw-font-semibold tw-text-2xl tw-my-3">Your Journey</h3>
        <p className="tw-font-medium-tw-text-lg tw-mb-8">Skip to another step if you’d like.</p>
        <Breadcrumbs data-testid="breadcrumbs" />
      </div>
      <Contact data-testid="contact" />
    </div>
  )
}

export default MyJourney

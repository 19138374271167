import {routes} from './routes'
import {deserialize} from 'jsonapi-fractal'

export const fetchConfigs = async (client) => {
  const configsStored = localStorage.getItem(routes.configs.base)

  if (configsStored) {
    return JSON.parse(configsStored)
  }

  const response = await client.get(routes.configs.base).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e)
  })

  if (!response?.data) {
    return {data: []}
  }

  const deserializedResponse = deserialize(response.data)

  localStorage.setItem(routes.configs.base, JSON.stringify(deserializedResponse))

  return deserializedResponse
}

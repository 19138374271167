import React from 'react'
import PropTypes from 'prop-types'

const Label = ({htmlFor, disabled, required, children, styles}) => (
  <label
    htmlFor={htmlFor}
    className={`${styles} tw-font-semibold ${disabled ? 'tw-text-brand-body_text--muted' : ''} ${
      required ? 'required' : ''
    }`}
  >
    {children}
  </label>
)

Label.propTypes = {
  children: PropTypes.string.isRequired,
  htmlFor: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  styles: PropTypes.string,
}

export default Label

import React, {useState} from 'react'
import {useHistory} from 'react-router'
import QuestionsCallDealershipPhone from 'Src/components/QuestionsCallDealershipPhone'
import {
  ECodeReturnTo,
  useCustomerDispatchContext,
  useCustomerStateContext,
} from '../../../contexts/CustomerContext'
import {useFrameContext} from '../../../contexts/FrameContext'
import {useRegistrationContext} from '../../../contexts/RegistrationContext'
import {trans} from '../../../utilities/Helpers'
import InputRadioGroup from '../../InputRadioGroup'
import RegistrationLayout from '../../Layouts/RegistrationLayout'
import {sendVerification} from '../../../api/VerificationActions'
import {useAnalytics} from '../../../services/analytics/useAnalytics'
import {ACTION_DETAILS} from '../../../services/analytics/constants'
import {routes} from '../../../api/routes'
import CtaButton from '../../Buttons/CtaButton'

const SendCode = () => {
  const {customerData: customer, codeContactMethod: contactMethod} = useCustomerStateContext()
  const {a2zApi} = useFrameContext()
  const customerDispatch = useCustomerDispatchContext()
  const {registrationDispatch} = useRegistrationContext()
  const [serverError, setServerError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const history = useHistory()
  const {trackEvent, events} = useAnalytics()

  const contactMethodInputs = [
    {
      id: 'email',
      label: customer.maskedEmail,
    },
  ]

  if (customer.maskedPhone) {
    contactMethodInputs.push({
      id: 'sms',
      label: `(***) ***-${customer.maskedPhone.match(/\d{4}$/)[0]}`,
    })
  }

  const onSubmit = async (event) => {
    setIsSubmitting(true)
    setServerError(false)

    event.preventDefault()
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.REGISTER.SEND.CODE
    )
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.SELECTION, contactMethod)

    const customerVerification = {
      id: customer.id,
      email: customer.email,
      phone: customer.phone,
    }

    try {
      await sendVerification(a2zApi, customerVerification, {method: contactMethod})
      customerDispatch({type: 'setCodeReturnTo', payload: ECodeReturnTo.SEND_CODE})
      trackEvent(
        events.NAVIGATION,
        events.NAVIGATION.actions.NAVIGATE,
        ACTION_DETAILS.REGISTER.VERIFY
      )
      history.push(routes.register.paths.verifyCode())
    } catch (err) {
      setIsSubmitting(false)
      setServerError(true)
    }
  }

  const onNotCustomerClick = (event) => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.REGISTER.SEND.NOT_THE_CUSTOMER
    )
    event.preventDefault()

    registrationDispatch({type: 'reset'})
    customerDispatch({type: 'clearCustomer'})
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.PAGES.REGISTER)
    history.push('/register')
  }

  const setContactMethod = (value) => {
    customerDispatch({type: 'setCodeContactMethod', payload: value})
  }

  const {
    dealership: {
      attributes: {name: dealershipName},
    },
  } = useFrameContext()

  return (
    <RegistrationLayout>
      <h4 className="h4 tw-mb-10 tw-text-center">Hi, {customer.firstName}!</h4>
      <p className="tw-text-center">
        Where would you like us to send your one-time verification code?
      </p>
      {serverError ? (
        <p className="error tw-text-center">Sorry, there was a problem sending verification code</p>
      ) : null}
      <div className="tw-mt-4 tw-flex tw-flex-col tw-items-center">
        <InputRadioGroup
          inputs={contactMethodInputs}
          name="contact"
          onChange={setContactMethod}
          selected={contactMethod}
        />
      </div>

      <div className="tw-my-10 tw-text-center">
        <CtaButton type="submit" className="tw-w-80" onClick={onSubmit} disabled={isSubmitting}>
          {trans('Send Code')}
        </CtaButton>
      </div>
      <p className="tw-text-center tw-text-xs">
        By selecting Send Code, you agree to {dealershipName} sending you a one-time verification
        code using the delivery method above.
      </p>
      <p className="tw-mt-4 tw-text-center tw-text-xs">
        {contactMethod === 'email' ? (
          <>
            The email will be sent by our partner <span className="tw-font-semibold">A2Z Sync</span>
            .
          </>
        ) : (
          <>Your mobile carrier’s message and data rates may apply.</>
        )}
      </p>
      <div className="divider tw-my-12" />
      <div className="tw-my-10 tw-text-center">
        <CtaButton
          type="submit"
          preset="secondary"
          className="tw-w-80"
          onClick={onNotCustomerClick}
        >
          Not {customer.firstName}
        </CtaButton>
      </div>
      <QuestionsCallDealershipPhone
        className="tw-mt-2 tw-text-center tw-font-semibold tw-text-brand-body_text--muted"
        questionsClassName={null}
      />
    </RegistrationLayout>
  )
}

export default SendCode

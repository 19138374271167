import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'

const InputCheckBox = forwardRef(
  ({error = '', onChange, checked, className, disabled = false, ...props}, ref) => (
    <>
      <input
        type="checkbox"
        ref={ref}
        className={className}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      {error && <span className="error">{error}</span>}
    </>
  )
)

export default InputCheckBox

InputCheckBox.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

import React from 'react'
import {trans} from 'Src/utilities/Helpers'

const OrButtonDivider = () => (
  <div className="tw-mx-auto tw-flex tw-w-3/4 tw-items-center tw-justify-center md:tw-hidden">
    <div className="divider" />
    <p className="tw-mx-3 tw-font-extrabold">{trans('OR')}</p>
    <div className="divider" />
  </div>
)

export default OrButtonDivider

import {deserialize, serialize} from 'jsonapi-fractal'
import {stringifyFilters} from './helpers'
import {routes} from './routes'

export const createFilteredProductsUrl = (dealershipId, byDealTypeName, byVehicleTypeName) =>
  `${routes.products.base}${stringifyFilters({
    availableOnline: true,
    byDealTypeName,
    byVehicleTypeName,
    dealershipId,
  })}`

export const fetchProducts = async (client, dealershipId, dealTypeName, vehicleTypeName) => {
  if (!dealershipId) {
    return {data: []}
  }

  const productsUrl = createFilteredProductsUrl(dealershipId, dealTypeName, vehicleTypeName)
  const productsResponseStored = localStorage.getItem(productsUrl)

  if (!productsResponseStored) {
    const response = await client.get(productsUrl).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })

    if (!response?.data || response?.error) {
      return {data: []}
    }

    const deserializedResponse = deserialize(response.data)
    localStorage.setItem(productsUrl, JSON.stringify(deserializedResponse))

    return deserializedResponse
  }

  return JSON.parse(productsResponseStored)
}

export const requestBatchProductRates = async (client, params) => {
  if (!params?.products?.length) {
    return null
  }

  const response = await client
    .post(routes.products.paths.rates(), serialize(params, 'rates', {relationships: ['customer']}))
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })

  if (!response?.data) {
    return null
  }

  return {
    ...deserialize(response.data),
    hash: params.hash,
  }
}

export const fetchCompletedProductRates = async (client, batchId) => {
  if (!batchId) {
    return {data: []}
  }

  const response = await client.get(routes.products.paths.ratesBatch(batchId)).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e)
  })

  if (!response?.data) {
    return {data: []}
  }

  return deserialize(response.data)
}

import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import Helmet from 'react-helmet'
import PlaceholderSpinner from 'Src/components/Spinners/PlaceholderSpinner'
import CheckAvailabilityButton from 'Src/components/Buttons/CheckAvailabilityButton'
import BackButton from 'Src/components/Buttons/BackButton'
import NextButton from 'Src/components/Buttons/NextButton'
import CtaButton from 'Src/components/Buttons/CtaButton'
import {saveQuote} from 'Src/actions/DealActions'
import {clearTradeValues, handleTradeIntegrationErrorResponse} from 'Src/actions/TradeActions'
import {
  PROGRESS_STATE,
  PROGRESS_STEP,
  useProgressDispatchContext,
  useProgressStateContext,
} from 'Src/contexts/ProgressContext'
import {useCustomerDispatchContext, useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useProductContext} from 'Src/contexts/ProductContext'
import {useTradeContext} from 'Src/contexts/TradeContext'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {
  backButtonBreadcrumbInteraction,
  breadcrumbProgressTracking,
  trans,
} from 'Src/utilities/Helpers'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import {useFetchTradeIntegration} from 'Src/queries/useFetchDealerships'
import TradeLogo from 'Src/components/TradePartners/common/TradeLogo'
import PageLayoutCenter from 'Src/components/Layouts/PageLayoutCenter'
import {IIntegrationData} from 'Src/queries/useFetchDealerships'
import OrButtonDivider from 'Src/components/OrButtonDivider'
import PreviousDealModal from '../Deal/PreviousDealModal'

const Trade = () => {
  const [hasInitialized, setHasInitialized] = useState(false)
  const {trackEvent, events} = useAnalytics()
  const customerState = useCustomerStateContext()
  const customerDispatch = useCustomerDispatchContext()
  const frameContext = useFrameContext()
  const {isSaveDealEnabled} = frameContext
  const dealDispatch = useDealDispatchContext()
  const dealState = useDealStateContext()
  const {dealType, loading: deskingIsLoading} = dealState
  const history = useHistory()
  const ProgressDispatch = useProgressDispatchContext()
  const progress = useProgressStateContext()
  const tradeContext = useTradeContext()
  const {leadVehicles, previouslySavedQuote} = customerState
  const leadVehicle = leadVehicles?.find(({isCurrent}) => isCurrent === true)
  const {hasProducts, getSelectedRatedProducts} = useProductContext()

  useEffect(() => {
    if (!hasInitialized && leadVehicle) {
      const saveQuoteAsync = async () => {
        await saveQuote(
          dealState,
          customerState,
          frameContext,
          customerDispatch,
          dealDispatch,
          getSelectedRatedProducts
        )
      }

      saveQuoteAsync()
      setHasInitialized(true)
    }
  }, [dealState, customerState, frameContext, customerDispatch])

  const {data, error, isSuccess} = useFetchTradeIntegration()

  useEffect(() => {
    if (error) {
      handleTradeIntegrationErrorResponse(error)
    }
  }, [error])

  const handleStartTradeExperience = () => {
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.TRADE.CAR_WORTH)
    trackEvent(
      events.NAVIGATION,
      events.NAVIGATION.actions.NAVIGATE,
      ACTION_DETAILS.PAGES.TRADE_EXPERIENCE
    )

    clearTradeValues(tradeContext)

    history.push('/trade-experience')
  }

  const renderTitle = () => {
    switch ((data as IIntegrationData['data'])?.integrationName) {
      case 'kbb':
        // Translation contains HTML.
        // eslint-disable-next-line react/no-danger
        return trans('kbb.get_kbb_br_tradein_value')
      case 'trade-pending':
      default:
        return trans('trade_int.get_your_estimated_tradein_value')
    }
  }

  const handleNextToProductsClick = () => {
    history.push('/products')
    ProgressDispatch({
      type: 'update',
      payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.products, dealType),
    })
  }

  const handleNextToCreditClick = () => {
    history.push('/credit')
    ProgressDispatch({
      type: 'update',
      payload: {trade: PROGRESS_STATE.untouched},
    })
  }

  const handleBackButtonClick = () => {
    ProgressDispatch({
      type: 'update',
      payload: backButtonBreadcrumbInteraction(history, progress, dealType),
    })
  }

  const handleCheckAvailabilityButtonClick = () => {
    history.push('/check-availability')
  }

  const handleSkipTradeInClick = () => {
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.TRADE.SKIP)

    if (hasProducts) {
      trackEvent(
        events.NAVIGATION,
        events.NAVIGATION.actions.NAVIGATE,
        ACTION_DETAILS.PAGES.PRODUCTS
      )
      handleNextToProductsClick()

      return
    }

    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.PAGES.CREDIT)
    handleNextToCreditClick()
  }

  const TradeNextButton = () =>
    hasProducts ? (
      <NextButton
        actionDetails={{
          click: ACTION_DETAILS.PAGES.PRODUCTS,
          navigate: ACTION_DETAILS.PAGES.PRODUCTS,
        }}
        onClick={handleNextToProductsClick}
        text={trans('Protection Plans')}
      />
    ) : (
      <NextButton
        actionDetails={{
          click: ACTION_DETAILS.CREDIT.PRE_APPROVED,
          navigate: ACTION_DETAILS.PAGES.CREDIT,
        }}
        onClick={handleNextToCreditClick}
        text={trans('Get Pre-Approved')}
        testId="get-pre-approved"
      />
    )

  return deskingIsLoading ? (
    <PlaceholderSpinner />
  ) : (
    <>
      <Helmet>
        <title>{trans('trade_int.document_title')}</title>
      </Helmet>
      <PageLayoutCenter className="tw-max-w-[550px]">
        <PageLayoutCenter.Body>
          {isSuccess ? (
            <>
              <div className="tw-mb-6 empty:tw-hidden">
                <TradeLogo integration={(data as IIntegrationData['data'])?.integrationName} />
              </div>
              <h1 className="h3 tw-mb-3">{renderTitle()}</h1>
              <p className="tw-mb-10">{trans('trade_int.not_an_offer_contingent_on_inspection')}</p>
              <div className="tw-mb-6">
                <CtaButton
                  preset="secondary"
                  className="tw-w-full md:tw-max-w-md"
                  onClick={handleStartTradeExperience}
                >
                  {trans('trade_int.whats_my_car_worth')}
                </CtaButton>
              </div>
              <div className="lg:tw-mb-6">
                <CtaButton
                  preset="link"
                  className="!tw-text-sm !tw-font-bold"
                  onClick={handleSkipTradeInClick}
                >
                  {trans('Skip Trade-In')}
                </CtaButton>
              </div>
            </>
          ) : (
            <PlaceholderSpinner />
          )}
        </PageLayoutCenter.Body>
        <PageLayoutCenter.Footer>
          <div className="divider tw-my-12 lg:tw-invisible lg:tw-my-8" />
          {isSaveDealEnabled ? (
            <div className="tw-flex tw-flex-col tw-justify-center tw-gap-x-6 sm:tw-mb-4 sm:tw-flex-wrap sm:tw-justify-between md:tw-flex-row-reverse lg:tw-mb-0">
              <TradeNextButton />
              <div className="tw-mt-4 tw-py-2 sm:tw-mt-0 sm:tw-flex">
                <BackButton additionOnClick={handleBackButtonClick} />
              </div>
            </div>
          ) : (
            <>
              <div className="tw-flex tw-flex-col tw-justify-center tw-gap-x-6 tw-gap-y-3 sm:tw-flex-wrap sm:tw-justify-between md:tw-flex-row-reverse md:tw-justify-evenly">
                <TradeNextButton />
                <OrButtonDivider />
                <CheckAvailabilityButton onClick={handleCheckAvailabilityButtonClick} />
              </div>
              <div className="tw-mt-4 tw-py-2">
                <BackButton additionOnClick={handleBackButtonClick} />
              </div>
            </>
          )}
        </PageLayoutCenter.Footer>
      </PageLayoutCenter>
      {isSaveDealEnabled && previouslySavedQuote ? <PreviousDealModal /> : null}
    </>
  )
}

export default Trade

import {formatDate} from 'Src/utilities/formatters'
import {FetchDocumentsResponse} from 'Src/api/documents'
import {JsonApiCompoundResponse} from 'Src/api/types'
import {AxiosResponse} from 'axios'

export type UploadedDocument = {
  id: string
  filename: string
  type: string
  updatedAt: string
  url: string
  fileType: string
}

export const getTransformedDocuments = (
  res: AxiosResponse<JsonApiCompoundResponse<FetchDocumentsResponse>>
): UploadedDocument[] =>
  res.data.data
    .map((doc) => ({
      id: doc.id,
      filename: doc.attributes.file_name ?? '',
      type: doc.attributes.type,
      updatedAt: formatDate(doc.attributes.updatedAt, 'MMM d, yyyy HH:mm a'),
      url: doc.attributes.url ?? '',
    }))
    .filter((doc) => doc !== null) as UploadedDocument[]

import React from 'react'

const creditLevels = {
  poor: {
    min: 0,
    max: 579,
  },
  fair: {
    min: 580,
    max: 669,
  },
  good: {
    min: 670,
    max: 739,
  },
  veryGood: {
    min: 740,
    max: 799,
  },
  exceptional: {
    min: 800,
    max: 850,
  },
}

const INPUT_NAMES = {
  CREDIT_SCORE_RANGE: 'creditScoreRange',
}

const CreditSlider = ({min, max, value, onChange, onPointerUp, reference}) => {
  const creditLevel = Object.keys(creditLevels).find((key) => {
    const {min: minimum, max: maximum} = creditLevels[key]
    return value >= minimum && value <= maximum
  })

  const classes = `credit-range hover:tw-cursor-pointer ${creditLevel}`

  return (
    <div className="credit-range-wrapper">
      <span className="outer-label-left" data-testid="min">
        {min}
      </span>
      <span className="inner-label-left tw-pointer-events-none">Poor</span>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        className={classes}
        {...reference(INPUT_NAMES.CREDIT_SCORE_RANGE)}
        onMouseUp={(e) => onPointerUp(e.target.value)}
        onTouchEnd={(e) => onPointerUp(e.target.value)}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        data-testid="input"
      />
      <span className="inner-label-right tw-pointer-events-none">Excellent</span>
      <span className="outer-label-right" data-testid="max">
        {max}
      </span>
    </div>
  )
}

export default CreditSlider

import React, {MouseEvent} from 'react'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {useHistory} from 'react-router'
import {THistory} from 'Src/utilities/useAxios'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import {trans} from 'Src/utilities/Helpers'

interface IBackButton {
  className?: string
  additionOnClick?(): void
}

const BackButton = ({className = '', additionOnClick = () => undefined}: IBackButton = {}) => {
  const history: THistory = useHistory()
  const {trackEvent, events} = useAnalytics()

  const doesIndexPathnameInclude = (index: number, pathToFind: string) =>
    history.entries?.[index].pathname.includes(pathToFind)

  const defaultClick = () => {
    const paths = {
      error: 'error',
      register: 'register',
    }

    if (doesIndexPathnameInclude(history.length - 2, paths.register)) {
      /** Loop backwards through history looking for register */
      for (let i = history.length - 1; i > 0; i--) {
        if (doesIndexPathnameInclude(i, paths.register)) {
          const previousIndex = i - 1

          if (doesIndexPathnameInclude(previousIndex, paths.error)) {
            history.push('/')
            break
          }

          const pointerPriorToRegister = previousIndex - history.length

          history.go(pointerPriorToRegister)
          break
        }
      }
    }

    history.goBack()
  }

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.BACK)
    defaultClick()
    additionOnClick()
  }

  return (
    <button
      type="button"
      className={classNames('link !tw-text-sm !tw-font-bold !tw-leading-normal', className)}
      onClick={handleButtonClick}
      data-testid="back-button"
    >
      <FontAwesomeIcon icon={faChevronLeft} className="tw-mr-1.5" />
      <span>{trans('Back')}</span>
    </button>
  )
}

export default BackButton

import React from 'react'
import TermsHeader from './Terms/TermsHeader'
import LeaseTerms from './Terms/LeaseTerms'
import DownPayment from './DownPayment/DownPayment'
import LeaseMileageOptions from './LeaseMileageOptions/LeaseMileageOptions'
import LeaseMileageHeader from './LeaseMileageOptions/LeaseMileageHeader'
import ZipCode from './ZipCode/ZipCode'
import DueAtSigning from './DueAtSigning/DueAtSigning'
import CreditScore from './CreditScore/CreditScore'
import {useDealStateContext} from '../../contexts/DealContext'

const Lease = () => {
  const {
    data,
    zipCode,
    leaseTerm,
    selectedLeaseMileageOption,
    leaseDownPayment,
    leaseDownPaymentIncrements,
    leaseFixedDownPayment,
  } = useDealStateContext()

  const lease = data.attributes?.dealTypes?.lease
  const mileages = lease?.programs?.mileageGroup
  const {termsGroup} = mileages[selectedLeaseMileageOption]
  const {dueAtSigning, dueAtSigningBreakdown, downPayment} = termsGroup[leaseTerm].downPaymentGroup[
    leaseDownPayment
  ]
  return (
    <div>
      <DownPayment
        downPayment={downPayment}
        downPaymentIncrements={leaseDownPaymentIncrements}
        leaseFixedDownPayment={leaseFixedDownPayment}
      />
      <div className="divider tw-my-12" />
      {dueAtSigning ? (
        <DueAtSigning
          header="Due at Signing"
          toolTipContent={dueAtSigningBreakdown}
          dueAtSigning={dueAtSigning}
          leaseFixedDownPayment={leaseFixedDownPayment}
          downPaymentIncrements={leaseDownPaymentIncrements}
        />
      ) : null}
      {zipCode ? <ZipCode /> : ''}
      <div className="divider tw-my-12" />
      <CreditScore />
      <div className="divider tw-my-12" />
      {termsGroup ? (
        <>
          <TermsHeader />
          <LeaseTerms terms={termsGroup} />
          {mileages ? (
            <div>
              <div className="divider tw-my-12" />
              <LeaseMileageHeader /> <LeaseMileageOptions mileages={mileages} />{' '}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  )
}

export default Lease

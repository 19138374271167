import {fetchDocuments} from 'Src/api/documents'
import {getTransformedDocuments} from 'Src/transformers/documentTransformer'
import {useQuery} from 'react-query'
import {useFrameContext} from 'Src/contexts/FrameContext'

export const useFetchDocuments = ({leadId}: {leadId: string | null}) => {
  const {a2zApi} = useFrameContext()

  return useQuery(
    ['documents', leadId],
    async () => {
      if (a2zApi && leadId) {
        const res = await fetchDocuments({a2zApi, leadId})

        return {
          data: res?.data,
          transformed: getTransformedDocuments(res),
        }
      }
    },
    {
      enabled: !!leadId,
      staleTime: 60 * 60 * 1000,
    }
  )
}

import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import * as yup from 'yup'
import Input from '../../Input'
import InputSelect from '../../InputSelect'
import useForm from '../../../utilities/useForm'
import {useTradeContext} from '../../../contexts/TradeContext'
import {useFrameContext} from '../../../contexts/FrameContext'
import {trans} from '../../../utilities/Helpers'
import {useShiftEvents, SHIFT_EVENTS} from '../../../utilities/useShiftEvents'
import {buildUrl, getOptions, TRADE_ACTION_STATES} from '../../../actions/TradeActions'
import CtaButton from '../../Buttons/CtaButton'

const AboutYourCar = () => {
  const {a2zApi, setMessages} = useFrameContext()
  const {
    setTradeVehicleYearId,
    setTradeVehicleMakeId,
    setTradeVehicleModelId,
    setTradeVehicleTrimId,
    setTradeVehicleId,
    setTradeVehicleMileage,
    setTradeVehicleMetadata,
    setTradeVehicleCondition,
    setDropDowns,
    TradeVehicleYearId,
    TradeVehicleMakeId,
    TradeVehicleModelId,
    TradeVehicleTrimId,
    TradeVehicleMileage,
    TradeVehicleMetadata,
    dropDowns,
  } = useTradeContext()
  const history = useHistory()
  const {trackShiftEvents} = useShiftEvents()
  const [reportStatus, setReportStatus] = useState(TRADE_ACTION_STATES.LOADING)

  const [years, setYears] = useState(dropDowns.years)
  const [makes, setMakes] = useState(dropDowns.makes)
  const [models, setModels] = useState(dropDowns.models)
  const [styles, setStyles] = useState(dropDowns.styles)
  const [mileage, setMileage] = useState(TradeVehicleMileage)

  const [selectedYear, setSelectedYear] = useState(TradeVehicleYearId)
  const [selectedMake, setSelectedMake] = useState(TradeVehicleMakeId)
  const [selectedModel, setSelectedModel] = useState(TradeVehicleModelId)
  const [selectedStyle, setSelectedStyle] = useState(TradeVehicleTrimId)

  const onSubmit = (values) => {
    // values are the KBB IDs for each field
    setTradeVehicleYearId(values.year)
    setTradeVehicleMakeId(values.make)
    setTradeVehicleModelId(values.model)
    setTradeVehicleTrimId(values.style)
    setTradeVehicleMileage(values.mileage)
    setDropDowns({years, makes, models, styles})
    history.push('/trade-experience/vehicle-condition')
  }

  const clearValues = (parent, tradeCopy) => {
    // purposefully allow case fallthrough so we wipe out all subsequent answers
    switch (parent) {
      case 'year':
        setSelectedMake(null)
        tradeCopy.make = ''
      case 'make':
        setSelectedModel(null)
        tradeCopy.model = ''
      case 'model':
        setSelectedStyle(null)
        tradeCopy.trim = ''
        break
      default:
        break
    }

    return tradeCopy
  }

  const buildTrade = (selectedOption, value) => {
    const trade = {
      year: selectedYear,
      make: selectedMake,
      model: selectedModel,
      trim: selectedStyle,
    }

    if (selectedOption && value) {
      trade[selectedOption] = value
    }

    return trade
  }

  const handleError = (message) => {
    setMessages([
      {
        title: trans('trade_int.trade_evaluation_error'),
        description: message,
        type: 'error',
      },
    ])
  }

  const makeCall = async (selectedOption = '', value = '', tradeCopy = {}) => {
    const url = buildUrl(selectedOption, value, tradeCopy)
    const {payload, status} = await getOptions(a2zApi, url)

    if (status === TRADE_ACTION_STATES.ERROR) {
      handleError(payload)

      return null
    }

    if (!selectedOption) {
      // we are looking for years
      const year = new Date().getFullYear()

      return payload.filter(({label}) => parseInt(label, 10) <= year).reverse()
    }

    setReportStatus(status)

    return payload
  }

  const getYears = async () => {
    setReportStatus(TRADE_ACTION_STATES.LOADING)
    const payload = await makeCall()
    setYears(payload)
  }

  const getMakes = async (year) => {
    setReportStatus(TRADE_ACTION_STATES.LOADING)
    const local = buildTrade('year', year)
    const tradeCopy = clearValues('year', local)

    if (!year) {
      return
    }

    const payload = await makeCall('year', year, tradeCopy)
    setMakes(payload)
  }

  const getModels = async (year, make) => {
    setReportStatus(TRADE_ACTION_STATES.LOADING)
    const local = buildTrade('make', make)
    const tradeCopy = clearValues('make', local)

    if (!year || !make) {
      return
    }

    const payload = await makeCall('make', make, tradeCopy)
    setModels(payload)
  }

  const getStyles = async (year, model) => {
    setReportStatus(TRADE_ACTION_STATES.LOADING)
    const local = buildTrade('model', model)
    const tradeCopy = clearValues('model', local)

    if (!year || !model) {
      return
    }

    const payload = await makeCall('model', model, tradeCopy)
    setStyles(payload)
  }

  const getReportForAllSelected = async () => {
    setReportStatus(TRADE_ACTION_STATES.LOADING)
    const local = buildTrade('trim', selectedStyle)
    const tradeCopy = clearValues('trim', local)
    const url = buildUrl('trim', selectedStyle, tradeCopy)
    const {id, payload, report, status} = await getOptions(a2zApi, url)
    setReportStatus(status)

    if (status === TRADE_ACTION_STATES.ERROR) {
      handleError(payload)

      return
    }

    TradeVehicleMetadata.reports = {
      // we always fetch the 'Good' report first (before we've selected another condition)
      good: {...report},
    }

    setTradeVehicleMetadata({...payload, ...report, ids: tradeCopy})
    setTradeVehicleCondition('good') // default value
    setTradeVehicleId(id)
  }

  useEffect(() => {
    if (!years) {
      getYears()
    }
  }, [])

  // Fires off in the case of having the vehicle already known.
  useEffect(() => {
    if (!selectedYear || !selectedMake || !selectedModel || !selectedStyle) {
      return
    }

    getReportForAllSelected()
  }, [selectedYear, selectedMake, selectedModel, selectedStyle])

  const transYear = trans('Year')
  const transMake = trans('Make')
  const transModel = trans('Model')
  const transStyle = trans('style')
  const transMileage = trans('Mileage')

  const kbbCarSchema = yup.object().shape({
    year: yup
      .number()
      .min(1000)
      .max(new Date().getFullYear() + 10)
      .label(transYear)
      .required(trans('validation.filled', {attribute: transYear}))
      .nullable(),
    make: yup
      .string()
      .label(transMake)
      .required(trans('validation.filled', {attribute: transMake}))
      .nullable(),
    model: yup
      .string()
      .label(transModel)
      .required(trans('validation.filled', {attribute: transModel}))
      .nullable(),
    style: yup
      .string()
      .label(transStyle)
      .required(trans('validation.filled', {attribute: transStyle}))
      .nullable(),
    mileage: yup
      .number()
      .min(0)
      .max(999999)
      .label(transMileage)
      .required(trans('validation.filled', {attribute: transMileage}))
      .test(
        'not-within-range',
        `${trans('validation.between.numeric', {
          attribute: transMileage,
          min: 0,
          max: '999,999',
        })}`,
        (value) => value > 0 && value < 999999
      ),
  })

  const {handleSubmit, getError, errors, clearError} = useForm(
    kbbCarSchema,
    {
      year: selectedYear,
      make: selectedMake,
      model: selectedModel,
      style: selectedStyle,
      mileage: mileage || undefined,
    },
    onSubmit
  )

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="h2 tw-mx-auto tw-w-full md:tw-max-w-md">{trans('trade_int.about_car')}</h1>
      <p className="tw-mx-auto tw-mb-4 tw-w-full md:tw-max-w-md">
        {trans('trade_int.vehicle_value_will_be_based_on_standard_equipment')}
      </p>
      <p id="formInstructions" className="tw-mx-auto tw-mb-6 tw-w-full tw-text-xs md:tw-max-w-md">
        {trans('required_field_sr')}
      </p>
      <p id="errors" role="alert" aria-atomic="true" className="tw-sr-only">
        {errors?.map(({message}, index) => (
          <React.Fragment key={`error-${index.toString()}`}>
            <span>{message}</span>
            <br />
          </React.Fragment>
        ))}
      </p>
      <div
        className="tw-mx-auto tw-mb-3 tw-w-full md:tw-max-w-md"
        aria-live="assertive"
        aria-atomic="false"
      >
        <InputSelect
          label={transYear}
          name="year"
          value={years?.find(({value}) => value.toString() === selectedYear?.toString()) ?? ''}
          options={years}
          disabled={!years}
          loading={!years}
          required
          onChange={({value}) => {
            setSelectedYear(value)
            clearError('year')
            getMakes(value)
            trackShiftEvents([SHIFT_EVENTS.TRADE_IN_START])
          }}
          error={getError('year')}
          inputMode="numeric" // "react-select" docs say the component accepts inputMode, but not positive it's working
        />
      </div>
      <div
        className="tw-mx-auto tw-mb-3 tw-w-full md:tw-max-w-md"
        aria-live="assertive"
        aria-atomic="false"
      >
        <InputSelect
          label={transMake}
          name="make"
          value={makes?.find(({value}) => value.toString() === selectedMake?.toString()) ?? ''}
          options={makes}
          disabled={!selectedYear || !makes}
          loading={selectedYear && !makes}
          required
          onChange={({value}) => {
            setSelectedMake(value)
            clearError('make')
            getModels(selectedYear, value)
          }}
          error={getError('make')}
        />
      </div>
      <div
        className="tw-mx-auto tw-mb-3 tw-w-full md:tw-max-w-md"
        aria-live="assertive"
        aria-atomic="false"
      >
        <InputSelect
          label={transModel}
          name="model"
          options={models}
          value={models?.find(({value}) => value.toString() === selectedModel?.toString()) ?? ''}
          disabled={!selectedMake || !models}
          loading={selectedMake && !models}
          required
          onChange={({value}) => {
            setSelectedModel(value)
            clearError('model')
            getStyles(selectedYear, value)
          }}
          error={getError('model')}
        />
      </div>
      <div
        className="tw-mx-auto tw-mb-3 tw-w-full md:tw-max-w-md"
        aria-live="assertive"
        aria-atomic="false"
      >
        <InputSelect
          label={transStyle}
          name="style"
          value={styles?.find(({value}) => value.toString() === selectedStyle?.toString()) ?? ''}
          options={styles}
          disabled={!selectedModel || !styles || styles.length === 0}
          loading={selectedModel && !styles}
          required
          onChange={({value}) => {
            setSelectedStyle(value)
            clearError('style')
          }}
          error={getError('style')}
        />
      </div>
      <div
        className="tw-mx-auto tw-mb-6 tw-w-full md:tw-mb-12 md:tw-max-w-md"
        aria-live="assertive"
        aria-atomic="false"
      >
        <Input
          label={transMileage}
          value={mileage ?? ''}
          type="number"
          name="mileage"
          id="mileage"
          required
          aria-required="true"
          onChange={(event) => {
            clearError('mileage')
            setMileage(event.target.value)
          }}
          error={getError('mileage')}
          inputMode="numeric"
          maxLength={6}
        />
      </div>
      <div className="divider tw-my-12 md:tw-hidden" />
      <div className="tw-text-center">
        <CtaButton
          type="submit"
          className="tw-w-full md:tw-max-w-md"
          disabled={[TRADE_ACTION_STATES.LOADING, TRADE_ACTION_STATES.ERROR].includes(reportStatus)}
        >
          {trans('Next')}
        </CtaButton>
      </div>
    </form>
  )
}

export default AboutYourCar

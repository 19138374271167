import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import classNames from 'classnames'

const selectOptions = [
  {key: 'Unmarried', text: 'Unmarried'},
  {key: 'Married', text: 'Married'},
  {key: 'Separated', text: 'Separated'},
]

const MaritalStatusSelect = ({name, prefix, label, required = false, groupWidth, inputWidth}) => {
  const {values, setFieldValue} = useFormikContext()
  const [maritalStatus, setMaritalStatus] = useState(
    prefix.includes('coBuyer') ? values.coBuyers[0].maritalStatus : values.maritalStatus
  )

  return (
    <div
      className={classNames({
        [`tw-w-${groupWidth}/12`]: groupWidth,
        'tw-w-full': !groupWidth,
      })}
    >
      {label && (
        <label
          className={classNames('tw-select-none', {
            required,
          })}
          htmlFor={prefix + name}
        >
          {label || <>&nbsp;</>}
        </label>
      )}
      <div
        className={classNames({
          [`tw-w-${inputWidth}/12`]: inputWidth,
          'tw-w-full': !inputWidth,
        })}
      >
        <Field
          as="select"
          name={prefix + name}
          id={prefix + name}
          className="tw-w-full tw-rounded tw-px-4 tw-py-2"
          value={maritalStatus}
          onChange={({target: {name, value}}) => {
            setFieldValue(name, value)
            setMaritalStatus(value)
          }}
        >
          <option value="">Select Status</option>
          {selectOptions.map(({key, text}) => (
            <option key={key} value={key}>
              {text}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name={prefix + name}
          render={(error) => <span className="error">{error}</span>}
        />
      </div>
    </div>
  )
}

MaritalStatusSelect.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  groupWidth: PropTypes.number,
  inputWidth: PropTypes.number,
}

export default MaritalStatusSelect

import React from 'react'
import {useRegistrationContext} from '../../contexts/RegistrationContext'
import {trans} from '../../utilities/Helpers'

const Header = () => {
  const {registrationState, registrationDispatch} = useRegistrationContext()
  const {newLead} = registrationState
  const hasAccount = !newLead ? trans('dont') : trans('already')

  const handleHasAccountLinkClick = (event) => {
    event.preventDefault()
    registrationDispatch({type: 'setNewLead', payload: !newLead})
    registrationDispatch({type: 'setSkipMatchingLead', payload: false})
  }

  return (
    <>
      <h1 className="tw-sr-only">{trans('reg.sign_up_get_started')}</h1>
      <h2 className="h3 tw-mx-6 tw-text-left tw-mb-4">{trans('reg.get_to_know')}</h2>
      <p className="tw-mx-6 text tw-mb-2">{trans('reg.why')}</p>
      <p className="tw-mx-6 text" id="formInstructions">
        {trans('required_field_sr')}
      </p>
      <h4 className="tw-text-center tw-mt-4 tw-mb-4 tw-text-base tw-font-bold">
        <a
          className="link tw-select-none"
          href="#"
          target="_self"
          onClick={handleHasAccountLinkClick}
        >
          {trans('reg.has_account', {has_account: hasAccount})}
        </a>
      </h4>
    </>
  )
}

export default Header

import React from 'react'
import PropTypes from 'prop-types'
import SelectInput from './SelectInput'

const selectOptions = [
  {key: 'Spouse', text: 'Spouse'},
  {key: 'Other', text: 'Other'},
]

const RelationshipSelect = ({name, prefix, label, required = true, groupWidth = 12}) => (
  <SelectInput
    name={name}
    prefix={prefix}
    label={label}
    required={required}
    selectOptions={selectOptions}
    groupWidth={groupWidth}
    placeholder="Select Status"
  />
)

RelationshipSelect.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  groupWidth: PropTypes.number,
}

export default RelationshipSelect

import React from 'react'
import PopoverHover from 'Src/components/Popovers/PopoverHover'
import IconQuestionCircle from 'Src/components/IconQuestionCircle'

const LeaseMileageOptionsTooltip = () => {
  const toolTipContent = (
    <p>
      Maximum number of miles you can drive per year without incurring a penalty. Increasing your
      mileage will increase your monthly payment and vice versa.
    </p>
  )

  return (
    <PopoverHover content={toolTipContent} data-testid="tooltip">
      <IconQuestionCircle />
    </PopoverHover>
  )
}

export default LeaseMileageOptionsTooltip

import {trans} from 'Src/utilities/Helpers'

export const populateStateSelectors = (dispatch, resolve, reject, a2zApi) => {
  a2zApi
    .get(`/states`)
    .then((response) => {
      const states = response.data.data.map(
        ({id, attributes: {abbreviation, community_property}}) => ({
          key: id,
          text: abbreviation,
          communityProperty: community_property,
        })
      )

      dispatch({states})

      if (resolve && typeof resolve === 'function') {
        resolve()
      }
    })
    .catch(() => {
      if (reject && typeof reject === 'function') {
        reject()
      }
    })
}

const formatCreditAppConfig = (creditAppConfig, dealershipId) => ({
  ...creditAppConfig,
  ...creditAppConfig.requirements,
  defaultBureauId: creditAppConfig.credit_app_bureau_id,
  dealershipId,
  privacy: {
    link: creditAppConfig.privacy_policy_url,
    name: 'Privacy.pdf',
    uploadedFileExists: !!creditAppConfig.privacy_policy_url,
  },
})

export const sectionHasErrors = (errors, sectionRows, prefix, isCoBuyer) => {
  if (!Object.keys(errors).length) {
    return false
  }

  if (isCoBuyer) {
    errors = errors?.coBuyers?.length ? errors.coBuyers[0] : {}
  }

  return sectionRows.some(({columns}) =>
    columns.some(({fields}) =>
      fields.some(({name}) => {
        name = name.replace('otherIncome[0].', '')

        return (
          (!prefix && errors[name]) ||
          (prefix && errors[prefix] && errors[prefix]?.some((errorFields) => errorFields?.[name]))
        )
      })
    )
  )
}

export const submitCreditApplication = async ({
  values,
  history,
  setSubmitting,
  leadId,
  a2zApi,
  setMessages,
  progressDispatch,
  customerDispatch,
}) => {
  const {references} = values
  values.references = values.references.reduce((acc, el) => {
    Object.values(el).some((i) => i && acc.push(el))

    return acc
  }, [])

  if (!values.coBuyerPresent) {
    values.coBuyers = []
  }

  values.creditAppConfig = formatCreditAppConfig(values.creditAppConfig, values.dealership.id)
  values.dealership = {id: parseInt(values.dealership.id, 10), ...values.dealership.attributes}

  // If the application type is "Joint" and the buyer and coBuyer are spouses, delete the 'spouse' key and subsequent value
  // from both the buyer and the coBuyer objects
  const spouses = {}
  const buyerHasSpouse = values.isCommunityPropertyResidence && values.maritalStatus !== 'Unmarried'
  const coBuyerHasSpouse =
    values.coBuyerPresent &&
    values.coBuyers[0].isCommunityPropertyResidence &&
    values.coBuyers[0].maritalStatus !== 'Unmarried'

  if (values.isCoBuyerSpouse || !buyerHasSpouse) {
    spouses.buyer = values.spouse
    delete values.spouse
  }

  if (values.coBuyerPresent && (values.isCoBuyerSpouse || !coBuyerHasSpouse)) {
    spouses.coBuyer = values.coBuyers[0].spouse
    delete values.coBuyers[0].spouse
  }

  try {
    const response = await a2zApi.post(`/customers/leads/${leadId}/credit_application`, values)

    await submitCreditApplicationSignatures({
      api: a2zApi,
      creditAppId: response.data.creditAppId,
      values,
      setMessages,
    })

    if (!values.coBuyerPresent) {
      delete values.coBuyers
    }

    values.references = references

    progressDispatch({
      type: 'update',
      payload: {credit: 'complete'},
    })

    customerDispatch({type: 'setHasActiveCreditApp', payload: true})

    history.push('/credit-complete')
  } catch (error) {
    if (!values.coBuyerPresent) {
      delete values.coBuyers
    }

    values.references = references

    if (spouses.buyer) {
      values.spouse = spouses.buyer
    }

    if (spouses.coBuyer) {
      values.coBuyers[0].spouse = spouses.coBuyer
    }

    console.log(error)
    console.log(error?.response?.data)

    if (!error?.response?.data || error.response.data.errors) {
      return
    }

    const messages = Object.keys(error.response.data)

    setMessages(
      messages.map((message) => ({
        title: message.includes('.') ? 'Error' : message[0].toUpperCase() + message.slice(1),
        description: error.response.data[message],
        type: 'error',
      }))
    )
  }

  setSubmitting(false)
}

export const submitCreditApplicationSignatures = async ({
  api,
  creditAppId,
  values,
  setMessages,
}) => {
  const showError = () => {
    setMessages((prevState) => [
      ...prevState,
      {
        description: trans('electronic_signatures.generic_error'),
        type: 'error',
      },
    ])
  }

  try {
    const {
      customerId: buyerId,
      email: buyerEmail,
      buyerSignature,
      buyerTypeSignature,
      coBuyerSignature,
      coBuyerTypeSignature,
    } = values

    const response = await api.post('signature/authorization', {
      data: {
        type: 'signature',
        attributes: {
          buyerId,
          buyerEmail,
          buyerAccepts: true,
          buyerFontSize: '50',
          buyerSignature,
          buyerTypeSignature,
          coBuyerAccepts: true,
          coBuyerFontSize: '50',
          coBuyerSignature,
          coBuyerTypeSignature,
          parentView: 'credit_application',
          creditAppId,
        },
      },
    })

    if (response?.error?.detail) {
      showError()
    }
  } catch (error) {
    console.error(error)
    showError()
  }
}

import {useEffect, useState} from 'react'
import {useAxios} from '../utilities/useAxios'

export const getPhoneTypeBySlug = (phoneTypes, slug) => {
  return phoneTypes.find((phoneType) => phoneType.attributes.slug === slug)
}

export const usePhoneTypes = () => {
  const [a2zApi, isApiReady] = useAxios()
  const [phoneTypes, setPhoneTypes] = useState(null)

  useEffect(() => {
    if (!isApiReady) return

    a2zApi
      .get(`/resources/phone_types`)
      .then((response) => response.data)
      .then((phoneTypesData) => {
        setPhoneTypes(phoneTypesData.data)
      })
  }, [isApiReady])

  return phoneTypes
}

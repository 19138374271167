import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {useCustomerDispatchContext, useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {addRequestType} from 'Src/actions/RequestTypeActions'
import {trans} from 'Src/utilities/Helpers'
import CopyDealEmailQuote from 'Src/components/CopyDealEmailQuote'
import BackButton from 'Src/components/Buttons/BackButton'
import PageLayoutCenter from 'Src/components/Layouts/PageLayoutCenter'
import DealershipWillContactYou from '../DealershipWillContactYou'
import PreviousDealModal from '../Deal/PreviousDealModal'

const Meet = () => {
  const dealDispatch = useDealDispatchContext()
  const dealState = useDealStateContext()
  const customerState = useCustomerStateContext()
  const {leadVehicles, previouslySavedQuote} = customerState
  const leadVehicle = leadVehicles?.find(({isCurrent}) => isCurrent === true)
  const frameContext = useFrameContext()
  const {isSaveDealEnabled} = frameContext
  const customerDispatch = useCustomerDispatchContext()
  const [hasInitialized, setHasInitialized] = useState(false)
  const pageTitle = trans('Meet with Us')
  const pageHeading = trans('Meet In Person or Remotely')

  useEffect(() => {
    if (!hasInitialized && leadVehicle && dealState.loaded) {
      const addRequestTypeAsync = async () => {
        await addRequestType(
          'meet-ca',
          dealState,
          customerState,
          frameContext,
          customerDispatch,
          dealDispatch
        )
      }

      addRequestTypeAsync()
      setHasInitialized(true)
    }
  }, [dealState, customerState, frameContext, customerDispatch])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageLayoutCenter className="tw-max-w-[400px]">
        <PageLayoutCenter.Header>{pageHeading}</PageLayoutCenter.Header>
        <PageLayoutCenter.Body>
          <h2 className="h3 tw-mb-4 tw-font-bold !tw-text-brand-success">
            {trans('We’d love to meet you!')}
          </h2>
          <h3 className="tw-mb-4 tw-font-semibold tw-leading-normal">
            {trans('We can meet you at our store, or via a video conference.')}
          </h3>
          <DealershipWillContactYou showLongText />
          <CopyDealEmailQuote />
        </PageLayoutCenter.Body>
        <PageLayoutCenter.Footer>
          <div className="tw-mt-4 tw-py-2">
            <BackButton />
          </div>
        </PageLayoutCenter.Footer>
      </PageLayoutCenter>
      {isSaveDealEnabled && previouslySavedQuote ? <PreviousDealModal /> : null}
    </>
  )
}

export default Meet

import React from 'react'
import LeaseMileageOptionsTooltip from './LeaseMileageOptionsTooltip'

const LeaseMileageHeader = () => (
  <div>
    <span className="subheading" data-testid="subheading">
      What mileage would you like?
    </span>
    <LeaseMileageOptionsTooltip />
  </div>
)

export default LeaseMileageHeader

import React, {useMemo, useState, useRef, ChangeEvent, useEffect} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import InputSelect from './InputSelect'
import CtaButton from './Buttons/CtaButton'
import {convertMbToBytes, trans, transString} from 'Src/utilities/Helpers'
import {IDocumentSelectType} from 'Src/utilities/Types'
import {faImage} from '@fortawesome/free-regular-svg-icons'
import {getDocumentUploadOptions, MAX_FILE_SIZE_MB} from 'Src/utilities/Constants'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {ACTION_DETAILS, EVENTS} from 'Src/services/analytics/constants'
import DocumentsList, {OnDeleteDocument} from 'Src/components/Documents/DocumentsList'
import useShowMessage from 'Src/utilities/useShowMessage'
import {IMessage} from 'Src/utilities/useAxios'
import {deleteDocument, uploadDocument} from 'Src/api/documents'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useFetchDocuments} from 'Src/queries/useFetchDocuments'
import {useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {UploadedDocument} from 'Src/transformers/documentTransformer'

const DocumentUploadForm = () => {
  const {a2zApi, isApiReady} = useFrameContext()
  const {leadId} = useCustomerStateContext()
  const documentUploadOptions = useMemo(getDocumentUploadOptions, [])
  const [selectedDocumentType, setSelectedDocumentType] = useState<IDocumentSelectType>(
    documentUploadOptions.find((documentType) => documentType.value === 'drivers_license_front') ??
      documentUploadOptions[0]
  )
  const [uploadDocuments, setUploadDocuments] = useState<
    Pick<UploadedDocument, 'id' | 'url' | 'fileType'>[]
  >([])
  const [deleteFlashMessage, setDeleteFlashMessage] = useState<IMessage | null>(null)
  const fileInput = useRef<HTMLInputElement>(null)

  const {trackEvent} = useAnalytics()

  const {data: {transformed: documents} = {}, refetch: refetchDocuments} = useFetchDocuments({
    leadId,
  })

  useEffect(() => {
    refetchDocuments().catch(console.error)
    setUploadDocuments([])
  }, [selectedDocumentType])

  const handleDocumentTypeChange = (selection: IDocumentSelectType) => {
    setSelectedDocumentType(selection)
  }

  const handleUploadFile = async ({
    file,
    type,
    leadId,
  }: {
    file: File
    type: string
    leadId: string | null
  }) => {
    if (!file || !type || !leadId) {
      return null
    }

    const formData = new FormData()

    formData.append('file', file)
    formData.append('type', type)
    formData.append('leadId', leadId)

    if (!isApiReady) {
      return null
    }

    // Bypassing Typescript to allow useFrameContext() which returns an instance of
    // Axios when isApiReady is true
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return await uploadDocument({a2zApi: a2zApi, formData}).catch(console.error)
  }

  const validateFileInput = (file: File): boolean => {
    if (file.size <= convertMbToBytes(MAX_FILE_SIZE_MB)) {
      return true
    }

    setDeleteFlashMessage({
      title: transString('Error'),
      description: transString('file_too_large_for_upload', {
        size: `${MAX_FILE_SIZE_MB}MB`,
        name: file.name,
      }),
      onClose: () => setDeleteFlashMessage(null),
      type: 'error',
    })

    return false
  }

  const fileInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files?.length) {
      return
    }

    const file = Array.from(event.currentTarget.files || [])[0]

    if (!validateFileInput(file)) {
      event.target.value = ''

      return
    }

    handleUploadFile({file, type: selectedDocumentType.value, leadId})
      .then((response) => {
        if (!response) {
          return
        }

        const uploadDocId = response.data.data.id

        setUploadDocuments([{id: uploadDocId, url: URL.createObjectURL(file), fileType: file.type}])
      })
      .catch(console.error)

    if (fileInput.current) {
      fileInput.current.value = ''
    }
  }

  useShowMessage(deleteFlashMessage, deleteFlashMessage)

  const handleDeleteDocument: OnDeleteDocument = ({documentId}) => {
    if (!isApiReady) {
      return
    }

    // Bypassing Typescript to allow useFrameContext() which returns an instance of
    // Axios when isApiReady is true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    deleteDocument({axios: a2zApi, documentId})
      .then(() => {
        refetchDocuments().catch(console.error)
        setUploadDocuments((prevUploadDocuments) =>
          prevUploadDocuments.filter((document) => document.id !== documentId)
        )

        setDeleteFlashMessage({
          title: transString('Success'),
          description: transString('Document deleted.'),
          onClose: () => setDeleteFlashMessage(null),
          type: 'success',
        })
      })
      .catch(() => {
        setDeleteFlashMessage({
          title: transString('Error'),
          description: transString('Failed to delete document.'),
          onClose: () => setDeleteFlashMessage(null),
          type: 'error',
        })
      })
  }

  const handleUploadButtonClick = () => {
    trackEvent(
      EVENTS.ENGAGEMENT,
      EVENTS.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.DOC_UPLOADS.UPLOAD
    )
    fileInput?.current?.click()
  }

  const filteredDocuments =
    documents?.filter((document) => document?.type === selectedDocumentType.value) ?? []

  return (
    <form>
      <div className="tw-mx-auto tw-mb-2 tw-mt-8 tw-flex tw-w-80 tw-flex-col tw-gap-2">
        <div
          className="tw-mx-auto tw-mb-3 tw-w-full md:tw-max-w-md"
          aria-live="assertive"
          aria-atomic="false"
        >
          <InputSelect
            name="trim"
            value={selectedDocumentType}
            options={documentUploadOptions}
            required
            onChange={handleDocumentTypeChange}
          />
        </div>
      </div>
      <div className="tw-flex tw-justify-center">
        <p className="tw-m-4 tw-text-center">{selectedDocumentType?.description}</p>
      </div>
      <div className="tw-flex tw-justify-center">
        {!uploadDocuments.length && filteredDocuments.length ? (
          <DocumentsList documents={filteredDocuments} onDeleteDocument={handleDeleteDocument} />
        ) : null}
      </div>
      {uploadDocuments.map((uploadDocument) => (
        <div
          key={uploadDocument.id}
          className="tw-mx-auto tw-mt-8 tw-flex tw-w-80 tw-flex-col tw-items-center tw-gap-8 sm:tw-flex-col md:tw-flex-row md:tw-items-center md:tw-justify-center md:tw-gap-8"
        >
          {uploadDocument.fileType.includes('pdf') ? (
            <embed className="tw-blur-md hover:tw-blur-none" src={uploadDocument.url} />
          ) : (
            <img className="tw-blur-md hover:tw-blur-none" src={uploadDocument.url} alt="preview" />
          )}
          <CtaButton
            onClick={() => handleDeleteDocument({documentId: uploadDocument.id})}
            type="button"
            preset="dangerSecondary"
            className="tw-mb-0"
          >
            {trans('Delete')}
          </CtaButton>
        </div>
      ))}
      <div className="tw-mx-auto tw-mt-8 tw-flex tw-w-80 tw-flex-col tw-items-center tw-gap-8 sm:tw-flex-col md:tw-flex-row md:tw-items-center md:tw-justify-center md:tw-gap-8">
        <FontAwesomeIcon
          size="6x"
          icon={faImage}
          className="tw-mr-2 tw-text-brand-icon_document-upload"
        />
        <input
          onChange={fileInputChange}
          type="file"
          name="image"
          accept="image/*,.pdf"
          ref={fileInput}
          style={{display: 'none'}}
        />
        <CtaButton
          type="button"
          preset="secondary"
          className="tw-mb-0"
          onClick={handleUploadButtonClick}
        >
          {trans('Upload')}
        </CtaButton>
      </div>
    </form>
  )
}

export default DocumentUploadForm

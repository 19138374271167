import React from 'react'
import {useFrameContext} from 'Src/contexts/FrameContext'

const DealershipWillContactYou = ({showLongText = false}) => {
  const {
    dealership: {
      attributes: {name: dealershipName},
    },
  } = useFrameContext()

  if (!dealershipName) {
    return null
  }

  const text = showLongText
    ? `Someone from ${dealershipName} will contact you shortly to coordinate the best option and timing.`
    : `Someone from ${dealershipName} will contact you shortly to get you going.`

  return <p className="tw-mb-12 tw-text-sm">{text}</p>
}

export default DealershipWillContactYou

import React, {useEffect, useMemo} from 'react'
import {parseCurrentData} from 'Src/utilities/ProgramHelpers'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {retrieveDealData} from 'Src/actions/DealActions'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {getRebateValue} from 'Src/utilities/RebateHelpers'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import RebatesHeader from 'Src/components/desking-page/Rebates/RebatesHeader'
import Rebates from 'Src/components/desking-page/Rebates/Rebates'

const RebatesSection = () => {
  const dealState = useDealStateContext()
  const {
    data: {
      attributes: {rebates},
    },
  } = dealState
  const {selectedRebateIds} = dealState
  const {dealType, currentProgram} = parseCurrentData(dealState)
  const {availableRebatesIds, term, appliedRebatesIds, rebatesTotal} = currentProgram
  const dealDispatch = useDealDispatchContext()
  const customerContext = useCustomerStateContext()
  const {trackEvent, events} = useAnalytics()
  const {
    vin,
    dealership: {id: dealershipId},
    a2zApi,
    useCache,
  } = useFrameContext()

  useEffect(() => {
    if (rebatesTotal) {
      trackEvent(
        events.ENGAGEMENT,
        events.ENGAGEMENT.actions.SELECTION,
        ACTION_DETAILS.REBATE.TOTAL,
        null,
        {
          rebatesTotal,
        }
      )
    }
  }, [rebatesTotal])

  const updateRebateSelections = (rebateId, addRebate) => {
    if (addRebate) {
      selectedRebateIds.push(rebateId)
    } else {
      const deleteAtIndex = selectedRebateIds.indexOf(rebateId)
      selectedRebateIds.splice(deleteAtIndex, 1)
    }

    dealDispatch({type: 'update', payload: {selectedRebateIds}})
  }

  const handleCheck = async (rebateId, addRebate = false) => {
    dealDispatch({type: 'update', payload: {isUpdating: true}})
    updateRebateSelections(rebateId, addRebate)
    await retrieveDealData(
      {
        vin,
        dealershipId,
        useCache,
      },
      dealDispatch,
      a2zApi,
      dealState,
      customerContext
    )
  }

  const isRebatesExist = useMemo(() => {
    return availableRebatesIds?.some((rebateId) => {
      return rebates[rebateId] && getRebateValue(rebates[rebateId], dealType, term)
    })
  }, [dealType])

  if (!isRebatesExist) {
    return null
  }

  return (
    <div role="group" aria-labelledby="rebates">
      <RebatesHeader
        rebateIds={appliedRebatesIds}
        rebatesTotal={rebatesTotal}
        data-testid="header"
      />
      <Rebates
        programTerm={term}
        selectedDealType={dealType}
        availableRebateIds={availableRebatesIds}
        appliedRebatesIds={appliedRebatesIds}
        selectedRebateIds={selectedRebateIds}
        handleCheck={handleCheck}
        data-testid="rebates"
      />
    </div>
  )
}

export default RebatesSection

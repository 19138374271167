import React, {forwardRef} from 'react'
import classNames from 'classnames'
import Spinner from './Spinner'

interface IInlineButtonSpinner {
  besideText?: boolean
  className?: string
}

export const SPINNER_CLASS = {
  LARGE: 'tw--mx-2',
  SMALL: 'tw-ml-2 tw-align-baseline',
}

/**
 * Width can either be "4" or "7", which equates to Tailwind CSS class names
 * "tw-w-4" and "tw-w-7", respectively.
 *
 * Because these classes are dynamically generated using template literals,
 * we explicitly include the full class names in this comment due to the way
 * Tailwind CSS scans the code (including comments) looking for fully-qualified
 * classes to include in the build.
 *
 * See: https://www.codeconcisely.com/posts/tailwind-css-dynamic-class/
 */

const InlineButtonSpinner = forwardRef<HTMLElement, IInlineButtonSpinner>(
  ({besideText = false, className = '', ...props}, ref) => {
    const propsCombined = {
      width: '7',
      className: classNames(SPINNER_CLASS.LARGE, className),
      ref,
      ...props,
    }

    if (besideText) {
      propsCombined.width = '4'
      propsCombined.className = classNames(SPINNER_CLASS.SMALL, className)
    }

    return <Spinner {...propsCombined} />
  }
)

export default InlineButtonSpinner

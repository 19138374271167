import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {Helmet} from 'react-helmet'
import {useCustomerDispatchContext, useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {addRequestType} from 'Src/actions/RequestTypeActions'
import {useProgressDispatchContext, useProgressStateContext} from 'Src/contexts/ProgressContext'
import {useProductContext} from 'Src/contexts/ProductContext'
import {backButtonBreadcrumbInteraction, trans} from 'Src/utilities/Helpers'
import BackButton from 'Src/components/Buttons/BackButton'
import PageLayoutCenter from 'Src/components/Layouts/PageLayoutCenter'
import DealershipWillContactYou from 'Src/components/DealershipWillContactYou'
import CopyDealEmailQuote from 'Src/components/CopyDealEmailQuote'
import PreviousDealModal from '../Deal/PreviousDealModal'

const CheckAvailability = () => {
  const history = useHistory()
  const dealDispatch = useDealDispatchContext()
  const dealState = useDealStateContext()
  const {dealType, vehicle} = dealState
  const customerState = useCustomerStateContext()
  const {leadVehicles, previouslySavedQuote} = customerState
  const leadVehicle = leadVehicles?.find(({isCurrent}) => isCurrent === true)
  const customerDispatch = useCustomerDispatchContext()
  const frameContext = useFrameContext()
  const {isSaveDealEnabled} = frameContext
  const ProgressDispatch = useProgressDispatchContext()
  const progress = useProgressStateContext()
  const {getSelectedRatedProducts} = useProductContext()
  const [hasInitialized, setHasInitialized] = useState(false)
  const pageTitle = trans('trade_int.check_availability')

  useEffect(() => {
    if (!hasInitialized && leadVehicle && dealState.loaded) {
      const addRequestTypeAsync = async () => {
        await addRequestType(
          'check-availability',
          dealState,
          customerState,
          frameContext,
          customerDispatch,
          dealDispatch,
          getSelectedRatedProducts
        )
      }

      addRequestTypeAsync()
      setHasInitialized(true)
    }
  }, [dealState, customerState, frameContext, customerDispatch])

  useEffect(() => {
    ProgressDispatch({type: 'update', payload: {finalize: 'complete'}})
  }, [])

  const handleBackButtonClick = () => {
    ProgressDispatch({
      type: 'update',
      payload: backButtonBreadcrumbInteraction(history, progress, dealType),
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageLayoutCenter className="tw-max-w-[400px]">
        <PageLayoutCenter.Header>{pageTitle}</PageLayoutCenter.Header>
        <PageLayoutCenter.Body>
          <h2 className="h3 tw-mb-4 tw-font-bold !tw-text-brand-success">{trans('Thank You!')}</h2>
          <p className="tw-mb-4">
            We got your request for the
            <span className="tw-block tw-font-bold" data-testid="description">
              {vehicle?.description}
            </span>
          </p>
          <DealershipWillContactYou showLongText />
          <CopyDealEmailQuote />
        </PageLayoutCenter.Body>
        <PageLayoutCenter.Footer>
          <div className="tw-mt-4 tw-py-2">
            <BackButton additionOnClick={handleBackButtonClick} />
          </div>
        </PageLayoutCenter.Footer>
      </PageLayoutCenter>
      {isSaveDealEnabled && previouslySavedQuote ? <PreviousDealModal /> : null}
    </>
  )
}

export default CheckAvailability

import React from 'react'
import PropTypes from 'prop-types'
import PlaceholderSpinner from 'Src/components/Spinners/PlaceholderSpinner'
import SlideOut from 'Src/components/SlideOut'
import Toaster from 'Src/components/Notifications/Toaster'
import {useNavStateContext} from '../../contexts/NavContext'
import {LOADING_STATE} from '../../reducers/NavReducer'
import useSlideOutState from '../../utilities/useSlideOutState'

const SlideOutLayout = ({children}) => {
  const {isOpen} = useSlideOutState()
  const {loading} = useNavStateContext()

  return (
    <div id="index-div" className="tw-h-full tw-w-full">
      <SlideOut open={isOpen}>
        <Toaster
          autohide={loading !== LOADING_STATE.FAILED}
          destroyOnClose={loading === LOADING_STATE.FAILED}
        />
        {loading === LOADING_STATE.LOADING ? <PlaceholderSpinner /> : children}
      </SlideOut>
    </div>
  )
}

SlideOutLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SlideOutLayout

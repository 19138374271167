import PropTypes from 'prop-types'
import React from 'react'
import {useHistory} from 'react-router'
import {useCustomerDispatchContext} from '../../contexts/CustomerContext'
import {useFrameContext} from '../../contexts/FrameContext'
import HeaderlessLayout from './HeaderlessLayout'

const RegistrationLayout = ({children}) => {
  const {isPriceLocked, dealershipVehicleMissing} = useFrameContext()
  const customerDispatch = useCustomerDispatchContext()
  const history = useHistory()

  const inlineClose = () => {
    customerDispatch({type: 'clearCustomer'})
    history.push('/')
  }
  const onClose = isPriceLocked || dealershipVehicleMissing ? null : inlineClose

  return <HeaderlessLayout onClose={onClose}>{children}</HeaderlessLayout>
}

RegistrationLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

export default RegistrationLayout

import FieldInput from '../../../Form/Fields/FieldInput'
import IncomeSelect from '../../../Form/Fields/SelectFields/IncomeSelect'

const OtherIncomeFieldConfig = [
  {
    row: 1,
    columns: [
      {
        column: 1,
        columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-3',
        fields: [
          {
            name: 'otherIncome[0].amount',
            label: 'Other Gross Income',
            component: FieldInput,
            type: 'number',
            step: '0.01',
          },
        ],
      },
      {
        column: 2,
        columnWidth: 'sm:tw-col-span-4',
        fields: [
          {
            name: 'otherIncome[0].received',
            label: 'Other Income Received',
            component: IncomeSelect,
            type: 'number',
            step: '0.01',
          },
        ],
      },
      {
        column: 3,
        columnWidth: 'sm:tw-col-span-4',
        fields: [
          {
            name: 'otherIncome[0].source',
            label: 'Source',
            component: FieldInput,
          },
        ],
      },
    ],
  },
]

export default OtherIncomeFieldConfig

import {transString} from './Helpers'
import {IDocumentSelectType} from './Types'

export const MAX_FILE_SIZE_MB = 10

// This is a function so we can delay execution until translations have loaded
export const getDocumentUploadOptions = (): Array<IDocumentSelectType> => {
  // todo: update trans in store
  return [
    {
      value: 'drivers_license_front',
      label: transString('drivers_license_front'),
      description: transString('drivers_license_front_subheading'),
    },
    {
      value: 'drivers_license_back',
      label: transString('drivers_license_back'),
      description: transString('drivers_license_back_subheading'),
    },
    {
      value: 'insurance_proof',
      label: transString('proof_of_insurance'),
      description: transString('proof_of_insurance_subheading'),
    },
    {
      value: 'proof_of_income',
      label: transString('proof_of_income'),
      description: transString('proof_of_income_subheading'),
    },
    {
      value: 'proof_of_residence',
      label: transString('proof_of_residence'),
      description: transString('proof_of_residence_subheading'),
    },
    {
      value: 'incentives_rebates',
      label: transString('proof_of_rebates'),
      description: transString('proof_of_rebates_subheading'),
    },
  ]
}

import React, {useEffect, useMemo, useState} from 'react'
import {useHistory} from 'react-router'
import classNames from 'classnames'
import BackButton from 'Src/components/Buttons/BackButton'
import CheckAvailabilityButton from 'Src/components/Buttons/CheckAvailabilityButton'
import NextButton from 'Src/components/Buttons/NextButton'
import Spinner from 'Src/components/Spinners/Spinner'
import CtaButton from 'Src/components/Buttons/CtaButton'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCloud} from '@fortawesome/free-solid-svg-icons'
import useShowMessage from 'Src/utilities/useShowMessage'
import {
  PROGRESS_STEP,
  useProgressDispatchContext,
  useProgressStateContext,
} from 'Src/contexts/ProgressContext'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {
  backButtonBreadcrumbInteraction,
  breadcrumbProgressTracking,
  trans,
  transString,
} from 'Src/utilities/Helpers'
import ProductCard from 'Src/components/Products/ProductCard'
import {useProductContext} from 'Src/contexts/ProductContext'
import {handleUpdateDeskingDataFail, handleUpdateDeskingDataSuccess} from 'Src/actions/DealActions'
import {useCustomerStateContext, useCustomerDispatchContext} from 'Src/contexts/CustomerContext'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {applyRatesFromProducts, trackProductConversionEvent} from 'Src/utilities/ProductHelpers'
import {SHIFT_EVENTS, useTrackShiftEvents} from 'Src/utilities/useShiftEvents'
import {useDeskingWithSelectedProducts} from 'Src/queries/useDeskingWithSelectedProducts'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import OrButtonDivider from 'Src/components/OrButtonDivider'
import ProductRatingFilter from 'Src/components/PageComponents/ProductRatingFilter'
import {IProduct} from 'Src/utilities/Types'
import PageLayoutLeft from 'Src/components/Layouts/PageLayoutLeft'
import {navigateIfUserRegistered} from 'Src/actions/RegistrationActions'
import {useNavDispatchContext} from 'Src/contexts/NavContext'
import {saveQuote} from 'Src/actions/DealActions'
import PreviousDealModal from '../Deal/PreviousDealModal'
import PlaceholderSpinner from 'Src/components/Spinners/PlaceholderSpinner'

const Products = () => {
  const dealState = useDealStateContext()
  const history = useHistory()
  const ProgressDispatch = useProgressDispatchContext()
  const progress = useProgressStateContext()
  const {trackEvent, events} = useAnalytics()
  const customerState = useCustomerStateContext()
  const {previouslySavedQuote} = customerState
  const customerDispatch = useCustomerDispatchContext()
  const [productToFilter, setProductToFilter] = useState<IProduct | null>(null)
  const [showProductFilter, setShowProductFilter] = useState(false)
  const {
    assignProductFilter,
    getProductsByDealType,
    getRatedProduct,
    getSelectedRatedProducts,
    productFilters,
    productRates,
    setRatedProductsByDealType,
    selectProduct,
  } = useProductContext()
  const dealDispatch = useDealDispatchContext()
  const navDispatch = useNavDispatchContext()
  const frameContext = useFrameContext()
  const {dealType, isUpdating, loading: deskingIsLoading} = dealState
  const products: IProduct[] = useMemo(() => getProductsByDealType(dealType), [dealType])
  const {isSaveDealEnabled} = useFrameContext()
  const pageTitle = transString('protection_plans.title')

  const onFilter = (product: IProduct) => {
    setProductToFilter(product)
    setShowProductFilter(true)
  }

  const {data, isError, error, isLoading, isFetching} = useDeskingWithSelectedProducts()

  useEffect(() => {
    if (isLoading || !data) {
      return
    }

    if (isError) {
      handleUpdateDeskingDataFail(error, dealDispatch)

      return
    }

    handleUpdateDeskingDataSuccess(data, dealDispatch, false, null, dealState)
  }, [data])

  const handleSaveFilter = (productId: number, values: []) => {
    assignProductFilter(productId, values)
    selectProduct(productId, dealType)
  }

  useEffect(() => {
    if (productFilters?.size > 0 && productRates) {
      const products = getProductsByDealType(dealType)

      if (products) {
        setRatedProductsByDealType(
          dealType,
          applyRatesFromProducts(products, productRates, productFilters)
        )
      }
    }
  }, [productFilters])

  const handleNextButtonClick = () => {
    trackProductConversionEvent(
      customerState?.customerData?.id,
      events,
      getSelectedRatedProducts(dealType),
      trackEvent
    )
    ProgressDispatch({
      type: 'update',
      payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.credit, dealType),
    })
    history.push('/credit')
  }

  const handleSaveMyDealButtonClick = () => {
    navigateIfUserRegistered(
      customerState?.customerData?.id,
      '/products',
      navDispatch,
      history,
      async () => {
        trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.SAVE_MY_DEAL)
        dealDispatch({type: 'savingDealQuote', payload: true})
        await saveQuote(
          dealState,
          customerState,
          frameContext,
          customerDispatch,
          dealDispatch,
          getSelectedRatedProducts
        )
        dealDispatch({type: 'savingDealQuote', payload: false})
      }
    )
  }

  const handleBackButtonClick = () => {
    ProgressDispatch({
      type: 'update',
      payload: backButtonBreadcrumbInteraction(history, progress, dealType),
    })
    trackProductConversionEvent(
      customerState?.customerData?.id,
      events,
      getSelectedRatedProducts(dealType),
      trackEvent
    )
  }

  const handleCheckAvailabilityButtonClick = () => {
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.PAGES.FINALIZE)
    trackProductConversionEvent(
      customerState?.customerData?.id,
      events,
      getSelectedRatedProducts(dealType),
      trackEvent
    )
    ProgressDispatch({
      type: 'update',
      payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.finalize, dealType),
    })
    history.push('/check-availability')
  }

  useTrackShiftEvents([SHIFT_EVENTS.FI_SHOWN])

  useShowMessage(products && !products.length, {
    title: trans('whoops'),
    description: trans('protection_plans.toast.no_plans_for_vehicle'),
    type: 'error',
  })

  return deskingIsLoading ? (
    <PlaceholderSpinner />
  ) : (
    <>
      {showProductFilter ? (
        <ProductRatingFilter
          hide={() => setShowProductFilter(false)}
          product={productToFilter}
          onSubmit={handleSaveFilter}
        />
      ) : null}
      <PageLayoutLeft>
        <PageLayoutLeft.Header>{pageTitle}</PageLayoutLeft.Header>
        <PageLayoutLeft.Body>
          <p className="tw-mb-6 tw-font-semibold">
            {trans('protection_plans.subtitle')}
            <span>*</span>
          </p>
          {products?.length
            ? products.map((product) => {
                return (
                  <ProductCard
                    key={`product-card-${product.id}`}
                    dealType={dealType}
                    onFilter={onFilter}
                    isDisabled={isUpdating || isFetching}
                    product={product}
                    ratedProduct={getRatedProduct(product.id, dealType)}
                  />
                )
              })
            : null}
          {products?.length ? (
            <p className="tw-text-xs lg:tw-mb-6">{trans('protection_plans.footnote')}</p>
          ) : null}
        </PageLayoutLeft.Body>
        <PageLayoutLeft.Footer>
          <div className="divider tw-my-12 lg:tw-invisible lg:tw-my-8" />
          <div
            className={classNames(
              'tw-flex tw-flex-col tw-justify-center tw-gap-x-6 sm:tw-flex-wrap sm:tw-justify-between md:tw-flex-row-reverse md:tw-justify-evenly',
              {
                'tw-gap-y-3': !isSaveDealEnabled,
                'tw-gap-y-4': isSaveDealEnabled,
              }
            )}
          >
            <NextButton
              actionDetails={{
                click: ACTION_DETAILS.CREDIT.PRE_APPROVED,
                navigate: ACTION_DETAILS.PAGES.CREDIT,
              }}
              onClick={handleNextButtonClick}
              text={trans('Get Pre-Approved')}
              isDisabled={isUpdating}
            />
            {isSaveDealEnabled ? (
              <CtaButton
                preset="secondary"
                onClick={handleSaveMyDealButtonClick}
                disabled={isUpdating}
              >
                <div className="tw-flex tw-items-center tw-justify-center">
                  {isUpdating ? (
                    <Spinner className="tw-mr-2" />
                  ) : (
                    <FontAwesomeIcon icon={faCloud} className="tw-mr-2" />
                  )}
                  {trans('Save My Deal')}
                </div>
              </CtaButton>
            ) : (
              <>
                <OrButtonDivider />
                <CheckAvailabilityButton onClick={handleCheckAvailabilityButtonClick} />
              </>
            )}
          </div>
          <div className="tw-mt-4 tw-py-2">
            <BackButton additionOnClick={handleBackButtonClick} />
          </div>
        </PageLayoutLeft.Footer>
      </PageLayoutLeft>
      {isSaveDealEnabled && previouslySavedQuote ? <PreviousDealModal /> : null}
    </>
  )
}

export default Products

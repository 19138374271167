import React from 'react'
import {useFormikContext} from 'formik'
import ResidencyInformation from '../ResidencyInfo/ResidencyInformation'
import EmploymentInformation from '../EmploymentInfo/EmploymentInformation'
import PersonalReferences from '../PersonalReferences/PersonalReferences'
import CustomerFields from './CustomerFieldConfig'
import FormRowRenderer from '../../../Form/FormUtilities/FormRowRenderer'
import Accordion from '../../../Utilities/Accordion'
import {sectionHasErrors} from '../../../CreditApplicationActions'

const CustomerInformation = () => {
  const {id, title, rows} = CustomerFields
  const {errors, isSubmitting} = useFormikContext()

  return (
    <>
      <Accordion
        id={id}
        title={title}
        hasErrors={isSubmitting && sectionHasErrors(errors, rows, '', false)}
      >
        <FormRowRenderer rows={rows} />
      </Accordion>
      <ResidencyInformation />
      <EmploymentInformation />
      <PersonalReferences />
    </>
  )
}
export default CustomerInformation

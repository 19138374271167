import React from 'react'
import {PropTypes} from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus'
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes'
import FormRowRenderer from './FormRowRenderer'

export const HeaderGroup = ({formGroupHeading, index, rows, arrayName}) => (
  <div>
    <div className="tw-flex tw-flex-row tw-justify-between tw-self-center tw-mb-2">
      <h3 className="h5">{formGroupHeading}</h3>
    </div>
    <FormRowRenderer
      className={formGroupHeading && 'md:tw-mx-6'}
      rows={rows}
      prefix={`${arrayName}[${index}].`}
    />
  </div>
)

export const DynamicGroup = ({
  formGroupHeading,
  arrayHelpers,
  removeButtonText,
  index,
  rows,
  arrayName,
}) => (
  <>
    <div className="divider tw-my-6" />
    <div className="tw-flex tw-flex-row tw-justify-between tw-self-center tw-mb-2">
      <h3 className="h5">{formGroupHeading}</h3>
      <button
        type="button"
        className="link link--danger tw-font-semibold"
        onClick={() => arrayHelpers.remove(index)}
      >
        <FontAwesomeIcon icon={faTimes} className="tw-mr-2" />
        {removeButtonText}
      </button>
    </div>
    <FormRowRenderer
      className={formGroupHeading && 'md:tw-mx-6'}
      rows={rows}
      prefix={`${arrayName}[${index}].`}
    />
  </>
)

export const AddGroupButton = ({
  index,
  groupArray,
  arrayHelpers,
  addButtonText,
  addFields,
  maxFields = 9,
}) =>
  index + 1 === groupArray.length &&
  index < maxFields && (
    <div className="tw-flex tw-justify-end">
      <button
        type="button"
        className="link tw-font-semibold"
        onClick={() => {
          // toggles any additional addresses, not mailing address
          arrayHelpers.push(addFields)
        }}
      >
        <FontAwesomeIcon icon={faPlus} className="tw-mr-2" />
        {addButtonText}
      </button>
    </div>
  )

HeaderGroup.propTypes = {
  formGroupHeading: PropTypes.string,
  index: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  arrayName: PropTypes.string,
}

DynamicGroup.propTypes = {
  formGroupHeading: PropTypes.string,
  index: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  arrayName: PropTypes.string,
  arrayHelpers: PropTypes.shape({
    remove: PropTypes.func,
  }),
  removeButtonText: PropTypes.string,
}

AddGroupButton.propTypes = {
  index: PropTypes.number,
  groupArray: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  arrayHelpers: PropTypes.shape({
    push: PropTypes.func,
  }),
  addButtonText: PropTypes.string,
  addFields: PropTypes.shape({
    name: PropTypes.string,
  }),
  maxFields: PropTypes.number,
}

import {useEffect} from 'react'

const useMenuEscapeKey = (menuRef, isMenuOpen, handleCloseCallback) => {
  const handleKeyDown = (event = window.event) => {
    // If the "Escape" key is pressed and active focus is inside the menu element.
    if (event.keyCode === 27 && menuRef.current?.contains(document.activeElement)) {
      handleCloseCallback()
    }
  }

  useEffect(() => {
    if (!menuRef.current) {
      return
    }

    if (isMenuOpen) {
      menuRef.current.focus()

      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isMenuOpen])
}

export default useMenuEscapeKey

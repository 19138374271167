import React from 'react'
import {PropTypes} from 'prop-types'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import classNames from 'classnames'

const SelectInput = ({
  name,
  prefix = '',
  label,
  className,
  selectOptions,
  required = false,
  groupWidth = 12,
  placeholder = '',
  showPlaceholder = true,
  alignItems = false,
  visibility = '',
}) => {
  const {setFieldValue} = useFormikContext()
  const prefixName = prefix + name

  return (
    <section
      className={classNames(visibility, {
        [`tw-w-${groupWidth}/12`]: groupWidth,
        'tw-w-full': !groupWidth,
      })}
    >
      {label ? (
        <label
          className={classNames('tw-select-none', {
            'tw-invisible': label === 'displayNone',
            required,
          })}
          htmlFor={prefixName}
        >
          {!label || label === 'displayNone' ? <>&nbsp;</> : label}
        </label>
      ) : null}
      <div>
        <Field
          as="select"
          name={prefixName}
          id={prefixName}
          className={classNames('tw-w-full tw-rounded tw-px-4 tw-py-2', className)}
          onChange={(event) => {
            const {
              target: {name, value},
            } = event
            setFieldValue(name, value)

            if (label === 'Relationship') {
              setFieldValue('isCoBuyerSpouse', value === 'Spouse')
            }
          }}
        >
          {showPlaceholder ? <option value="">{placeholder}</option> : null}
          {selectOptions.map(({key, text}) => (
            <option key={key} value={key}>
              {text}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name={prefixName}
          render={(error) => (
            <span className={classNames('error', {'tw-absolute': alignItems})}>{error}</span>
          )}
        />
      </div>
    </section>
  )
}

SelectInput.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showPlaceholder: PropTypes.bool,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  required: PropTypes.bool,
  groupWidth: PropTypes.number,
  alignItems: PropTypes.bool,
  className: PropTypes.string,
  visibility: PropTypes.string,
}

export default SelectInput

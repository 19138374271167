import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import Helmet from 'react-helmet'
import BackButton from 'Src/components/Buttons/BackButton'
import NextButton from 'Src/components/Buttons/NextButton'
import CtaButton from 'Src/components/Buttons/CtaButton'
import CheckAvailabilityButton from 'Src/components/Buttons/CheckAvailabilityButton'
import {useCustomerDispatchContext, useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {
  backButtonBreadcrumbInteraction,
  breadcrumbProgressTracking,
  trans,
} from 'Src/utilities/Helpers'
import {
  useProgressStateContext,
  useProgressDispatchContext,
  PROGRESS_STEP,
} from 'Src/contexts/ProgressContext'
import {saveQuote} from 'Src/actions/DealActions'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {useProductContext} from 'Src/contexts/ProductContext'
import PageLayoutCenter from 'Src/components/Layouts/PageLayoutCenter'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import OrButtonDivider from 'Src/components/OrButtonDivider'
import {THistory} from 'Src/utilities/useAxios'
import PreviousDealModal from '../Deal/PreviousDealModal'

const CreditApp = () => {
  const dealDispatch = useDealDispatchContext()
  const dealState = useDealStateContext()
  const {dealType} = dealState
  const customerState = useCustomerStateContext()
  const {leadVehicles, hasActiveCreditApp, previouslySavedQuote} = customerState
  const leadVehicle = leadVehicles?.find(({isCurrent}) => isCurrent === true)
  const frameContext = useFrameContext()
  const {isSaveDealEnabled, isDocUploadsEnabled} = frameContext
  const customerDispatch = useCustomerDispatchContext()
  const history: THistory = useHistory()
  const progress = useProgressStateContext()
  const progressDispatch = useProgressDispatchContext()
  const [hasInitialized, setHasInitialized] = useState(false)
  const {trackEvent, events} = useAnalytics()
  const {hasProducts, getSelectedRatedProducts} = useProductContext()
  const pageTitle = trans('credit_app.document_title')

  useEffect(() => {
    if (!hasInitialized && leadVehicle) {
      const saveQuoteAsync = async () => {
        await saveQuote(
          dealState,
          customerState,
          frameContext,
          customerDispatch,
          dealDispatch,
          getSelectedRatedProducts
        )
      }

      saveQuoteAsync()
      setHasInitialized(true)
    }
  }, [dealState, customerState, frameContext, customerDispatch])

  useEffect(() => {
    if (hasActiveCreditApp) {
      progressDispatch({
        type: 'update',
        payload: {credit: 'complete'},
      })
      history.replace('/credit-complete')
    }
  }, [hasActiveCreditApp])

  const handleStartCreditApplicationButtonClick = () => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.CREDIT.APPLICATION.START
    )
    trackEvent(
      events.NAVIGATION,
      events.NAVIGATION.actions.NAVIGATE,
      ACTION_DETAILS.CREDIT.IS_CREDIT_APP
    )
    history.push('/is-credit-app')
  }

  const handleSkipCreditApplicationButtonClick = () => {
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CLICK,
      ACTION_DETAILS.CREDIT.APPLICATION.SKIP
    )
    trackEvent(events.NAVIGATION, events.NAVIGATION.actions.NAVIGATE, ACTION_DETAILS.PAGES.FINALIZE)
    history.push('/finalize')
  }

  const handleNextFinalizeButtonClick = () => {
    history.push('/finalize')
  }

  const handleNextDocumentsUploadButtonClick = () => {
    progressDispatch({
      type: 'update',
      payload: breadcrumbProgressTracking(progress, PROGRESS_STEP.uploads, dealType),
    })
    history.push('/document-upload')
  }

  const handleBackButtonClick = () => {
    progressDispatch({
      type: 'update',
      payload: backButtonBreadcrumbInteraction(history, progress, dealType),
    })

    if (
      hasProducts &&
      history?.entries &&
      history?.entries.length >= 2 &&
      history?.entries[history?.entries.length - 2].pathname.includes('/trade-experience')
    ) {
      return history.push('/trade')
    }
  }

  const handleCheckAvailabilityButtonClick = () => {
    history.push('/check-availability')
  }

  const CreditAppNextButton = () => {
    return isDocUploadsEnabled ? (
      <NextButton
        actionDetails={{
          click: ACTION_DETAILS.CREDIT.PRE_APPROVED,
          navigate: ACTION_DETAILS.PAGES.DOC_UPLOADS,
        }}
        onClick={handleNextDocumentsUploadButtonClick}
        text={trans('Documents Upload')}
      />
    ) : (
      <NextButton
        actionDetails={{
          click: ACTION_DETAILS.CREDIT.PRE_APPROVED,
          navigate: ACTION_DETAILS.PAGES.FINALIZE,
        }}
        onClick={handleNextFinalizeButtonClick}
        text={trans('Finalize')}
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageLayoutCenter>
        <PageLayoutCenter.Header>{pageTitle}</PageLayoutCenter.Header>
        <PageLayoutCenter.Body>
          <p className="tw-mb-10">
            This will allow you to get a head-start in getting your deal approved.{' '}
            <br className="tw-hidden xl:tw-block" />
            Rest assured, all your information is fully protected and secure.
          </p>
          <div className="tw-text-center">
            <div className="tw-mb-6">
              <CtaButton
                preset="secondary"
                className="tw-w-full md:tw-max-w-md"
                onClick={handleStartCreditApplicationButtonClick}
              >
                {trans('Start Credit Application')}
              </CtaButton>
            </div>
            <div className="lg:tw-mb-6">
              <CtaButton
                preset="link"
                className="!tw-text-sm !tw-font-bold"
                onClick={handleSkipCreditApplicationButtonClick}
              >
                {trans('Skip Credit Application')}
              </CtaButton>
            </div>
          </div>
        </PageLayoutCenter.Body>
        <PageLayoutCenter.Footer>
          <div className="divider tw-my-12 lg:tw-invisible lg:tw-my-8" />
          {isSaveDealEnabled ? (
            <div className="tw-flex tw-flex-col tw-justify-center tw-gap-x-6 sm:tw-mb-4 sm:tw-flex-wrap sm:tw-justify-between md:tw-flex-row-reverse lg:tw-mb-0">
              <CreditAppNextButton />
              <div className="tw-mt-4 tw-py-2 sm:tw-mt-0 sm:tw-flex">
                <BackButton additionOnClick={handleBackButtonClick} />
              </div>
            </div>
          ) : (
            <>
              <div className="tw-flex tw-flex-col tw-justify-center tw-gap-x-6 tw-gap-y-3 sm:tw-flex-wrap sm:tw-justify-between md:tw-flex-row-reverse md:tw-justify-evenly">
                <CreditAppNextButton />
                <OrButtonDivider />
                <CheckAvailabilityButton onClick={handleCheckAvailabilityButtonClick} />
              </div>
              <div className="tw-mt-4 tw-py-2">
                <BackButton additionOnClick={handleBackButtonClick} />
              </div>
            </>
          )}
        </PageLayoutCenter.Footer>
      </PageLayoutCenter>
      {isSaveDealEnabled && previouslySavedQuote ? <PreviousDealModal /> : null}
    </>
  )
}

export default CreditApp

import React, {useState} from 'react'
import {Formik, Form} from 'formik'
import {useHistory} from 'react-router'
import {Helmet} from 'react-helmet'
import {CreditApplicationSchema} from './creditApplicationSchema'
import AccessibilityErrors from './Sections/AccessibilityErrors/AccessibilityErrors'
import CustomerInformation from './Sections/CustomerInformation/CustomerInfo/CustomerInformation'
import CoBuyer from './Sections/CoBuyer/CoBuyer'
import Acknowledgement from './Sections/Acknowledgement/Acknowledgement'
import PrivacyPolicy from './Sections/PrivacyPolicy/PrivacyPolicy'
import {submitCreditApplication} from './CreditApplicationActions'
import ApplicationType from './Sections/ApplicationType/ApplicationType'
import SpouseInformation from './Sections/SpouseInformation/SpouseInformation'
import FormFooter from './FormFooter'
import {SHIFT_EVENTS, useTrackShiftEvents} from '../../utilities/useShiftEvents'
import {useFrameContext} from '../../contexts/FrameContext'
import DisplayFormikState from './Form/FormUtilities/DisplayFormikState'
import {useLendingCenterContext} from './LendingCenterContext'
import ErrorFocus from './Form/FormUtilities/ErrorFocus'
import {useCustomerDispatchContext, useCustomerStateContext} from '../../contexts/CustomerContext'
import {useProgressDispatchContext} from '../../contexts/ProgressContext'
import {useAnalytics} from '../../services/analytics/useAnalytics'
import {trans} from '../../utilities/Helpers'
import {ACTION_DETAILS} from '../../services/analytics/constants'

const CreditAppForm = () => {
  const {
    leadId,
    customerData: {id: customerId, firstName, lastName, email, phone, zipCode, cityCounty},
  } = useCustomerStateContext()
  const customerDispatch = useCustomerDispatchContext()
  const {trackEvent, events} = useAnalytics()
  const {
    lendingCenterData: {initialCreditAppValues, states},
  } = useLendingCenterContext()
  const progressDispatch = useProgressDispatchContext()

  const populatedCreditAppValues = {
    ...initialCreditAppValues,
    firstName,
    lastName,
    email,
    phone1: phone?.substring(phone.length - 10),
  }
  populatedCreditAppValues.residences[0].zipCode = zipCode
  populatedCreditAppValues.residences[0].city = cityCounty?.city || ''

  const {a2zApi, dealership, creditAppConfig, setMessages} = useFrameContext()
  const history = useHistory()

  const [showState, setShowState] = useState(false)
  const [showSpousePanel, setShowSpousePanel] = useState(false)
  const [spousePanelType, setSpousePanelType] = useState(false)

  window.showFormikState = (showFormikState) => {
    setShowState(showFormikState)

    return showFormikState ? 'Showing state values' : 'Hiding state values'
  }

  const triggerPanel = (toggle, type) => {
    setShowSpousePanel(toggle)
    setSpousePanelType(type)
  }

  const configureSpouseInformationPanel = (values) => {
    const {maritalStatus, isCommunityPropertyResidence, coBuyerPresent, coBuyers} = values

    const buyerSpousePanel =
      isCommunityPropertyResidence && (maritalStatus === 'Married' || maritalStatus === 'Separated')
    const coBuyerSpousePanel =
      coBuyerPresent &&
      coBuyers[0].isCommunityPropertyResidence &&
      (coBuyers[0].maritalStatus === 'Married' || coBuyers[0].maritalStatus === 'Separated')

    const buyerCoBuyerSpouses = coBuyerPresent && coBuyers[0].relationship === 'Spouse'
    const jointSpousePanel =
      buyerSpousePanel && coBuyerSpousePanel && coBuyers[0].relationship === 'Other'

    return jointSpousePanel
      ? triggerPanel(true, 'Joint')
      : buyerCoBuyerSpouses
      ? triggerPanel(false, false)
      : coBuyerSpousePanel
      ? triggerPanel(true, 'Co-Buyer')
      : buyerSpousePanel
      ? triggerPanel(true, 'Customer')
      : triggerPanel(false, false)
  }

  const handleErrorFocus = (values, errors) => {
    if (!values.coBuyerPresent && 'coBuyers' in errors) {
      errors = {}
    }

    return <ErrorFocus {...errors} />
  }

  const handleFormSubmit = (values, {setSubmitting}) => {
    values = {...values, dealership, customerId, creditAppConfig}

    const submissionType = values.coBuyerPresent
      ? ACTION_DETAILS.CREDIT.APPLICATION.SUBMITTED.JOINT
      : ACTION_DETAILS.CREDIT.APPLICATION.SUBMITTED.INDIVIDUAL

    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, submissionType)

    return submitCreditApplication({
      values,
      history,
      setSubmitting,
      leadId,
      a2zApi,
      setMessages,
      progressDispatch,
      customerDispatch,
    })
  }

  return (
    <>
      <Helmet>
        <title>{trans('credit_application')}</title>
      </Helmet>
      <Formik
        validationSchema={CreditApplicationSchema}
        initialValues={populatedCreditAppValues}
        onSubmit={handleFormSubmit}
      >
        {({values, isSubmitting, errors, dirty}) => {
          useTrackShiftEvents(dirty && [SHIFT_EVENTS.CREDIT_APP_START])

          return (
            <Form className="mobile-scroll tw-max-h-screen tw-overflow-y-scroll tw-px-6 tw-pb-6 tw-pt-10 md:tw-px-10">
              <h1 className="h3 tw-mb-6">{trans('credit_application')}</h1>
              <ApplicationType {...{states}} />
              <AccessibilityErrors {...{errors}} />
              <CustomerInformation />
              {values.coBuyerPresent ? <CoBuyer /> : null}
              {configureSpouseInformationPanel(values)}
              {showSpousePanel ? <SpouseInformation subtitle={spousePanelType} /> : null}
              <PrivacyPolicy />
              <Acknowledgement />
              {showState ? <DisplayFormikState /> : null}
              <FormFooter {...{isSubmitting}} />
              {isSubmitting ? handleErrorFocus(values, errors) : null}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default CreditAppForm

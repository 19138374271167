import FieldInput from 'Src/components/CreditApp/Form/Fields/FieldInput'
import MaskedInput from 'Src/components/CreditApp/Form/Fields/MaskedInput'
import MultiActionCheckbox from 'Src/components/CreditApp/Form/Fields/MultiActionCheckbox'
import StateSelect from 'Src/components/CreditApp/Form/Fields/SelectFields/StateSelect'

const SpouseFieldConfig = (
  subtitle: string,
  panelName: string,
  prefix: string,
  visibility: string
) =>
  subtitle !== 'Joint'
    ? {
        id: 'spouse',
        title: 'Spouse Information',
        rows: [
          {
            row: 1,
            columns: [
              {
                column: 1,
                fields: [
                  {
                    name: 'firstName',
                    label: 'First Name',
                    required: true,
                    component: FieldInput,
                  },
                ],
              },
              {
                column: 2,
                fields: [
                  {
                    name: 'lastName',
                    label: 'Last Name',
                    required: true,
                    component: FieldInput,
                  },
                ],
              },
            ],
          },
          {
            row: 2,
            columns: [
              {
                column: 1,
                columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-3',
                fields: [
                  {
                    name: 'phone',
                    label: 'Phone',
                    required: true,
                    component: MaskedInput,
                    mask: 'phone',
                  },
                ],
              },
            ],
          },
          {
            row: 3,
            columns: [
              {
                column: 1,
                fields: [
                  {
                    decorator: true,
                    name: `${prefix}sameAddressAs`,
                    label: 'Address',
                    required: false,
                    component: MultiActionCheckbox,
                    postLabel: `Same as ${subtitle}`,
                    prefix,
                  },
                ],
              },
            ],
          },
          {
            row: 4,
            visibility,
            columns: [
              {
                column: 1,
                fields: [
                  {
                    name: 'address1',
                    component: FieldInput,
                  },
                ],
              },
            ],
          },
          {
            row: 5,
            visibility,
            columns: [
              {
                column: 1,
                fields: [
                  {
                    name: 'address2',
                    label: 'Address 2',
                    component: FieldInput,
                  },
                ],
              },
            ],
          },
          {
            row: 6,
            visibility,
            columns: [
              {
                column: 1,
                columnWidth: 'sm:tw-col-span-5',
                fields: [
                  {
                    name: 'city',
                    label: 'City',
                    required: true,
                    component: FieldInput,
                  },
                ],
              },
              {
                column: 2,
                columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-5',
                fields: [
                  {
                    name: 'state',
                    label: 'State',
                    required: true,
                    component: StateSelect,
                  },
                ],
              },
              {
                column: 3,
                columnWidth: 'sm:tw-col-span-3 lg:tw-col-span-2',
                fields: [
                  {
                    name: 'zipCode',
                    label: 'ZIP Code',
                    required: true,
                    component: MaskedInput,
                    mask: 'zipCode',
                  },
                ],
              },
            ],
          },
        ],
      }
    : {
        id: 'spouse',
        title: 'Spouse Information',
        rows: [
          {
            row: 1,
            columns: [
              {
                column: 1,
                fields: [
                  {
                    name: 'firstName',
                    label: 'First Name',
                    required: true,
                    component: FieldInput,
                  },
                ],
              },
              {
                column: 2,
                fields: [
                  {
                    name: 'lastName',
                    label: 'Last Name',
                    required: true,
                    component: FieldInput,
                  },
                ],
              },
            ],
          },
          {
            row: 2,
            columns: [
              {
                column: 1,
                columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-3',
                fields: [
                  {
                    name: 'phone',
                    label: 'Phone',
                    required: true,
                    component: MaskedInput,
                    mask: 'phone',
                  },
                ],
              },
            ],
          },
          {
            row: 3,
            columns: [
              {
                column: 1,
                fields: [
                  {
                    decorator: true,
                    name: `${prefix}sameAddressAs`,
                    label: 'Address',
                    required: false,
                    component: MultiActionCheckbox,
                    postLabel: `Same as ${panelName}`,
                    paddingLeft: 3,
                    prefix,
                  },
                ],
              },
            ],
          },
          {
            row: 4,
            visibility,
            columns: [
              {
                column: 1,
                fields: [
                  {
                    name: 'address1',
                    label: 'displayNone',
                    component: FieldInput,
                  },
                ],
              },
            ],
          },
          {
            row: 5,
            visibility,
            columns: [
              {
                column: 1,
                fields: [
                  {
                    name: 'address2',
                    label: 'Address 2',
                    component: FieldInput,
                  },
                ],
              },
            ],
          },
          {
            row: 6,
            visibility,
            columns: [
              {
                column: 1,
                columnWidth: 'sm:tw-col-span-5',
                fields: [
                  {
                    name: 'city',
                    label: 'City',
                    required: true,
                    component: FieldInput,
                  },
                ],
              },
              {
                column: 2,
                columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-5',
                fields: [
                  {
                    name: 'state',
                    label: 'State',
                    required: true,
                    component: StateSelect,
                  },
                ],
              },
              {
                column: 3,
                columnWidth: 'sm:tw-col-span-3 lg:tw-col-span-2',
                fields: [
                  {
                    name: 'zipCode',
                    label: 'ZIP Code',
                    required: true,
                    component: MaskedInput,
                    mask: 'zipCode',
                  },
                ],
              },
            ],
          },
        ],
      }

export default SpouseFieldConfig

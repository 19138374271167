import React from 'react'
import classNames from 'classnames'
import {displayPhoneNumber, trans} from 'Src/utilities/Helpers'
import {useFrameContext} from 'Src/contexts/FrameContext'

interface IQuestionsCallDealershipPhoneProps {
  className?: string
  questionsClassName?: string | null
  callClassName?: string
}

const QuestionsCallDealershipPhone = ({
  className,
  questionsClassName = 'tw-font-semibold',
  callClassName,
}: IQuestionsCallDealershipPhoneProps = {}) => {
  const {
    dealership: {
      attributes: {phone: dealershipPhone},
    },
  } = useFrameContext()

  if (!dealershipPhone) {
    return null
  }

  return (
    <div className={className}>
      <span className={classNames(questionsClassName)}>{trans('questions')}</span>{' '}
      <span className={callClassName}>
        {trans('call_phone', {phoneNumber: displayPhoneNumber(dealershipPhone)})}
      </span>
    </div>
  )
}

export default QuestionsCallDealershipPhone

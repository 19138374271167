import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {formatMileage} from '../../../utilities/DataReformatters'
import {useDealDispatchContext, useDealStateContext} from '../../../contexts/DealContext'
import {parseCurrentData, validateProgram} from '../../../utilities/ProgramHelpers'
import {resetDownPaymentState} from '../../../actions/DealActions'
import {useAnalytics} from '../../../services/analytics/useAnalytics'
import CtaButton from '../../Buttons/CtaButton'
import {CSS_SECONDARY_BUTTON_GROUP} from '../constants'

const LeaseMileageOptions = ({mileages}) => {
  const dealState = useDealStateContext()
  const {selectedLeaseMileageOption, isUpdating, leaseTerm} = dealState
  const dealDispatch = useDealDispatchContext()
  const {currentProgram} = parseCurrentData(dealState)
  const {costPerMile} = currentProgram

  const {trackEvent, events} = useAnalytics()
  const [leaseMileageChangeCount, setLeaseMileageChangeCount] = useState(0)
  const trackLeaseChangeEvent = (selectedMileage) => {
    const changeCount = leaseMileageChangeCount + 1
    setLeaseMileageChangeCount(changeCount)
    trackEvent(
      events.ENGAGEMENT,
      events.ENGAGEMENT.actions.CHANGE,
      `mileage ${selectedMileage}`,
      null,
      {
        leaseMileageChangeCount: changeCount,
      }
    )
  }

  const handleChange = (selectedMileage) => {
    trackLeaseChangeEvent(selectedMileage)
    if (!validateProgram(dealState, {selectedMileage})) {
      dealDispatch({type: 'update', payload: {displayNoProgramFoundMessage: true}})
      return
    }

    resetDownPaymentState(dealDispatch)
    dealDispatch({type: 'update', payload: {selectedLeaseMileageOption: selectedMileage}})
  }

  return (
    <div className="mt-3">
      <div className="tw-grid tw-gap-y-3 tw-gap-x-6 md:tw-grid-cols-3">
        {Object.keys(mileages).map((mileageOption) => {
          const availableLeaseTerms = mileages?.[mileageOption]?.termsGroup
          if (availableLeaseTerms && !Object.keys(availableLeaseTerms).includes(leaseTerm)) {
            return null
          }

          const isActive = selectedLeaseMileageOption === mileageOption

          return (
            <CtaButton
              key={mileageOption}
              preset={isActive ? 'secondaryActive' : 'secondaryInactive'}
              className={CSS_SECONDARY_BUTTON_GROUP}
              onClick={() => handleChange(mileageOption)}
              disabled={isUpdating}
              aria-pressed={isActive}
              data-testid="mileage-button"
            >
              {formatMileage(mileageOption)} miles/year
            </CtaButton>
          )
        })}
      </div>
      {costPerMile ? (
        <div className="tw-text-legalese" data-testid="cost-per-mile">
          {`Excess miles will be charged at $${costPerMile.toFixed(
            2
          )} per mile at the end of the lease`}
        </div>
      ) : null}
    </div>
  )
}

LeaseMileageOptions.propTypes = {
  mileages: PropTypes.shape({}),
}

export default LeaseMileageOptions

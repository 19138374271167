import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router'
import * as yup from 'yup'
import _ from 'lodash'
import {trans} from '../../../utilities/Helpers'
import InputSelect from '../../InputSelect'
import useForm from '../../../utilities/useForm'
import {useFrameContext} from '../../../contexts/FrameContext'
import {useTradeContext} from '../../../contexts/TradeContext'
import {buildUrl, getOptions, TRADE_ACTION_STATES} from '../../../actions/TradeActions'
import CtaButton from '../../Buttons/CtaButton'

const AdditionalQuestions = () => {
  const {a2zApi, setMessages} = useFrameContext()
  const history = useHistory()
  const {TradeVehicleMetadata, setTradeVehicleMetadata} = useTradeContext()
  const initialTrade = {
    year: TradeVehicleMetadata?.yearId,
    make: TradeVehicleMetadata?.makeName,
    model: TradeVehicleMetadata?.modelName,
    trim: TradeVehicleMetadata?.trim,
  }

  const [selects, setSelects] = useState([])
  const [trade, setTrade] = useState(initialTrade)
  const [reportStatus, setReportStatus] = useState(TRADE_ACTION_STATES.INCOMPLETE)

  const buildSelectsEntry = ({filter, payload}) => {
    return {
      label: _.startCase(filter),
      name: filter,
      options: payload,
      value: '',
    }
  }

  // found and adjusted to our needs from https://github.com/jquense/yup/issues/559
  const useCustomFieldsExtendValidation = (selects) => {
    return selects.map((customField) => {
      return {
        ...customField,
        validationType: 'string',
        validations: [
          {
            type: 'label',
            params: [customField.label],
          },
          {
            type: 'required',
            params: [`${customField.label} is required.`],
          },
          {
            type: 'nullable',
            params: [],
          },
        ],
      }
    })
  }

  const useCustomFieldsDynamicSchema = (schema, config) => {
    const {name, validationType, validations = []} = config
    if (!yup[validationType]) {
      return schema
    }

    // set what type of validation it is, e.g. yup.string(), yup.number()
    let validator = yup[validationType]()
    validations.forEach((validation) => {
      const {params, type} = validation
      if (!validator[type]) {
        return
      }

      // for every validation we have (e.g. required, label, min/max), build the yup
      // validation object and assign it back to the validator we created earlier
      // one pass through would build a validation like .required('Name is required')
      validator = validator[type](...params)
    })

    // create the validation schema for the field in question
    schema[name] = validator

    return schema
  }

  const dynamicFormData = useCustomFieldsExtendValidation(selects)
  const customFieldsSchema = dynamicFormData.reduce(useCustomFieldsDynamicSchema, {})
  const moreQuestionsSchema = yup.object().shape(customFieldsSchema)

  const buildValues = () => {
    // build the values object for useForm
    return selects.reduce((total, current) => {
      total[current.name] = current.value

      return total
    }, {})
  }

  const onSubmit = () => {
    history.push('/trade-experience/owed-payments')
  }

  const {handleSubmit, getError, clearError} = useForm(moreQuestionsSchema, buildValues(), onSubmit)

  const handleOptionsResponse = (response, newSelects) => {
    const {status} = response
    setReportStatus(status)

    switch (status) {
      case TRADE_ACTION_STATES.INCOMPLETE:
        // push new question onto stack
        newSelects.push(buildSelectsEntry(response))
        break
      case TRADE_ACTION_STATES.COMPLETE:
        // set returned values and report in completeReport to tradeContext
        setTradeVehicleMetadata({...response.payload, ...response.report})
        break
      case TRADE_ACTION_STATES.ERROR:
      default:
        setMessages([
          {
            title: trans('trade_int.trade_evaluation_error'),
            description: response.payload,
            type: 'error',
          },
        ])
    }

    // set questions to changed list
    setSelects(newSelects)
  }

  const handleQuestionUpdates = (item, index, value, newSelects, values) => {
    if (!item) {
      return
    }

    clearError(item.name)

    // update the "value" property of the current InputSelect
    newSelects[index] = {...item, value}
    values[item.name] = value

    // remove all subsequent InputSelects and clear out any previously selected answers
    newSelects.slice(index + 1).forEach((select) => delete values[select.name])
  }

  const getNextQuestion = async (selectedOption = '', index = null, value = '') => {
    const item = selects[index]
    const values = {...trade, ...buildValues()}

    const newSelects = [...selects]
    handleQuestionUpdates(item, index, value, newSelects, values)

    setTrade(values)

    // make call to get the next question
    const url = buildUrl(selectedOption, value, values)
    const response = await getOptions(a2zApi, url)

    handleOptionsResponse(response, newSelects.slice(0, index + 1))
  }

  useEffect(() => {
    getNextQuestion()
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <br />
      <h2 className="h3 tw-text-center tw-w-full md:tw-max-w-md tw-mx-auto tw-mb-3 tw-mt-12">
        {TradeVehicleMetadata?.yearId} {TradeVehicleMetadata?.makeName}{' '}
        {TradeVehicleMetadata?.modelName} {TradeVehicleMetadata?.trim}
      </h2>
      <p className="tw-text-center tw-w-full md:tw-max-w-md tw-mx-auto tw-mb-5">
        Select additional details about your vehicle.
      </p>
      {selects.map((object, index) => {
        const previousSelect = selects?.[index - 1]

        if (!previousSelect || previousSelect.value) {
          const value =
            object.options?.find(
              ({value}) => value.toString() === trade[object.name]?.toString()
            ) ?? ''

          // if there is no previous select (we're on the first question)
          // or if we have already selected a value for the previous question
          return (
            <div className="tw-w-full md:tw-max-w-md tw-mx-auto tw-mb-3" key={object.name}>
              <InputSelect
                label={object.label}
                name={object.name}
                options={object.options}
                value={value}
                disabled={!object.options}
                loading={!object.options}
                required
                onChange={({value}) => getNextQuestion(object.name, index, value)}
                error={getError(object.name)}
              />
            </div>
          )
        }

        // don't show a question until the previous has been answered
        return null
      })}
      <div className="tw-text-center tw-mt-10 tw-mb-3">
        <CtaButton
          type="submit"
          className="tw-w-full md:tw-max-w-md"
          disabled={reportStatus !== TRADE_ACTION_STATES.COMPLETE}
        >
          {trans('Next')}
        </CtaButton>
      </div>
    </form>
  )
}

export default AdditionalQuestions

import React from 'react'
import PopoverHover from 'Src/components/Popovers/PopoverHover'
import IconQuestionCircle from 'Src/components/IconQuestionCircle'
import {trans} from 'Src/utilities/Helpers'

const RebatesTooltip = () => {
  const popoverContent = (
    <p role="tooltip" id="rebates-tooltip">
      {trans('rebates_tooltip')}
    </p>
  )

  return (
    <PopoverHover content={popoverContent} data-testid="rebates-tooltip">
      <IconQuestionCircle />
    </PopoverHover>
  )
}

export default RebatesTooltip

import React from 'react'
import {Route, Switch} from 'react-router'
import {useCustomerStateContext} from '../contexts/CustomerContext'
import {useFrameContext} from '../contexts/FrameContext'
import CreditApplication from './PageComponents/CreditApplication'
import CreditComplete from './PageComponents/CreditComplete'
import Desking from './PageComponents/Desking'
import DeskingTradeOutOfRange from './PageComponents/DeskingTradeOutOfRange'
import Finalize from './PageComponents/Finalize'
import TradeIntegration from './PageComponents/TradeIntegration'
import Meet from './PageComponents/Meet'
import CheckAvailability from './PageComponents/CheckAvailability'
import ScheduleTestDrive from './PageComponents/ScheduleTestDrive'
import Trade from './PageComponents/Trade'
import TradeComplete from './PageComponents/TradeComplete'
import Error from './pages/Error'
import PageNotFound from './pages/PageNotFound'
import CodeVerification from './pages/Registration/CodeVerification'
import MatchingLead from './pages/Registration/MatchingLead'
import NewLeadConfirmation from './pages/Registration/NewLeadConfirmation'
import Registration from './pages/Registration/Registration'
import SendCode from './pages/Registration/SendCode'
import TermsOfUse from './pages/TermsOfUse'
import ProtectedRoute from './ProtectedRoute'
import ScrollToTop from './ScrollToTop'
import SidePanel from './SidePanel'
import CreditApp from './CreditApp/CreditApp'
import CheckAuth from './CheckAuth'
import Products from './PageComponents/Products'
import {useProductContext} from '../contexts/ProductContext'
import {routes} from '../api/routes'
import EmailShareDeal from './PageComponents/EmailShareDeal'
import EmailShareConfirmation from './PageComponents/EmailShareConfirmation'
import DocumentUpload from './PageComponents/DocumentUpload'

const Routes = () => {
  const {trade} = useCustomerStateContext()
  const {isLangReady, isPriceLocked} = useFrameContext()
  const {hasProducts} = useProductContext()

  return (
    isLangReady && (
      <ScrollToTop>
        <CheckAuth />
        <Switch>
          <Route exact path="/error">
            <Error />
          </Route>
          <Route exact path="/register">
            <Registration />
          </Route>
          <Route path="/register/matching-lead">
            <MatchingLead />
          </Route>
          <Route path="/register/new-lead-confirmation">
            <NewLeadConfirmation />
          </Route>
          <Route path="/register/send-code">
            <SendCode />
          </Route>
          <Route path={routes.register.paths.verifyCode()}>
            <CodeVerification />
          </Route>
          <Route path="/terms-of-use">
            <TermsOfUse />
          </Route>
          <Route exact path="/side-panel">
            <SidePanel show standAlone />
          </Route>
          <ProtectedRoute exact path="/" override={!isPriceLocked}>
            <Desking />
          </ProtectedRoute>
          <ProtectedRoute exact path="/payment" override={!isPriceLocked}>
            <Desking />
          </ProtectedRoute>
          <ProtectedRoute exact path="/payment-out-of-range">
            <DeskingTradeOutOfRange />
          </ProtectedRoute>
          <ProtectedRoute exact path="/trade">
            {trade ? <TradeComplete /> : <Trade />}
          </ProtectedRoute>
          <ProtectedRoute exact path="/side-panel">
            <SidePanel show standAlone />
          </ProtectedRoute>
          <ProtectedRoute path="/trade-experience">
            <TradeIntegration />
          </ProtectedRoute>
          {hasProducts ? (
            <ProtectedRoute path="/products">
              <Products />
            </ProtectedRoute>
          ) : null}
          <ProtectedRoute exact path="/trade-complete">
            <TradeComplete />
          </ProtectedRoute>
          <ProtectedRoute exact path="/credit">
            <CreditApplication />
          </ProtectedRoute>
          <ProtectedRoute exact path="/is-credit-app">
            <CreditApp />
          </ProtectedRoute>
          <ProtectedRoute exact path="/finalize">
            <Finalize />
          </ProtectedRoute>
          <ProtectedRoute exact path="/credit-complete">
            <CreditComplete />
          </ProtectedRoute>
          <ProtectedRoute exact path="/check-availability">
            <CheckAvailability />
          </ProtectedRoute>
          <ProtectedRoute exact path="/meet">
            <Meet />
          </ProtectedRoute>
          <ProtectedRoute exact path="/schedule">
            <ScheduleTestDrive />
          </ProtectedRoute>
          <ProtectedRoute exact path="/document-upload">
            <DocumentUpload />
          </ProtectedRoute>
          <ProtectedRoute exact path="/email-share">
            <EmailShareDeal />
          </ProtectedRoute>
          <ProtectedRoute exact path="/email-share-confirmation">
            <EmailShareConfirmation />
          </ProtectedRoute>
          {/* 404 */}
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </ScrollToTop>
    )
  )
}

export default Routes

import FieldInput from '../../../Form/Fields/FieldInput'
import PhoneSelect from '../../../Form/Fields/SelectFields/PhoneSelect'
import StateSelect from '../../../Form/Fields/SelectFields/StateSelect'
import MaskedInput from '../../../Form/Fields/MaskedInput'
import MaritalStatusSelect from '../../../Form/Fields/SelectFields/MaritalStatusSelect'

const CustomerFields = {
  id: 'customerInformation',
  title: 'Customer Information',
  rows: [
    {
      row: 1,
      columns: [
        {
          column: 1,
          fields: [
            {
              name: 'firstName',
              label: 'First Name',
              required: true,
              component: FieldInput,
              autoComplete: 'given-name',
            },
          ],
        },
        {
          column: 2,
          fields: [
            {
              name: 'middleName',
              label: 'Middle',
              component: FieldInput,
              autoComplete: 'additional-name',
            },
          ],
        },
      ],
    },
    {
      row: 2,
      columns: [
        {
          column: 1,
          fields: [
            {
              name: 'lastName',
              label: 'Last Name',
              required: true,
              component: FieldInput,
              autoComplete: 'family-name',
            },
          ],
        },
      ],
    },
    {
      row: 3,
      columns: [
        {
          column: 1,
          fields: [
            {
              name: 'dob',
              label: 'Date of Birth',
              required: true,
              component: MaskedInput,
              mask: 'date',
              placeholder: 'MM/DD/YYYY',
              autoComplete: 'bday',
            },
          ],
        },
        {
          column: 2,
          fields: [
            {
              name: 'ssn',
              label: 'SSN',
              required: true,
              component: MaskedInput,
              mask: 'ssn',
              placeholder: '___-__-____',
            },
          ],
        },
      ],
    },
    {
      row: 4,
      columns: [
        {
          column: 1,
          fields: [
            {
              name: 'driverLicenseNumber',
              label: 'Driver License',
              required: true,
              component: FieldInput,
            },
          ],
        },
        {
          column: 2,
          fields: [
            {
              name: 'driverLicenseState',
              label: 'DL State',
              placeholder: 'Select State',
              required: true,
              component: StateSelect,
            },
          ],
        },
      ],
    },
    {
      row: 5,
      columns: [
        {
          column: 1,
          columnWidth: 'sm:tw-col-span-12',
          fields: [
            {
              name: 'email',
              label: 'Email',
              required: true,
              component: FieldInput,
              autoComplete: 'email',
            },
          ],
        },
      ],
    },
    {
      row: 6,
      columns: [
        {
          column: 1,
          columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-3',
          fields: [
            {
              name: 'phone1',
              label: 'Phone',
              required: true,
              component: MaskedInput,
              mask: 'phone',
              autoComplete: 'tel-national',
            },
          ],
        },
        {
          column: 2,
          columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-3',
          fields: [
            {
              name: 'phoneType1',
              label: 'displayNone',
              component: PhoneSelect,
              mask: 'phone',
            },
          ],
        },
      ],
    },
    {
      row: 7,
      columns: [
        {
          column: 1,
          columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-3',
          fields: [
            {
              name: 'phone2',
              component: MaskedInput,
              mask: 'phone',
              placeholder: 'Phone 2',
              autoComplete: 'tel-national',
            },
          ],
        },
        {
          column: 2,
          columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-3',
          fields: [
            {
              name: 'phoneType2',
              component: PhoneSelect,
              mask: 'phone',
              label: 'displayNone',
            },
          ],
        },
      ],
    },
    {
      row: 8,
      columns: [
        {
          column: 1,
          columnWidth: 'sm:tw-col-span-4 lg:tw-col-span-3',
          fields: [
            {
              name: 'maritalStatus',
              label: 'Marital Status',
              required: false,
              component: MaritalStatusSelect,
            },
          ],
        },
      ],
    },
  ],
}

export default CustomerFields

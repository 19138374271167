import {useState} from 'react'

const useForm = (validationSchema, values, callback) => {
  const [errors, setErrors] = useState([])

  const handleSubmit = async (event) => {
    if (event) event.preventDefault()

    setErrors([])

    return validationSchema
      .validate(values, {abortEarly: false})
      .then((values) => {
        callback(values)
      })
      .catch(({inner}) => {
        setErrors(inner)
      })
  }

  const getError = (field) => {
    if (errors) {
      const localError = errors.find((error) => error.path === field)
      if (localError) {
        return localError.message
      }
    }

    return undefined
  }

  const clearError = (field) => {
    // If the error exists for this field, then clear it out when the value of the field changes
    const filteredErrorList = errors.filter((error) => error.path !== field)

    setErrors(filteredErrorList)
  }

  return {handleSubmit, errors, getError, clearError}
}

export default useForm

import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import PlaceholderSpinner from 'Src/components/Spinners/PlaceholderSpinner'
import IconButton from 'Src/components/Buttons/IconButton'

const ResultsIframe = ({open, handleClose = () => undefined, src} = {}) => {
  const [iframeSrc, setIframeSrc] = useState(null)
  const [isIframeLoading, setIsIframeLoading] = useState(true)
  const iframeRef = useRef()

  const srcInjections =
    /**
     * Inject the base domain so relative paths in Ajax requests and links
     * resolve to the correct URLs.
     */
    `<base href="https://snap-api.tradepending.com" target="_blank" />` +
    `<script>` +
    /**
     * TradePending's script attempts to update the "mileage" query param
     * every time the "Update" button is clicked.  Because this is an iframe
     * without an actual URL, the script fails and throws an error.
     * Here, we override the "History.pushState()" function to prevent the
     * script from failing.
     */
    `
      window.history.pushState = () => {};
    ` +
    /**
     * Post-load scripts:
     */
    `
      window.addEventListener('load', () => {
    ` +
    /**
     * Because we are using <base> above to set the domain, it affects page jumplinks
     * as well, and causes them to redirect to the base domain + hash,
     * for example: "https://snap-api.tradepending.com/#calculations"
     * To fix this, we recreate and override the jumplink functionality using JavaScript.
     */
    `
        document
          .querySelectorAll('a[href^="#"]')
          .forEach((element) => {
            element.addEventListener('click', (event) => {
              event.preventDefault()
              const targetElement = document.querySelector(event.target.hash)
              targetElement && targetElement.scrollIntoView({behavior: 'smooth'});
            });
          });
    ` +
    /**
     * Some of the links have the target attribute set to top (target="_top"), so here
     * we change them to open in a blank window instead (target="_blank").
     */
    `
        document
          .querySelectorAll('a[target="_top"]')
          .forEach((element) => {
            element.setAttribute('target', '_blank');
          });
      });
    </script>`

  /**
   * Load the source in the iframe only after "open" is true and only the first time.
   * If the user closes the iframe overlay, and reopens the iframe overlay,
   * the source will not reload in the iframe.
   */
  useEffect(() => {
    if (open && iframeSrc !== src) {
      setIframeSrc(src)
    }
  }, [open, src, iframeSrc, setIframeSrc])

  /**
   * Show the Loading Spinner behind the iframe and remove it when the iframe
   * has finished loading.  Remove the onLoad event listener so that the
   * Loading Spinner only shows before the first onLoad.
   */
  const handleIframeLoad = () => {
    setIsIframeLoading(false)
    iframeRef.current.removeEventListener('onload', handleIframeLoad)
  }

  return open ? (
    <div className="tw-absolute tw-inset-0 tw-bg-brand-body tw-flex tw-flex-col">
      <div className="tw-flex-none tw-flex tw-justify-end">
        <IconButton size={12} onClick={handleClose} data-testid="results-iframe-close-button">
          <FontAwesomeIcon icon={faTimes} className="tw-text-2xl" />
        </IconButton>
      </div>
      <div className="tw-flex-1 tw-flex tw-relative">
        {isIframeLoading ? (
          <div className="tw-absolute tw-inset-0 tw-z-0" data-testid="results-iframe-loading">
            <PlaceholderSpinner />
          </div>
        ) : null}
        <iframe
          ref={iframeRef}
          srcDoc={iframeSrc ? `${srcInjections}${iframeSrc}` : ''}
          onLoad={handleIframeLoad}
          title="TradePending Results"
          className="tw-flex-1 tw-relative tw-z-10"
          data-testid="results-iframe"
          rel="noopener noreferrer"
        />
      </div>
    </div>
  ) : null
}

ResultsIframe.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  src: PropTypes.string,
}

export default ResultsIframe

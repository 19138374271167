import {trans} from '../../../utilities/Helpers'

export const conditionOptions = () => {
  return [
    {
      value: 'fair',
      label: trans('vehicle_condition.fair'),
      description: trans('vehicle_condition.fair_description'),
      text: trans('vehicle_condition.fair_text'),
    },
    {
      value: 'good',
      label: trans('vehicle_condition.good'),
      description: trans('vehicle_condition.good_description'),
      text: trans('vehicle_condition.good_text'),
    },
    {
      value: 'verygood',
      label: trans('vehicle_condition.verygood'),
      description: trans('vehicle_condition.verygood_description'),
      text: trans('vehicle_condition.verygood_text'),
    },
    {
      value: 'excellent',
      label: trans('vehicle_condition.excellent'),
      description: trans('vehicle_condition.excellent_description'),
      text: trans('vehicle_condition.excellent_text'),
    },
  ]
}

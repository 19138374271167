import React from 'react'
import PropTypes from 'prop-types'
import SelectInput from './SelectInput'

const phoneOptions = [
  {
    key: 'home',
    text: 'Home',
  },
  {
    key: 'work',
    text: 'Work',
  },
  {
    key: 'mobile',
    text: 'Mobile',
  },
]

const PhoneSelect = ({name, prefix, label, required = false, groupWidth = 12}) => (
  <SelectInput
    name={name}
    prefix={prefix}
    label={label}
    placeholder="Select Type"
    required={required}
    selectOptions={phoneOptions}
    groupWidth={groupWidth}
  />
)

PhoneSelect.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  groupWidth: PropTypes.number,
}

export default PhoneSelect

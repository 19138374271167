import React from 'react'
import DueAtSigning from './DueAtSigning/DueAtSigning'
import ZipCode from './ZipCode/ZipCode'
import {useDealStateContext} from '../../contexts/DealContext'
import {parseCurrentData} from '../../utilities/ProgramHelpers'

const Cash = () => {
  const dealState = useDealStateContext()
  const {zipCode} = dealState
  const {currentProgram} = parseCurrentData(dealState)
  const {totalDue} = currentProgram
  return (
    <div>
      {totalDue ? <DueAtSigning header="Total Due" dueAtSigning={totalDue} /> : ''}
      {zipCode ? <ZipCode /> : ''}
    </div>
  )
}

export default Cash
